import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { MdClear } from 'react-icons/md';
import { toast } from 'react-toastify';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import InputFile from '../../../../components/Form/Input/File';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table } from '../../../../components/Table';

function EmpresaModeloCarregar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleSubmit = useCallback(async (data) => {
    try {
      const { descricao } = data;

      await api.post('modelo', {
        descricao,
      });

      toast.success('Relacionamento cadastrado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Carregar relacionamento" />
      <FormWrapper>
        <Form
          id="formEmprModelo"
          ref={formRef}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <InputFile
                id="lista"
                name="lista"
                label="Carregar lista de modelo"
                accept=".csv"
              />
            </Form.Row>
          </Form.Column>
          <Table.Wrapper>
            <Table withCollapse>
              <TableHeader
                withPadding
                titles={[
                  { fieldname: null, title: null },
                  { fieldname: 'idEmpresa', title: 'Empresa' },
                  { fieldname: 'idModelo', title: 'Modelo' },
                  { fieldname: 'modeloExt', title: 'Modelo Externo' },
                ]}
                cbSort={() => {}}
              />
              <tbody>
                <Table.RowList>
                  <td>
                    <Table.Action>
                      <Table.BtnRemove type="button" onClick={() => {}}>
                        <MdClear size={15} />
                      </Table.BtnRemove>
                    </Table.Action>
                  </td>
                  <td>1</td>
                  <td>1</td>
                  <td>1.256</td>
                </Table.RowList>
                <Table.RowList>
                  <td>
                    <Table.Action>
                      <Table.BtnRemove type="button" onClick={() => {}}>
                        <MdClear size={15} />
                      </Table.BtnRemove>
                    </Table.Action>
                  </td>
                  <td>1</td>
                  <td>1</td>
                  <td>1.256</td>
                </Table.RowList>
              </tbody>
            </Table>
          </Table.Wrapper>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmprModelo">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EmpresaModeloCarregar;
