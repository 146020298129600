import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

/** cria a persistência dos dados */
export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      /** CATALOGOELETRONICOPECAS */
      key: '44DE759895D3101C275E999AA8C32326265CEE0E191D0D54',
      storage: storageSession,
      // whiteList: ['auth', 'user', 'cart'],
      whiteList: ['auth', 'user', 'param'],
      blacklist: ['cart'],
    },
    reducers
  );

  return persistedReducer;
};
