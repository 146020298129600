import { takeLatest, call, put, all } from 'redux-saga/effects';

import { globParamSuccess, globParamFailure } from './actions';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';

export function* globParam({ payload }) {
  try {
    const { user } = payload;

    const { empresa } = user || {};

    if (empresa && empresa.idEmpresa) {
      const response = yield call(
        api.get,
        `empresa/${empresa.idEmpresa}/glob-param`
      );

      const { globParam: parametros } = response.data;
      yield put(
        globParamSuccess(
          parametros.map(({ chave, valor }) => ({ chave, valor }))
        )
      );
    }
  } catch (error) {
    AppError(
      'Falha ao carregar os parâmetros da empresa. Entre em contato com o suporte.'
    );

    yield put(globParamFailure());
  }
}

export default all([takeLatest('@auth/SIGN_IN_SUCCESS', globParam)]);
