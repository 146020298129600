import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import api from '../../../../services/api';
import history from '../../../../services/history';

import {
  labelExtExtra01,
  labelExtExtra02,
  labelExtExtra03,
  labelExtExtra04,
} from '../../../../lib/paramUtils';

import TitleBar from '../../../../components/TitleBar';

import Pagination from '../../../../components/EditorAI/Pagination';
import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import InputViewer from '../../../../components/Form/Input/Viewer';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';

import { Container, Wrapper } from './styles';

function EmpresaRetiradaDetalhe() {
  const location = useLocation();
  const { idEmpresa, idSolicitacao } = location.state;

  const formRef = useRef(null);

  const [retirada, setRetirada] = useState(null);
  const [itensRetirada, setItensRetirada] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  /** pesquisa */
  const [search, setSearch] = useState(null);

  /** sincroniza rótulos */
  const rotuloExtExtra01 = useMemo(() => labelExtExtra01(), []);
  const rotuloExtExtra02 = useMemo(() => labelExtExtra02(), []);
  const rotuloExtExtra03 = useMemo(() => labelExtExtra03(), []);
  const rotuloExtExtra04 = useMemo(() => labelExtExtra04(), []);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/solicitacao/${idSolicitacao}`)
      .then((response) => {
        const { instInc, dataDesa } = response.data.solicitacao;
        setRetirada({
          ...response.data.solicitacao,
          instInc: format(parseISO(instInc), 'dd/MM/yyyy HH:mm:ss'),
          dataDesa: dataDesa
            ? format(parseISO(dataDesa), 'dd/MM/yyyy HH:mm:ss')
            : null,
        });
      });
  }, [idEmpresa, idSolicitacao]);

  useEffect(() => {
    /** verifica campos de pesquisa */
    const query = search || null;

    api
      .get(`empresa/${idEmpresa}/solicitacao/${idSolicitacao}/item`, {
        params: {
          page: currentPage,
          q: query,
        },
      })
      .then((response) => {
        setItensRetirada(response.data.itemSolicitacao);
        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (currentPage > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [idEmpresa, idSolicitacao, currentPage, search]);

  const handleVisualizar = useCallback(
    (e, idItemSolicitacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push(`/empresa/retirada/visualizar`, {
          background: location,
          idEmpresa,
          idSolicitacao,
          idItemSolicitacao,
        });
      }
    },
    [location, idEmpresa, idSolicitacao]
  );

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      <Wrapper>
        <Form initialData={retirada}>
          <Form.Row>
            <InputViewer
              id="idSolicitacao"
              name="idSolicitacao"
              label="Solicitação"
            />
            <InputViewer
              id="solicitante.nome"
              name="solicitante.nome"
              label="Solicitante"
            />
            <InputViewer id="instInc" name="instInc" label="Data" />
          </Form.Row>
          <h3>Cancelamento</h3>
          <Form.Row>
            <InputViewer id="dataDesa" name="dataDesa" label="Data" />
            <InputViewer
              id="cancelamento.nome"
              name="cancelamento.nome"
              label="Responsável"
            />
          </Form.Row>
          <h3>Mobilidade</h3>
          <Form.Row>
            <InputViewer
              id="mobileColetor"
              name="mobileColetor"
              label="Coletor"
            />
            <InputViewer id="mobileIMEI" name="mobileIMEI" label="IMEI" />
          </Form.Row>
          <h3>Integração</h3>
          <Form.Row>
            <InputViewer
              id="extCodiDiv2"
              name="extCodiDiv2"
              label="Ext. Divisão"
            />
            <InputViewer id="extNumeOS" name="extNumeOS" label="Ext. Nº OS" />
            <InputViewer
              id="extUsuario"
              name="extUsuario"
              label="Ext. Usuário"
            />
            <InputViewer
              id="token"
              name="token"
              label="Token"
              type="textarea"
            />
          </Form.Row>
          <Form.Row>
            <InputViewer
              id="extExtra01"
              name="extExtra01"
              label={rotuloExtExtra01}
            />
            <InputViewer
              id="extExtra02"
              name="extExtra02"
              label={rotuloExtExtra02}
            />
            <InputViewer
              id="extExtra03"
              name="extExtra03"
              label={rotuloExtExtra03}
            />
            <InputViewer
              id="extExtra04"
              name="extExtra04"
              label={rotuloExtExtra04}
            />
          </Form.Row>
          <Form.Row>
            <InputViewer
              id="extObservacao"
              name="extObservacao"
              label="Ext. Observação"
              type="textarea"
            />
          </Form.Row>
        </Form>
      </Wrapper>

      <h2>Itens da solitação</h2>
      <Form
        ref={formRef}
        onSubmit={(data) => setSearch(data.search)}
        autoComplete="off"
      >
        <Input
          id="search"
          name="search"
          type="text"
          placeholder="Digite para pesquisar..."
          width={300}
          onChange={() => formRef.current.submitForm()}
        />
      </Form>

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idItemSolicitacao', title: 'Item' },
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'codimate', title: 'Cód. Material ERP' },
            { fieldname: 'extItemOS', title: 'Ext. Item OS' },
            { fieldname: 'quantidade', title: 'Quantidade' },
            { fieldname: 'situacao', title: 'Situação' },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {itensRetirada.map((item) => (
            <RowMaster
              key={item.idItemSolicitacao}
              onClick={(e) => handleVisualizar(e, item.idItemSolicitacao)}
            >
              <td>{item.idItemSolicitacao}</td>
              <td>{item.materialFabricante.partnumber}</td>
              <td>{item.materialERP ? item.materialERP.codimate : ''}</td>
              <td>{item.extItemOS}</td>
              <td>{item.quantidade}</td>
              <td>{item.dataDesa ? 'Cancelado' : 'Solicitado'}</td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </Container>
  );
}

export default EmpresaRetiradaDetalhe;
