import styled, { css } from 'styled-components';

export const IconPopupWrapper = styled.div`
  position: absolute;
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  /* z-index: 87; */

  ${(props) =>
    props.leftIcon &&
    css`
      left: ${props.leftIcon};
    `}

  ${(props) =>
    props.topIcon &&
    css`
      top: ${props.topIcon};
    `}

    ${(props) =>
    props.rightIcon &&
    css`
      right: ${props.rightIcon};
    `}

    ${(props) =>
    props.bottomIcon &&
    css`
      bottom: ${props.bottomIcon};
    `}
`;

export const Icon = styled.span`
  position: absolute;
  color: #2c405a;
`;

export const Container = styled.div`
  position: absolute;
  background: rgba(44, 64, 90, 0.95);
  color: #fff;
  display: flex;
  flex: 1;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  top: 10px;
  left: 15px;
  animation: fadein 0.3s;
  z-index: 99;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

Container.Wrapper = styled.div`
  width: auto;
  height: auto;
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;

  #grey-italic-span {
    color: #a1a1aa;
    font-size: 12px;
    font-style: italic;
  }

  #grey-italic-padding-span {
    padding: 10px;
    color: #a1a1aa;
    font-size: 12px;
    font-style: italic;
  }

  #white-bold-span {
    color: #f4f4f5;
    font-size: 16px;
    font-style: normal;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > span {
      min-width: max-content;
    }

    > strong {
      font-weight: bold;
    }
  }
`;
