import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

import api from "../../../services/api";
import { AppError } from "../../../errors/AppError";
import { toast } from 'react-toastify';

import Checkbox from '@mui/material/Checkbox';

import { 
  RightContainer, 
  LeftContainer, 
  InfoItemBox, 
  ListItensBox, 
  Wrapper, 
  Label, 
  BtnDisplay,
  LoadingBox
} from "./styles"
import TitleBar from "../../../components/TitleBar"
import { Form } from "../../../components/Form"
import Input from "../../../components/Form/Input";
import Switch from "../../../components/Form/Input/Switch";
import { opConferencia, opConferenciaAtualNovo, opSalvarRascunhoConfirmar } from "../../../lib/const";

import { GiSandsOfTime } from "react-icons/gi";

const FILTRO = {
  todos: 'todos',
  conferir: 'conferir',
};

function CatalogoConferenciaItens() {

  const formRef = useRef();

  const observerTarget = useRef(null)

  const location = useLocation();
  const { idCatalogo } = location.state;

  const [selectedItem, setSelectedItem] = useState(null)
  const [catalogo, setCatalogo] = useState()
  const [mergeMaterialFabricante, setMergeMaterialFabricante] = useState([])
  const [view, setView] = useState(FILTRO.todos);
  const [itensConferidos, setItensConferidos] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState()

  const carregaPagina = useCallback((config) => {
    api.get(`/merge-material-fabricante/catalogo/${idCatalogo}`, { params: config })
      .then((response) => {
        const { mergeMaterialFabricante, catalogo, total, pagAtual } = response.data

        setMergeMaterialFabricante(mergeMaterialFabricante)

        setCatalogo(catalogo)

        setTotalPage(total)
        setCurrentPage(pagAtual)
      })
  }, [idCatalogo])

  useEffect(() => {
    const itemSelecionado = mergeMaterialFabricante.find(i => i.idMaterialBase === selectedItem) || {}

    if (formRef.current && currentPage === totalPage) {
      formRef.current.setFieldValue('partnumberAtual', itemSelecionado.materialBase.partnumber)
      formRef.current.setFieldValue('idMaterialAtual', itemSelecionado.materialBase.idMaterial)
      formRef.current.setFieldValue('descricaoBRAtual', itemSelecionado.materialBase.descricaoBR)
      formRef.current.setFieldValue('descricaoENAtual', itemSelecionado.materialBase.descricaoEN)

      formRef.current.setFieldValue('partnumberNovo', itemSelecionado.materialCopia.partnumber)
      formRef.current.setFieldValue('idMaterialNovo', itemSelecionado.materialCopia.idMaterial)
      formRef.current.setFieldValue('descricaoBRNovo', itemSelecionado.materialCopia.descricaoBR)
      formRef.current.setFieldValue('descricaoENNovo', itemSelecionado.materialCopia.descricaoEN)

      if (itemSelecionado.status === opConferencia.CONFERIDO && itemSelecionado.escolha === opConferenciaAtualNovo.ATUAL) {
        formRef.current.setFieldValue('partnumberAtual', itemSelecionado.partnumberNovo)
        formRef.current.setFieldValue('idMaterialAtual', itemSelecionado.idMaterialBase)
        formRef.current.setFieldValue('descricaoBRAtual', itemSelecionado.descricaoBRNovo)
        formRef.current.setFieldValue('descricaoENAtual', itemSelecionado.descricaoENNovo)
      }

      if (itemSelecionado.status === opConferencia.CONFERIDO && itemSelecionado.escolha === opConferenciaAtualNovo.NOVO) {
        formRef.current.setFieldValue('partnumberNovo', itemSelecionado.partnumberNovo)
        formRef.current.setFieldValue('idMaterialNovo', itemSelecionado.idMaterialCopia)
        formRef.current.setFieldValue('descricaoBRNovo', itemSelecionado.descricaoBRNovo)
        formRef.current.setFieldValue('descricaoENNovo', itemSelecionado.descricaoENNovo)
      }

      if (itensConferidos.length > 0 && itemSelecionado.escolha === opConferenciaAtualNovo.ATUAL) {
        const itemConferido = itensConferidos.find(i => Number(i.idMaterial) === Number(itemSelecionado.idMaterialBase))
        const atualizarMaterialConferido = mergeMaterialFabricante.find(mat => Number(mat.idMaterialBase) === Number(itemConferido.idMaterial))

        atualizarMaterialConferido.idMaterialCopia = itemConferido.idMaterial
        atualizarMaterialConferido.partnumberNovo = itemConferido.partnumber
        atualizarMaterialConferido.descricaoBRNovo = itemConferido.descricaoBR
        atualizarMaterialConferido.descricaoENNovo = itemConferido.descricaoEN

        formRef.current.setFieldValue('partnumberAtual', atualizarMaterialConferido.partnumberNovo)
        formRef.current.setFieldValue('idMaterialAtual', atualizarMaterialConferido.idMaterialBase)
        formRef.current.setFieldValue('descricaoBRAtual', atualizarMaterialConferido.descricaoBRNovo)
        formRef.current.setFieldValue('descricaoENAtual', atualizarMaterialConferido.descricaoENNovo)
      }

      if (itensConferidos.length > 0 && itemSelecionado.escolha === opConferenciaAtualNovo.NOVO) {
        const itemConferido = itensConferidos.find(i => Number(i.idMaterial) === Number(itemSelecionado.idMaterialCopia))
        const atualizarMaterialConferido = mergeMaterialFabricante.find(mat => Number(mat.idMaterialCopia) === Number(itemConferido.idMaterial))

        atualizarMaterialConferido.idMaterialCopia = itemConferido.idMaterial
        atualizarMaterialConferido.partnumberNovo = itemConferido.partnumber
        atualizarMaterialConferido.descricaoBRNovo = itemConferido.descricaoBR
        atualizarMaterialConferido.descricaoENNovo = itemConferido.descricaoEN

        formRef.current.setFieldValue('partnumberNovo', atualizarMaterialConferido.partnumberNovo)
        formRef.current.setFieldValue('idMaterialNovo', atualizarMaterialConferido.idMaterialCopia)
        formRef.current.setFieldValue('descricaoBRNovo', atualizarMaterialConferido.descricaoBRNovo)
        formRef.current.setFieldValue('descricaoENNovo', atualizarMaterialConferido.descricaoENNovo)
      }
    }
  }, [currentPage, totalPage, itensConferidos, mergeMaterialFabricante, selectedItem])

  const handleAlterarItemChecagem = useCallback(
    (e, atual, idMaterial, checked) => {
      e.stopPropagation();

      const isConferido = itensConferidos.find(i => Number(i.idMaterial) === Number(idMaterial))

      if (atual) {
        const itemAtualChecked = mergeMaterialFabricante.find(i => i.idMaterialBase === idMaterial)

        itemAtualChecked.escolha = opConferenciaAtualNovo.ATUAL

        const fmtItemAtualChecked = {
          idMaterial: String(itemAtualChecked.idMaterialBase),
          partnumber: String(itemAtualChecked.materialBase.partnumber),
          descricaoBR: itemAtualChecked.materialBase.descricaoBR || null,
          descricaoEN: itemAtualChecked.materialBase.descricaoEN || null,
          escolha: opConferenciaAtualNovo.ATUAL
        }

        if (isConferido) {
          setItensConferidos(prevState => prevState.filter(i => Number(i.idMaterial) !== Number(idMaterial)))

          if (checked === false) {
            itemAtualChecked.escolha = null
          } else {
            itemAtualChecked.escolha = opConferenciaAtualNovo.ATUAL
            setItensConferidos(prevState => [...prevState, fmtItemAtualChecked])
          }
        } else {
          setItensConferidos(prevState => [...prevState, fmtItemAtualChecked])
        }
      }

      if (!atual) {
        const itemNovoChecked = mergeMaterialFabricante.find(i => i.idMaterialCopia === idMaterial)

        itemNovoChecked.escolha = opConferenciaAtualNovo.NOVO

        const fmtItemNovoChecked = {
          idMaterial: String(itemNovoChecked.idMaterialCopia),
          partnumber: String(itemNovoChecked.materialCopia.partnumber),
          descricaoBR: itemNovoChecked.materialCopia.descricaoBR || null,
          descricaoEN: itemNovoChecked.materialCopia.descricaoEN || null,
          escolha: opConferenciaAtualNovo.NOVO
        }

        if (isConferido) {
          setItensConferidos(prevState => prevState.filter(i => Number(i.idMaterial) !== Number(idMaterial)))

          if (checked === false) {
            itemNovoChecked.escolha = null
          } else {
            itemNovoChecked.escolha = opConferenciaAtualNovo.NOVO
            setItensConferidos(prevState => [...prevState, fmtItemNovoChecked])
          }
        } else {
          setItensConferidos(prevState => [...prevState, fmtItemNovoChecked])
        }
      }
    },
    [mergeMaterialFabricante, itensConferidos]
  );

  const handleSelecionaTodos = useCallback((checked, lista) => {
    if (checked) {
      const fmtListaItemChecked = mergeMaterialFabricante.map((mat) => {
        const materialLista = lista === 'atual' ? mat.materialBase : mat.materialCopia

        return {
          idMaterial: lista === 'atual' ? Number(mat.idMaterialBase) : Number(mat.idMaterialCopia),
          partnumber: String(materialLista.partnumber),
          descricaoBR: materialLista.descricaoBR === null ? null : String(materialLista.descricaoBR),
          descricaoEN: materialLista.descricaoEN === null ? null : String(materialLista.descricaoEN),
          escolha: lista === 'atual' ? opConferenciaAtualNovo.ATUAL : opConferenciaAtualNovo.NOVO
        }
      })

      setItensConferidos(fmtListaItemChecked)

      mergeMaterialFabricante.forEach(i => i.escolha = lista === 'atual' ? opConferenciaAtualNovo.ATUAL : opConferenciaAtualNovo.NOVO)
    } else {
      setItensConferidos([])

      mergeMaterialFabricante.forEach(i => i.escolha = null)
    }
  }, [mergeMaterialFabricante])

  const handleCloseDisplay = useCallback(() => {
    setSelectedItem(null)
  }, [])

  const handleSalvarRascunhoConfirmar = useCallback(async (tipo) => {
    try {
      await api.put(`/merge-material-fabricante/catalogo/${idCatalogo}`, {
        tipo,
        itensConferidos
      })

      toast.success('Itens conferidos com sucesso!')

      setItensConferidos([])

      setView(FILTRO.todos)

      carregaPagina()
    } catch (error) {
      throw new AppError(error)
    }
  }, [idCatalogo, itensConferidos, carregaPagina])

  const handleSalvarItem = useCallback((lista) => {
    const idMaterial = lista === 'atual'
      ? formRef.current.getFieldValue('idMaterialAtual')
      : formRef.current.getFieldValue('idMaterialNovo')
    const partnumber = lista === 'atual'
      ? formRef.current.getFieldValue('partnumberAtual')
      : formRef.current.getFieldValue('partnumberNovo')
    const descricaoBR = lista === 'atual'
      ? formRef.current.getFieldValue('descricaoBRAtual')
      : formRef.current.getFieldValue('descricaoBRNovo')
    const descricaoEN = lista === 'atual'
      ? formRef.current.getFieldValue('descricaoENAtual')
      : formRef.current.getFieldValue('descricaoENNovo')

    const fmtItem = {
      idMaterial,
      partnumber,
      descricaoBR,
      descricaoEN,
      escolha: lista === 'atual' ? opConferenciaAtualNovo.ATUAL : opConferenciaAtualNovo.NOVO
    }

    const itemConferido = itensConferidos.find(i => Number(i.idMaterial) === Number(fmtItem.idMaterial))

    if (itemConferido) {
      itemConferido.idMaterial = fmtItem.idMaterial
      itemConferido.partnumber = fmtItem.partnumber
      itemConferido.descricaoBR = fmtItem.descricaoBR
      itemConferido.descricaoEN = fmtItem.descricaoEN

      toast.success('Item conferido atualizado com sucesso!');
    }

    if (!itemConferido) {
      const itemIndex = mergeMaterialFabricante.findIndex(i => Number(i.idMaterialBase) === Number(idMaterial))
      mergeMaterialFabricante[itemIndex].escolha = lista === 'atual' ? opConferenciaAtualNovo.ATUAL : opConferenciaAtualNovo.NOVO

      setItensConferidos(prevState => [...prevState, fmtItem])

      toast.success('Item conferidos com sucesso!');
    }
  }, [mergeMaterialFabricante, itensConferidos])

  useEffect(() => {
    if (currentPage === totalPage) {
      const tableAtual = document.getElementsByClassName("tableAtual")[0]
      const tableNovo = document.getElementsByClassName("tableNovo")[0]
  
      const tableAtualRows = tableAtual.querySelectorAll("tr:not(.table-header)");
      const tableNovoRows = tableNovo.querySelectorAll("tr:not(.table-header)");
  
      for (let i = 0; i < tableAtualRows.length; i++) {
        const tableAtualRow = tableAtualRows[i];
        const tableNovoRow = tableNovoRows[i];
  
        tableAtualRow.addEventListener("mouseover", function (e) {
          tableAtualRow.classList.add("hover-class");
          tableNovoRow.classList.add("hover-class");
        })
  
        tableAtualRow.addEventListener("mouseleave", function (e) {
          tableAtualRow.classList.remove("hover-class");
          tableNovoRow.classList.remove("hover-class");
        })
  
        tableNovoRow.addEventListener("mouseover", function (e) {
          tableAtualRow.classList.add("hover-class");
          tableNovoRow.classList.add("hover-class");
        })
  
        tableNovoRow.addEventListener("mouseleave", function (e) {
          tableAtualRow.classList.remove("hover-class");
          tableNovoRow.classList.remove("hover-class");
        })
      }
    }
  }, [currentPage, totalPage, mergeMaterialFabricante])

  const changeView = useCallback(() => {
    handleCloseDisplay()
    setView((prevState) => (prevState === FILTRO.todos ? FILTRO.conferir : FILTRO.todos));
  }, [handleCloseDisplay]);

  const carregaItensObserver = useCallback(() => {
    if (currentPage < totalPage) {
      api.get(`/merge-material-fabricante/catalogo/${idCatalogo}`, { params: {
        page: currentPage + 1
      } })
      .then((response) => {
        const { mergeMaterialFabricante, pagAtual } = response.data

        setMergeMaterialFabricante((prevState) => [...prevState, ...mergeMaterialFabricante])

        setCurrentPage(pagAtual)
      })
    }
  }, [currentPage, totalPage, idCatalogo])

  useEffect(() => {
    if (view === FILTRO.conferir) {
      carregaPagina({ q: opConferencia.NAO_CONFERIDO })
    }
    if (view === FILTRO.todos) {
      carregaPagina()
    }
  }, [view, carregaPagina])

  useEffect(() => {
    const observerCurrent = observerTarget.current

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        carregaItensObserver()
      }
    }, { threshold: 0 })
    
    if (observerCurrent) {
      observer.observe(observerCurrent)
    }

  }, [observerTarget, carregaItensObserver])

  return (
    <>

      <TitleBar
        back
        title="Conferência de Itens"
        rowActionReverse
        onSalvarRascunho={() => handleSalvarRascunhoConfirmar(opSalvarRascunhoConfirmar.SALVAR_RASCUNHO)}
        onConfirm={() => handleSalvarRascunhoConfirmar(opSalvarRascunhoConfirmar.CONFIRMAR)}
      >
        <Switch
          margin={'0 15px 0 0'}
          rigthText="A Conferir"
          checked={view === FILTRO.conferir}
          onChange={changeView}
        />
      </TitleBar>

      {currentPage === totalPage ? (
        <Wrapper>

        <LeftContainer>

          {catalogo && (
            <div className="columnSpace">

              <div>
                <h3>{catalogo.nome}</h3>
                <p>{catalogo.arquivo.fabricante.descricao}</p>
                <p>ID {catalogo.idCatalogo}</p>
              </div>

              <div className="counter">
                <p>{mergeMaterialFabricante.filter(i => i.status === 1).length} de {mergeMaterialFabricante.length}</p>
              </div>

            </div>
          )}

        </LeftContainer>

        <RightContainer>

          <div className="titleColuns">

            <div className="wrapperCheckTitle">
              <Checkbox
                id={`atual`}
                name={`atual`}
                disableRipple
                style={{ width: '0', height: '0' }}
                onChange={(e) => {
                  handleSelecionaTodos(e.target.checked, 'atual');
                }}
              />
              <h3>ATUAL ({mergeMaterialFabricante.length})</h3>
            </div>

            <div className="wrapperCheckTitle">
              <Checkbox
                id={`novo`}
                name={`novo`}
                disableRipple
                style={{ width: '0', height: '0' }}
                onChange={(e) => {
                  handleSelecionaTodos(e.target.checked, 'novo');
                }}
              />
              <h3>NOVO ({mergeMaterialFabricante.length})</h3>
            </div>  

          </div>

            <ListItensBox>

            <div className="leftItens">

              <table className="tableAtual">
                <thead>
                  <tr className="table-header">
                    <th width={"35px"}></th>
                    <th width={"60px"}>Material</th>
                    <th width={"300px"}>Descricao BR</th>
                  </tr>
                </thead>
                <tbody className="body-atual">
                  {mergeMaterialFabricante.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => setSelectedItem(Number(item.idMaterialBase))}
                      className={selectedItem === Number(item.idMaterialBase) ? "selected-item" : ""}
                    >
                      <td>
                        <Checkbox
                          id={`item-${index}`}
                          name={`item-${index}`}
                          checked={
                            (item.escolha === opConferenciaAtualNovo.ATUAL && item.status === opConferencia.CONFERIDO) ||
                            (item.escolha === opConferenciaAtualNovo.ATUAL && item.status === opConferencia.NAO_CONFERIDO)
                          }
                          disableRipple
                          style={{ width: '0', height: '0' }}
                          onChange={(e) => {
                            handleAlterarItemChecagem(e, true, item.idMaterialBase, e.target.checked);
                          }}
                        />
                      </td>
                      <td>{item.idMaterialBase}</td>
                      <td>{item.materialBase.descricaoBR}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div >

            <div className="rightItens">

              <table className="tableNovo">
                <thead>
                  <tr className="table-header">
                    <th width={"35px"}></th>
                    <th width={"60px"}>Material</th>
                    <th width={"300px"}>Descricao BR</th>
                  </tr>
                </thead>
                <tbody className="body-novo">
                  {mergeMaterialFabricante.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => setSelectedItem(Number(item.idMaterialCopia))}
                      className={selectedItem === Number(item.idMaterialCopia) ? "selected-item" : ""}
                    >
                      <td>
                        <Checkbox
                          id={`item-${index}`}
                          name={`item-${index}`}
                          checked={
                            (item.escolha === opConferenciaAtualNovo.NOVO && item.status === opConferencia.CONFERIDO) ||
                            (item.escolha === opConferenciaAtualNovo.NOVO && item.status === opConferencia.NAO_CONFERIDO)
                          }
                          disableRipple
                          style={{ width: '0', height: '0' }}
                          onChange={(e) => {
                            handleAlterarItemChecagem(e, false, item.idMaterialCopia, e.target.checked);
                          }}
                        />
                      </td>
                      <td>{item.idMaterialCopia}</td>
                      <td>{item.materialCopia.descricaoBR}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </ListItensBox>

          {selectedItem && (

            <Form
              id="formConferencia"
              ref={formRef}
              onSubmit={() => { }}
              initialData={selectedItem}
              autoComplete="off"
            >
              <InfoItemBox>

                <Label gridArea={"1 / 1 / 1 / 2"}>Partnumber</Label>
                <Input
                  withoutWrapper
                  id="partnumberAtual"
                  name="partnumberAtual"
                  style={{ gridArea: "1 / 2 / 1 / 4" }}
                />
                <Label gridArea={"2 / 1 / 2 / 2"}>Item ID</Label>
                <Input
                  withoutWrapper
                  id="idMaterialAtual"
                  name="idMaterialAtual"
                  style={{ gridArea: "2 / 2 / 2 / 4" }}
                />
                <Label gridArea={"3 / 1 / 3 / 2"}>Descricao BR</Label>
                <Input
                  withoutWrapper
                  id="descricaoBRAtual"
                  name="descricaoBRAtual"
                  style={{ gridArea: "3 / 2 / 3 / 4" }}
                />
                <Label gridArea={"4 / 1 / 4 / 2"}>Descricao EN</Label>
                <Input
                  withoutWrapper
                  id="descricaoENAtual"
                  name="descricaoENAtual"
                  style={{ gridArea: "4 / 2 / 4 / 4" }}
                />

                <BtnDisplay gridArea={"6 / 1 / 6 / 1"} onClick={handleCloseDisplay}>Fechar</BtnDisplay>

                <BtnDisplay gridArea={"6 / 3 / 6 / 3"} isSave onClick={() => handleSalvarItem('atual')}>Salvar</BtnDisplay>

                <Label gridArea={"1 / 4 / 1 / 4"}>Partnumber</Label>
                <Input
                  withoutWrapper
                  id="partnumberNovo"
                  name="partnumberNovo"
                  style={{ gridArea: "1 / 5 / 1 / 7" }}
                />
                <Label gridArea={"2 / 4 / 2 / 4"}>Item ID</Label>
                <Input
                  withoutWrapper
                  id="idMaterialNovo"
                  name="idMaterialNovo"
                  style={{ gridArea: "2 / 5 / 2 / 7" }}
                />
                <Label gridArea={"3 / 4 / 3 / 4"}>Descricao BR</Label>
                <Input
                  withoutWrapper
                  id="descricaoBRNovo"
                  name="descricaoBRNovo"
                  style={{ gridArea: "3 / 5 / 3 / 7" }}
                />
                <Label gridArea={"4 / 4 / 4 / 4"}>Descricao EN</Label>
                <Input
                  withoutWrapper
                  id="descricaoENNovo"
                  name="descricaoENNovo"
                  style={{ gridArea: "4 / 5 / 4 / 7" }}
                />

                <BtnDisplay gridArea={"6 / 6 / 6 / 6"} isSave onClick={() => handleSalvarItem('novo')}>Salvar</BtnDisplay>

              </InfoItemBox>
            </Form>

          )}

        </RightContainer >

      </Wrapper>
      ) : (
        <LoadingBox>
          <GiSandsOfTime size={30} />
        </LoadingBox>
      )}

      {currentPage < totalPage && (
        <div ref={observerTarget}></div>
      )}

    </>
  )
}

export { CatalogoConferenciaItens }