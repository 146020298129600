import React, { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { getNivelClassificacao } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';

function CatalogoNivelCriar() {
  const location = useLocation();
  const { idCatalogo } = location.state;

  const formRef = useRef();

  const handleConfirmar = useCallback(
    async (data) => {
      const { nivel, codigo, descricaoBR, descricaoEN } = data;

      try {
        await api.post(`/catalogo/${idCatalogo}/nivel`, {
          nivel,
          codigo,
          descricaoBR,
          descricaoEN,
        });
        history.goBack();

        toast.success('Nível adicionado com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idCatalogo]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Novo nível" />

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleConfirmar}
          initialData={{}}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <AsyncSelect
                id="nivel"
                name="nivel"
                label="Nível"
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) =>
                  getNivelClassificacao({ id: idCatalogo, value }, cb)
                }
              />
              <Input id="codigo" name="codigo" label="Código" />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricaoBR"
                name="descricaoBR"
                label="Descrição (BR)"
              />
              <Input
                id="descricaoEN"
                name="descricaoEN"
                label="Descrição (EN)"
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default CatalogoNivelCriar;
