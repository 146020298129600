import produce from 'immer';

import { opTipoAcao } from '../../../lib/const';

const INITIAL_STATE = {
  token: null,
  administrator: null,
  signed: false,
  loading: false,
  app: false,
  action: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_TOKEN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        const { token, user, app } = action.payload;
        const { userId = null, adm } = user || {};

        draft.token = token;
        draft.loading = false;
        draft.action = action.payload.action;

        /** verifica o tipo de ação e se o usuário existe */
        draft.signed = Boolean(
          [
            opTipoAcao.PLATAFORMA,
            opTipoAcao.INTEGRACAO_CATALOGO,
            opTipoAcao.INTEGRACAO_CONSULTA,
            opTipoAcao.INTEGRACAO_SOLICITACAO,
          ].includes(action.payload.action) && user
        );

        /** verifica se existe usuário autenticado para determinar parâmetros */
        if (userId) {
          draft.app = app;
          draft.administrator = adm;
        }
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.administrator = null;
        draft.signed = false;
        draft.app = false;
        draft.action = null;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.token = null;
        draft.loading = false;
        draft.signed = false;
        break;
      }
      default:
    }
  });
}
