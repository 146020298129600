import styled from 'styled-components';
import { darken } from 'polished';

export const Action = styled.div`
  display: flex;
  align-items: center;
`;

Action.BtnSquare = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border: 0;
  border-radius: 5px;
  height: 42px;
  width: 50px !important;

  &.btn-nav-ref {
    background: none;
    color: #cbd0d8;
    transition: all 1s;

    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
      /* transform: scale(1.1); */
    }
  }
`;
