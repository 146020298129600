/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useLocation } from 'react-router-dom';
import { MdLink } from 'react-icons/md';
import { RiAddBoxFill } from 'react-icons/ri';
import PropTypes from 'prop-types';

import history from '../../../services/history';

import { EditButton, LinkButton } from './Actions';

import { Table, HeaderOptions, TableRow, Wrapper } from './styles';

const TableEditor = forwardRef(
  (
    { idCatalogo, idPagina, itensPagina, isDraggable, onLinkHighlight },
    ref
  ) => {
    const location = useLocation();

    const [table, setTable] = useState([]);

    useEffect(() => {
      if (itensPagina) {
        setTable(
          itensPagina.map((item) => ({
            ...item,
            ...item.materialFabricante,
          }))
          // .sort((a, b) => a.ordena - b.ordena)
        );
      }
    }, [itensPagina]);

    /** define a propriedade de destaque do item de acordo com o estado fornecido */
    const handleHighlight = useCallback((id, state) => {
      setTable((old) =>
        old.map((item) =>
          item.idItem === id
            ? { ...item, highlighted: item.locked || state }
            : item
        )
      );
    }, []);

    const handleLock = useCallback((id, state) => {
      setTable((old) =>
        old.map((item) =>
          item.idItem === id
            ? {
                ...item,
                highlighted: state || item.highlighted,
                locked: state,
              }
            : item
        )
      );
    }, []);

    useImperativeHandle(ref, () => ({
      getLockStates: () =>
        table.map(
          (item) =>
            ({
              idItem: item.idItem,
              locked: item.locked,
            } || null)
        ),

      highlightLinks: (links) => {
        setTable((old) =>
          old.map((item) => {
            if (item.linked && (links.includes(item.idItem) || item.locked)) {
              item.highlighted = true;
            } else {
              item.highlighted = false;
            }
            return item;
          })
        );
      },
    }));

    const handleDraggable = useCallback(
      (e) => {
        const row = table.find((i) => String(i.idItem) === String(e.target.id));
        e.dataTransfer.setData('text/row-list', JSON.stringify(row));
      },
      [table]
    );

    const handleAdicionarItem = useCallback(() => {
      history.push('/catalogo/itens/criar', {
        background: location,
        idCatalogo,
        idPagina,
      });
    }, [idCatalogo, idPagina, location]);

    const handleLockHighlight = useCallback(
      (id) => {
        const link = table.find((_item) => _item.idItem === id);
        if (link) handleLock(id, !link.locked);
      },
      [table, handleLock]
    );

    const handleHoverHighlight = useCallback(
      (id) => {
        const link = table.find((_item) => _item.idItem === id);
        if (link) handleHighlight(id, !link.highlighted);
      },
      [table, handleHighlight]
    );

    useEffect(() => {
      const highlightedItems = table
        .filter((item) => item.linked && (item.highlighted || item.locked))
        .map((item) => item.idItem);

      onLinkHighlight(highlightedItems, 'table');
    }, [table, onLinkHighlight]);

    return (
      <>
        <Wrapper>
          <Table>
            <thead>
              <tr>
                {onLinkHighlight && <th>Link</th>}
                <th>Item</th>
                <th>Código</th>
                <HeaderOptions>
                  <button
                    type="button"
                    title="Adicionar item"
                    onClick={handleAdicionarItem}
                  >
                    <RiAddBoxFill size={22} />
                  </button>
                </HeaderOptions>
              </tr>
            </thead>
            <tbody>
              {table.map((item, index) => (
                <TableRow
                  key={index}
                  id={item.idItem}
                  draggable={isDraggable}
                  linked={item.linked}
                  highlighted={item.highlighted || item.locked}
                  onDragStart={handleDraggable}
                  onMouseEnter={(e) => {
                    if (
                      item.linked &&
                      e.target.matches(':hover') &&
                      !item.highlighted
                    )
                      handleHoverHighlight(item.idItem);
                  }}
                  onMouseLeave={(e) => {
                    if (
                      item.linked &&
                      !e.target.matches(':hover') &&
                      item.highlighted
                    )
                      handleHoverHighlight(item.idItem);
                  }}
                >
                  {onLinkHighlight && (
                    <td>
                      {item.linked && (
                        <LinkButton
                          handleItem={() => handleLockHighlight(item.idItem)}
                        >
                          <MdLink className="linkSvg" size={20} />
                        </LinkButton>
                      )}
                    </td>
                  )}
                  <td>{item.idItem}</td>
                  <td>{item.partnumber}</td>
                  <td>
                    <EditButton
                      idCatalogo={idCatalogo}
                      idPagina={idPagina}
                      item={item}
                    />
                  </td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </Wrapper>
      </>
    );
  }
);

export default TableEditor;

TableEditor.propTypes = {
  idCatalogo: PropTypes.number.isRequired,
  idPagina: PropTypes.number.isRequired,
  itensPagina: PropTypes.instanceOf(Object).isRequired,
  isDraggable: PropTypes.bool,
  onLinkHighlight: PropTypes.func,
};

TableEditor.defaultProps = {
  isDraggable: false,
  onLinkHighlight: () => {},
};
