import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  notificationRead: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        draft.notificationRead = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.notificationRead = null;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.profile = null;
        draft.notificationRead = null;
        break;
      }
      case '@notification/SET_READ': {
        draft.notificationRead = action.payload.isRead;
        break;
      }
      default:
    }
  });
}
