import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

import { ThemeProvider } from './config/themeContext';

function App() {
  return (
    /** Camada redux */
    <Provider store={store}>
      {/* Camada de persistência de dados no "localStorage" */}
      <PersistGate persistor={persistor}>
        {/* Gerenciador de rotas */}
        <Router history={history}>
          <ThemeProvider>
            <>
              {/* Rotas do sistema */}
              <Routes />

              {/* Estilos globais */}
              <GlobalStyle />

              {/* Container de mensagens */}
              <ToastContainer autoClose={3000} />
            </>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
