import styled, { css } from 'styled-components';

const handleColorStatus = (id) => {
  switch (id) {
    case 1:
      return 'rgba(230,222,42,.9)';
    case 2:
      return 'rgba(83,128,97,.9)';
    default:
      return 'rgba(170,170,170,.9)';
  }
};

export const Container = styled.div`
  > h2 {
    padding: 20px 10px 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

export const CatalogStatus = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 150px;
  min-width: 150px;

  ${({ type }) =>
    css`
      color: ${handleColorStatus(type)};
      border: 2px solid ${handleColorStatus(type)};
    `}

  & + span {
    margin-left: 5px;
  }
`;
