import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/Form/Input/TextArea';
import { opNotificacaoTipo, opTipoAcesso } from '../../../../lib/const';
import { Button } from '../../../Notificacao/styles';
import { useSelector } from 'react-redux';

function EmpresaNotificacaoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id, idEmpresa } = location.state;

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const [notificacao, setNotificacao] = useState(null);

  useEffect(() => {
    api.get(`/empresa/${idEmpresa}/notificacao/${id}`).then((response) => {
      setNotificacao(response.data.notificacao);
    });
  }, [idEmpresa, id]);

  const handleConferencia = useCallback((descricao) => {
    const idCatalogo = descricao.split(" ")[1].trim()
    history.push('/catalogo/merge', { idCatalogo: Number(idCatalogo) })
  }, [])

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Notificação" />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          initialData={notificacao}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="titulo" name="titulo" label="Título" disabled />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="descricao"
                name="descricao"
                label="Descrição"
                rows="5"
                disabled
              />
            </Form.Row>
            {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && notificacao && notificacao.tipo === opNotificacaoTipo.CONFERENCIA && (
              <Button
                onClick={() => handleConferencia(notificacao.descricao)}
                title='Conferência de Itens'
              >
                Conferência
              </Button>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaNotificacaoEditar };
