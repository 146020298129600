import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import { AppError } from '../../../errors/AppError';

import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport, opImport } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';
import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';

import Pagination from '../../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function CatalogoItem() {
  const location = useLocation();
  const { id } = location.state;

  const [itemPagina, setItemPagina] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [itensExclusao, setItensExclusao] = useState([]);

  const carregaItemPaginaCatalogo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`/catalogo/${id}/item`, {
        params: configFiltro,
      })
      .then((response) => {
        setItemPagina(
          response.data.itemPagina.map(({ quantidade, ...item }) => ({
            ...item,
            quantidade: quantidade.toFixed(4),
            checked: !!itensExclusao?.find(
              (i) =>
                i.idPagina === item.idPagina &&
                i.idItem === item.idItem &&
                i.idMaterial === item.idMaterial
            ),
          }))
        );
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id, itensExclusao]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaItemPaginaCatalogo();
  }, [location, currentPage, carregaItemPaginaCatalogo]);

  const handleCriar = useCallback(() => {
    history.push('/catalogo/itens/criar', {
      background: location,
      idCatalogo: id,
    });
  }, [location, id]);

  const handleEditar = useCallback(
    (idPagina, idItem, idMaterial) => {
      history.push('/catalogo/itens/editar', {
        background: location,
        idCatalogo: id,
        idPagina,
        idItem,
        idMaterial,
      });
    },
    [location, id]
  );

  const handleVisualizar = useCallback(
    (e, idPagina, idItem, idMaterial) => {
      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD')
        handleEditar(idPagina, idItem, idMaterial);
    },
    [handleEditar]
  );

  const handleRemover = useCallback(
    async (idItem, idMaterial) => {
      const deletar = itensExclusao.map((item) => ({
        idPagina: item.idPagina,
        idMaterial: item.idMaterial,
        idItem: item.idItem,
      }));

      if (
        deletar.length === 0
          ? window.confirm(
              `Deseja realmente excluir todos os itens do catálogo? \nCatálogo: ${id}`
            )
          : window.confirm(
              `Deseja realmente excluir os itens do catálogo? \nCatálogo: ${id} \nItem: ${deletar
                .map((item) => item.idItem)
                .join(',')}`
            )
      ) {
        try {
          await api.patch(`catalogo/${id}/item`, {
            itens: deletar,
          });

          setItemPagina((state) =>
            state.filter(
              (item) => item.idItem !== idItem && item.idMaterial !== idMaterial
            )
          );

          setItensExclusao([]);

          carregaItemPaginaCatalogo();

          toast.success('Itens excluídos com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [id, carregaItemPaginaCatalogo, itensExclusao]
  );

  const handleAlterarItemChecagem = useCallback((e, item) => {
    e.stopPropagation();

    setItensExclusao((prevState) => {
      const idx = prevState.findIndex(
        (p) =>
          p.idPagina === item.idPagina &&
          p.idItem === item.idItem &&
          p.idMaterial === item.idMaterial
      );

      if (idx >= 0) {
        prevState.splice(idx, 1);
        return [...prevState];
      }

      return [...prevState, item];
    });
  }, []);

  const handleImportar = useCallback(() => {
    history.push('/catalogo/item/importar', {
      background: { ...location },
      parametros: { op: opImport.CATALOGO_ITEMPAGINA, idCatalogo: id },
    });
  }, [id, location]);

  const handleItemKit = useCallback(
    async (idCatalogo, idPagina, idItem, idMaterial, itemOrdena) => {
      history.push(`/catalogo/conjunto`, {
        background: location,
        idCatalogo,
        idPagina,
        idItem,
        idMaterial,
        itemOrdena,
      });
    },
    [location]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_ITEMPAGINA, { idCatalogo: id });
  }, [id]);

  return (
    <Container>
      <TitleBar
        back
        title="Itens do catálogo"
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
        onIconDeletar={handleRemover}
      />
      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idPagina', title: 'Página' },
              { fieldname: 'idItem', title: 'Item' },
              { fieldname: 'partnumber', title: 'Partnumber' },
              { fieldname: 'descricao', title: 'Descrição' },
              { fieldname: 'quantidade', title: 'Quantidade' },
              { fieldname: 'ordena', title: 'Ordena' },
              { fieldname: null, title: null },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {itemPagina.map((item, index) => (
              <RowMaster
                key={index}
                onClick={(e) =>
                  handleVisualizar(
                    e,
                    item.idPagina,
                    item.idItem,
                    item.materialFabricante.idMaterial
                  )
                }
              >
                <td>{item.idPagina}</td>
                <td>{item.idItem}</td>
                <td>{item.materialFabricante.partnumber}</td>
                <td>{item.materialFabricante.descricao}</td>
                <td>{item.quantidade}</td>
                <td>{item.ordena}</td>
                <td>
                  <Checkbox
                    id={`item-${index}`}
                    name={`item-${index}`}
                    checked={item.checked}
                    disableRipple
                    onChange={(e) => {
                      handleAlterarItemChecagem(e, item);
                    }}
                  />
                </td>
                <td>
                  <ActionMenu
                    data={item}
                    onEdit={() =>
                      handleEditar(
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial
                      )
                    }
                    onItemKit={() =>
                      handleItemKit(
                        item.idCatalogo,
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial,
                        item.ordena
                      )
                    }
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </Container>
  );
}

export default CatalogoItem;
