import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { AppError } from '../../../../errors/AppError';
import { getCatalogoPartnumber } from '../../../../lib/asyncUtils';
import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';
import Input from '../../../../components/Form/Input';

/** validação dos campos */
const schema = Yup.object().shape({
  partnumber: Yup.string().required('O campo "Partnumber" deve ser preenchido'),
  codiEqui: Yup.string()
    .max(30, 'O campo "Cód. Equipamento" precisa ter no máximo 30 caracteres')
    .required('O campo "Cód. Equipamento" deve ser preenchido'),
  numeroLote: Yup.string()
    .max(30, 'O campo "Número Lote" precisa ter no máximo 30 caracteres')
    .required('O campo "Número Lote" deve ser preenchido'),
});

function EmpresaSerieLoteCatalogoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idLote, background } = location.state;

  const [serieLote, setSerieLote] = useState(null);

  useEffect(() => {
    api.get(`empresa/${idEmpresa}/serie-lote/${idLote}`).then((response) => {
      const { serieCatalogo } = response.data;

      formRef.current.setFieldValue('partnumber', {
        value: `${serieCatalogo.idCatalogo}.${serieCatalogo.idSerie}`,
        label: serieCatalogo.partnumber,
      });

      setSerieLote({
        ...response.data,
        fmtInstInc: format(
          parseISO(response.data.instInc),
          'dd/MM/yyyy HH:mm:ss'
        ),
      });
    });
  }, [idEmpresa, idLote]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { partnumber, codiEqui, numeroLote } = data;

        const [idCatalogo, idSerie] = partnumber.split('.');

        await api.put(`empresa/${idEmpresa}/serie-lote/${idLote}`, {
          idCatalogo,
          idSerie,
          codiEqui,
          numeroLote,
        });

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idLote]
  );

  return (
    <>
      <TitleBar back={!background} title="Alterar Série / Lote" />
      <FormWrapper>
        <Form
          id="formSerieLoteCatalogo"
          ref={formRef}
          schema={schema}
          initialData={serieLote}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <AsyncSelect
                id="partnumber"
                name="partnumber"
                label="Partnumber"
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) =>
                  getCatalogoPartnumber({ id: idEmpresa, value }, cb)
                }
              />
              <Input
                id="fmtInstInc"
                name="fmtInstInc"
                label="Criado em"
                disabled
                width={450}
              />
            </Form.Row>
            <Form.Row>
              <Input id="codiEqui" name="codiEqui" label="Cód. Equipamento" />
              <Input id="numeroLote" name="numeroLote" label="Lote" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaSerieLoteCatalogoEditar };
