import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;

  ${({ location }) =>
    location &&
    css`
      border: 1px solid #ddd;
      border-radius: 8px;
    `}
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${darken(0.04, '#f2f5fc')};
    color: #79869e;
    border: 0;
    margin: 0 1px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    transition: background 0.5s;

    :hover {
      background: #ff6d3c;
      color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 0 5px;

  ${({ location }) =>
    location &&
    css`
      flex: 1;
    `}

  & > form {
    display: flex;
    align-items: center;
  }
`;
