import styled from 'styled-components';

export const Button = styled.button`
  margin-top: 10px;
  width: 150px;
  height: 42px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  background: #e0e5ed;
  color: #2c405a;
  transition: 0.5s;

  :hover {
    background: #cbd0d8;
  }
`;