import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import {
  MdDelete,
  MdComputer,
  MdSmartphone,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

import { PDF, opPdfExport } from '../../../../lib/pdf-core';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import { toCSV } from '../../../../lib/csv-core';

import TitleBar from '../../../../components/TitleBar';
import { Form } from '../../../../components/Form';
import DebounceInput from '../../../../components/Form/Input/Debounce';
import Select from '../../../../components/Form/Input/Select';
import Modal from '../../../../components/Modal';

import { opSolicitacao } from '../../../../lib/inputOption';

import {
  Wrapper,
  RequestWrapper,
  RequestRow,
  RequestItems,
  WrapperPage,
  RequestInfo,
} from './styles';

function MobileEmpresaSolicitacao() {
  const location = useLocation();
  const { extCodiDiv2 = null, extNumeOS = null, extUsuario = null } =
    location.state || {};

  const formRef = useRef();

  const { app } = useSelector((state) => state.auth);
  const {
    profile: { adm, empresa },
  } = useSelector((state) => state.user || {});

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [formExtNumeOS, setFormExtNumeOS] = useState(null);
  const [formDataDesa, setFormDataDesa] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [indexToOpen, setIndexToOpen] = useState();

  const [openModal, setOpenModal] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [indexItem, setIndexItem] = useState();

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaSolicitacao = useCallback(
    (pageMore = 1) => {
      if (pageMore === 1) {
        setSolicitacoes([]);
      }

      api
        .get('solicitacao', {
          params: {
            page: pageMore,
            extCodiDiv2,
            extNumeOS: extNumeOS || formExtNumeOS,
            dataDesa: formDataDesa,
          },
        })
        .then((response) => {
          setSolicitacoes(() => [
            ...response.data.solicitacao.map(
              ({ instInc, dataDesa, itemSolicitacao, ...solicitacao }) => ({
                ...solicitacao,
                dataDesa,
                instInc: format(parseISO(instInc), 'dd/MM/yyyy HH:mm'),
                dataDesaFormatado:
                  dataDesa &&
                  format(parseISO(dataDesa), "dd/MM/yyyy 'às' HH:mm"),
                itemSolicitacao: itemSolicitacao.map(
                  ({ dataDesa: dataDesaItem, ...item }) => ({
                    dataDesa: dataDesaItem,
                    dataDesaFormatado:
                      dataDesaItem &&
                      format(parseISO(dataDesaItem), "dd/MM/yyyy 'às' HH:mm"),
                    ...item,
                  })
                ),
              })
            ),
          ]);

          setCurrentPage(response.data.pagAtual);
          setTotalPage(response.data.total);
        });
    },
    [extCodiDiv2, extNumeOS, formExtNumeOS, formDataDesa]
  );

  useEffect(() => {
    carregaSolicitacao();
  }, [extCodiDiv2, extNumeOS, formExtNumeOS, formDataDesa, carregaSolicitacao]);

  const handleExportar = useCallback(
    (id) => {
      const filtroSolicitacao = id
        ? solicitacoes.filter((item) => item.idSolicitacao === id)
        : solicitacoes;

      const fields = [
        'Solicitação',
        'Solicitação Relizada',
        'Solicitado Por',
        'Solicitação Cancelada',
        'Cancelado Por',

        // 'Divisão 2',
        'Número OS',
        'Outras Informações',
        'Item OS',
        'Item Cancelado',
        'Cancelado Por',

        'IMEI',
        'Coletor',

        'Código Material (ERP)',
        'Descrição (ERP)',

        'Partnumber',
        'Descrição (BR)',
        'Descrição (EN)',

        'Quantidade',
        'Unidade',
      ];

      const data = filtroSolicitacao.map((solicitacao) => {
        const {
          idSolicitacao,
          instInc,
          dataDesaFormatado,
          mobileIMEI,
          mobileColetor,
          // extCodiDiv2: codiDiv2,
          extNumeOS: numeOS,
          extObservacao,
          solicitante: { nome },
          cancelamento,
          itemSolicitacao,
        } = solicitacao;

        const { nome: nomeDesa = '' } = cancelamento || {};

        const linha = itemSolicitacao.map((item) => {
          const {
            dataDesaFormatado: dataDesaItem,
            cancelamento: cancelamentoItem,
            materialERP,
            materialFabricante: { partnumber, descricaoBR, descricaoEN },
            quantidade,
            unidade,
            extItemOS: itemOS,
          } = item;

          const { nome: nomeDesaItem = '' } = cancelamentoItem || {};

          const { codimate = null, descricao = null } = materialERP || {};

          return [
            idSolicitacao,
            instInc,
            dataDesaFormatado,
            nomeDesa,
            nome,
            // codiDiv2,
            numeOS,
            extObservacao,
            itemOS,
            dataDesaItem,
            nomeDesaItem,
            mobileIMEI,
            mobileColetor,
            codimate,
            descricao,
            partnumber,
            descricaoBR,
            descricaoEN,
            quantidade,
            unidade,
          ];
        });

        return linha;
      });

      const csv = toCSV(fields, data.flat());

      try {
        /** cria um link para o processo de download */
        const link = document.createElement('a');
        const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

        link.href = file;
        link.download = `catalogo-eletronico-retirada.csv`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } catch (err) {
        AppError(err);
      }
    },

    [solicitacoes]
  );

  const handlePDF = useCallback(
    async (id) => {
      const { idEmpresa } = empresa;

      if (id) {
        /** cria PDF de retirada específica */
        PDF.criarExportacao(opPdfExport.RETIRADA_ITEM, {
          idEmpresa,
          idSolicitacao: id,
        });

        return;
      }

      /** cria PDF com a lista de retiradas */
      PDF.criarExportacao(opPdfExport.RETIRADA_LISTA, {
        idEmpresa,
      });
    },
    [empresa]
  );

  const handleCarregar = useCallback(
    (passToPage) => {
      carregaSolicitacao(currentPage + passToPage); // NextPage PreviousPage
    },
    [currentPage, carregaSolicitacao]
  );

  const handleCancelar = useCallback(
    async (id) => {
      /** confirmação */
      if (window.confirm('Deseja realmente cancelar a solicitação?')) {
        try {
          /** exclui o registro */
          await api.delete(`solicitacao/${id}`);

          carregaSolicitacao();

          /** mensagem de sucesso */
          toast.success('Solicitação cancelada com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaSolicitacao]
  );

  const handleCancelarItem = useCallback(
    async (id, idItem) => {
      /** confirmação */
      if (window.confirm('Deseja realmente cancelar o item da solicitação?')) {
        try {
          /** exclui o registro */
          await api.delete(`solicitacao/${id}/item/${idItem}`);

          carregaSolicitacao();

          /** mensagem de sucesso */
          toast.success('Item cancelado com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaSolicitacao]
  );

  const handleChangeDataDesa = useCallback((data) => {
    const dataDesaFormatado = data ? data.map((item) => item.value) : [1];
    setFormDataDesa(dataDesaFormatado.includes(1) || null);
  }, []);

  const handleIndexOpen = (index, toOpen, setToOpen, open, setOpen) => {
    if (toOpen !== index && open === true) {
      setOpen(false);
      setToOpen(index);
      setOpen(true);
    } else if (toOpen === index) {
      setOpen(!open);
    } else {
      setToOpen(index);
      setOpen(!open);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
    setIndexItem();
    setOpenItem();
  };

  return (
    <>
      <TitleBar
        back={!app}
        title="Minhas retiradas"
        onFileDownload={() => handleExportar(null)}
        onFilePrint={() => handlePDF(null)}
        width={950}
      />
      <Wrapper>
        <Form
          ref={formRef}
          initialData={{
            extNumeOS,
          }}
          autoComplete="off"
        >
          <Form.Row>
            <Form.Row>
              <DebounceInput
                id="extNumeOS"
                name="extNumeOS"
                placeholder="Digite para pesquisar..."
                onChange={({ target }) => setFormExtNumeOS(target.value)}
                type="number"
                width={200}
                disabled={app}
              />
              <Select
                id="dataDesa"
                name="dataDesa"
                isSearchable
                isMulti
                placeholder="Selecione para pesquisar a situação da retirada..."
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={opSolicitacao[0]}
                options={opSolicitacao}
                onChange={handleChangeDataDesa}
                width={400}
              />
            </Form.Row>
          </Form.Row>
        </Form>
        {solicitacoes.length ? (
          <RequestWrapper>
            {solicitacoes.map((solicitacao) => (
              <React.Fragment key={solicitacao.idSolicitacao}>
                <RequestRow
                  cancelled={!!solicitacao.dataDesa}
                  isOpen={openInfo && indexToOpen === solicitacao.idSolicitacao}
                  openInfo={
                    openInfo && indexToOpen === solicitacao.idSolicitacao
                  }
                >
                  <RequestRow.Wrapper>
                    <RequestRow.Item.Button
                      onClick={() =>
                        handleIndexOpen(
                          solicitacao.idSolicitacao,
                          indexToOpen,
                          setIndexToOpen,
                          openInfo,
                          setOpenInfo
                        )
                      }
                    >
                      {openInfo && indexToOpen === solicitacao.idSolicitacao ? (
                        <MdOutlineKeyboardArrowUp size={20} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={20} />
                      )}
                    </RequestRow.Item.Button>
                    <RequestRow.Item>
                      <strong>Solicitação</strong>
                      <span>{`#${solicitacao.idSolicitacao}`}</span>
                    </RequestRow.Item>
                    <RequestRow.Item>
                      <strong>Realizada em</strong>
                      <span>{solicitacao.instInc}</span>
                    </RequestRow.Item>
                    {(adm !== 0 ||
                      Number(extUsuario) === solicitacao.extUsuario) &&
                      !solicitacao.dataDesa && (
                        <RequestRow.Item.Button
                          type="button"
                          title="Cancelar"
                          onClick={() =>
                            handleCancelar(solicitacao.idSolicitacao)
                          }
                        >
                          <MdDelete size={20} />
                        </RequestRow.Item.Button>
                      )}
                  </RequestRow.Wrapper>
                  {solicitacao.dataDesa && (
                    <RequestRow.Item.Cancelado>
                      {`Solicitação ${solicitacao.idSolicitacao} cancelada pelo usuário ${solicitacao.cancelamento.nome} em ${solicitacao.dataDesaFormatado}`}
                    </RequestRow.Item.Cancelado>
                  )}
                </RequestRow>
                {openInfo && indexToOpen === solicitacao.idSolicitacao && (
                  <RequestInfo
                    cancelled={!!solicitacao.dataDesa}
                    isOpen={
                      openInfo && indexToOpen === solicitacao.idSolicitacao
                    }
                  >
                    <RequestInfo.Box directionRow>
                      <RequestInfo.Box.Item>
                        {solicitacao.mobileIMEI ? (
                          <MdSmartphone size={20} title="Mobilidade" />
                        ) : (
                          <MdComputer size={20} title="Computador" />
                        )}
                      </RequestInfo.Box.Item>
                      <RequestInfo.Box.Item>
                        <strong>Solicitado por</strong>{' '}
                        <span>{solicitacao.solicitante.nome}</span>{' '}
                      </RequestInfo.Box.Item>
                      {solicitacao.mobileIMEI && (
                        <RequestInfo.Box.Item>
                          <strong>Coletor</strong>
                          <span>{solicitacao.mobileColetor}</span>
                        </RequestInfo.Box.Item>
                      )}
                      <RequestInfo.Box.Item>
                        <strong>Qtde. Itens</strong>
                        <span>{solicitacao.itemSolicitacao.length}</span>
                      </RequestInfo.Box.Item>
                    </RequestInfo.Box>
                    {!!solicitacao.extNumeOS && (
                      <RequestInfo.Box>
                        <RequestInfo.Box.Item directionRow>
                          <strong>Outras Informações:</strong>
                        </RequestInfo.Box.Item>
                        <RequestInfo.Box.Item directionRow>
                          <span>{`Nº OS: ${solicitacao.extNumeOS}`}</span>
                        </RequestInfo.Box.Item>
                        {solicitacao.extUsuario && (
                          <RequestInfo.Box.Item directionRow>
                            <span>{`Usuário: ${solicitacao.extUsuario}`}</span>
                          </RequestInfo.Box.Item>
                        )}
                        {solicitacao.extExtra01 && (
                          <RequestInfo.Box.Item directionRow>
                            <span>{`Nº Requisição: ${solicitacao.extExtra01}`}</span>
                          </RequestInfo.Box.Item>
                        )}
                        {solicitacao.extExtra02 && (
                          <RequestInfo.Box.Item directionRow>
                            <span>{`Nº Tarefa: ${solicitacao.extExtra02}`}</span>
                          </RequestInfo.Box.Item>
                        )}
                        {solicitacao.extExtra03 && (
                          <RequestInfo.Box.Item directionRow>
                            <span>{`Ext. Extra 03: ${solicitacao.extExtra03}`}</span>
                          </RequestInfo.Box.Item>
                        )}
                        {solicitacao.extExtra04 && (
                          <RequestInfo.Box.Item directionRow>
                            <span>{`Ext. Extra 04: ${solicitacao.extExtra04}`}</span>
                          </RequestInfo.Box.Item>
                        )}
                        <RequestInfo.Box.Item directionRow>
                          <span>{solicitacao.extObservacao || ''}</span>
                        </RequestInfo.Box.Item>
                      </RequestInfo.Box>
                    )}
                    <RequestInfo.Box.Item>
                      <RequestInfo.Box.Item.Button
                        onClick={() => handleOpenModal()}
                      >
                        Itens Solicitados
                      </RequestInfo.Box.Item.Button>
                    </RequestInfo.Box.Item>
                  </RequestInfo>
                )}
                {openModal && indexToOpen === solicitacao.idSolicitacao && (
                  <Modal
                    width={95}
                    height={95}
                    onClose={() => handleOpenModal()}
                  >
                    <RequestItems>
                      <h3>Itens Solicitados</h3>
                      {solicitacao.itemSolicitacao.map((item, index) => (
                        <RequestItems.Box
                          key={index}
                          isOpen={openItem && indexItem === index}
                        >
                          <RequestItems.Box.Item directionColumn>
                            <RequestItems.Box.Item.Info
                              space
                              isItem
                              isOpen={openItem && indexItem === index}
                            >
                              <RequestItems.Box.Item.Info.Button
                                onClick={() =>
                                  handleIndexOpen(
                                    index,
                                    indexItem,
                                    setIndexItem,
                                    openItem,
                                    setOpenItem
                                  )
                                }
                              >
                                {openItem && indexItem === index ? (
                                  <MdOutlineKeyboardArrowUp size={20} />
                                ) : (
                                  <MdOutlineKeyboardArrowDown size={20} />
                                )}
                              </RequestItems.Box.Item.Info.Button>
                              <strong>
                                {item.materialFabricante.partnumber}
                              </strong>
                              {(adm !== 0 ||
                                Number(extUsuario) ===
                                  solicitacao.extUsuario) &&
                                !item.dataDesa && (
                                  <RequestItems.Box.Item.Info
                                    icon
                                    type="button"
                                    title="Cancelar"
                                    onClick={() =>
                                      handleCancelarItem(
                                        solicitacao.idSolicitacao,
                                        item.idItemSolicitacao
                                      )
                                    }
                                  >
                                    <MdDelete size={20} />
                                  </RequestItems.Box.Item.Info>
                                )}
                            </RequestItems.Box.Item.Info>
                            {openItem && indexItem === index && (
                              <>
                                <RequestItems.Box.Item.Info>
                                  <span>
                                    {item.materialFabricante.descricaoBR ||
                                      item.materialFabricante.descricaoEN}
                                  </span>
                                </RequestItems.Box.Item.Info>
                                <RequestItems.Box.Item.Info>
                                  <span>
                                    {`Fabricante: ${item.materialFabricante.fabricante.descricao}`}
                                  </span>
                                </RequestItems.Box.Item.Info>
                                <RequestItems.Box.Item.Info>
                                  <span>{`Quantidade: ${item.quantidade}`}</span>
                                </RequestItems.Box.Item.Info>
                                {item.materialERP && (
                                  <RequestItems.Box.Item
                                    directionColumn
                                    isParalela
                                  >
                                    <RequestItems.Box.Item.Info>
                                      <strong>
                                        {`Código ERP: ${item.materialERP.codimate}`}
                                      </strong>
                                    </RequestItems.Box.Item.Info>
                                    <RequestItems.Box.Item.Info>
                                      <span>{item.materialERP.descricao}</span>
                                    </RequestItems.Box.Item.Info>
                                    <RequestItems.Box.Item.Info>
                                      {!!item.materialERP.unidade && (
                                        <span>
                                          {`Unidade: ${
                                            item.materialERP.unidade || ''
                                          }`}
                                        </span>
                                      )}
                                    </RequestItems.Box.Item.Info>
                                    <RequestItems.Box.Item.Info>
                                      {!!item.extItemOS && (
                                        <span>{`Item O.S.: ${item.extItemOS}`}</span>
                                      )}
                                    </RequestItems.Box.Item.Info>
                                  </RequestItems.Box.Item>
                                )}
                              </>
                            )}
                          </RequestItems.Box.Item>
                        </RequestItems.Box>
                      ))}
                    </RequestItems>
                  </Modal>
                )}
              </React.Fragment>
            ))}
            <WrapperPage>
              <WrapperPage.Btn onClick={() => handleCarregar(-1)}>
                <MdOutlineKeyboardArrowLeft size={20} />
              </WrapperPage.Btn>
              <WrapperPage.Display>
                {currentPage} de {totalPage}
              </WrapperPage.Display>
              <WrapperPage.Btn onClick={() => handleCarregar(1)}>
                <MdOutlineKeyboardArrowRight size={20} />
              </WrapperPage.Btn>
            </WrapperPage>
          </RequestWrapper>
        ) : (
          <RequestWrapper.NotFound>
            Nenhum item encontrado
          </RequestWrapper.NotFound>
        )}
      </Wrapper>
    </>
  );
}

export { MobileEmpresaSolicitacao };
