import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.nav`
  background: ${(props) => props.theme.headerBackground};
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px #00000033;
  padding: 0 5px;
  min-height: 60px;

  margin: 0 auto;
  width: 100%;
  max-width: 2000px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > button {
    color: ${(props) => props.theme.color};
  }
`;

export const WrapperButtonClose = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
`;

export const ButtonTheme = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;

  height: 42px;
  width: 42px;
`;

export const ButtonBurguer = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: ${(props) => (props.isClose ? '#ff6d3c' : '#2c405a')};
  color: ${(props) => props.theme.color};
  color: ${(props) => props.isClose && '#ff6d3c'};
  border-radius: 5px;
  height: 42px;
  width: 42px;

  margin-right: 20px;
`;

export const MenuImage = styled.div`
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  padding: 0 2px;

  img {
    max-height: 55px;
    width: auto;
  }
`;

export const Cart = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    background: none;
    border: 0;

    color: ${(props) => props.theme.color};
    height: 42px;
    width: 42px;
  }

  > span {
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(255, 109, 60, 0.9);
    border-radius: 50%;
    padding: 2px 0;
    width: 14px;
    height: 14px;

    color: #fff;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
  }
`;

export const MenuBurguer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 60px 10px;

  background: ${(props) => props.theme.cardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  & > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 20px 5px;
    width: 100%;
    height: 100%;
  }
`;

MenuBurguer.BtnClose = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: ${(props) => (props.isClose ? '#ff6d3c' : '#79869e')};
  border-radius: 5px;
  height: 42px;
  width: 42px;
`;

export const Menu = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  height: 42px;
  min-width: 200px;

  margin: 5px 0;

  a {
    color: ${(props) => props.theme.colorMenuBurguer};

    ${(props) =>
      props.selected &&
      css`
        color: #ff6d3c;
      `}
  }
`;

Menu.Divide = styled.div`
  margin-top: 5px;
  width: 150px;
  padding: 5px 0;

  border-top: ${(props) => `1px solid ${props.theme.color}`};
`;

Menu.LinkBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  height: 42px;
  min-width: 150px;

  border: 0;
  background: none;

  color: ${(props) => props.theme.colorMenuBurguer};
`;

Menu.ExitBtn = styled.button`
  border: 1px solid #dd0312;
  border-radius: 5px;
  background: #dd0312;
  color: #fff;
  font-weight: bold;
  height: 42px;
  width: 150px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

User.Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 20px 5px 0 5px;
  width: 100%;
  flex: 1;

  > h4 {
    font-size: 18px;
    font-weight: lighter;

    padding: 0 5px;
  }
  > button {
    border: 0;
    background: none;
    display: flex;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    width: 90px;

    color: ${(props) => props.theme.colorMenuBurguer};
  }
`;

User.Login = styled.button`
  border: 0;

  > svg {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }
`;

User.Signin = styled.button`
  border: 1px solid #ff6d3c;
  border-radius: 5px;

  &:hover {
    color: #f8f8f8;
    background: #ff6d3c;
  }
`;

User.WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

User.WrapperButtons.Button = styled.button`
  border: none;
  background-color: ${(props) => (props.isLogout ? '#ff6d3c' : 'transparent')};
  text-transform: uppercase;
  color: ${(props) => (props.isLogout ? '#fff' : '#2c405a')};
  padding: 10px;
  border-radius: ${(props) => props.isLogout && '5px'};
  margin-top: ${(props) => props.isLogout && '20px'};
`;

export const CartMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: relative;

  > button {
    background: none;
    border: 0;
    color: #2c405a;
    transform: color 1s;

    + button {
      margin-left: 10px;
    }

    :hover {
      color: ${darken(0.05, '#2C405A')};
    }
  }

  > span {
    position: absolute;
    right: 5px;
    top: -5px;
    background: rgba(255, 109, 60, 0.9);
    border-radius: 50%;
    padding: 2px 0;
    width: 14px;
    height: 14px;
    margin: 0 5px;

    color: #fff;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
  }

  h4 {
    font-weight: normal;
  }
`;
