import * as Yup from 'yup';
import { toast } from 'react-toastify';

const AppError = (err, formRef = null) => {
  const validationErrors = {};

  /** erro de validação de campos no cliente */
  if (err instanceof Yup.ValidationError) {
    err.inner.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
    formRef.current.setErrors(validationErrors);

    return;
  }

  /** erro padrão */
  let message = err;

  /** verifica retorno do servidor */
  if (err.response && err.response.data) {
    const { validation = null } = err.response.data;

    message = err.response.data.message;

    /** erro de validação de campos no servidor */
    if (validation && validation.body) {
      message = validation.body.message;
    }
  } else if (err.message) {
    /** mensagem gerada no cliente */
    message = err.message;
  }

  /** mensagem de erro */
  toast.error(message);
};

export { AppError };
