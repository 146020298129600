import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { opImport } from '../../../lib/csv-core';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import { ActionTitleMenu } from '../../../components/ActionTitleMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaNavegacaoClassificacao() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [navegacaoClassificacao, setNavegacaoClassificacao] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNavegacaoClassificacao = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/navegacao-classificacao`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setNavegacaoClassificacao(response.data.navegacaoClassificacao);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleRestaurarPadrao = useCallback(async () => {
    if (
      window.confirm(
        'Atenção: Essa operação vai apagar a estrutura personalizada e vai restaurar o menu padrão. Deseja continuar?'
      )
    ) {
      try {
        await api.post(
          `empresa/${idEmpresa}/navegacao-classificacao/restaurar`
        );

        carregaNavegacaoClassificacao();
      } catch (err) {
        AppError(err);
      }
    }
  }, [idEmpresa, carregaNavegacaoClassificacao]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/navegacao-classificacao/criar`, {
      background: location,
      idEmpresa,
    });
  }, [location, idEmpresa]);

  const handleEditar = useCallback(
    (idClassificacao) => {
      history.push(`/empresa/navegacao-classificacao/editar`, {
        background: location,
        idEmpresa,
        id: idClassificacao,
      });
    },
    [location, idEmpresa]
  );

  const handleRemover = useCallback(
    async (idClassificacao) => {
      if (
        window.confirm('Deseja realmente excluir a classificação da navegação?')
      ) {
        try {
          await api.delete(
            `empresa/${idEmpresa}/navegacao-classificacao/${idClassificacao}`
          );

          carregaNavegacaoClassificacao();
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaNavegacaoClassificacao]
  );

  const handleVisualizar = useCallback(
    (e, idClassificacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push(`/empresa/navegacao-classificacao/detalhe`, {
          idEmpresa,
          id: idClassificacao,
        });
      }
    },
    [idEmpresa]
  );

  const handleImportar = useCallback(() => {
    history.push(`/empresa/menu/importar`, {
      background: { ...location },
      parametros: { op: opImport.EMPRESA_MENU, idEmpresa },
    });
  }, [idEmpresa, location]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNavegacaoClassificacao();
  }, [location, currentPage, carregaNavegacaoClassificacao]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Menu` : ''}
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
      >
        <ActionTitleMenu.Root>
          <ActionTitleMenu.Item
            title="Restaurar Padrão"
            onHandler={handleRestaurarPadrao}
          />
        </ActionTitleMenu.Root>
      </TitleBar>

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idClassificacao', title: 'Menu' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {navegacaoClassificacao.map((classificacao) => (
            <RowMaster
              key={classificacao.idClassificacao}
              onClick={(e) =>
                handleVisualizar(e, classificacao.idClassificacao)
              }
            >
              <td>{classificacao.idClassificacao}</td>
              <td>{classificacao.descricao}</td>
              <td>
                <ActionMenu
                  data={classificacao}
                  onLineRemove={() =>
                    handleRemover(classificacao.idClassificacao)
                  }
                  onEdit={() => handleEditar(classificacao.idClassificacao)}
                  onRemove={() => handleRemover(classificacao.idClassificacao)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaNavegacaoClassificacao };
