import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper } from './styles';

function AuthRedefinirLayout({ children }) {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}

AuthRedefinirLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthRedefinirLayout };
