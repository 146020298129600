import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';

import { opAdministrador } from '../../../lib/inputOption';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

const schema = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'O campo "nome" precisa ter no mínimo 3 caracteres')
    .required('O campo "nome" é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "e-mail" é obrigatório'),
  telefone: Yup.string().nullable(),
  ramal: Yup.string().nullable(),
  adm: Yup.string().required('O campo "Administrador" é obrigatório'),
});

function UsuarioEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idUsuario } = location.state;

  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    api.get(`usuario/${idUsuario}`).then((response) => {
      const { adm, dataDesa } = response.data;

      /** Atribui valor inicial para o "select" */
      formRef.current.setFieldValue('adm', opAdministrador[adm]);

      setUsuario({
        ...response.data,
        /** formata campo data */
        dataDesa: dataDesa
          ? format(parseISO(dataDesa), 'dd/MM/yyyy HH:mm:ss')
          : null,
      });
    });
  }, [idUsuario]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { login, nome, email } = data;

        /** submete os dados */
        await api.put(`usuario/${idUsuario}`, {
          login,
          nome,
          email,
        });

        toast.success('Usuário atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idUsuario]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Usuário" />
      <FormWrapper>
        <Form
          id="formUsuario"
          ref={formRef}
          initialData={usuario}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="login"
                name="login"
                label="Login"
                type="text"
                disabled
              />
              <Input
                id="dataDesa"
                name="dataDesa"
                label="Data de desativação"
                type="text"
                width={500}
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" type="text" />
              <Input id="email" name="email" label="E-mail" type="text" />
            </Form.Row>
            <Form.Row>
              <Select
                id="adm"
                name="adm"
                label="Administrador"
                isSearchable={false}
                options={opAdministrador}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formUsuario">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { UsuarioEditar };
