import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../errors/AppError';
import api from '../../services/api';
import history from '../../services/history';

import { CSV, opExport } from '../../lib/csv-core';

import ActionMenu from '../../components/ActionMenu';
import TitleBar from '../../components/TitleBar';
import { Table, RowMaster } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import Pagination from '../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function Modelo() {
  const location = useLocation();

  const [modelos, setModelos] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaModelo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get('modelo', {
        params: configFiltro,
      })
      .then((response) => {
        setModelos(response.data.modelo);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleCriar = useCallback(() => {
    history.push('/modelo/criar', { background: location });
  }, [location]);

  const handleEditar = useCallback(
    (idModelo) => {
      history.push('/modelo/editar', { background: location, idModelo });
    },
    [location]
  );

  const handleVisualizar = useCallback(
    (e, idModelo) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') handleEditar(idModelo);
    },
    [handleEditar]
  );

  const handleRemover = useCallback(async (idModelo) => {
    if (window.confirm('Deseja realmente excluir o modelo?')) {
      try {
        await api.delete(`modelo/${idModelo}`);

        setModelos((state) =>
          state.filter((modelo) => modelo.idModelo !== idModelo)
        );

        toast.success('Modelo excluído com sucesso!');
      } catch (err) {
        AppError(err);
      }
    }
  }, []);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.MODELO);
  }, []);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaModelo();
  }, [location, currentPage, carregaModelo]);

  return (
    <Container>
      <TitleBar
        title="Modelos"
        isSearchable
        onNew={handleCriar}
        onFileDownload={handleExportar}
      />

      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idModelo', title: 'Modelo' },
              { fieldname: 'descricao', title: 'Descrição' },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {modelos.map((modelo) => (
              <RowMaster
                key={modelo.idModelo}
                onClick={(e) => handleVisualizar(e, modelo.idModelo)}
              >
                <td>{modelo.idModelo}</td>
                <td>{modelo.descricao}</td>
                <td>
                  <ActionMenu
                    data={modelo}
                    onEdit={() => handleEditar(modelo.idModelo)}
                    onRemove={() => handleRemover(modelo.idModelo)}
                    onLineRemove={() => handleRemover(modelo.idModelo)}
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </Container>
  );
}

export default Modelo;
