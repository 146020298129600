import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdFileDownload } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import { AppError } from '../../../errors/AppError';

import api from '../../../services/api';
import history from '../../../services/history';

import { Table } from '../../../components/Table';
import TitleBar from '../../../components/TitleBar';

import { Container, Wrapper } from './styles';

function Download() {
  const location = useLocation();
  const { catalogId } = location.state;

  const [pages, setPages] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    async function loadPages() {
      /** consulta páginas para download */
      const response = await api.get(`/download/${catalogId}`);
      /** adiciona campo de controle de checagem */
      setPages(
        response.data.map((page) => ({
          ...page,
          checked: false,
        }))
      );
    }

    loadPages();
  }, [catalogId]);

  function handleChangeAll() {
    /** seta controle de checagem */
    setPages(
      pages.map((page) => ({
        ...page,
        checked: !checked,
      }))
    );

    setChecked(!checked);
  }

  function handleChange(id) {
    /** seta controle de checagem */
    const page = pages.find((p) => p.pageId === id);
    if (page) {
      page.checked = !page.checked;
      setPages(
        [...pages.filter((p) => p.pageId !== page.pageId), page].sort(
          (a, b) => a.pageId - b.pageId
        )
      );
    }
  }

  async function handleDownload(id) {
    try {
      const filter = !id ? (p) => p.checked : (p) => p.pageId === id;

      const response = await api.post(`/download/${catalogId}`, {
        pages: pages.filter(filter).map((p) => p.pageId),
      });

      const { path } = response.data;

      const a = await api.get(path);

      // /** cria um link para o processo de download */
      const link = document.createElement('a');
      const file = new Blob([a.data], { type: 'application/zip' });

      link.target = '_blank';
      link.href = URL.createObjectURL(file);
      link.download = true;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      AppError(err);
    }
  }

  return (
    <Container>
      <TitleBar title="Página de downloads" isSearchable>
        <button type="button" id="btn-back" onClick={() => history.goBack()}>
          <MdChevronLeft size={16} />
          Voltar
        </button>
      </TitleBar>

      <Wrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleChangeAll}
                  checked={checked}
                />
              </th>
              <th>#</th>
              <th>Nome</th>
              <th>
                <button type="button" onClick={() => handleDownload()}>
                  <MdFileDownload size={20} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {pages &&
              pages.map((page) => (
                <tr key={page.pageId}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleChange(page.pageId)}
                      checked={page.checked}
                    />
                  </td>
                  <td>{page.pageId}</td>
                  <td>{page.describe}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleDownload(page.pageId)}
                    >
                      <MdFileDownload size={20} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Wrapper>
    </Container>
  );
}

export default Download;
