import styled from 'styled-components';
import { darken } from 'polished';

export const Main = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

Main.Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  width: 100%;

  background: ${(props) => props.theme.background};

  #btn-back {
    color: ${(props) => props.theme.color};
  }
`;

Main.Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
  overflow-y: auto;

  > form {
    justify-content: flex-start;
  }
`;

export const NotFound = styled.span`
  display: block;
  width: 100%;
  text-align: center;

  font-size: 14px;
  font-style: italic;
  color: ${(props) => props.theme.color};
  padding: 40px 0;
`;

export const ContainerItemRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px;
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.cardBackground};
  border-radius: 10px;
  border: 1px solid #ccc;

  button {
    border: none;
    background: transparent;

    color: ${(props) => props.theme.color};
  }

  strong {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

ItemRow.BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

ItemRow.Btn = styled.button`
  width: 30px;
  height: 30px;
`;

ItemRow.InfoWrapper = styled.div`
  display: flex;
  /* flex-direction: column;
  justify-content: center;
  align-items: flex-start; */
  padding: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.cardBackground};
  border-radius: 10px;
  border: 1px solid #ccc;

  > span {
    background: ${(props) => props.theme.backgroundSpanCart};
    box-shadow: 0px 3px 6px rgb(118, 114, 114, 0.76);
    border-radius: 5px;

    > img {
      filter: ${(props) => `invert(${props.theme.invertCatalogImage})`};
      width: 150px;
    }
  }
`;

ItemRow.Container = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    padding: 5px;
  }
  justify-content: center;
  text-align: center;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  max-width: 600px;
  width: 100%;

  font-size: 12px;

  padding: 5px;

  > thead {
    > tr {
      > th {
        font-size: 12px;
        color: #2c405a;
        font-weight: normal;
        text-align: left;
        padding: 0 5px;
      }
    }
  }

  > tbody {
    max-width: 600px;

    > tr {
      > td {
        text-align: left;
        vertical-align: top;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 10px 5px;
        background: #fff;
        color: #2c405a;

        button {
          border: 0;
          background: none;
        }

        &:first-child {
          border-left: 1px solid #ccc;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          text-align: center;
          border-right: 1px solid #ccc;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      &:last-child {
        border: 1px solid;
      }
    }
  }
`;

Table.Description = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    margin-top: 5px;
  }
`;

Table.Combo = styled.div`
  display: flex;
  /* align-items: center; */
  margin-top: 5px;

  > div {
    padding: 0;
    margin-left: 5px;
  }
`;

Table.Error = styled.span`
  color: #e95938;
  font-style: italic;
  font-size: 11px;
`;

Table.Quantity = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 30px;

  span {
    text-align: center;
    min-width: 40px;
    padding: 5px 0;
  }
`;

Table.Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Table.BtnRemove = styled.button`
  color: #8fa0ba;
  transition: color 1s;
`;

export const Section = styled.div`
  padding: 5px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 10px;

  > button {
    border: 0;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
    margin: 10px;
  }

  #btn-whatsapp {
    /* light green  */
    /* background-color: #5ffc7b; */
    /* background-color: #25d366; */
    /* background-color: #59ce72; */
    /* dark green */
    background: #28d146;
    color: #ffffff;
  }

  #btn-action {
    border: 0;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
    background: #2c405a;
    transition: background 1s;

    :hover {
      color: #fff;
      background: ${darken(0.05, '#2c405a')};
    }
  }
`;

Footer.Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  height: 42px;
  min-width: 220px;
  background: #2c405a;
  transition: background 1s;

  #icon {
    margin: 0 5px;
  }
`;

Footer.Export = styled.button`
  background: #2c405a;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#2c405a')};
  }
`;

Footer.Submit = styled.button`
  background: #ff6d3c;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#ff6d3c')};
  }
`;
