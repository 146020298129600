import React, { useCallback, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdShoppingCart,
  MdMenu,
  MdClose,
  MdLightMode,
  MdDarkMode,
} from 'react-icons/md';

import { useTheme } from '../../../config/themeContext';

import Logo from '../../../assets/cdi.svg';

import history from '../../../services/history';

import { signOut } from '../../../store/modules/auth/actions';

import { opTipoAcesso, opTipoAcao } from '../../../lib/const';

import {
  Container,
  Menu,
  MenuImage,
  Cart,
  User,
  Wrapper,
  ButtonBurguer,
  MenuBurguer,
  ButtonTheme,
} from './styles';

export function MobileHeader() {
  const { url } = useRouteMatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { theme, toggleTheme } = useTheme();

  const profile = useSelector((state) => {
    const { userId, name = '', adm, empresa } = state.user.profile || {};
    const { idEmpresa, url: logotipo } = empresa || {};

    return { userId, name, adm, idEmpresa, url: logotipo };
  });

  const { app, action } = useSelector((state) => state.auth || {});

  /** verifica se existe itens no carrinho */
  const quantidadeItens = useSelector((state) => state.cart.items.length);

  const dispatch = useDispatch();

  const handleMenuBurguer = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleLogout = useCallback(() => {
    handleMenuBurguer();
    dispatch(signOut(profile.name));
  }, [dispatch, handleMenuBurguer, profile]);

  const handleUsuarioPerfil = useCallback(() => {
    const { userId, adm, idEmpresa } = profile;

    handleMenuBurguer();

    switch (Number(adm)) {
      case opTipoAcesso.ADMIN_PLATAFORMA:
      case opTipoAcesso.ADMIN_EMPRESA:
      case opTipoAcesso.USUARIO:
        history.push('/empresa/usuario/perfil', {
          background: location,
          idEmpresa,
          id: userId,
        });
        break;
      case opTipoAcesso.CLIENTE:
        history.push('/usuario/perfil', {
          id: userId,
        });
        break;
      default:
        break;
    }
  }, [location, profile, handleMenuBurguer]);

  const handleUsuarioSolicitacao = useCallback(() => {
    const { userId, adm, idEmpresa } = profile;

    handleMenuBurguer();

    switch (Number(adm)) {
      case opTipoAcesso.ADMIN_PLATAFORMA:
      case opTipoAcesso.ADMIN_EMPRESA:
      case opTipoAcesso.USUARIO:
        history.push('/empresa/solicitacao', {
          idEmpresa,
          id: userId,
        });
        break;
      case opTipoAcesso.CLIENTE:
        history.push('/usuario/solicitacao', {
          id: userId,
        });
        break;
      default:
        break;
    }
  }, [profile, handleMenuBurguer]);

  const handleCarrinho = useCallback(() => {
    history.push('/carrinho');
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          {/* <ButtonTheme onClick={toggleTheme}>
            {theme.mode === 'light' ? (
              <MdLightMode size={20} />
            ) : (
              <MdDarkMode size={20} />
            )}
          </ButtonTheme> */}
          {![opTipoAcao.INTEGRACAO_TOKEN].includes(Number(action)) && (
            <ButtonBurguer onClick={() => handleMenuBurguer()}>
              <MdMenu size={32} />
            </ButtonBurguer>
          )}
          <MenuImage>
            <img
              src={profile.url ? profile.url : Logo}
              alt="CDI - Catálogo Eletrônico"
            />
          </MenuImage>
          <Cart>
            {quantidadeItens !== 0 && <span>{quantidadeItens}</span>}
            <button type="button" title="Carrinho" onClick={handleCarrinho}>
              <MdShoppingCart size={26} />
            </button>
          </Cart>
        </Wrapper>
      </Container>
      {isOpen && (
        <MenuBurguer>
          <MenuBurguer.BtnClose isClose onClick={handleMenuBurguer}>
            <MdClose size={30} />
          </MenuBurguer.BtnClose>
          <ButtonTheme onClick={toggleTheme}>
            {theme.mode === 'light' ? (
              <MdLightMode size={20} />
            ) : (
              <MdDarkMode size={20} />
            )}
          </ButtonTheme>

          <User>
            <User.Profile>
              <h4>{`Olá, ${profile.name.split(' ', 1)}`}</h4>
              <button type="button" onClick={handleUsuarioPerfil}>
                Meu Perfil
              </button>
            </User.Profile>
            {/* {signed && (
              <>
                {!app && (
                  <User.WrapperButtons>
                    <User.WrapperButtons.Button onClick={handleUsuarioPerfil}>
                      Meu Perfil
                    </User.WrapperButtons.Button>
                    <User.WrapperButtons.Button
                      onClick={handleUsuarioSolicitacao}
                    >
                      Minhas Solicitações
                    </User.WrapperButtons.Button>
                    <User.WrapperButtons.Button isLogout onClick={handleLogout}>
                      Sair
                    </User.WrapperButtons.Button>
                  </User.WrapperButtons>
                )}
              </>
            )} */}
          </User>

          <ul>
            {!app &&
              [
                opTipoAcesso.ADMIN_EMPRESA,
                opTipoAcesso.CLIENTE,
                opTipoAcesso.USUARIO,
              ].includes(Number(profile.adm)) && (
                <Menu selected={url.startsWith('/catalogo.partes')}>
                  <Link to="/catalogo.partes" onClick={handleMenuBurguer}>
                    Home
                  </Link>
                </Menu>
              )}

            {!app &&
              [opTipoAcesso.ADMIN_EMPRESA].includes(Number(profile.adm)) && (
                <Menu
                  selected={
                    url.startsWith('/empresa') ||
                    url.startsWith('/edicao') ||
                    (url.startsWith('/catalogo') && !url.includes('.'))
                  }
                >
                  <Link
                    to={{
                      pathname: '/empresa/detalhe',
                      state: { id: profile.idEmpresa },
                    }}
                    onClick={handleMenuBurguer}
                  >
                    Administrativo
                  </Link>
                </Menu>
              )}

            <Menu.Divide />

            <Menu>
              <Menu.LinkBtn type="button" onClick={handleUsuarioSolicitacao}>
                Minhas solicitações
              </Menu.LinkBtn>
            </Menu>

            <Menu>
              <Menu.ExitBtn type="button" onClick={handleLogout}>
                Sair
              </Menu.ExitBtn>
            </Menu>
          </ul>
        </MenuBurguer>
      )}
    </>
  );
}
