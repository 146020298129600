import styled from 'styled-components';

export const Table = styled.table`
  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    font-weight: normal;
    text-align: left;
  }

  th,
  td {
    margin: 0;
    padding: 5px 2px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }

    input {
      padding: 0;
      margin: 0;
      border: 0;
      width: 100%;

      :focus {
        background: #dfe092;
      }
    }

    button {
      background: none;
      border: none;
      /* width: 100%; */
      color: #de3b3b;
    }

    .resizer {
      display: inline-block;
      background: blue;
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;

      &.isResizing {
        background: red;
      }
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;

  > button {
    border: 0;
    background: none;
    height: 28px;
    width: 28px;
    transition: all 1s;

    + button {
      margin-left: 5px;
    }

    :hover {
      color: #ff6d3c;
    }
  }

  > span {
    padding: 0 10px;

    > input {
      width: 80px;
      margin: 0 10px;
    }
  }
`;

export const OperationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    width: 42px;
    margin-left: 3px;

    transition: all 0.3s ease-in-out;

    + button {
      margin-right: 3px;
    }

    &:hover {
      color: #fff;
      background-color: #de3b3b;
    }
  }
`;
