import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  MdFirstPage,
  MdChevronLeft,
  MdChevronRight,
  MdLastPage,
} from 'react-icons/md';

import { Form, Row } from '../../Form';
import Input from '../../Form/Input';
import { Location } from './Location';

import { Container, Wrapper, Navigation } from './styles';

const Pagination = forwardRef(
  ({ pageCount, initialData, onPageChange, location }, ref) => {
    const formRef = useRef(null);

    const [initialPage, setInitialPage] = useState(initialData);

    /** useImperativeHandle functions */
    useImperativeHandle(ref, () => ({
      setPage: (page) => formRef.current.setFieldValue('paginaAtual', page),
      getPage: () => formRef.current.getFieldValue('paginaAtual'),
    }));

    const handlePageChange = useCallback(
      (page) => {
        formRef.current.setFieldValue('paginaAtual', page);

        onPageChange(Number(page));
      },
      [onPageChange]
    );

    const handleLeftRightChange = useCallback(
      (page) => {
        const paginaAtual = Number(
          formRef.current.getFieldValue('paginaAtual')
        );
        const paginaNova = paginaAtual + page;

        if (paginaNova >= 1 && paginaNova <= pageCount) {
          handlePageChange(paginaNova);
        }
      },
      [formRef, pageCount, handlePageChange]
    );

    const handleSubmit = useCallback(
      (data) => {
        const { paginaAtual } = data;

        /** verifica se a página é maior que o total de páginas */
        if (paginaAtual >= 1 && paginaAtual <= pageCount) {
          handlePageChange(Number(paginaAtual));
        }
      },
      [pageCount, handlePageChange]
    );

    useEffect(() => {
      setInitialPage(initialData);
    }, [initialData]);

    return (
      <Container location={!!location}>
        <Navigation>
          <button type="button" onClick={() => handlePageChange(1)}>
            <MdFirstPage size={15} />
          </button>
          <button type="button" onClick={() => handleLeftRightChange(-1)}>
            <MdChevronLeft size={15} />
          </button>

          <Wrapper location={!!location}>
            <Form
              ref={formRef}
              initialData={{ paginaAtual: initialPage }}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Row customPagination>
                <Input
                  id="paginaAtual"
                  name="paginaAtual"
                  width={90}
                  type="number"
                  min={1}
                  max={pageCount || 999}
                  customPagination
                />
                <span>{`de ${pageCount}`}</span>
              </Row>
            </Form>
          </Wrapper>

          <button type="button" onClick={() => handleLeftRightChange(1)}>
            <MdChevronRight size={15} />
          </button>
          <button type="button" onClick={() => handlePageChange(pageCount)}>
            <MdLastPage size={15} />
          </button>
        </Navigation>

        {location && <Location location={location} />}
      </Container>
    );
  }
);

export default Pagination;

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialData: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  location: PropTypes.shape({
    catalogo: PropTypes.number,
    pagina: PropTypes.number,
  }),
};
Pagination.defaultProps = {
  initialData: 1,
  location: null,
};
