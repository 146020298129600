import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Scope } from '@unform/core';
import { MdDelete, MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { AppError } from '../../../errors/AppError';

import {
  atualizaQuantidadeRequest,
  atualizaItemOSRequest,
  removeCarrinho,
  limpaCarrinho,
} from '../../../store/modules/cart/actions';

import history from '../../../services/history';
import api from '../../../services/api';

import { opTipoAcao } from '../../../lib/const';
import {
  sendServerEmail,
  // sendServerWhatsApp,
  labelExtExtra01,
  labelExtExtra02,
  labelExtExtra03,
  labelExtExtra04,
} from '../../../lib/paramUtils';
import { toCSV } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';

import TitleBar from '../../../components/TitleBar';

import { Form } from '../../../components/Form';
import InputViewer from '../../../components/Form/Input/Viewer';
import Select from '../../../components/Form/Input/Select';

import { RowMaster } from '../../../components/Table';

import { emailTipo } from '../../Usuario/_usuario-email';

import { Container, Wrapper, Section, Table, Footer } from './styles';
// import { whatsAppTipo } from '../../WhatsApp';

function Carrinho() {
  const location = useLocation();
  const dispatch = useDispatch();

  const itensCarrinho = useSelector((state) => {
    const { cart, user } = state;

    /** dados do carrinho */
    const {
      items,
      order: {
        extCodiDiv2,
        extNumeOS,
        extItemOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
      },
    } = cart;

    /** dados do usuário */
    const { profile } = user || {};
    const { name = '' } = profile || {};

    /** percorre todos os itens do carrinho */
    const itens = items.map((item) => {
      const { materialFabricante, paginaCatalogo, ...itemRestante } = item;

      const {
        catalogo: { modelo },
      } = paginaCatalogo;

      return {
        ...itemRestante,
        ...materialFabricante,
        itemOSFormatado: extItemOS.map((sequencia) => ({
          value: sequencia,
          label: sequencia,
        })),
        paginaCatalogo,
        modelo: modelo.map((m) => m.descricao).join(', '),
      };
    });

    const extObservacaoFormatada = extObservacao || '';

    return {
      extCodiDiv2,
      extNumeOS,
      extObservacao: extObservacaoFormatada.split('$n').join('\r\n'),
      extUsuario,
      extExtra01,
      extExtra02,
      extExtra03,
      extExtra04,
      nome: name,
      itens,
    };
  });

  const { app, action } = useSelector((state) => state.auth || {});

  const handleExcluir = useCallback(
    (idCatalogo, idPagina, idItem, idMaterial, materialEmpresa) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      dispatch(
        removeCarrinho(idCatalogo, idPagina, idItem, idMaterial, codimate)
      );
    },
    [dispatch]
  );

  const handleAdicionar = useCallback(
    (
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      materialEmpresa,
      quantidade = 1
    ) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      const itemIndex = codimate
        ? itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial &&
              item.materialEmpresa.codimate === codimate
          )
        : itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial
          );

      if (itemIndex >= 0) {
        const quantidadeTotal =
          itensCarrinho.itens[itemIndex].quantidadeTotal + quantidade;

        dispatch(
          atualizaQuantidadeRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            quantidadeTotal,
            codimate
          )
        );
      }
    },
    [itensCarrinho, dispatch]
  );

  const handleRemover = useCallback(
    (
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      materialEmpresa,
      quantidade = 1
    ) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      const itemIndex = codimate
        ? itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial &&
              item.materialEmpresa.codimate === codimate
          )
        : itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial
          );

      if (itemIndex >= 0) {
        const quantidadeTotal =
          itensCarrinho.itens[itemIndex].quantidadeTotal - quantidade;

        dispatch(
          atualizaQuantidadeRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            quantidadeTotal,
            codimate
          )
        );
      }
    },
    [itensCarrinho, dispatch]
  );

  const handleSelecionado = useCallback(
    ({ value }, idCatalogo, idPagina, idItem, idMaterial, materialEmpresa) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      dispatch(
        atualizaItemOSRequest(
          idCatalogo,
          idPagina,
          idItem,
          idMaterial,
          value,
          codimate
        )
      );
    },
    [dispatch]
  );

  const handleExportar = useCallback(() => {
    const data = itensCarrinho.itens.map((item) => {
      const {
        materialEmpresa,
        partnumber,
        descricao,
        especTecnica,
        modelo,
        fabricante: { descricao: fabricante },
        quantidade,
        quantidadeTotal,
        paginaCatalogo: {
          descricao: pagina,
          catalogo: { nome: catalogo, versao },
        },
      } = item;

      const {
        codimate: codimateERP = null,
        descricao: descricaoERP = null,
        unidade: unidadeERP = null,
      } = materialEmpresa || {};

      return [
        codimateERP,
        descricaoERP,
        unidadeERP,
        partnumber,
        descricao,
        especTecnica,
        modelo,
        fabricante,
        quantidade,
        quantidadeTotal,
        pagina,
        catalogo,
        versao,
      ];
    });

    const fields = [
      'Código Material (ERP)',
      'Descrição (ERP)',
      'Unidade (ERP)',
      'Partnumber',
      'Descrição',
      'Espec. Técnica',
      'Modelo',
      'Fabricante',
      'Quantidade Catálogo',
      'Quantidade Solicitada',
      'Página',
      'Catálogo',
      'Versão',
    ];

    const csv = toCSV(fields, data);

    try {
      /** cria um link para o processo de download */
      const link = document.createElement('a');
      const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

      link.href = file;
      link.download = `catalogo-eletronico-carrinho.csv`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      AppError(err);
    }
  }, [itensCarrinho]);

  const handleEmail = useCallback(
    async (paraEmpresa = false) => {
      const data = itensCarrinho.itens.map((item) => {
        const {
          materialEmpresa,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante: { descricao: fabricante },
          quantidade,
          quantidadeTotal,
          paginaCatalogo: {
            descricao: pagina,
            catalogo: { nome: catalogo, versao },
          },
        } = item;

        const {
          codimate: codimateERP = null,
          descricao: descricaoERP = null,
          unidade: unidadeERP = null,
        } = materialEmpresa || {};

        return [
          codimateERP,
          descricaoERP,
          unidadeERP,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante,
          quantidade,
          quantidadeTotal,
          pagina,
          catalogo,
          versao,
        ];
      });

      const fields = [
        'Código Material (ERP)',
        'Descrição (ERP)',
        'Unidade (ERP)',
        'Partnumber',
        'Descrição',
        'Espec. Técnica',
        'Modelo',
        'Fabricante',
        'Quantidade Catálogo',
        'Quantidade Solicitada',
        'Página',
        'Catálogo',
        'Versão',
      ];

      try {
        const csv = toCSV(fields, data);
        const blobFile = new Blob([`\uFEFF${csv}`], {
          type: 'text/csv;charset=utf-8;',
        });

        /** verifica parâmetro para envio de e-mail pelo servidor ou serviço próprio */
        if (sendServerEmail()) {
          history.push('/enviar-email', {
            background: location,
            tipo: emailTipo.EMAIL_CARRINHO,
            paraEmpresa,
            data: { attachment: blobFile },
          });
        } else {
          const formData = new FormData();
          formData.append('file', blobFile);

          const response = await api.post('email', formData);
          const { path } = response.data;

          const subject = encodeURIComponent(
            `Catálogo Eletrônico - Lista de Compras`
          );
          const body = encodeURIComponent(
            `Olá,\n\nSegue link para download da lista de compras:\n${path}\n\nAssiste - Catálogo Eletrônico`
          );

          /** cria um link para o processo de download */
          const link = document.createElement('a');
          link.href = `mailto:nome@endereco.com?subject=${subject}&body=${body}`;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      } catch (err) {
        AppError(err);
      }
    },
    [location, itensCarrinho]
  );

  // const handleWhatsApp = useCallback(
  //   () => async (paraEmpresa = false) => {
  //     console.log('Entrou no handleWhatsapp');
  //     const data = itensCarrinho.itens.map((item) => {
  //       const {
  //         materialEmpresa,
  //         partnumber,
  //         descricao,
  //         especTecnica,
  //         modelo,
  //         fabricante: { descricao: fabricante },
  //         quantidade,
  //         quantidadeTotal,
  //         paginaCatalogo: {
  //           descricao: pagina,
  //           catalogo: { nome: catalogo, versao },
  //         },
  //       } = item;

  //       const {
  //         codimate: codimateERP = null,
  //         descricao: descricaoERP = null,
  //         unidade: unidadeERP = null,
  //       } = materialEmpresa || {};

  //       return [
  //         codimateERP,
  //         descricaoERP,
  //         unidadeERP,
  //         partnumber,
  //         descricao,
  //         especTecnica,
  //         modelo,
  //         fabricante,
  //         quantidade,
  //         quantidadeTotal,
  //         pagina,
  //         catalogo,
  //         versao,
  //       ];
  //     });

  //     const fields = [
  //       'Código Material (ERP)',
  //       'Descrição (ERP)',
  //       'Unidade (ERP)',
  //       'Partnumber',
  //       'Descrição',
  //       'Espec. Técnica',
  //       'Modelo',
  //       'Fabricante',
  //       'Quantidade Catálogo',
  //       'Quantidade Solicitada',
  //       'Página',
  //       'Catálogo',
  //       'Versão',
  //     ];

  //     try {
  //       const csv = toCSV(fields, data);
  //       const blobFile = new Blob([`\uFEFF${csv}`], {
  //         type: 'text/csv;charset=utf-8;',
  //       });

  //       /** verifica parâmetro para envio de e-mail pelo servidor ou serviço próprio */
  //       if (sendServerWhatsApp()) {
  //         history.push('/whatsapp', {
  //           background: location,
  //           tipo: whatsAppTipo.WHATSAPP_CARRINHO,
  //           paraEmpresa,
  //           data: { attachment: blobFile },
  //         });
  //       } else {
  //         const formData = new FormData();
  //         formData.append('file', blobFile);

  //         const response = await api.post('email', formData);
  //         const { path } = response.data;

  //         const subject = encodeURIComponent(
  //           `Catálogo Eletrônico - Lista de Compras`
  //         );
  //         const body = encodeURIComponent(
  //           `Olá,\n\nSegue link para download da lista de compras:\n${path}\n\nAssiste - Catálogo Eletrônico`
  //         );

  //         /** cria um link para o processo de download */
  //         const link = document.createElement('a');
  //         link.href = `mailto:nome@endereco.com?subject=${subject}&body=${body}`;

  //         document.body.appendChild(link);
  //         link.click();

  //         document.body.removeChild(link);
  //       }
  //     } catch (err) {
  //       AppError(err);
  //     }
  //   },
  //   [location, itensCarrinho]
  // );

  const handleConfirmar = useCallback(async () => {
    /** solicitação por token */
    if (action === opTipoAcao.INTEGRACAO_TOKEN) {
      handleEmail(true);
      return;
    }

    try {
      const {
        extCodiDiv2,
        extNumeOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
      } = itensCarrinho;

      const response = await api.post('/solicitacao', {
        extCodiDiv2,
        extNumeOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
        itensCarrinho: itensCarrinho.itens,
      });

      const { idSolicitacao } = response.data;

      /** limpa itens do carrinho */
      dispatch(limpaCarrinho());

      /** redireciona para página de solicitação */
      history.push('/checkout', { id: idSolicitacao });
    } catch (err) {
      AppError(err);
    }
  }, [dispatch, itensCarrinho, action, handleEmail]);

  /** sincroniza rótulos */
  const rotuloExtExtra01 = useMemo(() => labelExtExtra01(), []);
  const rotuloExtExtra02 = useMemo(() => labelExtExtra02(), []);
  const rotuloExtExtra03 = useMemo(() => labelExtExtra03(), []);
  const rotuloExtExtra04 = useMemo(() => labelExtExtra04(), []);

  const redirectToPage = useCallback((idCatalogo, idPagina) => {
    api
      .get(`/catalogo/${idCatalogo}/nivel-classificacao/pagina/${idPagina}`)
      .then((response) => {
        const { data } = response;

        const {
          idNivel,
          relacNivel: { nivel },
        } = data;

        history.push(
          `/catalogo.partes/${idCatalogo}?IDN_${nivel}=${idNivel}&IDP=${idPagina}`
        );
      })
      .catch((erro) => {
        console.error(erro);
      });
  }, []);

  return (
    <Container>
      <TitleBar back title="Materiais selecionados" width={950} />
      <Wrapper>
        {itensCarrinho.itens.length > 0 ? (
          <Form initialData={itensCarrinho} onSubmit={handleConfirmar}>
            <Table>
              <thead>
                <tr>
                  <th> </th>
                  <th>Partnumber</th>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {itensCarrinho.itens.map((item, index) => (
                  <RowMaster key={index} noCursoPointer>
                    <td>
                      <Wrapper.BtnImg
                        onClick={() =>
                          redirectToPage(item.idCatalogo, item.idPagina)
                        }
                      >
                        <img src={item.imagemCatalogo} alt="Imagem catálogo" />
                      </Wrapper.BtnImg>
                    </td>
                    <td>
                      <strong>{item.partnumber}</strong>
                    </td>
                    <td>
                      <Table.Description>
                        <strong>{item.descricao}</strong>
                        <span>{`Modelo: ${item.modelo}`}</span>
                        <span>{`Fabricante: ${item.fabricante.descricao}`}</span>
                        {/* <span>{`Fabricante: ${item.materialFabricante.descricao}`}</span> */}
                        {/* <span>{`Fabricante: ${item.materialEmpresa}`}</span> */}
                        {item.materialEmpresa ? (
                          <>
                            <span>{`Código ERP: ${item.materialEmpresa.codimate}`}</span>
                            {item.materialEmpresa.paralelo === '1' && (
                              <Table.Error>
                                Atenção: O material selecionado está cadastrado
                                como não original
                              </Table.Error>
                            )}
                          </>
                        ) : (
                          <Table.Error>
                            Nenhum Código ERP relacionado com esse material para
                            integração
                          </Table.Error>
                        )}

                        {itensCarrinho.extNumeOS && (
                          <Scope path={`extItensOS[${index}]`}>
                            <InputViewer
                              id="itemKey"
                              name="itemKey"
                              defaultValue={index}
                              isHidden
                            />
                            <Table.Combo>
                              Item O.S.:
                              <Select
                                id="itemValue"
                                name="itemValue"
                                options={item.itemOSFormatado}
                                defaultValue={item.itemOSFormatado[0]}
                                width={80}
                                onChange={(select) =>
                                  handleSelecionado(
                                    select,
                                    item.idCatalogo,
                                    item.idPagina,
                                    item.idItem,
                                    item.idMaterial,
                                    item.materialEmpresa
                                  )
                                }
                              />
                            </Table.Combo>
                          </Scope>
                        )}
                      </Table.Description>
                    </td>
                    <td>
                      <strong>
                        <Table.Quantity>
                          <button
                            type="button"
                            onClick={() =>
                              handleRemover(
                                item.idCatalogo,
                                item.idPagina,
                                item.idItem,
                                item.idMaterial,
                                item.materialEmpresa
                              )
                            }
                          >
                            <MdChevronLeft size={25} />
                          </button>
                          <span>{item.quantidadeTotal}</span>
                          <button
                            type="button"
                            onClick={() =>
                              handleAdicionar(
                                item.idCatalogo,
                                item.idPagina,
                                item.idItem,
                                item.idMaterial,
                                item.materialEmpresa
                              )
                            }
                          >
                            <MdChevronRight size={25} />
                          </button>
                        </Table.Quantity>
                      </strong>
                    </td>
                    <td>
                      <Table.Action>
                        <Table.BtnRemove
                          type="button"
                          onClick={() =>
                            handleExcluir(
                              item.idCatalogo,
                              item.idPagina,
                              item.idItem,
                              item.idMaterial,
                              item.materialEmpresa
                            )
                          }
                        >
                          <MdDelete size={25} />
                        </Table.BtnRemove>
                      </Table.Action>
                    </td>
                  </RowMaster>
                ))}
              </tbody>
            </Table>

            <Section>
              <Form.Title>Outras Informações</Form.Title>
              <Form.Column>
                <Form.Row>
                  <InputViewer
                    id="nome"
                    name="nome"
                    label="Solicitante:"
                    asRow
                  />
                </Form.Row>
                {itensCarrinho.extNumeOS && (
                  <>
                    <InputViewer id="extCodiDiv2" name="extCodiDiv2" isHidden />
                    <Form.Row>
                      <InputViewer
                        id="extNumeOS"
                        name="extNumeOS"
                        label="Nº OS:"
                        asRow
                      />
                    </Form.Row>
                  </>
                )}
                {itensCarrinho.extUsuario && (
                  <Form.Row>
                    <InputViewer
                      id="extUsuario"
                      name="extUsuario"
                      label="Usuário:"
                      asRow
                    />
                  </Form.Row>
                )}
                {itensCarrinho.extExtra01 && (
                  <Form.Row>
                    <InputViewer
                      id="extExtra01"
                      name="extExtra01"
                      label={rotuloExtExtra01}
                      asRow
                    />
                  </Form.Row>
                )}
                {itensCarrinho.extExtra02 && (
                  <Form.Row>
                    <InputViewer
                      id="extExtra02"
                      name="extExtra02"
                      label={rotuloExtExtra02}
                      asRow
                    />
                  </Form.Row>
                )}
                {itensCarrinho.extExtra03 && (
                  <Form.Row>
                    <InputViewer
                      id="extExtra03"
                      name="extExtra03"
                      label={rotuloExtExtra03}
                      asRow
                    />
                  </Form.Row>
                )}
                {itensCarrinho.extExtra04 && (
                  <Form.Row>
                    <InputViewer
                      id="extExtra04"
                      name="extExtra04"
                      label={rotuloExtExtra04}
                      asRow
                    />
                  </Form.Row>
                )}
                {itensCarrinho.extObservacao && (
                  <Form.Row>
                    <InputViewer
                      id="extObservacao"
                      name="extObservacao"
                      type="textarea"
                    />
                  </Form.Row>
                )}
              </Form.Column>
            </Section>

            <Footer>
              <ActionMenu
                Icon="Exportar lista de compras"
                onExportCSV={handleExportar}
                onMail={handleEmail}
                // onWhatsApp={handleWhatsApp}
              />

              {(!app || action === opTipoAcao.INTEGRACAO_CATALOGO) && (
                <Footer.Submit type="submit">
                  {action === opTipoAcao.INTEGRACAO_TOKEN
                    ? 'Solicitar Orçamento'
                    : 'Solicitar Peças'}
                </Footer.Submit>
              )}
            </Footer>
          </Form>
        ) : (
          <Wrapper.NotFound>
            Nenhum item adicionado no carrinho
          </Wrapper.NotFound>
        )}
      </Wrapper>
    </Container>
  );
}

export default Carrinho;
