import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import Icon from '../../../assets/upload.svg';

import { AppError } from '../../../errors/AppError';

import api from '../../../services/api';

import { Container } from './styles';

export default function AvatarInput({ name, onUpload, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);
  const { defaultValue } = useField('file');

  const [preview, setPreview] = useState('');
  const [fileId, setFileId] = useState(defaultValue && defaultValue.id);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'dataset.fileid',
    });
  }, [fieldName, registerField]);

  async function handleChange(e) {
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      const response = await api.post('uploads', formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setPreview(`${Math.round(progress)}%`);
        },
      });

      const { idArquivo, original } = response.data.arquivo;

      onUpload(response.data);

      setPreview(original);
      setFileId(idArquivo);
    } catch (err) {
      setPreview('Falha no carregamento do arquivo');
      AppError(err);
    }
  }

  return (
    <Container>
      <label htmlFor={name}>
        <img src={Icon} alt="PDF" />
        {preview || 'Seleciona o arquivo aqui'}

        <input
          type="file"
          id={name}
          accept="application/pdf"
          data-fileid={fileId}
          onChange={handleChange}
          multiple
          ref={inputRef}
          {...rest}
        />
      </label>
    </Container>
  );
}

AvatarInput.propTypes = {
  name: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
};
