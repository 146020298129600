import styled from 'styled-components';
import { darken } from 'polished';

export const Action = styled.div`
  transition: all 0.5s;

  :hover {
    color: ${darken(0.05, '#1789dd')};
    cursor: pointer;
  }
`;

export const Faded = styled.span`
  svg {
    color: rgba(200, 200, 200, 80);
  }
`;
