import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { getMaterialFabricante } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';

/** validação dos campos */
const schema = Yup.object().shape(
  {
    partnumber: Yup.string().required(
      'O campo "Partnumber" deve ser preenchido'
    ),
    descricaoBR: Yup.string().when('descricaoEN', {
      is: (descricaoEN) => !descricaoEN,
      then: Yup.string().required(
        'É necessário preencher ao menos um dos campos "Descrição"'
      ),
    }),
    descricaoEN: Yup.string().when('descricaoBR', {
      is: (descricaoBR) => !descricaoBR,
      then: Yup.string().required(
        'É necessário preencher ao menos um dos campos "Descrição"'
      ),
    }),
  },
  ['descricaoBR', 'descricaoEN']
);

function MaterialFabricanteCriar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idFabricante } = location.state;

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { partnumber, descricaoBR, descricaoEN, idMaterialOri } = data;

        await api.post(`/material-fabricante`, {
          partnumber,
          descricaoBR,
          descricaoEN,
          idMaterialOri: Number(idMaterialOri) || null,
          idFabricante,
        });

        toast.success('Material Fabricante cadastrado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idFabricante]
  );

  return (
    <>
      <TitleBar
        back={!!location.background}
        title="Criar Material Fabricante"
      />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="partnumber" name="partnumber" label="Partnumber" />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricaoBR"
                name="descricaoBR"
                label="Descrição (BR)"
              />
              <Input
                id="descricaoEN"
                name="descricaoEN"
                label="Descrição (EN)"
              />
            </Form.Row>
            <Form.Row>
              <AsyncSelect
                id="idMaterialOri"
                name="idMaterialOri"
                label="Material Origem"
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) =>
                  getMaterialFabricante({ id: idFabricante, value }, cb)
                }
                menuPosition="fixed"
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="form-ref">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { MaterialFabricanteCriar };
