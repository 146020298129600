import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import { sendServerEmail } from '../../../lib/paramUtils';
import { toCSV } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';

import { emailTipo } from '../../Usuario/_usuario-email';

import { Container, Wrapper, Action } from './styles';

function Checkout() {
  const location = useLocation();
  const { id } = location.state;

  const handleExport = useCallback(async () => {
    const response = await api.get(`solicitacao/${id}`);

    const { idSolicitacao, instInc, itemSolicitacao } = response.data;

    const data = itemSolicitacao.map((item) => {
      const {
        materialERP,
        materialFabricante: { partnumber, descricaoBR, descricaoEN },
        quantidade,
        unidade,
      } = item;

      const { codimate, descricao } = materialERP || {
        codimate: null,
        descricao: null,
      };

      return [
        idSolicitacao,
        format(parseISO(instInc), 'dd/MM/yyyy HH:mm:ss'),
        codimate,
        descricao,
        partnumber,
        descricaoBR,
        descricaoEN,
        quantidade,
        unidade,
      ];
    });

    const fields = [
      'Solicitação',
      'instInc',

      'Código Material (ERP)',
      'Descrição (ERP)',

      'Partnumber',
      'Descrição (BR)',
      'Descrição (EN)',

      'Quantidade',
      'Unidade',
    ];

    const csv = toCSV(fields, data);

    try {
      /** cria um link para o processo de download */
      const link = document.createElement('a');
      const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

      link.href = file;
      link.download = `catalogo-eletronico-solicitacao.csv`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      AppError(err);
    }
  }, [id]);

  const handleMail = useCallback(async () => {
    const solicitacao = await api.get(`solicitacao/${id}`);

    const { idSolicitacao, instInc, itemSolicitacao } = solicitacao.data;

    const data = itemSolicitacao.map((item) => {
      const {
        materialERP,
        materialFabricante: { partnumber, descricaoBR, descricaoEN },
        quantidade,
        unidade,
      } = item;

      const { codimate, descricao } = materialERP || {
        codimate: null,
        descricao: null,
      };

      return [
        idSolicitacao,
        format(parseISO(instInc), 'dd/MM/yyyy HH:mm:ss'),
        codimate,
        descricao,
        partnumber,
        descricaoBR,
        descricaoEN,
        quantidade,
        unidade,
      ];
    });

    const fields = [
      'Solicitação',
      'instInc',

      'Código Material (ERP)',
      'Descrição (ERP)',

      'Partnumber',
      'Descrição (BR)',
      'Descrição (EN)',

      'Quantidade',
      'Unidade',
    ];

    try {
      const csv = toCSV(fields, data);
      const blobFile = new Blob([`\uFEFF${csv}`], {
        type: 'text/csv;charset=utf-8;',
      });

      /** verifica parâmetro para envio de e-mail pelo servidor ou serviço próprio */
      if (sendServerEmail()) {
        history.push('/enviar-email', {
          background: location,
          tipo: emailTipo.EMAIL_CHECKOUT,
          data: {
            idSolicitacao,
            attachment: blobFile,
          },
        });
      } else {
        /** abre serviço de e-mail padrão do cliente */
        const formData = new FormData();
        formData.append('file', blobFile);

        const response = await api.post('email', formData);
        const { path } = response.data;

        const subject = encodeURIComponent(
          `Catálogo Eletrônico - Solicitação de Peças`
        );
        const body = encodeURIComponent(
          `Olá,\n\nSegue link para download da solicitação #${idSolicitacao}:\n${path}\n\nAssiste - Catálogo Eletrônico`
        );

        /** cria um link para o processo de download */
        const link = document.createElement('a');
        link.href = `mailto:nome@endereco.com?subject=${subject}&body=${body}`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      }
    } catch (err) {
      AppError(err);
    }
  }, [id, location]);

  return (
    <Container>
      <Wrapper>
        <h2>Solicitação de peças realizada com sucesso</h2>
        <Action>
          <ActionMenu
            Icon="Exportar solicitação de peças"
            onExportCSV={handleExport}
            onMail={handleMail}
          />

          <Action.Home type="button" onClick={() => history.push('/')}>
            Voltar para a home
          </Action.Home>
        </Action>
      </Wrapper>
    </Container>
  );
}

export default Checkout;
