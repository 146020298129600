import React from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import history from '../../../services/history';

import { Container, Wrapper } from './styles';

export default function ModalLayout({
  withSize,
  withModalBtnClose,
  onBack,
  children,
}) {
  return (
    <Container visible>
      <Wrapper {...withSize}>
        {withModalBtnClose && (
          <button type="button" onClick={onBack || (() => history.goBack())}>
            <MdClose size={20} />
          </button>
        )}

        {children}
      </Wrapper>
    </Container>
  );
}

ModalLayout.propTypes = {
  withSize: PropTypes.objectOf(Object),
  withModalBtnClose: PropTypes.bool,
  onBack: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  children: PropTypes.element.isRequired,
};

ModalLayout.defaultProps = {
  withModalBtnClose: true,
  withSize: null,
  onBack: null,
};
