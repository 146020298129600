import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';

import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { AppError } from '../../../../errors/AppError';

import { getSerie } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';
import Switch from '../../../../components/Form/Input/Switch';

function CatalogoSerieCriar() {
  const location = useLocation();
  const { idCatalogo } = location.state;
  const formRef = useRef();

  const VIEW = {
    serie: 'serie',
    arranjo: 'arranjo',
  };

  const [view, setView] = useState(VIEW.serie);
  const [formState, setFormState] = useState({
    serie: {},
    arranjo: {},
  });

  /** validação dos campos */
  const schema = useCallback(() => {
    switch (view) {
      case VIEW.serie:
        return Yup.object().shape({
          numeroSerie: Yup.string().required('Preenchimento obrigatório'),
        });
      case VIEW.arranjo:
        return Yup.object().shape({
          idSerie: Yup.string().required('Preenchimento obrigatório'),
          nome: Yup.string().required('Preenchimento obrigatório'),
          descricao: Yup.string(),
        });
      default:
        return null;
    }
  }, [view, VIEW]);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        await schema().validate(data, {
          abortEarly: false,
        });

        const { numeroSerie, partnumber, idSerie, nome, descricao } = data;

        switch (view) {
          case VIEW.serie:
            await api.post(`/catalogo/${idCatalogo}/serie-catalogo`, {
              numeroSerie,
              partnumber,
            });
            break;
          case VIEW.arranjo:
            await api.post(
              `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo`,
              {
                nome,
                descricao,
              }
            );
            break;
          default:
            break;
        }

        reset();

        toast.success(
          `${
            view === VIEW.serie ? 'Número de série' : 'Arranjo'
          } adicionado com sucesso!`
        );
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idCatalogo, view, VIEW, schema]
  );

  /** salva o estado do formulário */
  const saveFormState = (formView) => {
    const toSave = formRef.current.getData();

    setFormState((state) => {
      delete state[formView];

      return { ...state, [formView]: toSave };
    });

    formRef.current.reset();
  };

  /** troca o formulário de visualização atual */
  const changeView = useCallback(() => {
    saveFormState(view);
    setView((old) => (old === VIEW.serie ? VIEW.arranjo : VIEW.serie));
  }, [VIEW, view]);

  /** carrega o formulário com o último estado daquela view */
  useEffect(() => {
    formRef.current.setData(formState[view]);
  }, [view, formState]);

  return (
    <>
      <TitleBar
        back={!!location.background}
        title={view === VIEW.serie ? 'Novo número de série' : 'Novo arranjo'}
      >
        <Switch
          margin={'0 16px 0 0'}
          leftText="Nº Série"
          rigthText="Arranjo"
          checked={view === VIEW.arranjo}
          onChange={changeView}
        />
      </TitleBar>

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{}}
          autoComplete="off"
        >
          <Form.Column>
            {view === VIEW.serie ? (
              <Form.Row>
                <Input
                  id="numeroSerie"
                  name="numeroSerie"
                  label="Nº de Série"
                />
                <Input id="partnumber" name="partnumber" label="Partnumber" />
              </Form.Row>
            ) : (
              <>
                <Form.Row>
                  <Input id="nome" name="nome" label="Nome do Arranjo" />
                  <AsyncSelect
                    id="idSerie"
                    name="idSerie"
                    label="Número de Série"
                    cacheOptions
                    defaultOptions
                    loadOptions={(value, cb) =>
                      getSerie({ id: idCatalogo, value }, cb)
                    }
                  />
                </Form.Row>
                <Form.Row>
                  <Input id="descricao" name="descricao" label="Descrição" />
                </Form.Row>
              </>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { CatalogoSerieCriar };
