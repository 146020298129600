import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  flex: 1;
  padding: 20px 60px;
  position: relative;
`;

export const ZoomWrapper = styled.div`
  flex: 1;
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    flex: 1;
    border-radius: 10px;
    max-height: 100%;
    min-width: 100%;
  }
`;

export const ZoomControl = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 2;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 5px;
    color: #cbd0d8;
    margin: 0 2px;
    padding: 10px;
    border: 0;

    :hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }
`;
