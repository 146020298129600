// import { jsPDF as JsPDF } from 'jspdf';
// import { format } from 'date-fns';

import { AppError } from '../errors/AppError';

import api from '../services/api';

export const opPdfExport = {
  CATALOGO_PAGINA: 'pagina-catalogo',
  RETIRADA_LISTA: 'retirada-lista',
  RETIRADA_ITEM: 'retirada-item',
};

const criarExportacao = async (
  exportacao,
  params = {},
  cb = null,
  cbBefore = null,
  cbAfter = null
) => {
  /** processo de callback no inicio do evento de exportacao */
  if (cbBefore) cbBefore();
  api
    .get('pdf/exportar', {
      params: {
        tipoExportacao: exportacao,
        ...params,
      },
    })
    /** anexa os dados e faz o download do documento */
    .then((response) => {
      const { filename } = response.data;

      /** retorna passando o endereço do arquivo */
      if (cb) {
        cb(filename);
        return;
      }

      /** cria link */
      const link = document.createElement('a');
      link.setAttribute('href', filename);
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');

      /** executa link para abrir PDF em uma nova aba */
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      AppError(err);
    })
    .finally(() => {
      /** processo de callback na finalizacao do evento de exportacao */
      if (cbAfter) cbAfter();
    });
};

// export default class PrintPDF {
//   constructor() {
//     this.context = new JsPDF({
//       encryption: {
//         userPassword: '',
//         ownerPassword: 'owner',
//         userPermissions: ['print'],
//       },
//       compress: true,
//     });
//     this.posY = 5;
//     this.posX = 5;
//     this.currentDataTime = format(Date.now(), 'dd/MM/yyyy HH:mm');
//   }

//   withHeader(name, empName, empImageUrl = '') {
//     /** define posição inicial do texto */
//     const currentPosX = empImageUrl ? 35 : 10;

//     if (empImageUrl) {
//       this.context.addImage(empImageUrl, 'PNG', 10, 15, 20, 8);
//     }

//     this.context.setFont('times', 'bold');
//     this.context.setFillColor('#2c405a');
//     this.context.setFontSize(18);
//     this.context.text(empName, currentPosX, 15);

//     this.context.setFontSize(14);
//     this.context.text(
//       'Catálogo Eletrônico | Assiste - Eng. de Softwares Técnicos',
//       currentPosX,
//       22
//     );

//     this.context.setFont('times', 'normal');
//     this.context.setFontSize(14);
//     this.context.text(name, currentPosX, 28);

//     // Contorno do cabeçalho
//     this.context.rect(5, 5, 200, 30);

//     this.posY = 32;
//   }

//   withFooter() {
//     this.context.setFont('times', 'normal');
//     this.context.setFontSize(10);
//     this.context.text(
//       `Página ${this.context.internal.getNumberOfPages()}`,
//       105,
//       287,
//       {
//         align: 'center',
//       }
//     );

//     this.context.text(`Emissão: ${this.currentDataTime}`, 200, 287, {
//       align: 'right',
//     });
//   }

//   isNewPage(value = 0) {
//     if (this.posY + value > 270) {
//       this.posY = 5;

//       this.context.addPage();

//       this.withFooter();
//     }
//   }
// }

const PDF = {
  criarExportacao,
};

export { PDF };
