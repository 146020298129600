import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  z-index: 99;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* background: #f2f5fc; */
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #00000033;
  margin: 5px;
  height: 80%;
  width: 60%;
  overflow-y: auto;

  @media (max-width: 600px) {
    width: 100%;
  }

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  padding: 10px 20px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2c405a;
    /* background: #eee; */
    /* border: 1px solid #eee; */
    background: none;
    border: 0;
    border-radius: 25px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    height: 32px;
    width: 32px;
    transition: background 1s;

    &:hover {
      background: ${darken(0.1, '#fff')};
    }
  }
`;
