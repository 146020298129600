import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import { unparse } from 'papaparse';
import { ReactSVG } from 'react-svg';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';

import Resizeble from '../../../../components/Resizeble';

import api from '../../../../services/api';

import { Container, MenuWrapper, TableWrapper } from './styles';

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

const DeleteButton = ({ row: { id }, deleteMyData }) => (
  <button type="button" onClick={() => deleteMyData(id)}>
    <MdDelete size={16} />
  </button>
);

function EditableTable({
  columns,
  data,
  updateMyData,
  deleteMyData,
  skipPageReset,
}) {
  const defaultColumn = useMemo(
    () => ({
      Cell: EditableCell,
      minWidth: 30,
      width: 200,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      deleteMyData,
    },
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: 'selection',
          disableResizing: true,
          width: 50,
          Header: () => <div />,
          Cell: DeleteButton,
        },
        ...columns,
      ]);
    }
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups &&
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows &&
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

function MappingTable({ idCatalogo, page, onClose }) {
  const resizeRef = useRef(null);

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);

  const [skipPageReset, setSkipPageReset] = useState(false);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const deleteMyData = (rowIndex) => {
    setSkipPageReset(true);
    setTableData(tableData.filter((row) => row !== tableData[rowIndex]));
  };

  useEffect(() => {
    if (resizeRef.current) {
      resizeRef.current.resizeClear();
    }

    setSkipPageReset(false);
  }, [tableData]);

  async function handleProcessar() {
    const selecao = resizeRef.current.resizeCoords();
    const { borderBox, points } = selecao;

    const response = await api.post(
      `editor.extrair/${idCatalogo}/${page.idPagina}`,
      {
        pages: [
          {
            pageNumber: page.idPagina,
            borderBox,
            points: points.sort((a, b) => a - b),
          },
        ],
      }
    );

    // const resTable = response.data[0];

    // console.log(resTable);

    // const resTableTitle = resTable.reduce((accumulator, currentValue) => [...accumulator, currentValue.id], []);
    // const resTableData = resTable.map(({ column }) =>  ([...column.map(({ str }) => str)]));

    // const maxRow = resTable.reduce((accumulator, currentValue) => currentValue.column.length > accumulator ? currentValue.column.length : accumulator , 0);
    // const tableRows = Array.from({ length: maxRow }, (v, k) => k + 1 - 1);

    // const formattedHeader = resTable.map(({ id }, index) => ({ Header: `Coluna ${index}`, accessor: id}));
    // const formattedData = tableRows.map((index) => Object.fromEntries(new Map(resTableData.map(data => data[index] === undefined ? '-' : data[index]).map((data, index) => [resTableTitle[index], data]))));

    // console.log(formattedHeader, formattedData);

    const { formattedData, formattedHeader } = response.data;

    setOriginalData(formattedData);
    setTableData(formattedData);
    setTableColumn(formattedHeader);

    if (resizeRef.current) {
      resizeRef.current.resizeClear();
    }
  }

  function handleDesfazer() {
    setTableData(originalData);
  }

  function handlePlanilha() {
    const csv = unparse(tableData, { delimiter: ';', encoding: 'ISO-8859-1' });

    const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;
    const link = document.createElement('a');
    link.setAttribute('href', file);
    link.setAttribute('download', `page-${page && page.idPagina}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Modal width={98} height={98} onClose={onClose}>
      <Container>
        <MenuWrapper>
          <button id="btn-back" type="button" onClick={onClose}>
            Voltar
          </button>
          <button type="button" onClick={handleProcessar}>
            Processar
          </button>
          <button type="button" onClick={handleDesfazer}>
            Desfazer
          </button>
          <button type="button" onClick={handlePlanilha}>
            Planilha
          </button>
        </MenuWrapper>
        {page && (
          <Resizeble id={Math.random()} ref={resizeRef}>
            <ReactSVG
              id={`page-${page.idPagina}`}
              src={page.urlEdicao}
              tabIndex="0"
              loading={() => <span>Loading</span>}
              fallback={() => <span>Selecione a imagem para editar</span>}
              // beforeInjection={(svg) => {}}
            />
          </Resizeble>
        )}
        <TableWrapper>
          <EditableTable
            columns={tableColumn}
            data={tableData}
            updateMyData={updateMyData}
            deleteMyData={deleteMyData}
            skipPageReset={skipPageReset}
          />
        </TableWrapper>
      </Container>
    </Modal>
  );
}

MappingTable.propTypes = {
  idCatalogo: PropTypes.number.isRequired,
  page: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MappingTable;
