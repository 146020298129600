import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { TextAreaWrapper } from '../..';

export default function TextArea({ name, label, width, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <TextAreaWrapper width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <textarea
        ref={inputRef}
        className={error ? 'has-error' : ''}
        defaultValue={defaultValue}
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </TextAreaWrapper>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
};

TextArea.defaultProps = {
  label: null,
  width: null,
};
