import {
  opTipoAcesso,
  opCatalogoTipoSequenciaPagina,
  opTipoDocumento,
  opTipoEmpresa,
} from './const';

/** opções para os campos de seleção */
export const opNoYes = [
  { value: 0, label: 'Não' },
  { value: 1, label: 'Sim' },
];

export const opSituacao = [
  { value: 0, label: 'Inativo' },
  { value: 1, label: 'Ativo' },
];

export const opCatalogoSituacao = [
  { value: 0, label: 'Em Desenvolvimento' },
  { value: 1, label: 'Aguardando Aprovação' },
  { value: 2, label: 'Publicado' },
];

export const opAdministrador = [
  { value: opTipoAcesso.USUARIO, label: 'Usuário' },
  { value: opTipoAcesso.ADMIN_PLATAFORMA, label: 'Administrador Plataforma' },
  { value: opTipoAcesso.ADMIN_EMPRESA, label: 'Administrador Empresa' },
  { value: opTipoAcesso.CLIENTE, label: 'Cliente' },
];

export const opEmpresaAdministrador = [
  { value: opTipoAcesso.USUARIO, label: 'Usuário' },
  { value: opTipoAcesso.ADMIN_EMPRESA, label: 'Administrador Empresa' },
];

export const opEmpresa = [
  { value: opTipoEmpresa.SERVICOS, label: 'Serviços / Manutenção' },
  { value: opTipoEmpresa.LOCADORA, label: 'Locadora' },
  { value: opTipoEmpresa.FABRICANTE, label: 'Fabricante' },
  { value: opTipoEmpresa.ADMIN_PLATAFORMA, label: 'Administrador Plataforma' },
];

export const opDocumento = [
  { value: opTipoDocumento.CPF, label: 'CPF' },
  { value: opTipoDocumento.CNPJ, label: 'CNPJ' },
  { value: opTipoDocumento.EXT, label: 'EXT' },
];

export const opMobilidade = [
  { value: 0, label: 'Ativo' },
  { value: 1, label: 'Inativo' },
  { value: 2, label: 'Manutenção' },
  { value: 3, label: 'Reserva' },
  { value: 4, label: 'Danificado' },
];

export const opIdioma = [
  { value: 0, label: 'PT-BR' },
  { value: 1, label: 'EN' },
];

export const opTipoItemPagina = [
  { value: 0, label: 'Não Aplicado' },
  { value: 1, label: 'Círculo' },
  { value: 2, label: 'Quadrado' },
  { value: 3, label: 'Ambos' },
];

export const opNotificacaoTipo = [
  { value: 0, label: 'Banner' },
  { value: 1, label: 'Privado' },
  { value: 2, label: 'Conferência' },
];

export const opStatusSugestao = [
  { value: 0, label: 'Pendente' },
  { value: 1, label: 'Aprovado' },
  { value: 2, label: 'Reprovado' },
];

export const opTipoSequenciaPagina = [
  {
    value: opCatalogoTipoSequenciaPagina.NAO_APLICADO,
    label: 'Não Aplicado',
    disabled: true,
  },
  {
    value: opCatalogoTipoSequenciaPagina.DESENHO_TABELA,
    label: 'Desenho - Tabela',
  },
  {
    value: opCatalogoTipoSequenciaPagina.TABELA_DESENHO,
    label: 'Tabela - Desenho',
  },
];

export const opTipoExtracaoTabela = [
  { value: 0, label: 'Não Aplicado' },
  { value: 1, label: 'Concatenar coluna (Y)' },
];

export const opNivel = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

export const opSolicitacao = [
  { label: 'Solicitada(s)', value: 0 },
  { label: 'Cancelada(s)', value: 1 },
];

export const opPaginaTipo = [
  { label: 'Não Classificado', value: 0 },
  { label: 'Desenho', value: 1 },
  { label: 'Tabela', value: 2 },
  { label: 'Misto', value: 3 },
  { label: 'Removido', value: 4 },
  { label: 'Erro', value: 5 },
];
export const opPaginaSituacao = [
  { label: 'Importado', value: 0 },
  { label: 'Classificado', value: 1 },
  { label: 'Concluído', value: 2 },
  { label: 'Editado', value: 3 },
  { label: 'Erro', value: 9 },
];

export const opDesenhoSituacao = [
  { label: 'Classificado', value: 1 },
  { label: 'Concluído', value: 2 },
  { label: 'Editado', value: 3 },
];
export const opTabelaSituacao = [
  { label: 'Classificado', value: 1 },
  { label: 'Concluído', value: 2 },
];

export const opConversaoTipo = [
  { value: 0, label: 'Manual' },
  { value: 1, label: 'Inteligência Artificial' },
];

export const opTokenDuracao = [
  { value: 0, label: 'Indeterminado' },
  { value: 1, label: '1' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 45, label: '45' },
];

export const opTokenTipo = [
  { value: 0, label: 'Público' },
  { value: 1, label: 'Privado' },
];

export const opNavegacao = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];
