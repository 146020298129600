import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { InputWrapper, FileWrapper } from '../..';

export default function InputFile({
  id,
  name,
  label,
  width,
  height,
  backgroundURL,
  canUpload,
  handleChange,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper width={width} height={height} flexDirection="column">
      <FileWrapper withBackground={backgroundURL}>
        {canUpload && (
          <div>
            <>
              <strong>ARRASTE E SOLTE OS ARQUIVOS AQUI</strong>
              <span>ou se preferir...</span>
              <label htmlFor={id}>
                {label}
                <input
                  id={id}
                  type="file"
                  ref={inputRef}
                  className={error ? 'has-error' : ''}
                  defaultValue={defaultValue}
                  onChange={handleChange}
                  {...rest}
                />
              </label>
            </>
          </div>
        )}
      </FileWrapper>
    </InputWrapper>
  );
}

InputFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundURL: PropTypes.string,
  canUpload: PropTypes.bool,
};

InputFile.defaultProps = {
  width: null,
  height: null,
  backgroundURL: null,
  canUpload: false,
};
