import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper } from './styles';

function Loading({ visible }) {
  return (
    <Container visible={visible}>
      <Wrapper type="bubbles" />
    </Container>
  );
}

Loading.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Loading;
