/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  MdShoppingCart,
  MdClose,
  MdChevronLeft,
  MdChevronRight,
  MdMoreHoriz,
} from 'react-icons/md';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { AppError } from '../../errors/AppError';

import { Form, Row, Column } from '../Form';
import SelectInput from '../Form/Input/Select';
import Input from '../Form/Input';

import { adicionaCarrinhoRequest } from '../../store/modules/cart/actions';

import { Info } from '../Info';

import { CartModal, Content } from './styles';

function ItemCart({ containerRef, item, onClose }) {
  const modalRef = useRef();
  const formRef = useRef();

  const dispatch = useDispatch();

  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  // const [move, setMove] = useState(false);

  useEffect(() => {
    const { quantidade } = item || { quantidade: 0 };

    /** insere a quantidade indicada do item */
    formRef.current.setFieldValue('quantidade', quantidade);
  }, [item]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        /** validação do preenchimento do campo código ERP  */
        if (item.materialEmpresa.length > 0) {
          const schema = Yup.object().shape({
            materialEmpresa: Yup.string().required('Selecione um material'),
          });

          await schema.validate(data, { abortEarly: false });
        }

        /** adiciona no carrinho */
        const {
          idCatalogo,
          idPagina,
          idItem,
          idMaterial,
          imagemCatalogo,
        } = item;
        const { quantidade, materialEmpresa } = data;
        dispatch(
          adicionaCarrinhoRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            Number(quantidade),
            materialEmpresa,
            imagemCatalogo
          )
        );

        /** executa método para fechar o formulário */
        onClose(data);
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [item, dispatch, onClose]
  );

  const handleAdicionar = useCallback(() => {
    /** pega informações do campo */
    const quantidade = Number(formRef.current.getFieldValue('quantidade'));

    formRef.current.setFieldValue('quantidade', quantidade + 1);
  }, []);

  const handleRemover = useCallback(() => {
    /** pega informações do campo */
    const quantidade = Number(formRef.current.getFieldValue('quantidade'));

    if (quantidade > 1) {
      formRef.current.setFieldValue('quantidade', quantidade - 1);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      const {
        left: leftContainer,
        top: topContainer,
      } = containerRef.current.getBoundingClientRect();
      const { width } = modalRef.current.getBoundingClientRect();

      setLeft(e.pageX - leftContainer - width / 2);
      setTop(e.pageY - topContainer - 5);

      e.stopPropagation();
      e.preventDefault();
    },
    [containerRef]
  );

  const handleMouseUp = useCallback(
    (e) => {
      // setMove(false);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      e.stopPropagation();
      e.preventDefault();
    },
    [handleMouseMove]
  );

  const handleMouseDown = useCallback(
    (e) => {
      // setMove(true);

      /** adiciona evento na tela */
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      e.stopPropagation();
      e.preventDefault();
    },
    [handleMouseMove, handleMouseUp]
  );

  return (
    <CartModal ref={modalRef} top={top} left={left}>
      <Content>
        <Content.Move onMouseDown={handleMouseDown}>
          <MdMoreHoriz size={24} />
        </Content.Move>

        <Content.BtnClose type="button" onClick={() => onClose()}>
          <MdClose size={20} />
        </Content.BtnClose>

        <Content.Title>
          <strong>{item.descricao}</strong>
          <span>{item.partnumber}</span>
          {item.especTecnica && (
            <Content.Title.Espec>{item.especTecnica}</Content.Title.Espec>
          )}

          {(item.nota || item.especTecnica) && (
            <Info.Root rightIcon="0" topIcon="0">
              {item.nota && (
                <div>
                  <strong>Nota:</strong>
                  <span>{item.nota}</span>
                </div>
              )}
              {item.especTecnica && (
                <div>
                  <strong>Esp.Tec.:</strong>
                  <span>{item.especTecnica}</span>
                </div>
              )}
              {item.materialEmpresa.map((material, index) => (
                <div key={index}>
                  <strong>Material ERP:</strong>
                  <ul>
                    <li>{`${material.descricao || ' - '}`}</li>
                    <li>{`Cod.Mat: ${material.codimate || ' -'} | UN: ${
                      material.unidade || ' - '
                    } | Paralelo: ${material.paralelo ? 'Sim' : 'Não'}`}</li>
                  </ul>
                </div>
              ))}
            </Info.Root>
          )}
        </Content.Title>
        <Content.Form>
          {item && (
            <Form id="formCart" ref={formRef} onSubmit={handleConfirmar}>
              <Column borderTop>
                {item.materialEmpresa.length > 0 && (
                  <SelectInput
                    id="materialEmpresa"
                    name="materialEmpresa"
                    label="Código ERP"
                    isClearable
                    options={item.materialEmpresa.map((material) => ({
                      value: material.codimate,
                      label: `${material.codimate} - ${material.descricao}`,
                      color: material.paralelo === '0' ? '#2c405a' : '#de3b3b',
                      fontStyle:
                        material.paralelo === '0' ? 'normal' : 'italic',
                    }))}
                    styles={{
                      option: (styles, { data }) => ({
                        ...styles,
                        color: data.color,
                        fontStyle: data.fontStyle,
                      }),
                    }}
                  />
                )}
                <Content.Form.Error>
                  {item.materialEmpresa.length > 0
                    ? 'Atenção: Materiais não originais estão destacados na listagem'
                    : 'Nenhum Código ERP relacionado com esse material para integração'}
                </Content.Form.Error>
              </Column>

              <Row>
                <Content.Form.Quantity>
                  <label htmlFor="quantidade">Quantidade</label>
                  <div>
                    <button type="button" onClick={handleRemover}>
                      <MdChevronLeft size={25} />
                    </button>

                    <Input
                      id="quantidade"
                      name="quantidade"
                      flexDirection="row"
                      // disabled
                      type="number"
                      min="1"
                      max="9999999.9999"
                      width={80}
                    />
                    <button type="button" onClick={handleAdicionar}>
                      <MdChevronRight size={25} />
                    </button>
                  </div>
                </Content.Form.Quantity>
              </Row>
            </Form>
          )}

          <Content.BtnAddCart type="submit" form="formCart">
            <MdShoppingCart size={20} /> Adicionar ao carrinho
          </Content.BtnAddCart>
        </Content.Form>
      </Content>
    </CartModal>
  );
}

ItemCart.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  containerRef: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ItemCart;
