import styled from 'styled-components';

const Menu = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  height: 100%;
  max-height: calc(100vh - 75px);
  width: ${(props) => (props.hidden ? '100%' : '400px')};
  z-index: 97;
`;

Menu.Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
`;

const Fixed = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  height: 100%;
  width: 400px;
`;

export { Menu, Fixed };
