import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useField } from '@unform/core';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';

import { InputWrapper } from '../..';

export default function AsyncCreatableSelectInput({
  name,
  label,
  createLabel,
  width,
  onCreate,
  ...rest
}) {
  const selectRef = useRef();
  const { fieldName, registerField, error } = useField(name);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'select.state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map((option) => option.value);
        }

        if (!ref.select.state.value) {
          return '';
        }

        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.select.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.select.select.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleCreate = useCallback(
    (inputValue) => {
      setLoading(true);
      onCreate(selectRef.current, inputValue);
      setLoading(false);
    },
    [onCreate]
  );

  return (
    <InputWrapper width={width} flexDirection="column">
      {label && <label htmlFor={fieldName}>{label}</label>}

      <AsyncCreatableSelect
        ref={selectRef}
        isClearable
        isLoading={isLoading}
        onCreateOption={handleCreate}
        formatCreateLabel={(inputValue) => `${createLabel}: ${inputValue}`}
        className={error ? 'has-error' : ''}
        placeholder="Seleciona uma opção"
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

AsyncCreatableSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  label: PropTypes.string,
  createLabel: PropTypes.string,
  width: PropTypes.number,
};

AsyncCreatableSelectInput.defaultProps = {
  label: null,
  createLabel: 'Criar',
  width: null,
};
