import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, Background } from './styles';

function AuthForgotLayout({ children }) {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
      <Background />
    </Container>
  );
}

AuthForgotLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthForgotLayout };
