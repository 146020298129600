import React, { useState, useEffect, useCallback } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdNavigateNext, MdNavigateBefore, MdBuild } from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';
import { opIdioma } from '../../../lib/inputOption';
import { opTipoAcesso } from '../../../lib/const';

import { permiteEmpSerieLoteCatalogo } from '../../../lib/paramUtils';

import { MobileMainMenu } from '../../../components/Mobile/MainMenu';
import { MobileCardCatalogo } from '../../../components/Mobile/CardCatalogo';

import { Main, Card, HandleMenu } from './styles';

export function MobileHome() {
  const location = useLocation();
  const { homePesquisar = false } = location.state || {};

  const [parametroRota, setParametroRota] = useState(null);
  const [catalogos, setCatalogos] = useState([]);
  const [classificacoes, setClassificacoes] = useState([]);
  const [, setHiddenMenu] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const { url } = useRouteMatch();

  /** aguarda carregamento dos parâmetros */
  const waitingStore = useSelector((state) => {
    const { param } = state;
    return param.globParams && param.globParams.length > 0;
  });

  /** carregamento das informações de autorização */
  const auth = useSelector((state) => state.auth || {});

  useEffect(() => {
    /**
     * 1. verifica se NAO existe parâmetro na url
     * 2. verifica se o tela de pesquisa NAO foi fechada
     * 3. verifica se o usuário autenticado é um "USUARIO"
     * 4. verifica parâmetro global
     */
    if (
      !location.search &&
      !homePesquisar &&
      auth.administrator === opTipoAcesso.USUARIO &&
      waitingStore &&
      permiteEmpSerieLoteCatalogo()
    )
      history.push('/catalogo-pesquisar', {
        background: location,
      });
  }, [location, homePesquisar, auth, waitingStore]);

  useEffect(() => {
    const routeQuery = new URLSearchParams(location.search);
    setParametroRota({ ...Object.fromEntries(routeQuery) });
  }, [location]);

  useEffect(() => {
    if (parametroRota) {
      /** gera parâmetros atuais da rota */
      const handleRoute = () => {
        const query = Object.keys(parametroRota).reduce(
          (accParam, currParam) => {
            accParam.push(`${currParam}=${parametroRota[currParam]}`);
            return accParam;
          },
          []
        );

        return `?${query.join('&')}`;
      };

      /** contador de parâmetros da rota */
      const handleRouteCounter = () => Object.keys(parametroRota).length;

      /** gera link com parâmetros para próximo passo da rota */
      const handleLinkTo = (
        currParam,
        idParam,
        valueParam,
        defaultP1 = 0,
        defaultP2 = 0
      ) => {
        /** padrão 1 - "fabricante" */
        if (defaultP1 === 1) {
          return `${currParam}&npd_1=${valueParam}`;
        }

        /** padrão 2 - "modelo" */
        if (defaultP2 === 1) {
          return `${currParam}&npd_2=${valueParam}`;
        }

        /** padrão genérico "navegacao_classificacao", "navegacao" e "relac_navegacao_catalogo" */
        return `${currParam}&nav_${idParam + 1}=${valueParam}`;
      };

      /** gera link com parâmetros para passo anterior da rota */
      const handleBackTo = (index) => {
        const param = `${Object.keys(parametroRota).reduce(
          (accParam, _, idxParam, arrKey) => {
            /** parâmetro anteriores */
            const prev = arrKey[idxParam - 1];

            if (idxParam < index) {
              if (accParam) {
                return `${accParam}&${prev}=${parametroRota[prev]}`;
              }

              if (prev) {
                return `?${prev}=${parametroRota[prev]}`;
              }
            }

            return accParam;
          },
          ''
        )}`;

        if (index > 0) {
          return `catalogo.partes${param}`;
        }

        return param;
      };

      /** controle de visualização dos itens de menu */
      const handleVisible = (curr, idxItem, itemCount) => {
        const params = handleRouteCounter();

        /** limite de exibição de itens de menu (MAX:3) */
        if (!curr && params > 0 && params < itemCount) {
          return idxItem === params - 1 || idxItem === params - 2;
        }

        if (!curr && params === itemCount) {
          return idxItem === itemCount - 1;
        }

        return curr;
      };

      api
        .get('home-navegacao', {
          params: {
            ...parametroRota,
          },
        })
        .then((response) => {
          /** parâmetros iniciais */
          const params = handleRoute();

          /** contador de parâmetros */
          const itemsCounter = response.data.navegacao.length;
          const paramsCounter = handleRouteCounter();

          /** controle de exibiação dos itens de menu */
          setHiddenMenu(paramsCounter > 0 && paramsCounter < itemsCounter);
          setOpenMenu((state) => state && paramsCounter < itemsCounter);

          /** processa os menus */
          setClassificacoes(
            response.data.navegacao.map((classificacao, index, arr) => {
              const { navegacao, series, ...item } = classificacao;

              const isActive = paramsCounter === index;
              const isVisible = handleVisible(isActive, index, arr.length);

              const backTo = handleBackTo(index);

              return {
                idMenu: item.idClassificacao,
                title: item.descricao,
                active: isActive,
                visible: isVisible,
                backTo,
                items: navegacao.map((nav) => {
                  const linkTo = handleLinkTo(
                    params,
                    index,
                    nav.idNavegacao,
                    Number(classificacao.padraoFabricante),
                    Number(classificacao.padraoModelo)
                  );

                  return {
                    id: nav.idNavegacao,
                    name: nav.descricao,
                    linkTo,
                  };
                }),
              };
            })
          );

          setCatalogos(response.data.catalogo);
        });
    }
  }, [parametroRota]);

  const handleMenu = useCallback(() => {
    setOpenMenu((state) => !state);
  }, []);

  return (
    <Main>
      <HandleMenu onClick={handleMenu}>
        {!openMenu ? (
          <MdNavigateNext size={25} />
        ) : (
          <MdNavigateBefore size={25} />
        )}
      </HandleMenu>
      {openMenu && (
        <MobileMainMenu hidden={openMenu} menuItems={classificacoes} />
      )}
      <Main.Container>
        <Main.Title>
          <h2>Catálogos</h2>
        </Main.Title>
        <Main.Wrapper>
          <Main.Section>
            {/* {catalogos
              // .filter((c) => !c.imagemUrl)
              .map((catalogo) => (
                <Card key={catalogo.idCatalogo}>
                  <Link to={`${url}/${catalogo.idCatalogo}`}>
                    <Card.Wrapper>
                      <Card.Language>
                        {opIdioma[Number(catalogo.idioma)].label}
                      </Card.Language>
                      <Card.Title>{`${catalogo.nome} (${catalogo.versao})`}</Card.Title>
                      <Card.Content>{`${catalogo.modelo} - ${catalogo.fabricante}`}</Card.Content>
                      {catalogo.numeroSerie && (
                        <Card.Content>{`S/N: ${catalogo.numeroSerie}`}</Card.Content>
                      )}
                    </Card.Wrapper>
                  </Link>
                  {catalogo.emManutencao && (
                    <Card.Maintenance>
                      <Card.Maintenance.Content>
                        <MdBuild size={16} />
                        <span>
                          Catálogo em manutenção. Em breve estará disponível.
                        </span>
                      </Card.Maintenance.Content>
                    </Card.Maintenance>
                  )}
                </Card>
              ))} */}

            {catalogos.map((catalogo) => (
              <MobileCardCatalogo
                key={catalogo.idCatalogo}
                catalogo={catalogo}
                url={url}
              />
            ))}
          </Main.Section>
        </Main.Wrapper>
      </Main.Container>
    </Main>
  );
}
