import styled from 'styled-components';

export const WrapperCardCatalogo = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  border-radius: 10px;
  border: ${(props) => `1px solid ${props.theme.borderCardCatalogoMobile}`};
  background: ${(props) => props.theme.cardBackground};
  box-shadow: ${(props) => `0px 3px 6px ${props.theme.boxShadow}`};

  & + & {
    margin-top: 5px;
  }
`;

export const WrapperImageAndInfo = styled.div`
  display: flex;
  height: 100%;
  /* padding: 5px; */
`;

export const WrapperImage = styled.div`
  background: #f8f8f8;
  width: 40%;
  /* border-radius: 5px; */
  border-radius: 10px 0 0 10px;

  display: flex;
  align-items: center;

  > img {
    width: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    text-align: center; //acessibilidade
  }
`;

export const WrapperCatalogoInfo = styled.div`
  width: 60%;
  padding-left: 10px;
  color: ${(props) => props.theme.contentCardCatalogoMobile};

  > h3 {
    margin: 10px 0;
    color: ${(props) => props.theme.nameCardCatalogoMobile};

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
  }
`;

export const WrapperMaintenance = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WrapperMaintenanceContent = styled.div`
  background-color: rgba(255, 255, 255, 0.92);
  padding: 10px;
  border-radius: 5px;
  color: #de3b3b;
  margin: 0 40px;
  text-align: center;

  > span {
    margin-left: 2px;
  }
`;

export const Label = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 10px;
`;

export const ManufacturerName = styled.span`
  display: inline-block;
  font-weight: bold;
  max-width: 220px;
  margin-top: 3px;
  padding: 5px 10px;
  background: #d9d9d9;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CatalogContent = styled.span`
  display: block;
  margin-top: 3px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
`;

export const LanguageContent = styled.span`
  color: ${(props) => props.theme.languageCardCatalogoMobile};
  font-size: 10px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
`;
