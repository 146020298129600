import styled from 'styled-components';
import { darken } from 'polished';

const Item = styled.div`
  flex: 1;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  background: ${(props) => props.color};
  box-shadow: inset 0 0 1em #00000026;
  position: relative;

  width: 100%;
  max-width: 400px;
  height: 100%;
  overflow-y: auto;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50px;
    display: block;
    border-right: 1px solid ${(props) => `${darken(0.1, props.color)}`};
    border-bottom: 1px solid ${(props) => `${darken(0.1, props.color)}`};
    background: ${(props) => `${darken(0.08, props.color)}`};
    width: 25px;
    height: 25px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

Item.Title = styled.div`
  padding: 40px 40px 5px;

  > div {
    display: flex;
    align-items: center;
    color: #2c405a;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: none;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;

      :hover {
        background: ${darken(0.05, '#dde3f1')};
      }
    }
  }

  > form {
    border-bottom: 1px solid rgba(44, 64, 90, 0.2);
  }
`;

Item.Wrapper = styled.ul`
  color: #000;
  font-size: 18px;
  transform: color 1s;

  > li {
    > a {
      display: flex;
      padding: 15px 40px;
      color: #2c405a;
    }

    a.disabled-link {
      color: rgba(44, 64, 90, 0.6);
      pointer-events: none;
    }

    a:hover {
      background: ${darken(0.05, '#dde3f1')};
    }

    a:visited,
    a:active {
      color: rgba(44, 64, 90, 0.6);
    }
  }
`;

export { Item };
