/* eslint-disable prefer-const */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import SelectorElement from './Selector';
import GhostSelector from './GhostSelector';
import { Container } from './styles';

const Resizeble = forwardRef(
  (
    {
      id,
      activeKey,
      withLines,
      noResize,
      mousePos,
      defaultSelectorGroup,
      children,
    },
    ref
  ) => {
    /** refs do ghost */
    const startCoordRef = useRef({ x: -1, y: -1 });
    const finishCoordRef = useRef({ x: 0, y: 0 });
    const minCoordRef = useRef({ x: 0, y: 0 });
    const maxCoordRef = useRef({ x: 0, y: 0 });
    const ghostRef = useRef(HTMLElement);

    /** array de ref das seleções */
    const selectionRef = useRef([]);

    /** refs do container */
    const containerRef = useRef();
    const containerCoordsRef = useRef({});
    const scrollRef = useRef(HTMLElement);

    const [delegateMouseMove, setDelegateMouseMove] = useState(false);
    const [delegateKeyUp, setDelegateKeyUp] = useState(false);

    const [selectorGroup, setSelectorGroup] = useState([]);
    const [duplicating, setDuplicating] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setSelectorGroup([...defaultSelectorGroup]);
      }, 600);
    }, [id, defaultSelectorGroup]);

    const setGhostCoords = useCallback(
      (coords) => ghostRef.current.updateCoords(coords),
      []
    );

    const getGhost = useCallback(() => {
      const ghostCoords = ghostRef.current.getCoords();

      return {
        top: ghostCoords.top,
        left: ghostCoords.left,
        w: ghostCoords.width,
        h: ghostCoords.height,
        width: ghostCoords.width + ghostCoords.left,
        height: ghostCoords.height + ghostCoords.top,
      };
    }, []);

    const getSelection = useCallback(() => {
      let selection = [];

      /** seleciona das áreas criadas */
      selection = selectionRef.current.reduce((data, selector) => {
        data.push(selector.getData());

        return data;
      }, []);

      return selection.length ? selection : null;
    }, []);

    const getCurrentSelectorGroup = useCallback(
      () =>
        selectorGroup.map((g, idx) => ({
          ...g,
          initialLines: selectionRef.current[idx].getCurrentLines(),
        })),
      [selectorGroup]
    );

    /** remove todas as seleções */
    const clearSelection = useCallback(() => {
      setSelectorGroup([]);
    }, []);

    useImperativeHandle(ref, () => ({
      clearSelection,
      getSelection,
      getGhost,
      getCurrentSelectorGroup,
    }));

    /** cálculo das coordenadas da área de seleção */
    const setGhostSelection = useCallback(
      ({ clientX, clientY }) => {
        /** posição do mouse no evento ou por função externa passada na prop mousePos */
        const m = mousePos({ clientX, clientY }) || {
          x:
            Math.round(clientX) -
            containerCoordsRef.current.left +
            Math.round(scrollRef.current.scrollLeft),
          y:
            Math.round(clientY) -
            containerCoordsRef.current.top +
            Math.round(scrollRef.current.scrollTop),
        };

        /** define valor inicial se ainda for o valor padrão */
        if (
          JSON.stringify(startCoordRef.current) ===
          JSON.stringify({ x: -1, y: -1 })
        ) {
          startCoordRef.current = { x: Math.round(m.x), y: Math.round(m.y) };
        }

        finishCoordRef.current = { x: Math.round(m.x), y: Math.round(m.y) };

        /** especifica mínimos e máximos para evitar valores negativos */
        minCoordRef.current = {
          x: Math.min(startCoordRef.current.x, finishCoordRef.current.x),
          y: Math.min(startCoordRef.current.y, finishCoordRef.current.y),
        };
        maxCoordRef.current = {
          x: Math.max(startCoordRef.current.x, finishCoordRef.current.x),
          y: Math.max(startCoordRef.current.y, finishCoordRef.current.y),
        };

        /** limites máximo e mínimo */
        if (
          containerCoordsRef.current.left + minCoordRef.current.x <
          containerCoordsRef.current.left
        ) {
          minCoordRef.current.x = 0;
        }
        if (
          containerCoordsRef.current.top + minCoordRef.current.y <
          containerCoordsRef.current.top
        ) {
          minCoordRef.current.y = 0;
        }
        if (containerCoordsRef.current.width < maxCoordRef.current.x) {
          maxCoordRef.current.x = containerCoordsRef.current.width;
        }
        if (containerCoordsRef.current.height < maxCoordRef.current.y) {
          maxCoordRef.current.y = containerCoordsRef.current.height;
        }

        setGhostCoords({
          top: minCoordRef.current.y,
          left: minCoordRef.current.x,
          width: maxCoordRef.current.x - minCoordRef.current.x,
          height: maxCoordRef.current.y - minCoordRef.current.y,
        });
      },
      [
        mousePos,
        startCoordRef,
        finishCoordRef,
        containerCoordsRef,
        scrollRef,
        setGhostCoords,
      ]
    );

    const setContainerCoords = useCallback(() => {
      if (!(containerRef && containerRef.current)) return;

      let rect = containerRef.current.getBoundingClientRect();

      /** aplica compensação de scroll caso a página tenha algum scroll inicial */
      const { scrollTop, scrollLeft } = scrollRef.current;
      if (Object.values({ scrollTop, scrollLeft }) !== [0, 0]) {
        containerCoordsRef.current = {
          top: Math.round(rect.top) + Math.round(scrollRef.current.scrollTop),
          left:
            Math.round(rect.left) + Math.round(scrollRef.current.scrollLeft),
          width: rect.width,
          height: rect.height,
        };
      }

      /** formata para números inteiros por questões de performance */
      containerCoordsRef.current = {
        top: Math.round(containerCoordsRef.current.top),
        left: Math.round(containerCoordsRef.current.left),
        width: Math.round(containerCoordsRef.current.width),
        height: Math.round(containerCoordsRef.current.height),
      };
    }, []);

    /** atualiza coordenadas do container conforme se adapta ao tamanho da imagem */
    useEffect(() => {
      setContainerCoords();
    });

    const handleDuplicateSelector = useCallback(
      ({ coords, lines }) => {
        const random = Math.random();

        setContainerCoords();

        setSelectorGroup((group) => {
          const selector = {
            id: random,
            initialCoords: {
              ...coords,
              top: coords.top + 10,
              left: coords.left + 10,
            },
            containerCoords: containerCoordsRef.current,
            initialLines: lines,
          };

          setDuplicating(random);

          return [...group, selector];
        });
      },
      [setContainerCoords]
    );

    const handleRemoveSelector = (selectorId) => {
      setSelectorGroup((group) =>
        group.filter((selector) => selector.id !== selectorId)
      );
    };

    /** constantemente checa pelo comando para criar área fantasma de seleção (área pontilhada) */
    const handleContainerMouseMove = useCallback(
      (e) => {
        if (delegateMouseMove) return;

        if (e[`${activeKey}Key`]) {
          e.stopPropagation();

          setGhostSelection(e);
          return;
        }

        const ghostCoords = ghostRef.current.getCoords();
        if (!Object.keys(ghostCoords).some((k) => ghostCoords[k])) return;

        /** redefine valores iniciais para o valor padrão */
        setGhostCoords({
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        });

        startCoordRef.current = { x: -1, y: -1 };
      },
      [activeKey, delegateMouseMove, setGhostSelection, setGhostCoords]
    );

    const handleContainerMouseDown = useCallback(
      (e) => {
        if (containerRef && containerRef.current) setDelegateMouseMove(true);

        setContainerCoords();

        if (!e[`${activeKey}Key`]) return;

        setSelectorGroup((group) => {
          const selector = {
            id: Math.random(),
            initialCoords: ghostRef.current.getCoords(),
            containerCoords: containerCoordsRef.current,
            initialLines: [],
          };

          return [...group, selector];
        });
      },
      [activeKey, setContainerCoords]
    );

    const handleContainerMouseUp = useCallback(() => {
      setDelegateMouseMove(false);
    }, []);

    const handleKeyUp = useCallback(
      (e) => {
        if (delegateKeyUp) return;

        let command;
        switch (activeKey) {
          case 'ctrl':
            command = 'Control';
            break;
          case 'shift':
            command = 'Shift';
            break;
          case 'alt':
            command = 'Alt';
            break;
          default:
            break;
        }

        if (e.key === command) {
          setGhostCoords({
            top: 0,
            left: 0,
            width: 0,
            height: 0,
          });
          startCoordRef.current = { x: -1, y: -1 };
        }
      },
      [activeKey, delegateKeyUp, setGhostCoords]
    );

    /**
     * Limpa referências criadas dinamicamente quando
     *  componente representado é desmontado
     * Controla o menu de contexto para áreas criadas por duplicação
     */
    useEffect(() => {
      selectionRef.current = selectionRef.current.filter(
        (selection) => selection !== null
      );

      if (duplicating) {
        selectionRef.current
          .filter((selector) => selector.id === duplicating)[0]
          .showContext(true);

        setDuplicating(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorGroup]);

    useEffect(() => {
      if (document.querySelector('.topmostWrapper')) {
        scrollRef.current = document.querySelector('.topmostWrapper') || {
          scrollTop: 0,
          scrollLeft: 0,
        };
      }

      setDelegateMouseMove(false);
      setDelegateKeyUp(false);

      /** cleanup */
      return () => {
        setDelegateKeyUp(true);
      };
    }, [handleContainerMouseMove, handleKeyUp]);

    useEffect(() => {
      setContainerCoords();
    }, [setContainerCoords, scrollRef]);

    return (
      <Container
        ref={containerRef}
        onMouseMove={handleContainerMouseMove}
        onMouseDown={handleContainerMouseDown}
        onMouseUp={handleContainerMouseUp}
        onKeyUp={handleKeyUp}
      >
        <GhostSelector ref={ghostRef} />

        {selectorGroup.map((selector, idx) => (
          <SelectorElement
            key={selector.id}
            ref={(el) => {
              selectionRef.current[idx] = el;
            }}
            withLines={withLines}
            containerCoords={(() => containerCoordsRef.current)()}
            noResize={noResize}
            onRemove={() => handleRemoveSelector(selector.id)}
            onDuplicate={(set) => handleDuplicateSelector(set)}
            {...selector}
          />
        ))}
        {children}
      </Container>
    );
  }
);

export default Resizeble;

Resizeble.propTypes = {
  id: PropTypes.number.isRequired,
  activeKey: PropTypes.oneOf(['ctrl', 'shift', 'alt']),
  withLines: PropTypes.bool,
  mousePos: PropTypes.func,
  noResize: PropTypes.bool,
  defaultSelectorGroup: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Resizeble.defaultProps = {
  activeKey: 'ctrl',
  withLines: false,
  mousePos: () => {},
  noResize: false,
  defaultSelectorGroup: [],
};
