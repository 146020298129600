import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  position: relative;
  border: 2.5px dashed #35b0b5;
`;

export const Ghost = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: `${props.width}px`,
    height: `${props.height}px`,
  },
}))`
  position: absolute;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  //#4286f4 => rgba(66, 134, 244)
  background-color: ${lighten(0.2, 'rgba(66, 134, 244, 0.5)')};
  outline: 1px dashed #4286f4;
  z-index: 1;
`;
