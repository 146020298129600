import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
});

function EmpresaSistemaEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, id } = location.state;

  const [sistema, setSistema] = useState([]);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/sistema/${id}`)
      .then(({ data: { dataDesa, ...response } }) => {
        setSistema({
          ...response,
          dataDesa:
            dataDesa && format(parseISO(dataDesa), 'dd/MM/yyyy hh:mm:ss'),
        });
      });
  }, [idEmpresa, id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { descricao } = data;

        await api.put(`empresa/${idEmpresa}/sistema/${id}`, {
          descricao,
        });

        toast.success('Sistema atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa, id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Sistema" />
      <FormWrapper>
        <Form
          id="formEmpresaSistema"
          ref={formRef}
          schema={schema}
          initialData={sistema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="codiSist"
                name="codiSist"
                label="Código"
                width={300}
                disabled
              />
              <Input
                id="dataDesa"
                name="dataDesa"
                label="Desativação"
                width={400}
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                width={800}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresaSistema">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaSistemaEditar };
