import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    border: 0;
    padding: 0 2px;
    background: none;
    color: #cbd0d8;
    transition: color 1s;

    & + button {
      margin-left: 10px;
    }
  }

  #btn-line-view {
    :hover {
      color: #538061;
    }
  }

  #btn-line-download,
  #btn-line-edit,
  #btn-line-copy {
    :hover {
      color: #5485f7;
    }
  }

  #btn-line-user-password {
    :hover {
      color: #e6de2a;
    }
  }

  #btn-line-remove {
    :hover {
      color: #de3b3b;
    }
  }

  #btn-line-manager,
  #btn-line-generate-token {
    :hover {
      color: #538061;
    }
  }

  #btn-line-generate-qrcode {
    :hover {
      color: #374151;
    }
  }

  #btn-action {
    /* color: '#ff6d3c' */

    :hover {
      color: ${darken(0.1, '#ff6d3c')};
    }
  }

  #btn-whatsapp {
    /* light green  */
    /* background-color: #5ffc7b; */
    /* background-color: #25d366; */
    /* background-color: #59ce72; */
    /* dark green */
    /* background-color: #28d146; */
    color: #28d146;
    /* color: #ffffff; */
    /* padding: 8px; */
    border-radius: 5px;
  }
`;

export const Wrapper = styled.div`
  z-index: 99;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: relative;

  & > ul {
    position: absolute;
    right: 0;
    top: 30px;
    background: #fff;
    font-size: 14px;
    border: 1px solid #dbe1e9;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #00000026;
    min-width: 180px;

    & > li {
      display: flex;
      color: #444;
      align-items: center;
      margin: 0 10px;
      padding: 10px 5px;
      transition: background 0.3s;

      &:not(:last-child) {
        border-bottom: 1px solid #dbe1e9;
      }

      svg {
        margin-right: 5px;
      }

      button {
        flex: 1;
        display: flex;
        align-items: center;
        text-align: left;
        border: 0;
        padding: 0 2px;
        background: none;
        color: #444;

        &#act-exit {
          color: #dd0312;
        }
      }
    }
  }
`;

export const NotificationWrapper = styled.div`
  position: relative;
`;

export const NotificationBadge = styled.span`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  border: 1px solid #f2f5fc;
  border-radius: 50%;
  background: red;
  color: #ff474d;
`;
