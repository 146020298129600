import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { format, parseISO } from 'date-fns';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';
import { opStatusSugestao } from '../../../lib/inputOption';

function EmpresaSugestao() {
  const location = useLocation();

  const [sugestoes, setSugestoes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaSugestoes = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );

    const { idEmpresa } = location.state || {};

    const { q, page, status } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      status: Number(status) || null,
    };

    api
      .get(`/empresa/${idEmpresa}/sugestao/`, {
        params: configFiltro,
      })
      .then((response) => {
        setSugestoes(
          response.data.sugestao.map((s) => ({
            ...s,
            status: opStatusSugestao[Number(s.status)].label,
            instInc: format(parseISO(s.instInc), 'dd/MM/yyyy'),
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleEditar = useCallback(
    (idSugestao) => {
      history.push(`/empresa/sugestao/editar`, {
        background: location,
        id: idSugestao,
      });
    },
    [location]
  );

  const handleVisualizar = useCallback(
    (e, idSugestao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idSugestao);
      }
    },
    [handleEditar]
  );

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaSugestoes();
  }, [location, currentPage, carregaSugestoes]);

  return (
    <>
      <TitleBar
        back
        title="Sugestões"
        isSearchable
        filterSearchType="SUGESTAO"
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idSugestao', title: 'Sugestão' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: 'instInc', title: 'Data' },
            { fieldname: 'status', title: 'Status' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {sugestoes.map((sugestao) => (
            <RowMaster
              key={sugestao.idSugestao}
              onClick={(e) => handleVisualizar(e, sugestao.idSugestao)}
            >
              <td>{sugestao.idSugestao}</td>
              <td>{sugestao.descricao}</td>
              <td>{sugestao.instInc}</td>
              <td>{sugestao.status}</td>
              <td>
                <ActionMenu
                  data={sugestao}
                  onEdit={() => handleEditar(sugestao.idSugestao)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaSugestao };
