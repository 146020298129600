import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { MdBuild } from 'react-icons/md';
import { opIdioma } from '../../../lib/inputOption';

import cdiNoPhotoAvailable from '../../../assets/cdi-no-photo-available.png';

import {
  WrapperCardCatalogo,
  WrapperImageAndInfo,
  WrapperImage,
  WrapperCatalogoInfo,
  WrapperMaintenance,
  WrapperMaintenanceContent,
  Label,
  ManufacturerName,
  CatalogContent,
  LanguageContent,
} from './styles';

function MobileCardCatalogo({ catalogo, url }) {
  return (
    <WrapperCardCatalogo>
      <Link to={`${url}/${catalogo.idCatalogo}`}>
        <WrapperImageAndInfo>
          <WrapperImage>
            {catalogo.imagemUrl ? (
              <img
                src={catalogo.imagemUrl}
                alt={`Imagem catálogo: ${catalogo.fabricante}`}
              />
            ) : (
              <img
                src={cdiNoPhotoAvailable}
                alt="Imagem catálogo inexistente"
              />
            )}
          </WrapperImage>

          <WrapperCatalogoInfo>
            <h3>{catalogo.nome}</h3>

            <Label>fabricante</Label>
            <ManufacturerName>{catalogo.fabricante}</ManufacturerName>

            <Label>modelo</Label>
            <CatalogContent>{catalogo.modelo}</CatalogContent>

            {catalogo.numeroSerie && (
              <>
                <Label>s/n</Label>
                <CatalogContent>{catalogo.numeroSerie}</CatalogContent>
              </>
            )}
            <LanguageContent>
              {opIdioma[Number(catalogo.idioma)].label}
            </LanguageContent>
          </WrapperCatalogoInfo>
        </WrapperImageAndInfo>
      </Link>
      {catalogo.emManutencao && (
        <WrapperMaintenance>
          <WrapperMaintenanceContent>
            <MdBuild size={16} />
            <span> Catálogo em manutenção. Em breve estará disponível.</span>
          </WrapperMaintenanceContent>
        </WrapperMaintenance>
      )}
    </WrapperCardCatalogo>
  );
}

MobileCardCatalogo.propTypes = {
  catalogo: PropTypes.instanceOf(Object).isRequired,
  url: PropTypes.string.isRequired,
};

export { MobileCardCatalogo };
