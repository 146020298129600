import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  MdFirstPage,
  MdChevronLeft,
  MdChevronRight,
  MdLastPage,
} from 'react-icons/md';

import { Form, Row } from '../../Form';
import Input from '../../Form/Input';

import { Container } from './styles';

function Pagination({ pageCount, initialData, onPageChange }) {
  const formRef = useRef(null);

  const handlePageChange = useCallback(
    (page) => {
      formRef.current.setFieldValue('paginaAtual', page);

      onPageChange(page);
    },
    [onPageChange]
  );

  const handleLeftRightChange = useCallback(
    (page) => {
      const paginaAtual = Number(formRef.current.getFieldValue('paginaAtual'));
      const paginaNova = paginaAtual + page;

      if (paginaNova >= 1 && paginaNova <= pageCount) {
        handlePageChange(paginaNova);
      }
    },
    [formRef, pageCount, handlePageChange]
  );

  const handleSubmit = useCallback(
    (data) => {
      const { paginaAtual } = data;

      /** verifica se a página é maior que o total de páginas */
      if (paginaAtual >= 1 && paginaAtual <= pageCount) {
        handlePageChange(paginaAtual);
      }
    },
    [pageCount, handlePageChange]
  );

  return (
    <Container>
      <button type="button" onClick={() => handlePageChange(1)}>
        <MdFirstPage size={18} />
      </button>
      <button type="button" onClick={() => handleLeftRightChange(-1)}>
        <MdChevronLeft size={18} />
      </button>

      <Form
        ref={formRef}
        initialData={{ paginaAtual: initialData || 1 }}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Row alingItens>
          <Input
            id="paginaAtual"
            name="paginaAtual"
            width={80}
            type="number"
            min={1}
            max={999}
          />
          <span>{`de ${pageCount}`}</span>
        </Row>
      </Form>

      <button type="button" onClick={() => handleLeftRightChange(1)}>
        <MdChevronRight size={18} />
      </button>
      <button type="button" onClick={() => handlePageChange(pageCount)}>
        <MdLastPage size={18} />
      </button>
    </Container>
  );
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialData: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
