import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { opTipoDocumento, opMascaraDocumento } from '../../../lib/const';
import { opDocumento } from '../../../lib/inputOption';
import { YupValidaDocumento, splitArray } from '../../../lib/inputUtils';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';
import MaskedInput from '../../../components/Form/Input/MaskedInput';

const schema = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'O campo "nome" precisa ter no mínimo 3 caracteres')
    .required('O campo "nome" é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "e-mail" é obrigatório'),
  tipoDocumento: Yup.number()
    .typeError('O campo "Documento" deve ser preenchido')
    .required('O campo "Documento" precisa ser preenchido'),
  documento: Yup.string()
    .required('O campo "Número Documento" é obrigatório')
    .test('documento', 'O documento é inválido', function isValid(value) {
      return YupValidaDocumento(value, this);
    }),
});

function UsuarioPerfil() {
  const location = useLocation();
  const { id } = location.state;

  const formRef = useRef();

  const [usuario, setUsuario] = useState([]);
  const [usuarioComplemento, setUsuarioComplemento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState(opTipoDocumento.CPF);

  useEffect(() => {
    api.get(`/usuario/${id}`).then((response) => {
      const {
        tipoDocumento: tipoDoc,
        documento = '',
        complemento,
      } = response.data;

      formRef.current.setFieldValue(
        'tipoDocumento',
        opDocumento[tipoDoc] || opDocumento[0]
      );
      if (documento) formRef.current.setFieldValue('documento', documento);

      setUsuario(response.data);
      setUsuarioComplemento(complemento);
    });
  }, [id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          nome,
          email,
          tipoDocumento: tipoDoc,
          documento,
          complemento,
        } = data;

        const fmtComplemento = complemento
          ? Object.entries(complemento).map((arr) => {
              const [chave, valor] = arr;
              return { chave, valor };
            })
          : null;

        await api.put(`usuario/${id}`, {
          nome,
          email,
          tipoDocumento: String(tipoDoc),
          documento,
          complemento: fmtComplemento,
        });

        history.goBack();

        toast.success('Usuário atualizado com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  const handleTipoDocumento = useCallback((value) => {
    setTipoDocumento(value);
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Perfil do usuário" />

      <FormWrapper>
        <Form
          id="formPerfil"
          ref={formRef}
          initialData={usuario}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="login"
                name="login"
                label="Login"
                type="text"
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" type="text" />
              <Input id="email" name="email" label="E-mail" type="text" />
            </Form.Row>

            <Form.Row>
              <Select
                id="tipoDocumento"
                name="tipoDocumento"
                label="Documento"
                defaultValue={opDocumento[opTipoDocumento.CPF]}
                options={opDocumento}
                isSearchable={false}
                onChange={({ value }) => {
                  handleTipoDocumento(value);
                }}
                width={400}
              />
              <MaskedInput
                id="documento"
                name="documento"
                label="Número Documento"
                mask={opMascaraDocumento[tipoDocumento] || ''}
              />
            </Form.Row>
            <Form.Column>
              <h3>Informações complementares</h3>
              {usuarioComplemento && usuarioComplemento.length ? (
                <Scope path="complemento">
                  {splitArray(usuarioComplemento, 2).map((arr, idx1) => (
                    // divide array em subarrays de duas posições [[1,2],[3,4]]
                    <Form.Row key={idx1}>
                      {arr.map((cp, idx2) => (
                        <MaskedInput
                          key={idx2}
                          id={cp.chave}
                          name={cp.chave}
                          label={cp.descricao}
                          mask={cp.mascara}
                          defaultValue={cp.valor}
                        />
                      ))}
                    </Form.Row>
                  ))}
                </Scope>
              ) : (
                <Form.NotFound>Nenhum item encontrado</Form.NotFound>
              )}
            </Form.Column>
          </Form.Column>
          <Form.Separate withFlex height={20} />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formPerfil">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { UsuarioPerfil };
