import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { CSV, opExport, opImport } from '../../../lib/csv-core';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaSistema() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [sistemas, setSistemas] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaSistema = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/sistema`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setSistemas(
          response.data.sistema.map(({ dataDesa, ...item }) => ({
            ...item,
            dataDesa: dataDesa ? 'Inativo' : 'Ativo',
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/sistema/criar`, { background: location, idEmpresa });
  }, [location, idEmpresa]);

  const handleEditar = useCallback(
    (codiSist) => {
      history.push(`/empresa/sistema/editar`, {
        background: location,
        idEmpresa,
        id: codiSist,
      });
    },
    [location, idEmpresa]
  );

  const handleRemover = useCallback(
    async (codiSist) => {
      if (window.confirm('Deseja realmente excluir o sistema?')) {
        try {
          await api.delete(`empresa/${idEmpresa}/sistema/${codiSist}`);

          carregaSistema();

          toast.success('Sistema excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaSistema]
  );

  const handleVisualizar = useCallback(
    (e, codiSist) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(codiSist);
      }
    },
    [handleEditar]
  );

  const handleImportar = useCallback(() => {
    history.push(`/empresa/sistema/importar`, {
      background: { ...location },
      parametros: { op: opImport.EMPRESA_SISTEMA, idEmpresa },
    });
  }, [idEmpresa, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_SISTEMA, { idEmpresa });
  }, [idEmpresa]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaSistema();
  }, [location, currentPage, carregaSistema]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Sistemas` : ''}
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'codiSist', title: 'Sistema' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: 'dataDesa', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {sistemas.map((sistema) => (
            <RowMaster
              key={sistema.codiSist}
              onClick={(e) => handleVisualizar(e, sistema.codiSist)}
            >
              <td>{sistema.codiSist}</td>
              <td>{sistema.descricao}</td>
              <td>{sistema.dataDesa}</td>
              <td>
                <ActionMenu
                  data={sistema}
                  onLineRemove={() => handleRemover(sistema.codiSist)}
                  onEdit={() => handleEditar(sistema.codiSist)}
                  onRemove={() => handleRemover(sistema.codiSist)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaSistema };
