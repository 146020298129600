import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import { CSV, opExport } from '../../../../lib/csv-core';

import { opTipoAcesso } from '../../../../lib/const';

import ActionMenu from '../../../../components/ActionMenu';
import TitleBar from '../../../../components/TitleBar';
import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';
import Pagination from '../../../../components/EditorAI/Pagination';

function EmpresaUsuarioLogin() {
  const location = useLocation();
  const { idEmpresa, idUsuario } = location.state;

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const [empresa, setEmpresa] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [usuarioLogin, setUsuarioLogin] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaUsuario = useCallback(() => {
    api.get(`empresa/${idEmpresa}/usuario/${idUsuario}`).then((response) => {
      setEmpresa(response.data.empresa);
      setUsuario(response.data.usuario);
    });
  }, [idEmpresa, idUsuario]);

  const carregaUsuarioLogin = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`usuario/${idUsuario}/login`, {
        params: configFiltro,
      })
      .then((response) => {
        setUsuarioLogin(
          response.data.usuarioLogin.map((login) => ({
            ...login,
            instInc: format(
              parseISO(login.instInc),
              "dd/MM/yyyy 'às' HH:mm:ss"
            ),
            dataDesa: login.dataDesa
              ? format(parseISO(login.dataDesa), "dd/MM/yyyy 'às' HH:mm:ss")
              : null,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idUsuario]);

  const handleRemover = useCallback(
    async (idLogin) => {
      if (window.confirm('Deseja realmente desativar o token?')) {
        try {
          await api.delete(`usuario/${idUsuario}/login/${idLogin}`);

          carregaUsuarioLogin();

          /** mensagem de sucesso */
          toast.success('Token desativado com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idUsuario, carregaUsuarioLogin]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.USUARIO_LOGIN, { idUsuario });
  }, [idUsuario]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaUsuario();
    carregaUsuarioLogin();
  }, [location, currentPage, carregaUsuario, carregaUsuarioLogin]);

  return (
    <>
      <TitleBar
        back
        title={
          empresa && usuario
            ? `${empresa.descricao} / ${usuario.nome} / Login`
            : ''
        }
        filterSearchType="EMPRESA_LOGIN"
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'instInc', title: 'Acessado em' },
            { fieldname: 'dataDesa', title: 'Desativado em' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {usuarioLogin.map((login) => (
            <RowMaster key={login.idLogin}>
              <td>{login.instInc}</td>
              <td>{login.dataDesa}</td>
              <td>
                {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && (
                  <ActionMenu onRemove={() => handleRemover(login.idLogin)} />
                )}
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaUsuarioLogin };
