import React, { useState, useEffect } from 'react';
// import { MdAddCircle, MdModeEdit, MdDelete } from 'react-icons/md';
import { MdSearch } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { FormWrapper, Form } from '../../Form';
import Input from '../../Form/Input';
import Modal from '../../Modal';

import { Container, Table, TableRow, Wrapper } from './styles';

function TableEditor({ itensPagina, isDraggable, onSubmit }) {
  const [table, setTable] = useState([]);
  const [viewData, setViewData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (itensPagina) {
      setTable(
        itensPagina
          .map((item) => ({ ...item, ...item.materialFabricante }))
          .sort((a, b) => a.ordena - b.ordena)
      );
    }
  }, [itensPagina]);

  function handleDraggable(e) {
    const row = table.find((i) => String(i.idItem) === String(e.target.id));
    e.dataTransfer.setData('text/row-list', JSON.stringify(row));
  }

  function handleClose() {
    setShow(false);
  }

  async function handleSubmit(data) {
    try {
      /** submete os dados */
      const {
        idItem,
        quantidade,
        partnumber,
        descricaoBR,
        descricaoEN,
        nota,
        especTecnica,
      } = data;

      const items = table.filter((t) => t.idItem !== idItem);

      setTable(
        [
          ...items,
          {
            idItem,
            quantidade,
            partnumber,
            descricaoBR,
            descricaoEN,
            nota,
            especTecnica,
          },
        ].sort((a, b) => a.idItem - b.idItem)
      );

      onSubmit(
        [
          ...items,
          {
            idItem,
            quantidade,
            partnumber,
            descricaoBR,
            descricaoEN,
            nota,
            especTecnica,
          },
        ].sort((a, b) => a.idItem - b.idItem)
      );

      handleClose();

      /** mensagem de sucesso */
      toast.success('Tabela atualizada com sucesso!');
    } catch (err) {
      /** mensagem de erro */
      toast.error(`Ops! Ocorreu um problema. ${err}`);
    }
  }

  function handleView(idItem, idMaterial) {
    setViewData(
      table.find(
        (item) => item.idItem === idItem && item.idMaterial === idMaterial
      )
    );
    setShow(true);
  }

  // function handleRemove(id) {
  //   const items = table.filter((t) => t.idItem !== id);
  //   setTable([...items]);
  //   onSubmit([...items]);
  // }

  // function handleEdit(id) {
  //   const item = table.find((t) => t.idItem === id);
  //   setItem(item);
  //   setShow(true);
  // }

  return (
    <Container>
      <Wrapper>
        <Table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Código</th>
              <th> </th>
              {/* <th>
                <button type="button" onClick={handleView}>
                  <MdAddCircle size={26} color="#538061" />
                </button>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {table.map((item, index) => (
              <TableRow
                key={index}
                id={item.idItem}
                draggable={isDraggable}
                onDragStart={handleDraggable}
              >
                <td>{item.idItem}</td>
                <td>{item.partnumber}</td>
                <td>
                  <button type="button">
                    <MdSearch
                      onClick={() => handleView(item.idItem, item.idMaterial)}
                      size={20}
                    />
                  </button>
                </td>
                {/* <td>
                  <button type="button">
                    <MdModeEdit
                      onClick={() => handleEdit(item.idItem)}
                      size={20}
                    />
                  </button>
                  <button type="button">
                    <MdDelete
                      onClick={() => handleRemove(item.idItem)}
                      size={20}
                    />
                  </button>
                </td> */}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      {show && (
        <Modal onClose={handleClose} height={90} width={95}>
          <FormWrapper>
            <Form id="form" onSubmit={handleSubmit} initialData={viewData}>
              <Form.Column>
                <Form.Row>
                  <Input
                    id="idItem"
                    name="idItem"
                    label="Item"
                    type="text"
                    disabled
                  />
                  <Input
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade"
                    type="text"
                    disabled
                  />
                  <Input
                    id="partnumber"
                    name="partnumber"
                    label="Código"
                    type="text"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    id="descricaoBR"
                    name="descricaoBR"
                    label="Descrição (PT-BR)"
                    type="text"
                    disabled
                  />
                  <Input
                    id="descricaoEN"
                    name="descricaoEN"
                    label="Descrição (EN)"
                    type="text"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    id="nota"
                    name="nota"
                    label="Nota"
                    type="text"
                    disabled
                  />
                  <Input
                    id="especTecnica"
                    name="especTecnica"
                    label="Espec. Técnica"
                    type="text"
                    disabled
                  />
                </Form.Row>
              </Form.Column>
              <Form.Separate withFlex />
              <Form.Footer>
                <button id="btn-cancel" type="button" onClick={handleClose}>
                  Cancelar
                </button>
                {/* <button id="btn-submit" type="submit" form="formCatalogo">
                Confirmar
              </button> */}
              </Form.Footer>
            </Form>
          </FormWrapper>
        </Modal>
      )}
    </Container>
  );
}

TableEditor.propTypes = {
  itensPagina: PropTypes.instanceOf(Object).isRequired,
  isDraggable: PropTypes.bool,
  onSubmit: PropTypes.func,
};

TableEditor.defaultProps = {
  isDraggable: false,
  onSubmit: null,
};

export default TableEditor;
