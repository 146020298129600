import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../errors/AppError';
import api from '../../services/api';
import history from '../../services/history';

import { CSV, opExport } from '../../lib/csv-core';
import { opSituacao } from '../../lib/inputOption';

import ActionMenu from '../../components/ActionMenu';
import TitleBar from '../../components/TitleBar';
import TableHeader from '../../components/Table/TableHeader';
import { Table, RowMaster } from '../../components/Table';
import Pagination from '../../components/EditorAI/Pagination';

function Usuario() {
  const location = useLocation();

  const [usuarios, setUsuarios] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaUsuario = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get('usuario', {
        params: configFiltro,
      })
      .then((response) => {
        setUsuarios(
          response.data.usuario.map(({ adm, dataDesa, ...usuario }) => ({
            ...usuario,
            dataDesa,
            situacao: dataDesa ? opSituacao[0].label : opSituacao[1].label,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaUsuario();
  }, [location, currentPage, carregaUsuario]);

  const handleCriar = useCallback(() => {
    history.push(`/usuario/criar`, { background: location });
  }, [location]);

  const handleEditar = useCallback(
    (idUsuario) => {
      history.push(`/usuario/editar`, {
        background: location,
        idUsuario,
      });
    },
    [location]
  );

  const handleTrocarSenha = useCallback(
    (idUsuario) => {
      history.push(`/usuario/trocar`, {
        background: location,
        idUsuario,
      });
    },
    [location]
  );

  const handleRemover = useCallback(async (idUsuario) => {
    if (window.confirm('Deseja realmente excluir o usuário?')) {
      try {
        await api.delete(`usuario/${idUsuario}`);

        setUsuarios((state) =>
          state.filter((usuario) => usuario.idUsuario !== idUsuario)
        );

        /** mensagem de sucesso */
        toast.success('Usuário excluído com sucesso!');
      } catch (err) {
        AppError(err);
      }
    }
  }, []);

  const handleVisualizar = useCallback((e, idUsuario) => {
    e.preventDefault();

    /** verifica se a linha foi clicada */
    if (e.target.nodeName.toUpperCase() === 'TD') {
      history.push('/usuario/detalhe', {
        id: idUsuario,
      });
    }
  }, []);

  // async function handleAtivarInativar(idUsuario) {
  //   /** confirmação */
  //   if (window.confirm('Deseja realmente reativar o usuário?')) {
  //     try {
  //       const { login, name, email, adm } = usuarios.find(
  //         (usuario) => usuario.idUsuario === idUsuario
  //       );

  //       await api.put(`usuario/${idUsuario}`, {
  //         login,
  //         name,
  //         email,
  //         adm,
  //         inactiveDate: null,
  //       });

  //       /** atualiza informações da tela */
  //       // loadUsers();

  //       /** mensagem de sucesso */
  //       toast.success('Usuário reativado com sucesso!');
  //     } catch (err) {
  //       AppError(err);
  //     }
  //   }
  // }

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.USUARIO);
  }, []);

  return (
    <>
      <TitleBar
        title="Usuários"
        isSearchable
        onNew={handleCriar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'nome', title: 'Nome' },
            { fieldname: 'login', title: 'Login' },
            { fieldname: 'email', title: 'E-mail' },
            { fieldname: 'situacao', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {usuarios.map((usuario) => (
            <RowMaster
              key={usuario.idUsuario}
              onClick={(e) => handleVisualizar(e, usuario.idUsuario)}
            >
              <td>{usuario.nome}</td>
              <td>{usuario.login}</td>
              <td>{usuario.email}</td>
              <td>{usuario.situacao}</td>
              <td>
                <ActionMenu
                  onEdit={() => handleEditar(usuario.idUsuario)}
                  onRemove={() => handleRemover(usuario.idUsuario)}
                  onLineRemove={() => handleRemover(usuario.idUsuario)}
                  onLineUserPassword={() =>
                    handleTrocarSenha(usuario.idUsuario)
                  }
                  // onUserAtive={
                  //   usuario.dataDesa
                  //     ? () => handleAtivar(usuario.idUsuario)
                  //     : null
                  // }
                  // onUserInactive={
                  //   !usuario.dataDesa
                  //     ? () => handleInativar(usuario.idUsuario)
                  //     : null
                  // }
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { Usuario };
