import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AppError } from '../../../../../errors/AppError';

import api from '../../../../../services/api';
import history from '../../../../../services/history';

import TitleBar from '../../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import Select from '../../../../../components/Form/Input/Select';
import TextArea from '../../../../../components/Form/Input/TextArea';

import { opTokenDuracao, opTokenTipo } from '../../../../../lib/inputOption';

/** validação dos campos */
const schema = Yup.object().shape({
  nome: Yup.string().required('O campo "Nome" deve ser preenchido'),
  observacao: Yup.string()
    .max(500, 'O campo "Observação" precisa ter no máximo 500 caracteres')
    .required('O campo "Observação" deve ser preenchido'),
});

function EmpresaCatalogoTokenEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idCatalogo, id, background } = location.state;

  const [tokenAcesso, setTokenAcesso] = useState(null);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso/${id}`)
      .then((response) => {
        const token = response.data;

        const basePath = process.env.REACT_APP_HOMEPAGE;

        const url = basePath.endsWith('/')
          ? `${basePath}catalogo-token?token=${token.token}`
          : `${basePath}/catalogo-token?token=${token.token}`;

        setTokenAcesso({
          ...token,
          token: url,
          dataDesa: token.dataDesa
            ? format(parseISO(token.dataDesa), 'dd/MM/yyyy HH:mm:ss')
            : null,
          instLido: token.instLido
            ? format(parseISO(token.instLido), 'dd/MM/yyyy HH:mm:ss')
            : null,
        });

        formRef.current.setFieldValue('tipo', opTokenTipo[token.tipo]);
        formRef.current.setFieldValue('duracao', opTokenDuracao[token.duracao]);
      });
  }, [idEmpresa, idCatalogo, id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { nome, observacao, tipo } = data;

        await api.put(
          `empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso/${id}`,
          {
            nome,
            observacao,
            tipo,
          }
        );

        toast.success('Token atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idCatalogo, id]
  );

  return (
    <>
      <TitleBar back={!background} title="Editar token de acesso" />
      <FormWrapper>
        <Form
          id="formEmpresaCatalogoToken"
          initialData={tokenAcesso}
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" />
              <Input
                id="dataDesa"
                name="dataDesa"
                label="Data desativação"
                width={400}
                disabled
              />
              <Input
                id="instLido"
                name="instLido"
                label="Último acesso"
                width={400}
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Select
                id="tipo"
                name="tipo"
                label="Tipo"
                isSearchable={false}
                options={opTokenTipo}
                defaultValue={opTokenTipo[0]}
                width={200}
              />
              <Select
                id="duracao"
                name="duracao"
                label="Duração (Dias)"
                isSearchable={false}
                isDisabled
                options={opTokenDuracao}
                defaultValue={opTokenDuracao[0]}
                width={200}
              />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="observacao"
                name="observacao"
                label="Observação"
                rows={5}
              />
              <TextArea
                id="token"
                name="token"
                label="Token"
                rows={5}
                disabled
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaCatalogoTokenEditar };
