import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import { InputWrapper } from '../..';

export default function AsyncSelectInput({ name, label, width, ...rest }) {
  const selectRef = useRef();
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // path: 'select.state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map((option) => option.value);
        }

        if (!ref.select.state.value) {
          return '';
        }

        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.select.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <InputWrapper width={width} flexDirection="column">
      {label && <label htmlFor={fieldName}>{label}</label>}

      <AsyncSelect
        name={name}
        ref={selectRef}
        className={error ? 'has-error' : ''}
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        placeholder="Seleciona uma opção"
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

AsyncSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
};

AsyncSelectInput.defaultProps = {
  label: null,
  width: null,
};
