import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';

import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaRetirada() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [retiradas, setRetiradas] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaRetirada = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`/empresa/${idEmpresa}/solicitacao`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setRetiradas(
          response.data.solicitacao.map(({ instInc, ...retirada }) => ({
            ...retirada,
            instInc: format(parseISO(instInc), 'dd/MM/yyyy HH:mm:ss'),
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleVisualizar = useCallback(
    (e, idSolicitacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push(`/empresa/retirada/detalhe`, {
          idEmpresa,
          idSolicitacao,
        });
      }
    },
    [idEmpresa]
  );

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaRetirada();
  }, [location, currentPage, carregaRetirada]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Retiradas` : ''}
        isSearchable
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idSolicitacao', title: 'Solicitação' },
            { fieldname: 'instInc', title: 'Data e hora' },
            { fieldname: 'extCodiDiv2', title: 'Ext. Divisão' },
            { fieldname: 'extNumeOS', title: 'Ext. O.S.' },
            { fieldname: 'situacao', title: 'Situação' },
            { fieldname: 'qtdeItens', title: 'Qtde. Itens' },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {retiradas.map((retirada) => (
            <RowMaster
              key={retirada.idSolicitacao}
              onClick={(e) => handleVisualizar(e, retirada.idSolicitacao)}
            >
              <td>{`#${retirada.idSolicitacao}`}</td>
              <td>{retirada.instInc}</td>
              <td>{retirada.extCodiDiv2}</td>
              <td>{retirada.extNumeOS}</td>
              <td>{retirada.dataDesa ? 'Cancelada' : 'Solicitada'}</td>
              <td>{retirada.qtdeItens}</td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export default EmpresaRetirada;
