import produce from 'immer';

const INITIAL_STATE = {
  value: null,
  searchHandle: null,
  paginas: null,
  paginasERP: null,
};

export default function homeFilter(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@homeFilter/HANDLE_SEARCH': {
        const { value, searchHandle, paginas, paginasERP } = action;
        draft.value = value;
        draft.searchHandle = searchHandle;
        draft.paginas = paginas;
        draft.paginasERP = paginasERP;
        break;
      }
      case '@homeFilter/HANDLE_SEARCH_CLEAR': {
        draft.value = null;
        draft.searchHandle = null;
        draft.paginas = null;
        draft.paginasERP = null;
        break;
      }
      default:
    }
  });
}
