import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 20px;
  height: 100%;
  max-height: calc(100% - 1px);
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export const Mapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  width: 300px;

  > ul {
    & > li {
      margin: 10px 5px;
      text-align: center;
      color: #ccc;

      & > h1 {
        font-size: 16px;
      }

      & > span {
        border: 1px dashed #ccc;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin: 5px 20px;
        text-transform: uppercase;
      }
    }
  }
`;

export const Scroll = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-right: 20px;
  border-radius: 10px;

  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 100%;
  width: 100%;
  overflow-y: auto;

  table {
    flex: 1;
    border-collapse: collapse;

    th,
    td {
      padding: 5px;
      text-align: left;
      border-bottom: 1px solid #ddd;

      button {
        border: none;
        background: none;
      }
    }

    tbody > tr:hover {
      background: #f5f5f5;
    }
  }
`;
