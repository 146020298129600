import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../services/api';

import { getNivel, setNivel } from '../../lib/asyncUtils';

import Pagination from '../../components/Editor/Pagination';

import EditorArea from '../../components/Editor';
import EditorTable from '../../components/Editor/Table';

import { Form } from '../../components/Form';
import Input from '../../components/Form/Input';
import AsyncCreatableSelectInput from '../../components/Form/Input/AsyncCreatableSelect';

import TitleBar from '../../components/TitleBar';

import { Container, Wrapper, Panel } from './styles';

function Editor({
  location: {
    state: { id, paginaInicial = 1 },
  },
}) {
  const formRef = useRef();

  const [catalogo, setCatalogo] = useState(null);

  const [nivelClassificacao, setNivelClassificacao] = useState([]);

  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [itensPagina, setItensPagina] = useState([]);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    /** informações do catálogo */
    api.get(`editor.editar/${id}`).then((response) => {
      setPaginaCatalogo(null);

      setNivelClassificacao(
        response.data.nivelClassificacoes.map((classificacao) => ({
          idCatalogo: id,
          nivel: classificacao.nivel,
          descricao: classificacao.descricao,
        }))
      );

      setCatalogo({
        ...response.data,
        paginas: response.data.paginaCatalogo.map((pagina) => pagina.idPagina),
      });

      setTotalPage(response.data.paginaCatalogo.length);
      setCurrentPage(paginaInicial);
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    /** limpa variável de itens */
    setItensPagina([]);

    if (catalogo && currentPage) {
      const pagina = catalogo.paginas[currentPage - 1];

      /** carrega itens da página */
      api.get(`editor.editar/${id}/${pagina}`).then((response) => {
        /** formata níveis */
        const nivelValue = response.data.nivel.map((nivel) => ({
          name: `nivel_${nivel.nivel}`,
          value: {
            value: nivel.idNivel,
            label: nivel.descricao
              ? `${nivel.codigo} - ${nivel.descricao}`
              : `${nivel.codigo}`,
          },
        }));

        setPaginaCatalogo(response.data);

        setItensPagina(
          response.data.itemPagina.map((item) => ({
            ...item,
            ...item.materialFabricante,
          }))
        );

        /** carrega informações da página */
        if (formRef.current) {
          formRef.current.reset();

          nivelValue.forEach((nivel) => {
            formRef.current.setFieldValue(nivel.name, nivel.value);
          });
          formRef.current.setFieldValue('caminho', response.data.caminho);
          formRef.current.setFieldValue('descricao', response.data.descricao);
          formRef.current.setFieldValue('nota', response.data.nota);
        }
      });
    }
  }, [id, catalogo, currentPage]);

  const handleMapping = useCallback((items) => {
    // const { idioma } = catalogo;

    // setItensPagina(
    //   items.map((item) => {
    //     /** define o idioma (0 - PT-BR; 1 - EN) */
    //     const itemMapping =
    //       idioma === '0'
    //         ? {
    //             idItem: item.idItem,
    //             partnumber: item.partnumber,
    //             quantidade: item.quantidade ? parseFloat(item.quantidade) : 0,
    //             descricaoBR: item.descricao,
    //             nota: item.nota,
    //             especTecnica: item.especTecnica,
    //             ordena: item.ordena ? Number(item.ordena) : 0,
    //           }
    //         : {
    //             idItem: item.idItem,
    //             partnumber: item.partnumber,
    //             quantidade: item.quantidade ? parseFloat(item.quantidade) : 0,
    //             descricaoEN: item.descricao,
    //             nota: item.nota,
    //             especTecnica: item.especTecnica,
    //             ordena: item.ordena ? Number(item.ordena) : 0,
    //           };

    //     return itemMapping;
    //   })
    // );

    setItensPagina(items);
  }, []);

  const handleSave = useCallback(async ({ svg }) => {
    const formData = new FormData();
    formData.append('file', svg);

    /** enviar o arquivo para o servidor e recebe o local em que ele foi armazenado */
    const response = await api.post('files', formData);
    const { path } = response.data;

    formRef.current.setFieldValue('caminho', path);

    formRef.current.submitForm();
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const { descricao, nota, caminho } = data;

        const { idPagina } = paginaCatalogo;

        const niveis = [];
        Object.getOwnPropertyNames(data).forEach((attr) => {
          if (attr.includes('nivel_') && data[attr] !== '') {
            niveis.push({
              nivel: Number(attr.replace(/\D/g, '')),
              idNivel: data[attr],
            });
          }
        });

        /** envia todas as informações para serem persistidas */
        await api.put(`editor.editar/${id}`, {
          idPagina,
          descricao,
          nota,
          caminho,
          niveis,
          itensPagina,
        });

        /** mensagem de sucesso */
        toast.success('Página salva com sucesso!');

        // setCurrentPage(currentPage + 1);
      } catch (error) {
        /** mensagem de erro */
        toast.error(`Ops! Ocorreu um problema. ${error}`);
      }
    },
    [id, paginaCatalogo, itensPagina]
  );

  return (
    <Container>
      <TitleBar back title={catalogo && catalogo.nome} />

      {catalogo && (
        <Wrapper>
          <Panel>
            <Pagination
              pageCount={totalPage}
              initialData={paginaInicial}
              onPageChange={(selected) => {
                setCurrentPage(selected);
              }}
            />
            {paginaCatalogo && (
              <Form
                id="editorForm"
                ref={formRef}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Form.Column>
                  {nivelClassificacao.map((classificacao) => (
                    <AsyncCreatableSelectInput
                      key={classificacao.nivel}
                      id={classificacao.nivel}
                      name={`nivel_${classificacao.nivel}`}
                      label={classificacao.descricao}
                      defaultOptions
                      loadOptions={(value, cb) =>
                        getNivel(
                          {
                            id: classificacao.idCatalogo,
                            nivel: classificacao.nivel,
                            value,
                          },
                          cb
                        )
                      }
                      onCreate={(e, value) => {
                        setNivel({
                          e,
                          id: classificacao.idCatalogo,
                          value,
                        });
                      }}
                    />
                  ))}
                </Form.Column>
                <Form.Column>
                  <Input id="caminho" name="caminho" isHidden />
                  <Input
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    type="text"
                  />
                  <Input
                    id="nota"
                    name="nota"
                    label="Descrição da nota"
                    type="text"
                  />
                </Form.Column>
              </Form>
            )}
          </Panel>

          {paginaCatalogo && (
            <EditorArea
              pagina={paginaCatalogo}
              itensPagina={itensPagina}
              catalogo={catalogo}
              onMapping={handleMapping}
              onSave={handleSave}
              onCancel={() => {}}
            />
          )}

          {itensPagina && (
            <EditorTable
              itensPagina={itensPagina}
              isDraggable
              onSubmit={() => {}}
            />
          )}
        </Wrapper>
      )}
    </Container>
  );
}

Editor.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Editor;
