import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../../services/api';
import history from '../../../../../services/history';

import { AppError } from '../../../../../errors/AppError';

import TitleBar from '../../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../../components/Form';
import SelectInput from '../../../../../components/Form/Input/Select';

function EmpresaCatalogoNavegacaoCriar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idCatalogo } = location.state;

  const [catalogoNavegacao, setCatalogoNavegacao] = useState([]);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/navegacao-catalogo/${idCatalogo}`)
      .then((response) => {
        setCatalogoNavegacao(
          response.data.map((classificacao) => ({
            idClassificacao: classificacao.idClassificacao,
            descricao: classificacao.descricao,
            navegacoes: classificacao.navegacoes,
            itens: classificacao.navegacoes.map((navegacao) => ({
              value: navegacao.idNavegacao,
              label: `${navegacao.codigo} - ${navegacao.descricao}`,
            })),
            padrao: classificacao.padrao
              ? {
                  value: classificacao.padrao.idNavegacao,
                  label: `${classificacao.padrao.codigo} - ${classificacao.padrao.descricao}`,
                }
              : null,
          }))
        );
      });
  }, [idEmpresa, idCatalogo]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        /** monta a validação dinamicamente */
        const shape = {};

        catalogoNavegacao.forEach((classificacao) => {
          shape[`navegacao${classificacao.idClassificacao}`] = Yup.number()
            .typeError(
              `O campo "${classificacao.descricao}" deve ser preenchido`
            )
            .required(
              `O campo "${classificacao.descricao}" deve ser preenchido`
            );
        });
        const schema = Yup.object().shape(shape);

        await schema.validate(data, {
          abortEarly: false,
        });

        /** pega os itens gerados */
        const itens = Object.values(data).map((n) => n);

        await api.post(
          `empresa/${idEmpresa}/navegacao-catalogo/${idCatalogo}`,
          {
            itens,
          }
        );

        toast.success('Relacionamento de menu realizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idCatalogo, catalogoNavegacao]
  );

  return (
    <>
      <TitleBar
        back={!!location.background}
        title="Novo relacionamento catálogo / menu"
      />
      <FormWrapper>
        <Form
          id="formEmpresaCatalogoNavegacao"
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            {catalogoNavegacao.map((classificacao) => (
              <SelectInput
                key={classificacao.idClassificacao}
                id={`navegacao${classificacao.idClassificacao}`}
                name={`navegacao${classificacao.idClassificacao}`}
                label={classificacao.descricao}
                isSearchable
                options={classificacao.itens}
                defaultValue={classificacao.padrao}
              />
            ))}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button
              id="btn-submit"
              type="submit"
              form="formEmpresaCatalogoNavegacao"
            >
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaCatalogoNavegacaoCriar };
