import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { getModelo, getFabricante } from '../../../lib/asyncUtils';
import { opIdioma } from '../../../lib/inputOption';

import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';
import AsyncSelect from '../../../components/Form/Input/AsyncSelect';
import UploadInput from '../../../components/Form/UploadInput';

import TitleBar from '../../../components/TitleBar';

function EditorCriar() {
  const formRef = useRef(null);

  const [classificacaoNivel, setClassificacaoNivel] = useState([]);

  const handleSubmit = useCallback(async (data) => {
    // const config = {
    //   onUploadProgress(progress) {
    //     const { loaded, total } = progress
    //     const percentageProgress = Math.floor((loaded/total) * 100)
    //     console.log(loaded, total, percentageProgress);
    //   }
    // }

    try {
      const { idArquivo, nome, versao, idioma, fabricante, modelo, qtdeNivel } =
        data;

      const classificacao = [];
      Object.getOwnPropertyNames(data).forEach((attr) => {
        if (attr.includes('classificacaoNivel_')) {
          classificacao.push({
            nivel: Number(attr.replace(/\D/g, '')),
            descricao: data[attr],
          });
        }
      });

      /** processa o catálogo no servidor */
      const response = await api.post('/catalogo', {
        idArquivo,
        nome,
        versao,
        idioma,
        idFabricante: fabricante,
        idModelo: modelo,
        qtdeNivel,
        classificacaoNivel: classificacao,
        tipoConversao: '0',
      });

      const { idCatalogo } = response.data;

      history.push('/edicao/classificar', { id: idCatalogo });

      toast.success('Catálogo carregado com sucesso!');
    } catch (err) {
      toast.error(`Ops! Ocorreu um problema. ${err}`);
    }
  }, []);

  const handleUpload = useCallback(({ metadados }) => {
    const { title } = metadados;
    if (title) formRef.current.setFieldValue('name', title);
  }, []);

  const handleQtdeNivel = useCallback((e) => {
    const qtdeNivel = Number(e.target.value) || 0;

    const qtdeFormatada = Array.from({ length: qtdeNivel }, (v, k) => k + 1);
    setClassificacaoNivel(qtdeFormatada);
  }, []);

  return (
    <>
      <TitleBar back title="Carregar arquivo" width={1600} />

      <FormWrapper withBorder>
        <Form
          id="form"
          ref={formRef}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Form.Column alignCenter>
            <UploadInput name="idArquivo" onUpload={handleUpload} />
            <Input id="nome" name="nome" label="Nome" width={800} />
            <Input id="versao" name="versao" label="Versão" width={800} />
            <Select
              id="idioma"
              name="idioma"
              label="Idioma"
              isSearchable={false}
              defaultValue={opIdioma[0]}
              options={opIdioma}
              width={800}
            />
            <AsyncSelect
              id="modelo"
              name="modelo"
              label="Modelo"
              cacheOptions
              defaultOptions
              loadOptions={getModelo}
              width={800}
            />
            <AsyncSelect
              id="fabricante"
              name="fabricante"
              label="Fabricante"
              cacheOptions
              defaultOptions
              loadOptions={getFabricante}
              width={800}
            />
            <Input
              id="qtdeNivel"
              name="qtdeNivel"
              label="Quantidade de Níveis"
              type="number"
              onBlur={handleQtdeNivel}
              width={800}
            />
            <h2>Classificação dos níveis</h2>

            {classificacaoNivel.length !== 0 && (
              <>
                {classificacaoNivel.map((classificacao) => (
                  <Input
                    key={classificacao}
                    id={`classificacaoNivel_${classificacao}"`}
                    name={`classificacaoNivel_${classificacao}"`}
                    label={`Descrição Nível ${classificacao}`}
                    type="text"
                    defaultValue={`Nível ${classificacao}`}
                    width={800}
                  />
                ))}
              </>
            )}
          </Form.Column>
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="form">
              Próximo
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EditorCriar;
