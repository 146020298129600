import styled from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: `${props.width}px`,
    height: `${props.height}px`,
    zIndex: `${props.placeOnTop ? 10 : 1}`,
  },
}))`
  position: absolute;
  background: none;
`;

export const Resizers = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #4286f4;
  box-sizing: border-box;
`;

export const Resizer = styled.div`
  position: absolute;
  border: 1px solid #4286f4;
  background: #4286f4;
  border-radius: 50%;
  height: 6px;
  width: 6px;

  &#top-left {
    left: -3px;
    top: -3px;
    cursor: nwse-resize;
  }

  &#top-right {
    right: -3px;
    top: -3px;
    cursor: nesw-resize;
  }

  &#bottom-left {
    left: -3px;
    bottom: -3px;
    cursor: nesw-resize;
  }

  &#bottom-right {
    right: -3px;
    bottom: -3px;
    cursor: nwse-resize;
  }
`;

export const Line = styled.div.attrs((props) => ({
  style: {
    left: `${props.left}px`,
    position: props.position,
    top: props.top,
    bottom: props.bottom,
    border: `1px solid ${props.border}`,
    cursor: props.cursor,
  },
}))``;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

Context.Selector = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: space-between;
  top: 0;
  max-height: 100%;
  padding: 5px;
  width: 100%;
`;

Context.SelectorOptionGroup = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  max-height: 100%;
`;

Context.SelectorOption = styled(animated.button)`
  position: relative;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 2px;
  background: #fafaff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 3;

  & .rotate90 {
    transform: rotate(-90deg);
  }
`;

Context.Line = styled.div`
  position: absolute;
  display: grid;
  top: 50%;
  transform: translateY(-50%);
`;

Context.LineOption = styled(animated.button)`
  position: relative;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  top: 0;
  left: min(
    max(
      calc(20px * (${(props) => props.order})),
      ${(props) => `${props.left}px`}
    ),
    calc(
      ${(props) => `${props.maxleft}px`} -
        (20px * ${(props) => props.inverseorder})
    )
  );
  width: 20px;
  height: 20px;
  margin: 2px;
  background: #fafaff;
  border: 1px solid #ccc;
  border-radius: 5px;
  transform-origin: center;
  z-index: 2;
`;
