import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    border: 1px solid #ccc;
    border-radius: 25px;
    margin: 5px;

    svg {
      width: 250px;
      height: 350px;
    }
  }
`;
