import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { CheckWrapper } from '../..';

export default function CheckBoxInput({ name, label, width, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => (ref.checked ? '1' : '0'),
      clearValue: (ref) => {
        ref.checked = false;
      },
      setValue: (ref, value) => {
        ref.checked = value === '1';
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <CheckWrapper width={width}>
      {label ? (
        <label htmlFor={fieldName}>
          <input
            type="checkbox"
            ref={inputRef}
            className={error ? 'has-error' : ''}
            {...rest}
          />
          {label}
        </label>
      ) : (
        <input
          type="checkbox"
          ref={inputRef}
          className={error ? 'has-error' : ''}
          {...rest}
        />
      )}

      {error && <span className="error">{error}</span>}
    </CheckWrapper>
  );
}

CheckBoxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
};

CheckBoxInput.defaultProps = {
  label: null,
  width: null,
};
