import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  /* position: relative; */
  height: 100%;
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: 10px;
  height: 890px;
  overflow: auto;
`;

export const Table = styled.table`
  padding: 10px 0;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;

    button {
      border: none;
      background: none;
      padding: 5px;
      margin-right: 5px;
    }
  }

  tbody > tr:hover {
    background: #f5f5f5;
  }
`;

export const TableRow = styled.tr`
  background: ${(props) => (props.linked ? '#E6DE2A' : '#fff')};

  &:hover {
    background: ${(props) =>
      props.linked ? darken(0.2, '#E6DE2A') : '#f5f5f5'};
  }
`;
