import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';
import history from '../../services/history';

import { CSV, opExport } from '../../lib/csv-core';

import { AppError } from '../../errors/AppError';
import { conCatalogoSituacao } from '../../lib/const';
import { opCatalogoSituacao } from '../../lib/inputOption';

import TitleBar from '../../components/TitleBar';
import ActionMenu from '../../components/ActionMenu';
import { Table, RowMaster } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import Pagination from '../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function Catalogo() {
  const location = useLocation();
  const [catalogos, setCatalogos] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaCatalogo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page, ...flt } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      ...flt,
    };

    api
      .get('/catalogo', {
        params: configFiltro,
      })
      .then((response) => {
        setCatalogos(
          response.data.catalogo.map((catalogo) => ({
            ...catalogo,
            fabricante:
              catalogo.arquivo &&
              catalogo.arquivo.fabricante &&
              catalogo.arquivo.fabricante.descricao,
            modelo:
              catalogo.arquivo &&
              catalogo.arquivo.modelos
                .map((modelo) => modelo.descricao)
                .join(', '),
            responsavel: catalogo.empresaResponsavel.descricao,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleDownload = useCallback(
    async (idCatalogo) => {
      api
        .post(`download/${idCatalogo}`)
        .then(() => {
          toast.success(
            'Arquivo gerado com sucesso! Você já pode carregar as imagens na mobilidade.'
          );

          carregaCatalogo();
        })
        .catch((err) => {
          AppError(err);
        });
    },
    [carregaCatalogo]
  );

  const handlePublicar = useCallback(
    async (idCatalogo) => {
      try {
        const catalogo = catalogos.find((c) => c.idCatalogo === idCatalogo);

        if (catalogo) {
          let booPublicar = true;

          if (
            [
              conCatalogoSituacao.EM_DESENVOLVIMENTO,
              conCatalogoSituacao.AGUARDANDO_APROVACAO,
            ].includes(catalogo.situacao)
          ) {
            /** verifica se a conversão do catálogo foi concluída */
            const response = await api.get(`catalogo/${idCatalogo}/analise`);
            const { analise } = response.data || {};
            const { analisePaginaCatalogo } = analise || {};
            const { concluido = 0, total = 0 } = analisePaginaCatalogo || {};

            if (total === 0 || concluido !== total) {
              booPublicar = window.confirm(
                'A conversão do catálogo não foi concluída ainda, deseja publicar mesmo assim?'
              );
            }
          }

          if (booPublicar) {
            if (
              [
                conCatalogoSituacao.EM_DESENVOLVIMENTO,
                conCatalogoSituacao.AGUARDANDO_APROVACAO,
              ].includes(catalogo.situacao)
            ) {
              /** publicar catálogo */
              await api.patch(`catalogo/${idCatalogo}/publicar`);

              toast.success('Catálogo publicado com sucesso!');

              if (
                window.confirm(
                  'Deseja disponibilizar o catálogo para download da mobilidade?'
                )
              ) {
                handleDownload(idCatalogo);
                /** os dados serão recarregados pela rotina de download */
                return;
              }
            } else {
              /** despublicar catálogo */
              await api.patch(`catalogo/${idCatalogo}/despublicar`);
            }

            /** recarrega informações do catálogo */
            carregaCatalogo();
          }
        }
      } catch (err) {
        AppError(err);
      }
    },
    [catalogos, carregaCatalogo, handleDownload]
  );

  const handleEditar = useCallback((idCatalogo) => {
    history.push('/catalogo/detalhe', { id: idCatalogo });
  }, []);

  const handleCriar = useCallback(() => {
    history.push(`/edicao/catalogo-criar`, { background: location });
  }, [location]);

  const handleRemove = useCallback(
    async (idCatalogo) => {
      /** confirmação */
      if (window.confirm('Deseja realmente excluir o catálogo?')) {
        try {
          /** exclui o registro */
          await api.delete(`catalogo/${idCatalogo}`);

          carregaCatalogo();

          /** mensagem de sucesso */
          toast.success('Catálogo excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaCatalogo]
  );

  const handleVisualizar = useCallback(
    (e, idCatalogo) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idCatalogo);
      }
    },
    [handleEditar]
  );

  const handleVisualizarPublico = useCallback((idCatalogo) => {
    history.push(`/catalogo.partes/${idCatalogo}`);
  }, []);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO);
  }, []);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaCatalogo();
  }, [location, currentPage, carregaCatalogo]);

  return (
    <Container>
      <TitleBar
        title="Catálogos"
        isSearchable
        filterSearchType="CATALOGO"
        onNew={handleCriar}
        onFileDownload={handleExportar}
      />

      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idCatalogo', title: 'Catálogo' },
              { fieldname: 'nome', title: 'Classe Operacional' },
              { fieldname: 'modelo', title: 'Modelo' },
              { fieldname: 'fabricante', title: 'Fabricante' },
              { fieldname: 'versao', title: 'Versão' },
              { fieldname: 'responsavel', title: 'Responsável' },
              { fieldname: 'situacao', title: 'Situação' },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {catalogos.map((catalogo) => (
              <RowMaster
                key={catalogo.idCatalogo}
                onClick={(e) => handleVisualizar(e, catalogo.idCatalogo)}
              >
                <td>{catalogo.idCatalogo}</td>
                <td>{catalogo.nome}</td>
                <td>{catalogo.modelo}</td>
                <td>{catalogo.fabricante}</td>
                <td>{catalogo.versao}</td>
                <td>{catalogo.responsavel}</td>
                <td>
                  {opCatalogoSituacao[catalogo.situacao]
                    ? opCatalogoSituacao[catalogo.situacao].label
                    : ''}
                </td>
                <td>
                  <ActionMenu
                    data={catalogo}
                    onPublish={() => handlePublicar(catalogo.idCatalogo)}
                    onEdit={() => handleEditar(catalogo.idCatalogo)}
                    onRemove={() => handleRemove(catalogo.idCatalogo)}
                    onView={() => handleVisualizarPublico(catalogo.idCatalogo)}
                    onLineView={() =>
                      handleVisualizarPublico(catalogo.idCatalogo)
                    }
                    onLineDownload={() => handleDownload(catalogo.idCatalogo)}
                    useDownloadBadgeIndicator={
                      catalogo.situacao === 2 &&
                      (!catalogo.instDown ||
                        catalogo.instPubl > catalogo.instDown)
                    }
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </Container>
  );
}

export { Catalogo };
