import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
`;

Wrapper.NotFound = styled.span`
  display: block;
  width: 100%;
  text-align: center;

  font-size: 14px;
  font-style: italic;
  color: rgba(44, 64, 90, 0.5);
  padding: 40px 0;
`;

export const Action = styled.div`
  align-self: center;

  > button {
    background: none;
    border: 0;

    color: #2c405a;

    transition: color 1s;

    + button {
      margin-left: 10px;
    }

    :hover {
      color: ${darken(0.05, '#2c405a')};
    }
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;

  font-size: 14px;

  padding: 5px 0;

  > tbody {
    > tr {
      > td {
        text-align: left;
        vertical-align: top;
        border: 1px solid #cbd0d8;
        border-radius: 5px;
        background: #fff;
        color: #2c405a;
      }
    }
  }

  > tfoot {
    > tr {
      display: flex;

      > td {
        flex: 1;

        > button {
          border: 0;
          background: none;
          border-radius: 5px;
          color: #2c405a;
          height: 32px;
          width: 100%;
          transition: 1s all;

          :hover {
            color: #fff;
            background: #ff5a22;
          }
        }
      }
    }
  }
`;

Table.Container = styled.tr`
  > td {
    ${(props) =>
      props.cancelled &&
      css`
        border: 1px solid #de3b3b !important;
      `}
  }
`;

Table.Wrapper = styled.div`
  position: relative;
  flex: 1;

  strong {
    font-size: 12px;
    text-transform: uppercase;
  }

  span {
    font-size: 12px;

    + span {
      margin-top: 5px;
    }
  }
`;

Table.Header = styled.header`
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid #cbd0d8;
  display: flex;

  button {
    background: none;
    border: 0;
    color: #cbd0d8;
    transition: color 1s;

    & + button {
      margin-left: 10px;
    }
  }
`;

Table.Header.BtnPrint = styled.button`
  :hover {
    color: #2c405a;
  }
`;

Table.Header.BtnCancel = styled.button`
  :hover {
    color: #de3b3b;
  }
`;

Table.Content = styled.table`
  padding: 15px 20px;
  width: 100%;
`;

Table.Row = styled.div`
  display: flex;
  padding: 5px 10px;

  button {
    background: none;
    border: 0;
    color: #cbd0d8;
    transition: color 1s;
  }

  ${(props) =>
    props.withBackground &&
    css`
      background: #cbd0d8;
      border: 1px solid #cbd0d8;
      border-radius: 5px;
    `}

  ${(props) =>
    props.withFlex &&
    css`
      flex: 1;
    `}

  ${(props) =>
    props.withCenter &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

Table.Row.Cancel = styled.span`
  color: #de3b3b;
  font-weight: bold;
  font-style: italic;
  /* padding: 5px; */
`;

Table.Row.BtnCancel = styled.button`
  :hover {
    color: #de3b3b;
  }
`;

Table.Icon = styled.div`
  padding: 5px 20px 5px 0;
`;

Table.Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0 10px;

  span {
    white-space: pre-line;
  }
`;

export const RequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

RequestWrapper.NotFound = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: italic;
  color: rgba(44, 64, 90, 0.5);
  padding: 40px 0;
`;

export const RequestRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 13%) 0px 2px 5px 0px, rgb(0 0 0 / 11%) 0px 1px 2px 0px;
  border: ${(props) => (props.cancelled ? '1px solid #de3b3b' : 'none')};
  border-radius: ${(props) => (props.openInfo ? '5px 5px 0 0' : '5px')};
  padding: 10px 5px;
  margin-top: 15px;
  border-bottom: ${(props) => props.isOpen && '2px solid green'};
  margin: ${(props) => props.isOpen && '20px 0 0 0'};
`;

RequestRow.Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

RequestRow.Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c405a;
  font-size: 12px;

  strong {
    font-size: 10px;
    text-transform: uppercase;
  }
`;

RequestRow.Item.Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cbd0d8;
`;

RequestRow.Item.Cancelado = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  color: #de3b3b;
  font-size: 10px;
`;

export const RequestItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding-top: 25px;

  h3 {
    margin: auto;
    padding-bottom: 20px;
  }
`;

RequestItems.Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 5px;
  margin: ${(props) => props.isOpen && '20px 0'};
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.isOpen &&
    'rgb(0 0 0 / 13%) 0px 2px 5px 0px, rgb(0 0 0 / 11%) 0px 1px 2px 0px'};
`;

RequestItems.Box.Item = styled.div`
  display: flex;
  flex-direction: ${(props) => props.directionColumn && 'column'};
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${(props) => props.isParalela && '#cbd0d8'};
  border-radius: ${(props) => props.isParalela && '5px'};
  padding: ${(props) => (props.isParalela ? '10px' : '5px')};
`;

RequestItems.Box.OthersInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
`;

RequestItems.Box.Item.Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.space ? 'space-between' : 'center')};
  width: ${(props) => props.space && '100%'};
  font-size: 12px;
  padding-bottom: 5px;
  color: ${(props) => props.icon && '#cbd0d8'};
  border-bottom: ${(props) => props.isItem && '1px solid #cbd0d8'};
  border-bottom: ${(props) => props.isOpen && '2px solid green'};
  text-transform: uppercase;

  span,
  strong {
    padding: 5px;
  }
`;

RequestItems.Box.Item.Info.Button = styled.div`
  border: none;
  background-color: transparent;
  color: #cbd0d8;
`;

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

WrapperPage.Btn = styled.button`
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #cbd0d8;
`;

WrapperPage.Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  color: #2c405a;
`;

export const RequestInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: rgb(0 0 0 / 13%) 0px 2px 5px 0px, rgb(0 0 0 / 11%) 0px 1px 2px 0px;
  border-right: ${(props) => (props.cancelled ? '1px solid #de3b3b' : 'none')};
  border-left: ${(props) => (props.cancelled ? '1px solid #de3b3b' : 'none')};
  border-bottom: ${(props) => (props.cancelled ? '1px solid #de3b3b' : 'none')};
  margin: ${(props) => props.isOpen && '0 0 5px 0'};
`;

RequestInfo.Box = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.directionRow ? 'row' : 'column')};
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
`;

RequestInfo.Box.Item = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.directionRow ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${(props) => props.isParalela && '#cbd0d8'};
  color: #2c405a;
  border-radius: ${(props) => props.isParalela && '5px'};
  padding: ${(props) => (props.isParalela ? '0' : '5px')};
  padding: 10px;

  span {
    font-size: 12px;
  }

  strong {
    text-transform: uppercase;
    font-size: 10px;
  }
`;

RequestInfo.Box.Item.Button = styled.button`
  border: none;
  padding: 10px;
  color: blue;
  background-color: transparent;
  text-transform: uppercase;
`;
