import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';

import { FormWrapper, Form, Row, LinkButton } from '../../components/Form';
import Input from '../../components/Form/Input';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

import { Container } from './styles';

const SVGFormatted = ({ urlPublica, svgRef }) => {
  function linkFormatted(childNodes) {
    childNodes.forEach((node) => {
      if (node.nodeName === 'a') {
        const [, idCatalogo, idPagina] = node.href.baseVal.split('/');

        const idItem = node.childNodes[1].innerHTML;

        node.href.baseVal = `/${idCatalogo}/${idPagina}/${idItem}`;
      }

      if (node.hasChildNodes()) {
        return linkFormatted(node.childNodes);
      }

      return true;
    });

    return true;
  }

  return (
    <ReactSVG
      src={urlPublica}
      loading={() => <span>Loading</span>}
      afterInjection={(err, svg) => {
        if (svg) {
          linkFormatted(svg.childNodes);
          svg.setAttribute('width', '750');
          svg.setAttribute('height', '850');
          svgRef.current = svg;
        }
      }}
    />
  );
};

function Manutencao() {
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const formRef = useRef(null);

  useEffect(() => {
    // console.log(currentPage);
    formRef.current.submitForm();
  }, [currentPage]);

  const handleSubmit = useCallback(
    ({ idCatalogo }) => {
      setPaginaCatalogo([]);

      api
        .get(`manutencao/${idCatalogo}`, {
          params: { page: currentPage },
        })
        .then((response) => {
          setPaginaCatalogo(
            response.data.paginaCatalogo.map((c) => ({
              ...c,
              svgRef: createRef(),
            }))
          );
          setTotalPage(response.data.totalPage);
        });
    },
    [currentPage]
  );

  const handleAplicar = useCallback(async () => {
    const paginaPromise = paginaCatalogo.map(async (c) => {
      const blob = new Blob([c.svgRef.current.outerHTML], {
        type: 'image/svg+xml',
      });

      const formData = new FormData();
      formData.append('file', blob);

      const response = await api.post('manutencao', formData);

      const { temp } = response.data;

      await api.put(`manutencao/${c.idCatalogo}/${c.idPagina}`, {
        caminho: c.caminho,
        temp,
      });
    });

    try {
      await Promise.all(paginaPromise);
      toast.success('Desenhos atualizados com sucesso!');
    } catch (err) {
      toast.success('Ocorreu um problema no processo!');
    }
  }, [paginaCatalogo]);

  return (
    <FormWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Input
            id="idCatalogo"
            name="idCatalogo"
            label="Catálogo"
            width={300}
          />
          <LinkButton type="submit">Consultar</LinkButton>
          <LinkButton type="button" onClick={handleAplicar}>
            Aplicar
          </LinkButton>
        </Row>
        {totalPage !== 0 && (
          <Pagination
            pageCount={totalPage}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
            }}
          />
        )}
        <Container>
          {paginaCatalogo.map(({ idPagina, urlPublica, svgRef }) => (
            <SVGFormatted
              key={idPagina}
              urlPublica={urlPublica}
              svgRef={svgRef}
            />
          ))}
        </Container>
      </Form>
    </FormWrapper>
  );
}

export default Manutencao;
