import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 20px;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
  }
`;

export const ToolBar = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export const Panel = styled.div`
  width: 600px;
`;
