import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { Ghost } from '../styles';

const GhostSelector = forwardRef((_, ref) => {
  const [visible, setVisible] = useState(false);
  const [ghostCoords, setGhostCoords] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useImperativeHandle(ref, () => ({
    getCoords: () => ghostCoords,
    updateCoords: (coords) => setGhostCoords(coords),
  }));

  useEffect(() => {
    setVisible(
      () =>
        JSON.stringify(ghostCoords) !==
        JSON.stringify({
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        })
    );
  }, [ghostCoords]);

  return (
    <Ghost
      visible={visible}
      top={ghostCoords.top}
      left={ghostCoords.left}
      width={ghostCoords.width}
      height={ghostCoords.height}
    />
  );
});

export default GhostSelector;
