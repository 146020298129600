import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 10px;
  position: relative;

  ul {
    padding: 10px 0;

    li {
      padding: 5px 0;
      font-size: 16px;
    }
  }
`;
