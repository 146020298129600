import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import { AppError } from '../../../../errors/AppError';
import Icon from '../../../../assets/upload.svg';

import { Container } from './styles';

export function ImportacaoInput({
  name,
  parametros,
  onUploadStart,
  onUploadEnd,
  ...rest
}) {
  const inputRef = useRef(null);

  const [preview, setPreview] = useState('');
  const [{ op, ...params }, setParams] = useState({ op: '' });

  useEffect(() => {
    setParams(parametros);
  }, [parametros]);

  const handleChange = useCallback(
    async (e) => {
      try {
        onUploadStart();

        const file = e.target.files[0];
        if (!file) {
          AppError('Nenhum arquivo de importação fornecido');
        }

        if (file.type !== 'text/csv') {
          AppError('O arquivo de importação deve estar no formato .csv');
        }

        setPreview(file.name);

        const formData = new FormData();
        const { name: filename } = file;
        formData.append('file', file, filename);

        api
          .post('csv/importar', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              tipoImportacao: op,
              ...params,
            },
          })
          .then((response) => onUploadEnd(response.data))
          .catch((err) => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.validation
            ) {
              onUploadEnd(err.response.data);
            } else {
              AppError(err);
              onUploadEnd({ message: 'Importação cancelada' });
            }
          });
      } catch (err) {
        AppError(err);
      } finally {
        inputRef.current.value = null;
      }
    },
    [op, params, onUploadStart, onUploadEnd]
  );

  return (
    <Container>
      <label htmlFor={name}>
        <img src={Icon} alt="CSV" />
        {preview || 'Selecione o arquivo aqui'}

        <input
          ref={inputRef}
          type="file"
          id={name}
          accept="text/csv"
          onChange={handleChange}
          {...rest}
        />
      </label>
    </Container>
  );
}

ImportacaoInput.propTypes = {
  name: PropTypes.string.isRequired,
  parametros: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
};

ImportacaoInput.defaultProps = {
  parametros: {},
  onUploadStart: () => {},
  onUploadEnd: () => {},
};
