import React from 'react';

export const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    const scrollElement = document.querySelector('.topmostWrapper');

    const contentWidth = scrollElement.clientWidth;
    const totalWidth = scrollElement.offsetWidth;

    const scrollBarCompensation = totalWidth - contentWidth;

    scrollElement.style.overflow = 'hidden';
    scrollElement.style.paddingRight = `${scrollBarCompensation}px`;
  }, []);

  const unlockScroll = React.useCallback(() => {
    const scrollElement = document.querySelector('.topmostWrapper');
    scrollElement.style.overflow = '';
    scrollElement.style.paddingRight = '';
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};
