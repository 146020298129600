import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signInTokenRequest } from '../../../store/modules/auth/actions';
// import { setToken } from '../../../store/modules/auth/sagas';

import { adicionaOrdemRequest } from '../../../store/modules/order/actions';

import api from '../../../services/api';
import history from '../../../services/history';

import { useQuery } from '../../../lib/reactRouter';
import { opTipoAcao } from '../../../lib/const';

import { AppContainer } from '../../../components/Container';

const INITIAL_PARAMS = {
  modeloExt: null,
  idSist: null,
  idLoca: null,
  token: null,
  extNumeOS: null,
  extCodiDiv2: null,
  extItemOS: null,
  extObservacao: null,
  extUsuario: null,
  extExtra01: null,
  extExtra02: null,
  extExtra03: null,
  extExtra04: null,
};

const INITIAL_ROUTER = {
  situacao: 0,
  routeParams: null,
};

function AppIntegracao() {
  const dispatch = useDispatch();

  const routeQuery = useQuery(useLocation().search);

  const [router, setRouter] = useState(INITIAL_ROUTER);

  useEffect(
    () => {
      async function carregaRecursos() {
        const parametroRota = {
          ...INITIAL_PARAMS,
          ...Object.fromEntries(routeQuery),
        };

        const {
          modeloExt,
          idSist,
          idLoca,
          token,
          extCodiDiv2,
          extNumeOS,
          extItemOS,
          extObservacao,
          extUsuario,
          extExtra01,
          extExtra02,
          extExtra03,
          extExtra04,
        } = parametroRota;

        /** formata os itens recebidos por parâmetro */
        const extItemOSFormatado = extItemOS ? extItemOS.split('.') : [1];

        dispatch(
          adicionaOrdemRequest(
            extCodiDiv2,
            extNumeOS,
            extItemOSFormatado,
            extObservacao,
            extUsuario,
            extExtra01,
            extExtra02,
            extExtra03,
            extExtra04
          )
        );
        dispatch(signInTokenRequest(token, opTipoAcao.INTEGRACAO_CATALOGO));

        /** token informado por parâmetro */
        // setToken({
        //   payload: {
        //     auth: {
        //       token,
        //     },
        //   },
        // });

        /** controle de tentativas de consulta de catálogo  */
        const timerCounterMAX = 3;
        let timerCounter = 0;
        let timerID;

        const carregaCatalogoApp = async () => {
          /** controle de tentativas */
          timerCounter += 1;
          if (timerCounter > timerCounterMAX) clearInterval(timerID);

          /** verifica se o token já foi aplicado */
          if (api.defaults.headers.Authorization) {
            clearInterval(timerID);

            let routeRedirect = '';
            let paramsRedirect = [];

            try {
              /** consulta informações do catálogo */
              const response = await api.get('/catalogo-app', {
                params: {
                  modeloExt,
                  codiSist: idSist,
                  codiLoca: idLoca,
                },
              });

              /** extrai informações da página e do modelo */
              const { navegacao, catalogo } = response.data;

              paramsRedirect = navegacao.reduce((acumulado, atual, index) => {
                const {
                  padraoFabricante,
                  padraoModelo,
                  navegacao: item,
                } = atual;
                /** verifica se existe itens de navegação */
                if (item.length > 0) {
                  if (Number(padraoFabricante) === 1) {
                    /** padrão 1 - "fabricante" */
                    acumulado.push(`npd_1=${item[0].idNavegacao}`);
                  } else if (Number(padraoModelo) === 1) {
                    /** padrão 2 - "modelo" - concatena para a situação de existir mais de um modelo */
                    acumulado.push(
                      `npd_2=${item.map((i) => i.idNavegacao).join('.')}`
                    );
                  } else {
                    /** padrão genérico "navegacao_classificacao", "navegacao" e "relac_navegacao_catalogo" */
                    acumulado.push(`nav_${index + 1}=${item[0].idNavegacao}`);
                  }
                }

                return acumulado;
              }, []);

              if (catalogo) {
                const { idCatalogo, pagina } = catalogo;

                const { idPagina, nivel, sistema, local } = pagina || {};

                /** constroi rota com os valor padrão */
                routeRedirect = `/${idCatalogo}`;

                if (sistema) paramsRedirect.push(`FLT_IDS=${sistema.codiSist}`);

                if (local) paramsRedirect.push(`FLT_IDL=${local.codiLoca}`);

                /** níveis para redirecionamento das rotas */
                nivel.forEach((n) => {
                  paramsRedirect.push(`IDN_${n.nivel}=${n.idNivel}`);
                });

                /** caso tenha apenas uma página, reposiciona na página */
                if (idPagina) paramsRedirect.push(`IDP=${idPagina}`);
              }

              if (paramsRedirect.length > 0) {
                routeRedirect += `?${paramsRedirect.join('&')}`;
              }

              setRouter({
                situacao: paramsRedirect.length > 0 ? 2 : 1,
                routeRedirect,
              });
            } catch (err) {
              setRouter({
                situacao: 1,
                routeRedirect,
              });
            }
          }
        };

        /** seta intervalo de tempo para aguardar atribuição de token */
        timerID = setInterval(carregaCatalogoApp, 800);
      }

      carregaRecursos();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const appRedirect = useMemo(
    () =>
      router.routeRedirect ? (
        <Redirect to={`/catalogo.partes${router.routeRedirect}`} />
      ) : (
        <AppContainer>
          {router.situacao === 1 ? (
            <>
              <span>
                Nenhum catálogo relacionado ao modelo. Por favor, entre em
                contato com o administrador do sistema.
              </span>
              <button type="button" onClick={() => history.push('/not-found')}>
                Voltar
              </button>
            </>
          ) : (
            <span>Redirecionando, aguarde...</span>
          )}
        </AppContainer>
      ),
    [router]
  );

  return appRedirect;
}

export default AppIntegracao;
