import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import {
  opSugestaoOrigem,
  opSugestaoTipo,
  opSugestaoStatus,
} from '../../../../lib/const';
import { opStatusSugestao } from '../../../../lib/inputOption';
import { getFabricante, getModelo } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/Form/Input/TextArea';

const VIEW = {
  normal: 'normal',
  fabricante: 'fabricante',
  modelo: 'modelo',
};

function EmpresaSugestaoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id, background } = location.state;
  const { idEmpresa } = background.state;

  const [view, setView] = useState();

  const [sugestao, setSugestao] = useState(null);
  const [statusSugestao, setStatusSugestao] = useState(null);

  const schema = Yup.object().shape({
    para: Yup.string().required('O campo "Para" deve ser preenchido'),
  });

  useEffect(() => {
    api.get(`/empresa/${idEmpresa}/sugestao/${id}`).then((response) => {
      const { status, origem, instInc, ...sugestaoData } = response.data;

      setSugestao({
        ...sugestaoData,
        ...origem,
        status: opStatusSugestao[status].label,
        instInc: format(parseISO(instInc), 'dd/MM/yyyy'),
      });

      setStatusSugestao(opStatusSugestao[status].value);

      switch (Number(origem.origem)) {
        case opSugestaoOrigem.CATALOGO_CRIACAO_FABRICANTE:
          setView(VIEW.fabricante);
          break;
        case opSugestaoOrigem.CATALOGO_CRIACAO_MODELO:
          setView(VIEW.modelo);
          break;
        default:
          break;
      }

      if (origem.modelo) {
        api.get(`/modelo/${origem.modelo}`).then((response2) => {
          const { idModelo, descricao } = response2.data;
          formRef.current.setFieldValue('modelo', {
            value: idModelo,
            label: descricao,
          });
        });
      }

      if (origem.fabricante) {
        api.get(`/fabricante/${origem.fabricante}`).then((response2) => {
          const { idFabricante, descricao } = response2.data;
          formRef.current.setFieldValue('fabricante', {
            value: idFabricante,
            label: descricao,
          });
        });
      }
    });
  }, [idEmpresa, id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { para } = data;

        await api
          .put(`/empresa/${idEmpresa}/sugestao/${id}`, {
            para,
          })
          .then(() => toast.success('Sugestão atualizada com sucesso!'));

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, id, schema]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Sugestão" />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          initialData={sugestao}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="idSugestao"
                name="idSugestao"
                label="Sugestão"
                disabled
              />
              <Input id="instInc" name="instInc" label="Criado em" disabled />
              <Input id="status" name="status" label="Status" disabled />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                disabled
              />
            </Form.Row>
            {sugestao &&
              (sugestao.tipo === opSugestaoTipo.CRIACAO_FABRICANTE ||
                sugestao.tipo === opSugestaoTipo.CRIACAO_MODELO) && (
                <Form.Row>
                  <Input id="de" name="de" label="De" disabled />
                  <Input
                    id="para"
                    name="para"
                    label="Para"
                    disabled={statusSugestao !== opSugestaoStatus.PENDENTE}
                  />
                </Form.Row>
              )}
            <Form.Row>
              <TextArea
                id="motivo"
                name="motivo"
                label="Motivo"
                rows="5"
                disabled
              />
            </Form.Row>
            {sugestao && view === VIEW.fabricante && (
              <Form.Row>
                <AsyncSelect
                  id="fabricante"
                  name="fabricante"
                  label="Fabricante"
                  cacheOptions
                  defaultOptions
                  loadOptions={getFabricante}
                  menuPosition="fixed"
                  isDisabled
                />
              </Form.Row>
            )}
            {sugestao && view === VIEW.modelo && (
              <Form.Row>
                <AsyncSelect
                  id="modelo"
                  name="modelo"
                  label="Modelo"
                  cacheOptions
                  defaultOptions
                  loadOptions={getModelo}
                  menuPosition="fixed"
                  isDisabled
                />
              </Form.Row>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            {statusSugestao === opSugestaoStatus.PENDENTE && (
              <button id="btn-submit" type="submit">
                Confirmar
              </button>
            )}
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaSugestaoEditar };
