import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { CSV, opExport } from '../../../../lib/csv-core';

import TitleBar from '../../../../components/TitleBar';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';
import ActionMenu from '../../../../components/ActionMenu';

import Pagination from '../../../../components/EditorAI/Pagination';
import { opTipoAcesso } from '../../../../lib/const';

function EmpresaRelacPaginaSistLoc() {
  const location = useLocation();
  const { idEmpresa, idCatalogo } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);

  const auth = useSelector((state) => state.auth || {});

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaRelacionamentoSistemaLocal = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(
        `empresa/${idEmpresa}/catalogo/${idCatalogo}/relacionamento-sistema-local`,
        {
          params: configFiltro,
        }
      )
      .then((response) => {
        setEmpresa(response.data.empresa);
        setCatalogo(response.data.catalogo);
        setPaginaCatalogo(response.data.paginaCatalogo);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa, idCatalogo]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaRelacionamentoSistemaLocal();
  }, [currentPage, location, carregaRelacionamentoSistemaLocal]);

  const handleGerenciar = useCallback(
    (idPagina) => {
      history.push(`/empresa/catalogo/pagina-sistema-local/editar`, {
        background: location,
        idEmpresa,
        idCatalogo,
        idPagina,
      });
    },
    [location, idEmpresa, idCatalogo]
  );

  const opSituacao = useCallback(
    (situacao) =>
      situacao ? (
        <Table.Row.Kind isAdded>Sim</Table.Row.Kind>
      ) : (
        <Table.Row.Kind isRemoved>Não</Table.Row.Kind>
      ),
    []
  );

  const labTable = useMemo(() => {
    const { nivelClassificacao = [] } = catalogo || {};

    const titulo = nivelClassificacao.map(({ descricao, nivel }) => ({
      fieldname: `nivel_${nivel}`,
      title: descricao,
    }));

    return [
      ...titulo,
      { fieldname: 'descricao', title: 'Descrição' },
      { fieldname: 'relacionamento', title: 'Relacionado?' },
      { fieldname: null, title: null },
    ];
  }, [catalogo]);

  const paginaTabela = useMemo(() => {
    const { nivelClassificacao = [] } = catalogo || {};

    const corpo = paginaCatalogo.map((pagina) => {
      const processa = nivelClassificacao.map((classificacao) => {
        const nivel = pagina.nivel.find(
          (item) => item.nivel === classificacao.nivel
        );

        const { descricao = null } = nivel || {};

        return descricao;
      });

      return {
        idPagina: pagina.idPagina,
        data: [
          ...processa,
          pagina.descricao,
          opSituacao(pagina.relacionamento.length > 0),
        ],
      };
    });

    return corpo;
  }, [catalogo, paginaCatalogo, opSituacao]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_CATALOGO_SISTEMA_LOCAL, {
      idEmpresa,
      idCatalogo,
    });
  }, [idEmpresa, idCatalogo]);

  return (
    <>
      <TitleBar
        back
        title={
          empresa && catalogo
            ? `${empresa.descricao} / ${catalogo.nome} / Relac. Sistema e Local`
            : ''
        }
        isSearchable
        onFileDownload={
          auth.administrator === opTipoAcesso.ADMIN_PLATAFORMA
            ? handleExportar
            : null
        }
      />

      <Table>
        <TableHeader withPadding titles={labTable} cbSort={() => {}} />
        <tbody>
          {paginaTabela.map((pagina, indexTabela) => (
            <RowMaster key={indexTabela}>
              {pagina.data.map((descricao, indexPagina) => (
                <td key={indexPagina}>{descricao}</td>
              ))}
              <td>
                <ActionMenu
                  data={pagina}
                  onLineManager={() => handleGerenciar(pagina.idPagina)}
                  onManager={() => handleGerenciar(pagina.idPagina)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export default EmpresaRelacPaginaSistLoc;
