import React, { useEffect, useRef, useMemo } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { ViewerWrapper } from '../..';

export default function InputViewer({
  name,
  label,
  width,
  strong,
  flexDirection,
  isHidden,
  asRow,
  type,
  fnAttach,
  attachLabel,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const component = useMemo(() => {
    switch (type.toUpperCase()) {
      case 'TEXTAREA':
        return (
          <textarea
            ref={inputRef}
            defaultValue={defaultValue}
            rows={6}
            disabled
            {...rest}
          />
        );
      case 'LINK':
        return (
          <a
            ref={inputRef}
            href={defaultValue}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Exibe apenas o nome do arquivo */}
            {defaultValue && defaultValue.replace(/^.*[\\/]/, '')}
          </a>
        );
      case 'NUMBER':
        return (
          <input
            ref={inputRef}
            defaultValue={defaultValue}
            type="number"
            pattern="[0-9]+([\.,][0-9]+)?"
            disabled
            {...rest}
          />
        );
      case 'ATTACH':
        return (
          <button type="button" className="attach" onClick={fnAttach}>
            {attachLabel} <span>{defaultValue}</span>
          </button>
        );
      default:
        return (
          <input
            ref={inputRef}
            defaultValue={defaultValue}
            disabled
            {...rest}
          />
        );
    }
  }, [type, defaultValue, fnAttach, attachLabel, rest]);

  return (
    <ViewerWrapper
      width={width}
      isHidden={isHidden}
      strong={strong}
      flexDirection={flexDirection}
      asRow={asRow}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      {component}
    </ViewerWrapper>
  );
}

InputViewer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  strong: PropTypes.bool,
  flexDirection: PropTypes.string,
  asRow: PropTypes.bool,
  type: PropTypes.string,
  isHidden: PropTypes.bool,
  fnAttach: PropTypes.func,
  attachLabel: PropTypes.string,
};

InputViewer.defaultProps = {
  type: 'text',
  label: null,
  strong: false,
  flexDirection: 'column',
  asRow: false,
  width: null,
  isHidden: false,
  fnAttach: null,
  attachLabel: null,
};
