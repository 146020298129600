import React, { useState, useEffect, useCallback } from 'react';
// import { useLocation } from 'react-router-dom';
import { MdWarning } from 'react-icons/md';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Container } from '../../../../components/Container';

import TitleBar from '../../../../components/TitleBar';
import EditableTable from '../../../../components/Table/Editable';
import Modal from '../../../../components/Modal';

const INITIAL_MAPPER = [
  { value: 'idItem', label: 'Item' },
  { value: 'partnumber', label: 'Partnumber' },
  { value: 'descricao', label: 'Descrição' },
  { value: 'quantidade', label: 'Quantidade' },
  { value: 'nota', label: 'Nota' },
  { value: 'especTecnica', label: 'Especificação Técnica' },
  { value: 'ordena', label: 'Ordena' },
];

function EditorExtrairMapeamento({ headers, data, onCancel, onMapping }) {
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [mensagemErro, setMensagemErro] = useState(null);

  useEffect(() => {
    setOriginalData(data);
    setTableData(data);
    setTableColumn(headers);
  }, [headers, data]);

  useEffect(() => {
    setSkipPageReset(false);
  }, [tableData]);

  const handleSubmit = useCallback(async () => {
    /** ajusta dados para enviar */
    const submitData = tableData.map((lines) => {
      const entries = new Map(
        tableColumn
          .filter((column) => column.option)
          .map((column) => [column.option, lines[column.accessor] || null])
      );
      return Object.fromEntries(entries);
    });

    const schema = Yup.object().shape({
      idItem: Yup.string().required(
        '● Coluna "ITEM" deve ser determinada / preenchida'
      ),
      partnumber: Yup.string().required(
        '● Coluna "PARTNUMBER" deve ser determinada / preenchida'
      ),
      descricao: Yup.string().required(
        '● Coluna "DESCRICAO" deve ser determinada / preenchida'
      ),
      quantidade: Yup.number().required(
        '● Coluna "QUANTIDADE" deve ser determinada / preenchida'
      ),
      ordena: Yup.number().required(
        '● Coluna "ORDENA" deve ser determinada / preenchida'
      ),
    });

    /** efetua validação dos campos */
    const pendenteValidacao = submitData.map(async (item, index) => {
      try {
        await schema.validate(item, {
          abortEarly: false,
        });

        return { ...item, valid: true };
      } catch ({ errors }) {
        return {
          ...item,
          valid: false,
          err: `Linha ${index}:\n${errors.join('\n')}`,
        };
      }
    });
    const eValido = await Promise.all(pendenteValidacao);

    const errosEncontrados = eValido
      .filter((item) => !item.valid)
      .map((item) => item.err)
      .join('\n');

    setMensagemErro(errosEncontrados);

    if (!errosEncontrados) {
      onMapping(eValido);
      onCancel();
    }
  }, [tableColumn, tableData, onMapping, onCancel]);

  const handleReordenar = useCallback(() => {
    try {
      const { accessor = null } =
        tableColumn.find((column) => column.option === 'ordena') || {};

      if (!accessor) {
        throw new Error('Nenhuma coluna do tipo "Ordena" foi definida');
      }

      let ordenacao = 0;
      setTableData((state) =>
        state.map((atual, index) => {
          if (index === 0) {
            ordenacao = Number(atual[accessor]);
            return atual;
          }

          ordenacao += 1;

          atual[accessor] = String(ordenacao);

          return atual;
        })
      );
    } catch (err) {
      toast.error(err.message);
    }
  }, [tableColumn]);

  function handleDesfazer() {
    setTableData(originalData);
  }

  const updateMyHeaderData = (formData) => {
    const [attribute] = Object.keys(formData);
    const [value] = Object.values(formData);

    const index = tableColumn.findIndex(
      (column) => column.accessor === attribute
    );

    if (index >= 0) {
      tableColumn[index].option = value;
    }
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const deleteMyData = (rowIndex) => {
    setSkipPageReset(true);
    setTableData(tableData.filter((row) => row !== tableData[rowIndex]));
  };

  return (
    <Modal width={95} height={90} onClose={onCancel}>
      <TitleBar back={false} title="Mapeamento dos dados da tabela" />

      <Container.Wrapper>
        <EditableTable
          columns={tableColumn}
          data={tableData}
          headers={INITIAL_MAPPER}
          updateMyHeaderData={updateMyHeaderData}
          updateMyData={updateMyData}
          deleteMyData={deleteMyData}
          skipPageReset={skipPageReset}
        />
      </Container.Wrapper>

      {mensagemErro && (
        <MdWarning size={30} color="#dd0312" title={mensagemErro} />
      )}

      <Container.Note>
        Obs.: Utilize o @ para geração automática para os campos ITEM e
        PARTNUMBER
      </Container.Note>
      <Container.Footer withFlexEnd withSeparate>
        <button id="btn-cancel" type="button" onClick={handleReordenar}>
          Reordenar
        </button>
        <button id="btn-cancel" type="button" onClick={handleDesfazer}>
          Desfazer Alterações
        </button>
        <button id="btn-cancel" type="button" onClick={onCancel}>
          Cancelar
        </button>
        <button id="btn-submit" type="button" onClick={handleSubmit}>
          Confirmar
        </button>
      </Container.Footer>
    </Modal>
  );
}

EditorExtrairMapeamento.propTypes = {
  headers: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onMapping: PropTypes.func.isRequired,
};

export default EditorExtrairMapeamento;
