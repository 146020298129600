import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../services/api';
import history from '../../services/history';

import { CSV, opExport } from '../../lib/csv-core';

import { opSituacao } from '../../lib/inputOption';

import ActionMenu from '../../components/ActionMenu';
import { ActionTitleMenu } from '../../components/ActionTitleMenu';
import TitleBar from '../../components/TitleBar';
import { Table, RowMaster } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import Pagination from '../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function Empresa() {
  const location = useLocation();

  const [empresas, setEmpresas] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaEmpresa = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };
    api
      .get('empresa', {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresas(
          response.data.empresa.map(({ dataDesa, ...empresa }) => ({
            ...empresa,
            dataDesa,
            situacao: dataDesa ? opSituacao[0].label : opSituacao[1].label,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/criar`, { background: location });
  }, [location]);

  const handleEditar = useCallback((idEmpresa) => {
    history.push('empresa/detalhe', { id: idEmpresa });
  }, []);

  const handleVisualizar = useCallback(
    (e, idEmpresa) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idEmpresa);
      }
    },
    [handleEditar]
  );

  const handleTipoComplemento = useCallback(() => {
    history.push('empresa/tipo-complemento');
  }, []);

  const handleNotificacoes = useCallback(() => {
    history.push('/notificacao');
  }, []);

  const handleSugestoes = useCallback(() => {
    history.push('/sugestao');
  }, []);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA);
  }, []);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaEmpresa();
  }, [location, currentPage, carregaEmpresa]);

  return (
    <Container>
      <TitleBar
        title="Empresas"
        isSearchable
        onNew={handleCriar}
        onFileDownload={handleExportar}
      >
        <ActionTitleMenu.Root>
          <ActionTitleMenu.Item
            title="Tipo Complemento"
            onHandler={handleTipoComplemento}
          />
          <ActionTitleMenu.Item
            title="Notificações"
            onHandler={handleNotificacoes}
          />
          <ActionTitleMenu.Item title="Sugestões" onHandler={handleSugestoes} />
        </ActionTitleMenu.Root>
      </TitleBar>

      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idEmpresa', title: 'Empresa' },
              { fieldname: 'descricao', title: 'Descrição' },
              { fieldname: 'situacao', title: 'Situação' },
              { fieldname: 'qtdeUsuario', title: 'Qtde. Usuário' },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {empresas.map((empresa) => (
              <RowMaster
                key={empresa.idEmpresa}
                onClick={(e) => handleVisualizar(e, empresa.idEmpresa)}
              >
                <td>{empresa.idEmpresa}</td>
                <td>{empresa.descricao}</td>
                <td>{empresa.situacao}</td>
                <td>{empresa.qtdeUsuario}</td>
                <td>
                  <ActionMenu
                    data={empresa}
                    onEdit={() => handleEditar(empresa.idEmpresa)}
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </Container>
  );
}

export { Empresa };
