import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

function FabricanteEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const [fabricante, setFabricante] = useState();

  useEffect(() => {
    api.get(`fabricante/${id}`).then((response) => {
      setFabricante(response.data);
    });
  }, [id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        const { descricao } = data;

        await api.put(`fabricante/${id}`, {
          descricao,
        });

        toast.success('Fabricante atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Editar fabricante" />

      <FormWrapper>
        <Form
          id="formFabricante"
          ref={formRef}
          initialData={fabricante}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                width={600}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formFabricante">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FabricanteEditar;
