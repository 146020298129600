import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useField } from '@unform/core';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

import { InputWrapper } from '../..';

export default function CreatableSelectInput({ name, label, width, ...rest }) {
  const selectRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  const { fieldName, registerField, error } = useField(name);

  const styles = {
    multiValue: (base, state) =>
      state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
    multiValueLabel: (base, state) =>
      state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base,
    multiValueRemove: (base, state) =>
      state.data.isFixed ? { ...base, display: 'none' } : base,
  };

  const handleOrder = useCallback(
    (values) =>
      values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed)),
    []
  );

  const handleChange = useCallback(
    (newValue, actionMeta) => {
      if (actionMeta) {
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (!actionMeta.removedValue.isFixed)
              setValue(handleOrder(newValue));
            break;
          case 'clear':
            setValue((state) => state.filter((v) => v.isFixed));
            break;
          default:
            setValue(handleOrder(newValue));
            break;
        }

        return;
      }

      setValue(handleOrder(newValue));
    },
    [handleOrder]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (!inputValue) return;
      // 13 - Enter
      // 9  - Tab
      switch (e.key) {
        case 'Enter':
        case 'Tab': {
          e.preventDefault();

          /** elimina espaços excendentes */
          const input = String(inputValue).replace(/\s+/g, ' ').trim();
          const isDefined = value && !!value.find((opt) => opt.value === input);

          if (!isDefined) {
            selectRef.current.onChange([
              ...value,
              { label: input, value: input },
            ]);
          }

          setInputValue('');
          break;
        }
        default:
          break;
      }
    },
    [inputValue, value]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) return [];
          return ref.state.value.map((option) => option.value);
        }

        if (!ref.state.value) {
          return '';
        }

        return ref.state.value.value;
      },
      setValue: (ref, v) => {
        ref.onChange(v);
      },
    });
  }, [fieldName, selectRef, registerField, rest.isMulti]);

  return (
    <InputWrapper width={width} flexDirection="column">
      {label && <label htmlFor={fieldName}>{label}</label>}

      <CreatableSelect
        ref={selectRef}
        className={error ? 'has-error' : ''}
        components={{ DropdownIndicator: null }}
        styles={styles}
        value={value}
        isClearable={value.some((v) => !v.isFixed)}
        menuIsOpen={false}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        inputValue={inputValue}
        onInputChange={(newValue) => {
          setInputValue(newValue);
        }}
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

CreatableSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
};

CreatableSelectInput.defaultProps = {
  label: null,
  width: null,
};
