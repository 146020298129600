import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import ActionMenu from '../../../components/ActionMenu';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';

import { Wrapper } from './styles';

function CatalogoArranjo({ idCatalogo, idSerie }) {
  const location = useLocation();

  const [arranjos, setArranjos] = useState([]);
  const [showRelacMenu, setShowRelacMenu] = useState(null);

  const carregaArranjo = useCallback(async () => {
    /** lista de aranjos do catálogo */
    const response = await api.get(
      `catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo`
    );

    setArranjos(
      response.data.arranjoCatalogo.map((arranjo) => ({
        ...arranjo,
        numeroSerie: arranjo.serie && arranjo.serie.numeroSerie,
      }))
    );
  }, [idCatalogo, idSerie]);

  useEffect(() => {
    carregaArranjo();
  }, [idCatalogo, idSerie, carregaArranjo]);

  const handleEditarArranjo = useCallback(
    (idArranjo) => {
      history.push(`/catalogo/arranjo/editar`, {
        background: location,
        idCatalogo,
        idSerie,
        idArranjo,
      });
    },
    [location, idCatalogo, idSerie]
  );

  const handleRemoverArranjo = useCallback(
    async (idArranjo) => {
      if (
        window.confirm(
          `Deseja realmente excluir o arranjo? Atenção: Caso exista, esse processo vai excluir o relacionamento desse arranjo com as páginas desse catálogo.`
        )
      ) {
        try {
          await api.delete(
            `catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}`
          );

          carregaArranjo();

          toast.success('Arranjo removido com sucesso.');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idCatalogo, idSerie, carregaArranjo]
  );

  const handleGerenciarArranjo = useCallback(
    (idArranjo, relac) => {
      setShowRelacMenu(false);

      history.push(`/catalogo/arranjo/relac-${relac}`, {
        background: location,
        idCatalogo,
        idSerie,
        idArranjo,
      });
    },
    [location, idCatalogo, idSerie]
  );

  const handleVisualizarArranjo = useCallback(
    (e, idArranjo) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD')
        handleEditarArranjo(idArranjo);
    },
    [handleEditarArranjo]
  );

  return (
    <Table>
      <TableHeader
        withPadding
        titles={[
          { fieldname: 'nome', title: 'Nome do arranjo' },
          { fieldname: 'descricao', title: 'Descrição' },
          { fieldname: null, title: null },
        ]}
        cbSort={() => {}}
      />
      <tbody>
        {arranjos.map((arranjo) => (
          <RowMaster
            key={arranjo.idArranjo}
            onClick={(e) => handleVisualizarArranjo(e, arranjo.idArranjo)}
          >
            <td>{arranjo.nome}</td>
            <td>{arranjo.descricao}</td>
            <td>
              <ActionMenu
                data={arranjo}
                onEdit={() => handleEditarArranjo(arranjo.idArranjo)}
                onRemove={() => handleRemoverArranjo(arranjo.idArranjo)}
                onLineManager={() =>
                  setShowRelacMenu((state) =>
                    state === arranjo.idArranjo ? null : arranjo.idArranjo
                  )
                }
                onLineEdit={() => handleEditarArranjo(arranjo.idArranjo)}
                onLineRemove={() => handleRemoverArranjo(arranjo.idArranjo)}
              />
              <Wrapper visible={showRelacMenu === arranjo.idArranjo}>
                <ul>
                  <li>
                    <button
                      type="button"
                      onMouseDown={() => {
                        handleGerenciarArranjo(arranjo.idArranjo, 'pagina');
                      }}
                    >
                      Páginas
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onMouseDown={() => {
                        handleGerenciarArranjo(arranjo.idArranjo, 'item');
                      }}
                    >
                      Itens
                    </button>
                  </li>
                </ul>
              </Wrapper>
            </td>
          </RowMaster>
        ))}
      </tbody>
    </Table>
  );
}

CatalogoArranjo.propTypes = {
  idCatalogo: PropTypes.number.isRequired,
  idSerie: PropTypes.number.isRequired,
};

export { CatalogoArranjo };
