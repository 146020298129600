import React, { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { Form, FormWrapper } from '../../../components/Form';
import Input from '../../../components/Form/Input';

const schema = Yup.object().shape({
  senha: Yup.string().required('O campo "Senha" é obrigatório'),
  novaSenha: Yup.string()
    .min(6, 'O campo "Nova Senha" precisa ter no mínimo 6 caracteres')
    .required('O campo "Nova Senha" é obrigatório'),
  confirmaSenha: Yup.string().oneOf(
    [Yup.ref('novaSenha'), null],
    'A confirmação de senha não confere'
  ),
});

function UsuarioTrocarSenha() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { senha, novaSenha } = data;

        // await api.put(`empresa/${idEmpresa}/usuario/${id}/trocar`, {
        await api.patch(`usuario/${id}`, {
          senha,
          novaSenha,
        });

        history.goBack();

        toast.success('Senha alterada com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar senha" />
      <FormWrapper>
        <Form
          id="formTrocarSenha"
          ref={formRef}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Form.Column>
            <Input
              id="senha"
              name="senha"
              label="Sua senha atual"
              type="password"
            />
            <Input
              id="novaSenha"
              name="novaSenha"
              label="Nova Senha"
              type="password"
            />
            <Input
              id="confirmaSenha"
              name="confirmaSenha"
              label="Confirma senha"
              type="password"
            />
          </Form.Column>
          <Form.Separate withFlex height={20} />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formTrocarSenha">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { UsuarioTrocarSenha };
