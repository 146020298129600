import React from 'react';
import PropTypes from 'prop-types';

export function ActionTitleMenuItem({ title, onHandler }) {
  return (
    <li>
      <button type="button" id="act-default" onMouseDown={onHandler}>
        {title}
      </button>
    </li>
  );
}

ActionTitleMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  onHandler: PropTypes.func.isRequired,
};
