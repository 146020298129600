import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import history from '../../services/history';

import { AppError } from '../../errors/AppError';

import { getModelo, getFabricante, getEmpresa } from '../../lib/asyncUtils';
import {
  opNoYes,
  opCatalogoSituacao,
  opPaginaTipo,
  opPaginaSituacao,
  opEmpresaAdministrador,
  opMobilidade,
  opStatusSugestao,
} from '../../lib/inputOption';

import TitleBar from '../../components/TitleBar';
import { FormWrapper, Form } from '../../components/Form';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Input/Select';
import AsyncSelect from '../../components/Form/Input/AsyncSelect';

const opFilter = {
  CATALOGO: {
    title: 'Catálogo',
    fields: [
      {
        name: 'emp',
        label: 'Responsável',
        type: 1,
        isMulti: true,
        options: getEmpresa,
      },
      { name: 'clp', label: 'Classe Operacional', type: 0 },
      {
        name: 'fab',
        label: 'Fabricante',
        type: 1,
        isMulti: true,
        options: getFabricante,
      },
      {
        name: 'mdl',
        label: 'Modelo',
        type: 1,
        isMulti: true,
        options: getModelo,
      },
      {
        name: 'sto',
        label: 'Situação',
        type: 2,
        isMulti: true,
        options: opCatalogoSituacao,
      },
    ],
  },
  CATALOGO_PAGINA: {
    title: 'Página',
    fields: [
      {
        name: 'pag',
        label: 'Página',
        type: 0,
      },
      {
        name: 'dsc',
        label: 'Descrição',
        type: 0,
      },
      {
        name: 'tpo',
        label: 'Tipo',
        type: 2,
        isMulti: true,
        options: opPaginaTipo,
      },
      {
        name: 'sto',
        label: 'Situação',
        type: 2,
        isMulti: true,
        options: opPaginaSituacao,
      },
    ],
  },
  EMPRESA_CATALOGO: {
    title: 'Catálogo',
    fields: [
      { name: 'clp', label: 'Classe Operacional', type: 0 },
      {
        name: 'fab',
        label: 'Fabricante',
        type: 1,
        isMulti: true,
        options: getFabricante,
      },
      {
        name: 'mdl',
        label: 'Modelo',
        type: 1,
        isMulti: true,
        options: getModelo,
      },
      {
        name: 'sto',
        label: 'Situação',
        type: 2,
        isMulti: true,
        options: opCatalogoSituacao,
      },
    ],
  },
  EMPRESA_USUARIO: {
    title: 'Usuário',
    fields: [
      { name: 'lgn', label: 'Login', type: 0 },
      { name: 'nom', label: 'Nome', type: 0 },
      { name: 'eml', label: 'E-mail', type: 0 },
      {
        name: 'adm',
        label: 'Administrador',
        type: 2,
        isMulti: true,
        options: opEmpresaAdministrador,
      },
      {
        name: 'sto',
        label: 'Ativo?',
        type: 2,
        isMulti: false,
        options: opNoYes,
      },
    ],
  },
  EMPRESA_MOBILIDADE: {
    title: 'Mobilidade',
    fields: [
      { name: 'ime', label: 'IMEI', type: 0 },
      { name: 'col', label: 'Coletor', type: 0 },
      {
        name: 'sto',
        label: 'Situação',
        type: 2,
        isMulti: true,
        options: opMobilidade,
      },
    ],
  },
  SUGESTAO: {
    title: 'Sugestões',
    fields: [
      { name: 'status', label: 'Status', type: 2, options: opStatusSugestao },
    ],
  },
};

function FiltroPesquisa() {
  const formRef = useRef(null);

  const location = useLocation();

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    const { filterSearchType = '' } = location.state;

    // /** pega campos já informados na query */
    // const curQueryParams = Object.fromEntries(
    //   new URLSearchParams(background.search)
    // );

    setFilter(opFilter[filterSearchType]);
  }, [location]);

  const handleFechar = useCallback(() => {
    const { background } = location.state;

    const { pathname, search, state } = background;

    history.replace(`${pathname}${search}`, state);
  }, [location]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        const { background } = location.state;

        /** pega campos já informados na query */
        const curQueryParams = Object.fromEntries(
          new URLSearchParams(background.search)
        );

        Object.assign(curQueryParams, data);

        /** remove campos sem preenchimento */
        Object.keys(curQueryParams).forEach((key) => {
          if (
            !String(curQueryParams[key]) ||
            !String(curQueryParams[key]).length
          )
            delete curQueryParams[key];
        });

        const arrQueryParams = Object.keys(curQueryParams).map(
          (key) => `${key}=${encodeURI(curQueryParams[key])}`
        );

        history.replace(
          `${background.pathname}?${arrQueryParams.join('&')}`,
          background.state
        );
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [location]
  );

  return (
    <>
      {filter && (
        <>
          <TitleBar title={`Filtro Avançado de ${filter.title}`} />

          <FormWrapper>
            <Form
              id="formFiltroPesquisa"
              ref={formRef}
              onSubmit={handleConfirmar}
              autoComplete="off"
            >
              <Form.Column>
                {filter.fields.map((field, index) => {
                  switch (field.type) {
                    case 0:
                      return (
                        <Input
                          key={index}
                          id={field.name}
                          name={field.name}
                          label={field.label}
                          type="text"
                        />
                      );
                    case 1:
                      return (
                        <AsyncSelect
                          key={index}
                          id={field.name}
                          name={field.name}
                          label={field.label}
                          isMulti={field.isMulti}
                          cacheOptions
                          defaultOptions
                          isClearable
                          loadOptions={field.options}
                        />
                      );
                    case 2:
                      return (
                        <Select
                          key={index}
                          id={field.name}
                          name={field.name}
                          label={field.label}
                          isMulti={field.isMulti}
                          isClearable
                          options={field.options}
                        />
                      );
                    default:
                      return <></>;
                  }
                })}
              </Form.Column>
              <Form.Separate withFlex />
              <Form.Footer>
                <button id="btn-cancel" type="button" onClick={handleFechar}>
                  Cancelar
                </button>
                <button id="btn-submit" type="submit" form="formFiltroPesquisa">
                  Confirmar
                </button>
              </Form.Footer>
            </Form>
          </FormWrapper>
        </>
      )}
    </>
  );
}

export { FiltroPesquisa };
