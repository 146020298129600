import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import {
  opSugestaoOrigem,
  opSugestaoTipo,
  opSugestaoStatus,
} from '../../../lib/const';
import { opStatusSugestao } from '../../../lib/inputOption';
import { getFabricante, getModelo } from '../../../lib/asyncUtils';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import AsyncSelect from '../../../components/Form/Input/AsyncSelect';
import TextArea from '../../../components/Form/Input/TextArea';

const VIEW = {
  normal: 'normal',
  fabricante: 'fabricante',
  modelo: 'modelo',
};

function SugestaoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const [view, setView] = useState();

  const [sugestao, setSugestao] = useState(null);
  const [statusSugestao, setStatusSugestao] = useState(null);

  const schema = useCallback(
    (status) => {
      switch (view) {
        case VIEW.fabricante:
          if (status === opSugestaoStatus.APROVADO) {
            return Yup.object().shape({
              para: Yup.string().required('O campo "Para" deve ser preenchido'),
            });
          }

          return Yup.object().shape({
            para: Yup.string().required('O campo "Para" deve ser preenchido'),
            motivo: Yup.string().required(
              'O campo "Motivo" deve ser preenchido'
            ),
            idFabricante: Yup.string().required(
              'O campo "Fabricante" deve ser preenchido'
            ),
          });

        case VIEW.modelo:
          if (status === opSugestaoStatus.APROVADO) {
            return Yup.object().shape({
              para: Yup.string().required('O campo "Para" deve ser preenchido'),
            });
          }

          return Yup.object().shape({
            para: Yup.string().required('O campo "Para" deve ser preenchido'),
            motivo: Yup.string().required(
              'O campo "Motivo" deve ser preenchido'
            ),
            idModelo: Yup.string().required(
              'O campo "Modelo" deve ser preenchido'
            ),
          });
        default:
          return null;
      }
    },
    [view]
  );

  useEffect(() => {
    api.get(`/sugestao/${id}`).then((response) => {
      const { status, origem, instInc, ...sugestaoData } = response.data;

      setSugestao({
        ...sugestaoData,
        ...origem,
        status: opStatusSugestao[status].label,
        instInc: format(parseISO(instInc), 'dd/MM/yyyy'),
      });

      setStatusSugestao(opStatusSugestao[status].value);

      switch (Number(origem.origem)) {
        case opSugestaoOrigem.CATALOGO_CRIACAO_FABRICANTE:
          setView(VIEW.fabricante);
          break;
        case opSugestaoOrigem.CATALOGO_CRIACAO_MODELO:
          setView(VIEW.modelo);
          break;
        default:
          break;
      }

      if (origem.modelo) {
        api.get(`/modelo/${origem.modelo}`).then((response2) => {
          const { idModelo, descricao } = response2.data;
          formRef.current.setFieldValue('idModelo', {
            value: idModelo,
            label: descricao,
          });
        });
      }

      if (origem.fabricante) {
        api.get(`/fabricante/${origem.fabricante}`).then((response2) => {
          const { idFabricante, descricao } = response2.data;
          formRef.current.setFieldValue('idFabricante', {
            value: idFabricante,
            label: descricao,
          });
        });
      }
    });
  }, [id]);

  const handleConfirmar = useCallback(
    async (status) => {
      try {
        const {
          para,
          motivo,
          idModelo,
          idFabricante,
        } = formRef.current.getData();

        const data =
          view === VIEW.fabricante
            ? { para, motivo, idFabricante }
            : { para, motivo, idModelo };

        switch (status) {
          case opSugestaoStatus.APROVADO:
            await schema(status).validate(
              { para },
              {
                abortEarly: false,
              }
            );

            await api.put(`/sugestao/${id}/aprovacao`, {
              para,
            });

            toast.success('Sugestão aprovada com sucesso!');
            break;
          case opSugestaoStatus.REPROVADO:
            await schema(status).validate(data, {
              abortEarly: false,
            });

            await api.put(`/sugestao/${id}/reprovacao`, {
              ...data,
            });

            toast.success('Sugestão reprovada com sucesso!');
            break;
          default:
            break;
        }

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id, schema, view]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Sugestão" />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          initialData={sugestao}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="tipo" name="tipo" label="Tipo" disabled />
              <Input id="instInc" name="instInc" label="Criado em" disabled />
              <Input id="status" name="status" label="Status" disabled />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                disabled
              />
            </Form.Row>
            {sugestao &&
              (sugestao.tipo === opSugestaoTipo.CRIACAO_FABRICANTE ||
                sugestao.tipo === opSugestaoTipo.CRIACAO_MODELO) && (
                <Form.Row>
                  <Input id="de" name="de" label="De" disabled />
                  <Input
                    id="para"
                    name="para"
                    label="Para"
                    disabled={statusSugestao !== opSugestaoStatus.PENDENTE}
                  />
                </Form.Row>
              )}
            <Form.Row>
              <TextArea
                id="motivo"
                name="motivo"
                label="Motivo"
                rows="5"
                disabled={statusSugestao !== opSugestaoStatus.PENDENTE}
              />
            </Form.Row>

            {sugestao && view === VIEW.fabricante && (
              <Form.Row>
                <AsyncSelect
                  id="idFabricante"
                  name="idFabricante"
                  label="Fabricante"
                  cacheOptions
                  defaultOptions
                  loadOptions={getFabricante}
                  menuPosition="fixed"
                  isDisabled={statusSugestao !== opSugestaoStatus.PENDENTE}
                />
              </Form.Row>
            )}

            {sugestao && view === VIEW.modelo && (
              <Form.Row>
                <AsyncSelect
                  id="idModelo"
                  name="idModelo"
                  label="Modelo"
                  cacheOptions
                  defaultOptions
                  loadOptions={getModelo}
                  menuPosition="fixed"
                  isDisabled={statusSugestao !== opSugestaoStatus.PENDENTE}
                />
              </Form.Row>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>

            {statusSugestao === opSugestaoStatus.PENDENTE && (
              <>
                <button
                  id="btn-aprovar"
                  type="button"
                  form="form-ref"
                  onClick={() => handleConfirmar(opSugestaoStatus.APROVADO)}
                >
                  Aprovar
                </button>
                <button
                  id="btn-reprovar"
                  type="button"
                  form="form-ref"
                  onClick={() => handleConfirmar(opSugestaoStatus.REPROVADO)}
                >
                  Reprovar
                </button>
              </>
            )}
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { SugestaoEditar };
