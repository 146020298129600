import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px dashed #ccc;
  padding: 5px;
  width: 100%;
  height: 900px;
`;

export const ToolBar = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export const Image = styled.img`
  ${(props) =>
    props.orientation === 'portrait'
      ? css`
          height: 874px;
          width: 614px;
        `
      : css`
          height: 614px;
          width: 874px;
        `}
`;
