import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaTipoComplemento() {
  const location = useLocation();

  const [tipoComplemento, setTipoComplemento] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaTipoComplemento = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get('tipo-complemento', {
        params: configFiltro,
      })
      .then((response) => {
        setTipoComplemento(response.data.tipoComplemento);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/tipo-complemento/criar`, { background: location });
  }, [location]);

  const handleEditar = useCallback(
    (idComplemento) => {
      history.push(`/empresa/tipo-complemento/editar`, {
        background: location,
        id: idComplemento,
      });
    },
    [location]
  );

  const handleRemover = useCallback(
    async (idComplemento) => {
      if (window.confirm('Deseja realmente excluir o recurso?')) {
        try {
          await api.delete(`tipo-complemento/${idComplemento}`);

          carregaTipoComplemento();

          toast.success('Recurso excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaTipoComplemento]
  );

  const handleVisualizar = useCallback(
    (e, idComplemento) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idComplemento);
      }
    },
    [handleEditar]
  );

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaTipoComplemento();
  }, [location, currentPage, carregaTipoComplemento]);

  return (
    <>
      <TitleBar
        back
        title="Tipo Complemento"
        isSearchable
        onNew={handleCriar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'chave', title: 'Chave' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {tipoComplemento.map((complemento) => (
            <RowMaster
              key={complemento.idComplemento}
              onClick={(e) => handleVisualizar(e, complemento.idComplemento)}
            >
              <td>{complemento.chave}</td>
              <td>{complemento.descricao}</td>
              <td>
                <ActionMenu
                  data={complemento}
                  onLineRemove={() => handleRemover(complemento.idComplemento)}
                  onEdit={() => handleEditar(complemento.idComplemento)}
                  onRemove={() => handleRemover(complemento.idComplemento)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaTipoComplemento };
