import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.nav`
  background: #f8f8f8;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px #00000033;
  padding: 0 20px;
  min-height: 75px;

  margin: 0 auto;
  width: 100%;
  max-width: 2000px;

  img {
    max-height: 70px;
    width: auto;
  }

  & > ul {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 100%;
  }
`;

export const Menu = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: ${(props) => (props.selected ? '4px solid #ff6d3c' : '0')};

  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  height: 100%;
  min-width: 150px;

  & + li {
    margin-left: 15px;
  }

  a {
    color: ${(props) => (props.selected ? '#2C405A' : 'rgba(44,64,90, 0.5)')};
    transform: color 1s;

    &:hover {
      color: ${darken(0.05, '#2C405A')};
    }
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;

  & > h4 {
    font-size: 13px;
    font-weight: lighter;
    color: #2c405a;
    padding: 0 5px;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: #2c405a;
    margin-left: 5px;
    height: 42px;
    width: 100px;
    text-transform: uppercase;
  }
`;

User.Login = styled.button`
  border: 0;

  > svg {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }
`;

User.Signin = styled.button`
  border: 1px solid #ff6d3c;
  border-radius: 5px;

  &:hover {
    color: #f8f8f8;
    background: #ff6d3c;

    > h5 {
      color: #f8f8f8;
    }
  }
`;

export const CartMenu = styled.div`
  position: relative;

  + div {
    margin-left: 5px;
  }

  > button {
    background: none;
    border: 0;
    color: #2c405a;
    padding: 5px 10px;
    transform: color 1s;

    + button {
      margin-left: 10px;
    }

    :hover {
      color: ${darken(0.05, '#2C405A')};
    }
  }

  > span {
    position: absolute;
    right: 0;
    background: rgba(255, 109, 60, 0.9);
    border-radius: 50%;
    padding: 2px 0;
    width: 14px;
    height: 14px;
    margin: 0 5px;

    color: #fff;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
  }
`;
