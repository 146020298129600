import React from 'react';

import TitleBar from '../../components/TitleBar';

// import { Container } from './styles';

function Dashboard() {
  return (
    <>
      <TitleBar back title="Dashboard" />
    </>
  );
}

export default Dashboard;
