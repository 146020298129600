import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MdInfo } from 'react-icons/md';

import { IconPopupWrapper, Icon, Container } from './styles';

const InfoRoot = ({ topIcon, leftIcon, rightIcon, bottomIcon, children }) => {
  const [openBox, setOpenBox] = useState(false);

  return (
    <IconPopupWrapper
      topIcon={topIcon}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      bottomIcon={bottomIcon}
    >
      <Icon
        onMouseOver={() => setOpenBox(true)}
        onMouseLeave={() => setOpenBox(false)}
      >
        <MdInfo size={16} />
      </Icon>
      {openBox && (
        <Container>
          <Container.Wrapper>{children}</Container.Wrapper>
        </Container>
      )}
    </IconPopupWrapper>
  );
};

InfoRoot.propTypes = {
  topIcon: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  bottomIcon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func])
    .isRequired,
};

InfoRoot.defaultProps = {
  topIcon: null,
  leftIcon: null,
  rightIcon: null,
  bottomIcon: null,
};

export { InfoRoot };
