import React from 'react';
import PropTypes from 'prop-types';

import { TitleOrder } from '..';

function TableHeader({ withPadding, titles }) {
  return (
    <thead>
      <tr>
        {titles &&
          titles.map((header, index) =>
            header.title ? (
              <TitleOrder key={index} withPadding={withPadding}>
                {header.title}
              </TitleOrder>
            ) : (
              <th key={index}> </th>
            )
          )}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  withPadding: PropTypes.bool,
  titles: PropTypes.arrayOf(
    PropTypes.shape({
      fieldname: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

TableHeader.defaultProps = {
  withPadding: false,
};

export default TableHeader;
