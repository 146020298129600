import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import TitleBar from '../../../../TitleBar';

import { Form, Row } from '../../../../Form';
import Input from '../../../../Form/Input';

import { Container } from './styles';

function ItemEdit({ goStepBack, id, item, fnEdit }) {
  const formRef = useRef();

  const handleSubmit = useCallback(() => {
    try {
      fnEdit(id, item);

      toast.success('Item editado com sucesso');

      goStepBack();
    } catch (err) {
      toast.error(`Ocorreu um erro. ${err}`);
    }
  }, [fnEdit, goStepBack, id, item]);

  return (
    <Container>
      <TitleBar title="Editar Item">
        <button id="btn-back" type="button" onClick={goStepBack}>
          Voltar
        </button>
        <button id="btn-submit" type="submit">
          Gravar
        </button>
      </TitleBar>
      <Form initialData={item} ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Input id="item" name="item" label="Item" />
          <Input id="partnumber" name="partnumber" label="Código" />
        </Row>
        <Row>
          <Input id="describe" name="describe" label="Descrição" />
          <Input id="quantity" name="quantity" label="Quantidade" />
        </Row>
        <Input id="note" name="note" label="Nota" />
      </Form>
    </Container>
  );
}

ItemEdit.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  goStepBack: PropTypes.func.isRequired,
  fnEdit: PropTypes.func.isRequired,
};

export default ItemEdit;
