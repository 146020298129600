import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/Input/TextArea';

/** validação dos campos */
const schema = Yup.object().shape({
  titulo: Yup.string().required('O campo "Título" deve ser preenchido'),
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  dataProg: Yup.date(),
  dataExp: Yup.date(),
});

function NotificacaoCriar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { titulo, descricao, dataProg, dataExp } = data;

      await api.post('/notificacao', {
        titulo,
        descricao,
        dataProg,
        dataExp,
      });

      toast.success('Notificação cadastrada com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Nova Notificação" />
      <FormWrapper>
        <Form
          id="formEmpresaNotificacao"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="titulo" name="titulo" label="Título" />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="descricao"
                name="descricao"
                label="Descrição"
                rows="5"
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="dataProg"
                name="dataProg"
                label="Data de Programação"
                type="date"
                width={225}
              />
              <Input
                id="dataExp"
                name="dataExp"
                label="Data de Expiração"
                type="date"
                width={225}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresaNotificacao">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { NotificacaoCriar };
