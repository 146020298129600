import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';
import history from '../../services/history';

import { AppError } from '../../errors/AppError';
import { opNotificacaoTipo } from '../../lib/inputOption';

import TitleBar from '../../components/TitleBar';
import { Table, RowMaster } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import ActionMenu from '../../components/ActionMenu';
import Pagination from '../../components/EditorAI/Pagination';
import { opTipoAcesso } from '../../lib/const';
import { useSelector } from 'react-redux';

function Notificacao() {
  const location = useLocation();

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const [notificacoes, setNotificacoes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNotificacoes = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get('/notificacao', {
        params: configFiltro,
      })
      .then((response) => {
        setNotificacoes(
          response.data.notificacao.map((n) => ({
            ...n,
            tipo: opNotificacaoTipo[n.tipo].label,

          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleCriar = useCallback(() => {
    history.push(`/notificacao/criar`, { background: location });
  }, [location]);

  const handleEditar = useCallback(
    (idNotificacao) => {
      history.push(`/notificacao/editar`, {
        background: location,
        id: idNotificacao,
      });
    },
    [location]
  );

  const handleConferencia = useCallback(async (valor) => {
    const parseValor = JSON.parse(valor)

    const { idCatalogo } = parseValor

    history.push('/catalogo/merge', {
      idCatalogo: Number(idCatalogo)
    })
  }, [])

  const handleRemover = useCallback(
    async (idNotificacao) => {
      if (window.confirm('Deseja realmente excluir a notificação?')) {
        try {
          await api.delete(`/notificacao/${idNotificacao}`);

          carregaNotificacoes();

          toast.success('Notificação excluída com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaNotificacoes]
  );

  const handleVisualizar = useCallback(
    (e, idNotificacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idNotificacao);
      }
    },
    [handleEditar]
  );

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNotificacoes();
  }, [location, currentPage, carregaNotificacoes]);

  return (
    <>
      <TitleBar back title="Notificações" isSearchable onNew={handleCriar} />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'id', title: 'Notificação' },
            { fieldname: 'tipo', title: 'Tipo' },
            { fieldname: 'titulo', title: 'Título' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => { }}
        />
        <tbody>
          {notificacoes.map((notificacao) => (
            <RowMaster
              key={notificacao.idNotificacao}
              onClick={(e) => handleVisualizar(e, notificacao.idNotificacao)}
            >
              <td>{notificacao.idNotificacao}</td>
              <td>{notificacao.tipo}</td>
              <td>{notificacao.titulo}</td>
              <td>{notificacao.descricao}</td>
              <td>
                <ActionMenu
                  data={notificacao}
                  onLineRemove={() => handleRemover(notificacao.idNotificacao)}
                  onEdit={() => handleEditar(notificacao.idNotificacao)}
                  onRemove={() => handleRemover(notificacao.idNotificacao)}
                  onConferencia={
                    (tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && notificacao.tipo === "Conferência")
                      ? () => handleConferencia(notificacao.valor)
                      : null
                  }
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { Notificacao };
