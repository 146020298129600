import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import { MdPrint, MdDelete, MdComputer, MdSmartphone } from 'react-icons/md';
import { FaFileCsv } from 'react-icons/fa';

import { PDF, opPdfExport } from '../../../../lib/pdf-core';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import { opSolicitacaoSituacao } from '../../../../lib/const';
import { toCSV } from '../../../../lib/csv-core';
import { opSolicitacao } from '../../../../lib/inputOption';

import TitleBar from '../../../../components/TitleBar';
import { Form } from '../../../../components/Form';
import DebounceInput from '../../../../components/Form/Input/Debounce';
import Select from '../../../../components/Form/Input/Select';

import { Wrapper, Table, Action } from './styles';

function MobileUsuarioSolicitacao() {
  const location = useLocation();
  const { extCodiDiv2 = null, extNumeOS = null, extUsuario = null } =
    location.state || {};

  const formRef = useRef();

  const { app } = useSelector((state) => state.auth || {});
  const {
    profile: { adm },
  } = useSelector((state) => state.user || {});

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [formExtNumeOS, setFormExtNumeOS] = useState(null);
  const [formDataDesa, setFormDataDesa] = useState(0);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const loadSolicitacao = useCallback(
    (pageMore = 1) => {
      if (pageMore === 1) {
        setSolicitacoes([]);
      }

      api
        .get('solicitacao', {
          params: {
            emp: extCodiDiv2,
            os: extNumeOS || formExtNumeOS,
            sto: formDataDesa,
            page: pageMore,
          },
        })
        .then((response) => {
          setSolicitacoes((state) => [
            ...state,
            ...response.data.solicitacao.map(
              ({ instInc, dataDesa, itemSolicitacao, ...solicitacao }) => ({
                ...solicitacao,
                dataDesa,
                instInc: format(parseISO(instInc), 'dd/MM/yyyy HH:mm'),
                dataDesaFormatado:
                  dataDesa &&
                  format(parseISO(dataDesa), "dd/MM/yyyy 'às' HH:mm"),
                itemSolicitacao: itemSolicitacao.map(
                  ({ dataDesa: dataDesaItem, ...item }) => ({
                    dataDesa: dataDesaItem,
                    dataDesaFormatado:
                      dataDesaItem &&
                      format(parseISO(dataDesaItem), "dd/MM/yyyy 'às' HH:mm"),
                    ...item,
                  })
                ),
              })
            ),
          ]);

          setCurrentPage(response.data.pagAtual);
          setTotalPage(response.data.total);
        });
    },
    [extCodiDiv2, extNumeOS, formExtNumeOS, formDataDesa]
  );

  useEffect(() => {
    loadSolicitacao();
  }, [extCodiDiv2, extNumeOS, formExtNumeOS, formDataDesa, loadSolicitacao]);

  const handleExportar = useCallback(
    (id) => {
      const filtroSolicitacao = id
        ? solicitacoes.filter((item) => item.idSolicitacao === id)
        : solicitacoes;

      const fields = [
        'Solicitação',
        'Solicitação Relizada',
        'Solicitado Por',
        'Solicitação Cancelada',
        'Cancelado Por',

        // 'Divisão 2',
        'Número OS',
        'Outras Informações',
        'Item OS',
        'Item Cancelado',
        'Cancelado Por',

        'IMEI',
        'Coletor',

        'Código Material (ERP)',
        'Descrição (ERP)',

        'Partnumber',
        'Descrição (BR)',
        'Descrição (EN)',

        'Quantidade',
        'Unidade',
      ];

      const data = filtroSolicitacao.map((solicitacao) => {
        const {
          idSolicitacao,
          instInc,
          dataDesaFormatado,
          mobileIMEI,
          mobileColetor,
          // extCodiDiv2: codiDiv2,
          extNumeOS: numeOS,
          extObservacao,
          solicitante: { nome },
          cancelamento,
          itemSolicitacao,
        } = solicitacao;

        const { nome: nomeDesa = '' } = cancelamento || {};

        const linha = itemSolicitacao.map((item) => {
          const {
            dataDesaFormatado: dataDesaItem,
            cancelamento: cancelamentoItem,
            materialERP,
            materialFabricante: { partnumber, descricaoBR, descricaoEN },
            quantidade,
            unidade,
            extItemOS: itemOS,
          } = item;

          const { nome: nomeDesaItem = '' } = cancelamentoItem || {};

          const { codimate = null, descricao = null } = materialERP || {};

          return [
            idSolicitacao,
            instInc,
            dataDesaFormatado,
            nomeDesa,
            nome,
            // codiDiv2,
            numeOS,
            extObservacao,
            itemOS,
            dataDesaItem,
            nomeDesaItem,
            mobileIMEI,
            mobileColetor,
            codimate,
            descricao,
            partnumber,
            descricaoBR,
            descricaoEN,
            quantidade,
            unidade,
          ];
        });

        return linha;
      });

      const csv = toCSV(fields, data.flat());

      try {
        /** cria um link para o processo de download */
        const link = document.createElement('a');
        const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

        link.href = file;
        link.download = `catalogo-eletronico-retirada.csv`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } catch (err) {
        AppError(err);
      }
    },

    [solicitacoes]
  );

  const handlePDF = useCallback(async (id) => {
    if (id) {
      /** cria PDF de retirada específica */
      PDF.criarExportacao(opPdfExport.RETIRADA_ITEM, {
        idSolicitacao: id,
      });

      return;
    }

    /** cria PDF com a lista de retiradas */
    PDF.criarExportacao(opPdfExport.RETIRADA_LISTA, {});
  }, []);

  const handleCarregar = useCallback(() => {
    loadSolicitacao(currentPage + 1);
  }, [currentPage, loadSolicitacao]);

  const handleCancelar = useCallback(
    async (id) => {
      /** confirmação */
      if (window.confirm('Deseja realmente cancelar a solicitação?')) {
        try {
          /** exclui o registro */
          await api.delete(`solicitacao/${id}`);

          loadSolicitacao();

          /** mensagem de sucesso */
          toast.success('Solicitação cancelada com sucesso!');
        } catch (err) {
          /** mensagem de erro */
          AppError(err);
        }
      }
    },
    [loadSolicitacao]
  );

  const handleCancelarItem = useCallback(
    async (id, idItem) => {
      /** confirmação */
      if (window.confirm('Deseja realmente cancelar o item da solicitação?')) {
        try {
          /** exclui o registro */
          await api.delete(`solicitacao/${id}/item/${idItem}`);

          loadSolicitacao();

          /** mensagem de sucesso */
          toast.success('Item cancelado com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [loadSolicitacao]
  );

  const handleChangeDataDesa = useCallback((data) => {
    const dataDesaFormatado = data ? data.map((item) => item.value) : [];
    setFormDataDesa(() => {
      if (!dataDesaFormatado || dataDesaFormatado.length > 1)
        return opSolicitacaoSituacao.TODOS;

      if (dataDesaFormatado.includes(1)) return opSolicitacaoSituacao.CANCELADO;

      return opSolicitacaoSituacao.SOLICITADO;
    });
  }, []);

  return (
    <>
      <TitleBar back={!app} title="Minhas retiradas" width={950} />
      <Wrapper>
        <Form
          ref={formRef}
          initialData={{
            extNumeOS,
          }}
          autoComplete="off"
        >
          <Form.Row>
            <Form.Row>
              <DebounceInput
                id="extNumeOS"
                name="extNumeOS"
                placeholder="Digite para pesquisar..."
                onChange={({ target }) => setFormExtNumeOS(target.value)}
                type="number"
                width={200}
                disabled={app}
              />
              <Select
                id="dataDesa"
                name="dataDesa"
                isSearchable
                isMulti
                placeholder="Selecione para pesquisar a situação da retirada..."
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={opSolicitacao[0]}
                options={opSolicitacao}
                onChange={handleChangeDataDesa}
                width={400}
              />
            </Form.Row>
            <Action>
              <button
                type="button"
                title="Imprimir"
                // onClick={() => handleImprimir(null)}
                onClick={() => handlePDF(null)}
              >
                <MdPrint size={20} />
              </button>
              <button
                type="button"
                title="Exportar CSV"
                onClick={() => handleExportar(null)}
              >
                <FaFileCsv size={20} />
              </button>
            </Action>
          </Form.Row>
        </Form>

        {/* <RequestWrapper>
          {solicitacoes.map((solicitacao) => (
            <RequestRow key={solicitacao.idSolicitacao}>
              <RequestRow.Item>
                {solicitacao.mobileIMEI ? (
                  <MdSmartphone size={20} title="Mobilidade" />
                ) : (
                  <MdComputer size={20} title="Computador" />
                )}
              </RequestRow.Item>
              <RequestRow.Item>
                <strong>Solicitação</strong>
                <span>{`#${solicitacao.idSolicitacao}`}</span>
              </RequestRow.Item>
            </RequestRow>
          ))}
        </RequestWrapper> */}

        {solicitacoes.length ? (
          <Table>
            <tbody>
              {solicitacoes.map((solicitacao) => (
                <Table.Container
                  key={solicitacao.idSolicitacao}
                  cancelled={!!solicitacao.dataDesa}
                >
                  <td>
                    <Table.Wrapper>
                      <Table.Header>
                        <Table.Col>
                          <Table.Row withFlex>
                            <Table.Icon>
                              {solicitacao.mobileIMEI ? (
                                <MdSmartphone size={20} title="Mobilidade" />
                              ) : (
                                <MdComputer size={20} title="Computador" />
                              )}
                            </Table.Icon>
                            <Table.Col>
                              <strong>Solicitação</strong>
                              <span>{`#${solicitacao.idSolicitacao}`}</span>
                            </Table.Col>
                            <Table.Col>
                              <strong>Solicitação realizada</strong>
                              <span>{solicitacao.instInc}</span>
                            </Table.Col>
                            <Table.Col>
                              <strong>Solicitado por</strong>
                              <span>{solicitacao.solicitante.nome}</span>
                            </Table.Col>
                            {solicitacao.mobileIMEI && (
                              <Table.Col>
                                <strong>Coletor</strong>
                                <span>{solicitacao.mobileColetor}</span>
                              </Table.Col>
                            )}
                            <Table.Col>
                              <strong>Qtde. Itens</strong>
                              <span>{solicitacao.itemSolicitacao.length}</span>
                            </Table.Col>
                            <Table.Header.BtnPrint
                              type="button"
                              title="Imprimir"
                              // onClick={() =>
                              //   handleImprimir(solicitacao.idSolicitacao)
                              // }
                              onClick={() =>
                                handlePDF(solicitacao.idSolicitacao)
                              }
                            >
                              <MdPrint size={25} />
                            </Table.Header.BtnPrint>
                            {(adm !== 0 ||
                              Number(extUsuario) === solicitacao.extUsuario) &&
                              !solicitacao.dataDesa && (
                                <Table.Header.BtnCancel
                                  type="button"
                                  title="Cancelar"
                                  onClick={() =>
                                    handleCancelar(solicitacao.idSolicitacao)
                                  }
                                >
                                  <MdDelete size={25} />
                                </Table.Header.BtnCancel>
                              )}
                          </Table.Row>
                          <Table.Row withFlex withCenter>
                            {solicitacao.dataDesa && (
                              <Table.Row.Cancel>
                                {`Solicitação ${solicitacao.idSolicitacao} cancelada pelo usuário ${solicitacao.cancelamento.nome} em ${solicitacao.dataDesaFormatado}`}
                              </Table.Row.Cancel>
                            )}
                          </Table.Row>
                        </Table.Col>
                      </Table.Header>
                      <Table.Content>
                        <tbody>
                          {solicitacao.itemSolicitacao.map((item) => (
                            <tr key={item.idItemSolicitacao}>
                              <td>
                                <Table.Row withFlex>
                                  <Table.Row withFlex>
                                    <Table.Col>
                                      <strong>
                                        {item.materialFabricante.partnumber}
                                      </strong>
                                    </Table.Col>
                                    <Table.Col>
                                      <span>
                                        {item.materialFabricante.descricaoBR}
                                      </span>
                                      <span>
                                        {`Fabricante: ${item.materialFabricante.fabricante.descricao}`}
                                      </span>
                                      <span>
                                        {`Quantidade: ${item.quantidade}`}
                                      </span>
                                    </Table.Col>
                                  </Table.Row>
                                  <Table.Row
                                    withFlex
                                    withBackground={!!item.materialERP}
                                  >
                                    {item.materialERP && (
                                      <>
                                        <Table.Col>
                                          <strong>
                                            {`Código ERP: ${item.materialERP.codimate}`}
                                          </strong>
                                        </Table.Col>
                                        <Table.Col>
                                          <span>
                                            {item.materialERP.descricao}
                                          </span>

                                          {!!item.materialERP.unidade && (
                                            <span>
                                              {`Unidade: ${
                                                item.materialERP.unidade || ''
                                              }`}
                                            </span>
                                          )}

                                          {!!item.extItemOS && (
                                            <span>{`Item O.S.: ${item.extItemOS}`}</span>
                                          )}
                                        </Table.Col>
                                      </>
                                    )}
                                  </Table.Row>
                                  <Table.Row>
                                    {(adm !== 0 ||
                                      Number(extUsuario) ===
                                        solicitacao.extUsuario) &&
                                      !item.dataDesa && (
                                        <Table.Row.BtnCancel
                                          type="button"
                                          title="Cancelar"
                                          onClick={() =>
                                            handleCancelarItem(
                                              solicitacao.idSolicitacao,
                                              item.idItemSolicitacao
                                            )
                                          }
                                        >
                                          <MdDelete size={20} />
                                        </Table.Row.BtnCancel>
                                      )}
                                  </Table.Row>
                                </Table.Row>

                                {!solicitacao.dataDesa && item.dataDesa && (
                                  <Table.Row withFlex withCenter>
                                    <Table.Row.Cancel>
                                      {`Item ${item.materialFabricante.partnumber} cancelada pelo usuário ${item.cancelamento.nome} em ${item.dataDesaFormatado}`}
                                    </Table.Row.Cancel>
                                  </Table.Row>
                                )}
                              </td>
                            </tr>
                          ))}

                          {!!solicitacao.extNumeOS && (
                            <tr>
                              <td>
                                <Table.Row withFlex>
                                  <Table.Col>
                                    <span>Outras Informações:</span>
                                    <span>{`Número O.S.: ${solicitacao.extNumeOS}`}</span>
                                    <span>
                                      {solicitacao.extObservacao || ''}
                                    </span>
                                  </Table.Col>
                                </Table.Row>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table.Content>
                    </Table.Wrapper>
                  </td>
                </Table.Container>
              ))}
            </tbody>
            {currentPage < totalPage && (
              <tfoot>
                <tr>
                  <td>
                    <button type="button" onClick={handleCarregar}>
                      Carregar mais...
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        ) : (
          <Wrapper.NotFound>Nenhum item encontrado</Wrapper.NotFound>
        )}
      </Wrapper>
    </>
  );
}

export { MobileUsuarioSolicitacao };
