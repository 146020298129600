import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

const schema = Yup.object().shape({
  valor: Yup.string().required('O campo "valor" é obrigatório'),
});

function EmpresaParametroEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, id } = location.state;

  const [parametro, setParametro] = useState([]);

  useEffect(() => {
    api.get(`empresa/${idEmpresa}/glob-param/${id}`).then((response) => {
      setParametro(response.data);
    });
  }, [idEmpresa, id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { valor } = data;

        /** submete os dados */
        await api.put(`empresa/${idEmpresa}/glob-param/${id}`, {
          valor,
        });

        toast.success('Parâmetro atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Parâmetro" />
      <FormWrapper>
        <Form
          id="formParametro"
          ref={formRef}
          initialData={parametro}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="chave"
                name="chave"
                label="Chave"
                type="text"
                width={300}
                disabled
              />
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                type="text"
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Input id="valor" name="valor" label="Valor" type="text" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formParametro">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaParametroEditar };
