/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  MdShoppingCart,
  MdClose,
  MdChevronLeft,
  MdChevronRight,
  // MdWhatsapp,
} from 'react-icons/md';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { AppError } from '../../../errors/AppError';

import { Form, Row, Column } from '../../Form';
import SelectInput from '../../Form/Input/Select';
import Input from '../../Form/Input';

import { adicionaCarrinhoRequest } from '../../../store/modules/cart/actions';

import { Content, WrapperPopup, WrapperItem } from './styles';

function MobileItemCart({ item, onClose }) {
  const formRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    const { quantidade } = item || { quantidade: 0 };

    /** insere a quantidade indicada do item */
    formRef.current.setFieldValue('quantidade', quantidade);
  }, [item]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        /** validação do preenchimento do campo código ERP  */
        if (item.materialEmpresa.length > 0) {
          const schema = Yup.object().shape({
            materialEmpresa: Yup.string().required('Selecione um material'),
          });

          await schema.validate(data, { abortEarly: false });
        }

        /** adiciona no carrinho */
        const {
          idCatalogo,
          idPagina,
          idItem,
          idMaterial,
          imagemCatalogo,
        } = item;
        const { quantidade, materialEmpresa } = data;
        dispatch(
          adicionaCarrinhoRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            Number(quantidade),
            materialEmpresa,
            imagemCatalogo
          )
        );

        /** executa método para fechar o formulário */
        onClose(data);
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [item, dispatch, onClose]
  );

  const handleAdicionar = useCallback(() => {
    /** pega informações do campo */
    const quantidade = Number(formRef.current.getFieldValue('quantidade'));

    formRef.current.setFieldValue('quantidade', quantidade + 1);
  }, []);

  const handleRemover = useCallback(() => {
    /** pega informações do campo */
    const quantidade = Number(formRef.current.getFieldValue('quantidade'));

    if (quantidade > 1) {
      formRef.current.setFieldValue('quantidade', quantidade - 1);
    }
  }, []);

  // const handleWhatsApp = useCallback(async () => {
  //   const {
  //     idCatalogo,
  //     idPagina,
  //     idItem,
  //     idMaterial,
  //     fabricante,
  //     descricao,
  //   } = item;
  //   const quantidade = Number(formRef.current.getFieldValue('quantidade'));
  //   const materialEmpresa = String(
  //     formRef.current.getFieldValue('materialEmpresa')
  //   );
  //   // const { quantidade, materialEmpresa } = data;

  //   // console.log(data);
  //   console.log(item);
  //   console.log(materialEmpresa);

  //   console.log('whatsapp');
  //   console.log('https://api.whatsapp.com/send?phone=NUMERO&text=MENSAGEM');

  //   // Pegar o numero do telfone do usuario logado
  //   const numeroTelefone = '5519989479385';

  //   const fmtData = `
  //     *Catálogo:* ${idCatalogo},
  //     *Pagina:* ${idPagina},
  //     *Fabricante:* ${fabricante.descricao},
  //     *Item:* ${idItem},
  //     *Material:* ${idMaterial},
  //     *Descrição Peça:* ${descricao},
  //     *Quantidade:* ${quantidade}
  //   `;

  //   // const fmtData2 = `
  //   //   *Catálogo:* ${firstItem.catalogo},
  //   //   *Modelo:* ${firstItem.modelo},
  //   //   *Versão:* ${firstItem.versao},
  //   //   *Fabricante:* ${firstItem.fabricante}
  //   // `;

  //   console.log(fmtData);

  //   const filenameTeste = 'baixados.csv';

  //   const testeLinkURL = `${process.env.REACT_APP_BASE_URL}download/csv/${filenameTeste}`;
  //   const testeLink =
  //     'https://api-cdi.assisteweb.com.br/download/csv/catalogo-eletronico-carrinho.csv';

  //   const mensagem = encodeURIComponent(
  //     `*Envio via CDI* da página do catálogo: \n ${fmtData} \n *Link de download:* ${testeLink}`
  //   );

  //   // Chamar a criação do .csv e enviar o link
  //   // const linkArquivo = await handleExportar(true);
  //   // console.log(linkArquivo);

  //   // const linkArquivo = 'https://link-para-o-arquivo.xlsx';

  //   // const urlWhatsApp = `https://api.whatsapp.com/send?&text=${mensagem}&file=${linkArquivo.href}`;
  //   const urlWhatsApp = `https://api.whatsapp.com/send?&text=${mensagem}&app_absent=0`;

  //   setTimeout(() => window.open(urlWhatsApp, '_blank'), 5000);

  //   handleConfirmar({ quantidade, materialEmpresa });

  //   // window.open(urlWhatsApp, '_blank');
  // }, []);

  return (
    <WrapperPopup>
      <WrapperItem>
        <Content>
          <Content.BtnClose type="button" onClick={() => onClose()}>
            <MdClose size={20} />
          </Content.BtnClose>

          <Content.Title>
            <strong>{item.descricao}</strong>
            <span>{item.partnumber}</span>

            {item.especTecnica && (
              <Content.Title.Espec>{item.especTecnica}</Content.Title.Espec>
            )}
          </Content.Title>
          <Content.Form>
            {item && (
              <Form id="formCart" ref={formRef} onSubmit={handleConfirmar}>
                <Column borderTop>
                  {item.materialEmpresa.length > 0 && (
                    <SelectInput
                      id="materialEmpresa"
                      name="materialEmpresa"
                      label="Código ERP"
                      isClearable
                      options={item.materialEmpresa.map((material) => ({
                        value: material.codimate,
                        label: `${material.codimate} - ${material.descricao}`,
                        color:
                          material.paralelo === '0' ? '#2c405a' : '#de3b3b',
                        fontStyle:
                          material.paralelo === '0' ? 'normal' : 'italic',
                      }))}
                      styles={{
                        option: (styles, { data }) => ({
                          ...styles,
                          color: data.color,
                          fontStyle: data.fontStyle,
                        }),
                      }}
                    />
                  )}
                  <Content.Form.Error>
                    {item.materialEmpresa.length > 0
                      ? 'Atenção: Materiais não originais estão destacados na listagem'
                      : 'Nenhum Código ERP relacionado com esse material para integração'}
                  </Content.Form.Error>
                </Column>

                <Row>
                  <Content.Form.Quantity>
                    <label htmlFor="quantidade">Quantidade</label>
                    <div>
                      <button type="button" onClick={handleRemover}>
                        <MdChevronLeft size={25} />
                      </button>
                      <Input
                        id="quantidade"
                        name="quantidade"
                        flexDirection="row"
                        // disabled
                        type="number"
                        min="1"
                        max="9999999.9999"
                        width={80}
                      />
                      <button type="button" onClick={handleAdicionar}>
                        <MdChevronRight size={25} />
                      </button>
                    </div>
                  </Content.Form.Quantity>
                </Row>
              </Form>
            )}

            <Content.BtnAddCart type="submit" form="formCart">
              <MdShoppingCart size={20} /> Adicionar ao carrinho
            </Content.BtnAddCart>
            {/* <Content.BtnWhatsApp type="button" onClick={handleWhatsApp}>
              <MdWhatsapp size={25} /> Enviar WhatsApp
            </Content.BtnWhatsApp> */}
          </Content.Form>
        </Content>
      </WrapperItem>
    </WrapperPopup>
  );
}

MobileItemCart.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export { MobileItemCart };
