import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Table = styled.table`
  /* flex: 1; */
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;

  ${(props) =>
    props.withCollapse &&
    css`
      border-collapse: collapse;
      border-spacing: 0 10px;
    `}

  & > thead {
    & > th {
      font-size: 12px;
      color: #79869e;
      text-align: left;
      padding: 0 25px 0;
    }
  }
`;

Table.Wrapper = styled.div`
  padding: 10px 5px;
  margin: 5px 0;

  ${(props) =>
    props.withBorder &&
    css`
      border: 1px solid #ccc;
      border-radius: 10px;
    `}

  ${(props) =>
    props.minHeight &&
    css`
      height: calc(100% - 5px);
      /* max-height: ${props.minHeight}px; */
      overflow-y: auto;
    `}
`;

Table.List = styled(Table)`
  border-spacing: 0;

  & > tbody {
    & > tr {
      & > td {
        font-size: 18px;
        color: #000;
        text-align: left;
        padding: 0 25px 0;
      }

      :hover {
        background: ${darken(0.03, '#fff')};
      }
    }
  }
`;

Table.Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  > button {
    border: 0;
    background: none;
  }
`;

Table.Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

Table.BtnRemove = styled.button`
  color: #cbd0d8;
  width: 20px;
  height: 20px;
  transition: color 1s;

  :hover {
    color: ${darken(0.05, '#de3b3b')};
  }
`;

Table.BtnEdit = styled.button`
  color: #ccc;
  transition: color 1s;

  :hover {
    color: ${darken(0.05, '#ccc')};
  }
`;

Table.Row = styled.tr``;

Table.Row.Kind = styled.span`
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 5px 10px;

  ${(props) =>
    props.isAdded &&
    css`
      color: #538061;
      border: 2px solid #538061;
    `};

  ${(props) =>
    props.isRemoved &&
    css`
      color: #de3b3b;
      border: 2px solid #de3b3b;
    `}

  ${(props) =>
    props.isPending &&
    css`
      color: #e6de2a;
      border: 2px solid #e6de2a;
    `}
`;

Table.RowList = styled.tr`
  background: #fff;
  color: #79869e;
  font-size: 14px;
  cursor: default;

  > td {
    ${(props) =>
      props.withPadding &&
      css`
        padding: 5px 10px 2px;
      `}
  }
`;

export const RowDefault = styled.tr`
  background: #fff;
  cursor: default;

  &:hover {
    background: #f2f5fc;
  }

  & > td {
    padding: 0 25px;
    border: none;
  }
`;

export const RowMaster = styled.tr`
  background: #fff;
  cursor: pointer;
  /* height: 62px; */
  height: ${(props) => props.thin ? '25px' : '62px'};

  ${(props) =>
    props.noCursoPointer &&
    css`
      cursor: auto;
    `}

  &:hover {
    background: ${darken(0.03, '#fff')};
  }

  & > td {
    /* padding: 15px 20px 16px; */
    padding: ${(props) => props.thin ? '7px 10px 6px' : '15px 20px 16px'};
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    &:first-child {
      border-left: 1px solid #ccc;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-right: 1px solid #ccc;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

export const Expand = styled.td`
  svg {
    transform: rotate(0deg);
    transition: all 0.2s ease-out;
  }

  ${(props) =>
    props.rotate === '1' &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `};
`;

export const RowDetail = styled.tr`
  background: #fff;

  & > td {
    padding: 20px 25px 16px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    &:first-child {
      border-left: 1px solid #ccc;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-right: 1px solid #ccc;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

export const TitleOrder = styled.th`
  font-size: 12px;
  color: #79869e;
  text-align: left;

  ${(props) =>
    props.withPadding &&
    css`
      padding: 0 25px 0;
    `}

  button {
    border: none;
    background: none;
    color: #79869e;
    margin-left: 5px;
  }
`;

export const NotFound = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  color: #cbd0d8;
  font-style: italic;
  height: 100%;
`;
