import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';

import { MdEdit, MdList } from 'react-icons/md';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';

import Pagination from '../../../../components/EditorAI/Pagination';
import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import InputViewer from '../../../../components/Form/Input/Viewer';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';

import ActionMenu from '../../../../components/ActionMenu';

import { Container, Wrapper, Footer } from './styles';

function EmpresaNavegacaoClassificacaoDetalhe() {
  const location = useLocation();
  const { idEmpresa, id } = location.state;

  const formRef = useRef(null);

  const [navegacaoClassificacao, setNavegacaoClassificacao] = useState(null);
  const [navegacoes, setNavegacoes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  /** pesquisa */
  const [pesquisa, setPesquisa] = useState(null);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/navegacao-classificacao/${id}`)
      .then((response) => {
        const { padraoFabricante, padraoModelo } = response.data;

        setNavegacaoClassificacao({
          ...response.data,
          fmtPadraoFabricante: Number(padraoFabricante) === 1 ? 'Sim' : 'Não',
          fmtPadraoModelo: Number(padraoModelo) === 1 ? 'Sim' : 'Não',
        });
      });
  }, [location, idEmpresa, id]);

  useEffect(() => {
    api
      .get(`empresa/${idEmpresa}/navegacao-classificacao/${id}/navegacao`)
      .then((response) => {
        setNavegacoes(response.data.navegacao);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (paginationRef.current && currentPage > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa, id, currentPage, pesquisa]);

  const handleEditar = useCallback(() => {
    history.push('/empresa/navegacao-classificacao/editar', {
      background: location,
      idEmpresa,
      id,
    });
  }, [location, idEmpresa, id]);

  const handleNavegacao = useCallback(() => {
    history.push('/empresa/navegacao-classificacao/navegacao', {
      idEmpresa,
      idClassificacao: id,
    });
  }, [idEmpresa, id]);

  const handleNavegacaoCriar = useCallback(() => {
    const { padraoFabricante, padraoModelo } = navegacaoClassificacao;
    /** verifica se é menu padrão */
    if (Number(padraoFabricante) === 0 && Number(padraoModelo) === 0) {
      history.push('/empresa/navegacao-classificacao/navegacao/criar', {
        background: location,
        idEmpresa,
        idClassificacao: id,
      });
    }
  }, [location, navegacaoClassificacao, idEmpresa, id]);

  const handleNavegacaoEditar = useCallback(
    (idNavegacao) => {
      history.push('/empresa/navegacao-classificacao/navegacao/editar', {
        background: location,
        idEmpresa,
        idClassificacao: id,
        id: idNavegacao,
      });
    },
    [location, idEmpresa, id]
  );

  const handleNavegacaoRemover = useCallback(
    async (idNavegacao) => {
      if (!window.confirm('Deseja realmente excluir o item?')) return;

      try {
        await api.delete(
          `empresa/${idEmpresa}/navegacao-classificacao/${id}/navegacao/${idNavegacao}`
        );

        const filtered = navegacoes.filter(
          (item) => item.idNavegacao !== idNavegacao
        );
        if (filtered) {
          setNavegacoes(filtered);
        }
      } catch (err) {
        AppError(err);
      }
    },

    [idEmpresa, id, navegacoes]
  );

  const withItem = useMemo(() => {
    const { padraoFabricante, padraoModelo } = navegacaoClassificacao || {};
    return Number(padraoFabricante) === 0 && Number(padraoModelo) === 0;
  }, [navegacaoClassificacao]);

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      <Wrapper>
        <Form initialData={navegacaoClassificacao}>
          <Form.Row>
            <InputViewer
              id="idClassificacao"
              name="idClassificacao"
              label="Menu"
            />
            <InputViewer id="descricao" name="descricao" label="Descrição" />
          </Form.Row>
          <Form.Row>
            <InputViewer
              id="fmtPadraoFabricante"
              name="fmtPadraoFabricante"
              label="Padrão Fabricante"
            />
            <InputViewer
              id="fmtPadraoModelo"
              name="fmtPadraoModelo"
              label="Padrão Modelo"
            />
          </Form.Row>
          <Form.Separate height={80} />
          <Form.Footer withStart>
            <Form.Footer.Link type="button" onClick={handleEditar}>
              <MdEdit size={18} />
              Editar
            </Form.Footer.Link>
            {withItem && (
              <Form.Footer.Link type="button" onClick={handleNavegacao}>
                <MdList size={18} />
                Item Menu
              </Form.Footer.Link>
            )}
          </Form.Footer>
        </Form>
      </Wrapper>

      {withItem && (
        <>
          <h2>Itens do menu</h2>
          <Form
            ref={formRef}
            onSubmit={(data) => setPesquisa(data.search)}
            autoComplete="off"
          >
            <Input
              id="search"
              name="search"
              type="text"
              placeholder="Digite para pesquisar..."
              width={300}
              onChange={() => formRef.current.submitForm()}
            />
          </Form>

          <Table>
            <TableHeader
              withPadding
              titles={[
                { fieldname: 'codigo', title: 'Código' },
                { fieldname: 'descricao', title: 'Descrição' },
                { fieldname: null, title: null },
              ]}
              cbSort={() => {}}
            />
            <tbody>
              {navegacoes.map((navegacao, index) => (
                <RowMaster key={index}>
                  <td>{navegacao.codigo}</td>
                  <td>{navegacao.descricao}</td>
                  <td>
                    <ActionMenu
                      data={navegacao}
                      onLineEdit={() =>
                        handleNavegacaoEditar(navegacao.idNavegacao)
                      }
                      onLineRemove={() =>
                        handleNavegacaoRemover(navegacao.idNavegacao)
                      }
                      onEdit={() =>
                        handleNavegacaoEditar(navegacao.idNavegacao)
                      }
                      onRemove={() =>
                        handleNavegacaoRemover(navegacao.idNavegacao)
                      }
                    />
                  </td>
                </RowMaster>
              ))}
            </tbody>
          </Table>

          <Pagination
            ref={paginationRef}
            pageCount={totalPage}
            initialData={currentPage}
            onPageChange={(page) => {
              setCurrentPage(Number(page));
            }}
          />

          <Footer.Action>
            <button type="button" id="btn-new" onClick={handleNavegacaoCriar}>
              Adicionar
            </button>
          </Footer.Action>
        </>
      )}
    </Container>
  );
}

export { EmpresaNavegacaoClassificacaoDetalhe };
