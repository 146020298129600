import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Wrapper = styled.div`
  flex: 1;
  padding: 10px;
  height: 900px;
  overflow: auto;
`;

export const Table = styled.table`
  padding: 10px 0;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px 2px;
    text-align: center;
    border-bottom: 1px solid #ddd;

    button {
      border: none;
      background: none;
      align-items: center;
      transition: color 0.5s;

      :hover {
        color: #ff6d3c;
      }
    }

    &:nth-of-type(3) {
      box-sizing: content-box;

      margin: 0;
      padding: 0;
      align-items: center;

      button {
        border: none;
        background: none;
      }
    }
  }
`;

export const TableRow = styled.tr`
  background: ${({ highlighted }) =>
    highlighted ? lighten(0.1, '#538061') : '#fff'};

  .linkSvg {
    color: ${({ highlighted }) =>
      highlighted ? '#f5f5f5' : darken(0.2, '#f5f5f5')};
  }

  :hover {
    background: ${({ linked }) =>
      linked ? lighten(0.1, '#538061') : darken(0.1, '#f5f5f5')};

    .linkSvg {
      color: ${({ linked }) => (linked ? '#f5f5f5' : darken(0.2, '#f5f5f5'))};
    }
  }
`;

export const HeaderOptions = styled.th`
  button {
    padding: 0;
  }
`;

export const RowOptions = styled.td`
  padding: 8px 2px;

  button {
    padding: 0;
  }
`;
