export function handleHomeFilter(value, searchHandle, paginas, paginasERP) {
  return {
    type: '@homeFilter/HANDLE_SEARCH',
    value,
    searchHandle,
    paginas,
    paginasERP,
  };
}

export function handleHomeFilterClear() {
  return {
    type: '@homeFilter/HANDLE_SEARCH_CLEAR',
  };
}
