import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { MdAddCircle, MdModeEdit, MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';

import ItemAdd from './add';
import ItemEdit from './edit';

import { Container, Wrapper, Scroll, Mapper } from './styles';

function MappingModal({ onClose, onSubmit, data }) {
  // const { tablePath } = useSelector((state) => state.user.profile);

  const INITIAL_MAPPER = [
    { id: 0, field: 'idItem', name: 'Item', fromId: null, from: null },
    { id: 1, field: 'partnumber', name: 'Código', fromId: null, from: null },
    { id: 2, field: 'descricao', name: 'Descrição', fromId: null, from: null },
    {
      id: 3,
      field: 'quantidade',
      name: 'Quantidade',
      fromId: null,
      from: null,
    },
    { id: 4, field: 'nota', name: 'Nota', fromId: null, from: null },
    {
      id: 5,
      field: 'especTecnica',
      name: 'Especificação Técnica',
      fromId: null,
      from: null,
    },
    { id: 6, field: 'ordena', name: 'Ordena', fromId: null, from: null },
  ];

  const TITLE_COMPARE = [
    ['ITEM', 'NUMERO'],
    ['CODIGO', 'PARTNUMBER'],
    ['DISCRIMINACAO', 'DESCRICAO', 'NOME'],
    ['QUANTIDADE', 'TOTAL', 'QTDE', 'TODAS'],
    ['OBSERVACAO', 'OBS', 'NOTA'],
  ];

  const INITIAL_STEP = 0;

  const steps = ['/', '/choose', '/add', '/edit'];

  const [step, setStep] = useState(INITIAL_STEP);

  const [items, setItems] = useState([]);
  const [mapper, setMapper] = useState(INITIAL_MAPPER);

  function goStep(name) {
    setStep(steps.findIndex((value) => value === name));
  }

  function goStepBack() {
    setStep(INITIAL_STEP);
  }

  const props = { goStepBack, goStep };

  useEffect(() => {
    if (data && data.length) {
      setItems(data);
    }
  }, [data]);

  function oGetTitle(obj) {
    /** verifica se o objeto exist */
    if (!obj || obj === undefined) return [];
    if (Array.isArray(obj)) {
      /** verifica registros do objeto */
      if (obj.length === 0) return [];
      return Object.keys(obj[0]);
    }
  }

  function oExtractFile(file, extname) {
    /** percorre a string procurando por textos com aspas "" removendo espaços caso exista */
    const format = file.match(/"([^"]+)"/g);
    if (format) {
      format
        .map((text) => ({
          old: text,
          new: text.replaceAll('"', ''),
          // .replaceAll('\n', '')
        }))
        /** substitui os textos no retorno */
        .forEach((text) => (file = file.replace(text.old, text.new)));
    }

    let title = [];
    const data = [];

    /** quebra o retorno em linhas e forma o retorno */
    file.split('\n').forEach((line) => {
      line = line.split(extname === 'txt' ? '|' : ';');
      if (line && line.length > 1) {
        if (title && title.length === 0) {
          line.forEach((l) => title.push(l));
          title = title.map((t) =>
            t
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
          );
        } else {
          data.push(
            Object.fromEntries(new Map(line.map((l, i) => [title[i], l])))
          );
        }
      }
    });

    /** comprar títulos para preenchimento automático de relacionamento */
    title.forEach((title, titleId) => {
      TITLE_COMPARE.forEach((compare, compareId) => {
        if (compare.some((c) => c === title)) {
          const map = mapper.find((m) => m.id === compareId);
          map.fromId = titleId;
          map.from = title;
          setMapper(
            [...mapper.filter((m) => m.id !== map.id), map].sort(
              (a, b) => a.id - b.id
            )
          );
        }
      });
    });

    setItems(data);
  }

  function handleRemove(id) {
    const newItems = items;
    newItems.splice(id, 1);

    setItems([...newItems]);
  }

  function handleTitleDragStart(e) {
    const { id, textContent: name } = e.target;
    e.dataTransfer.setData('text/data-title', JSON.stringify({ id, name }));
  }

  function handleDragOver(e) {
    const isLink = e.dataTransfer.types.includes('text/data-title');
    if (isLink) e.preventDefault();
  }

  function handleDrop(e) {
    e.preventDefault();

    const title_id = JSON.parse(e.dataTransfer.getData('text/data-title'));
    if (title_id) {
      const li = e.target.parentElement;

      const map = mapper.find((m) => m.field === li.id);
      map.fromId = title_id.id;
      map.from = title_id.name;

      setMapper(
        [...mapper.filter((m) => m.id !== map.id), map].sort(
          (a, b) => a.id - b.id
        )
      );
    }
  }

  function handleSubmit() {
    items.forEach((item) => {
      mapper.forEach((m) => {
        if (item.hasOwnProperty(m.from)) {
          Object.defineProperty(
            item,
            m.field,
            Object.getOwnPropertyDescriptor(item, m.from)
          );
          delete item[m.from];
        }
      });
    });

    onSubmit(items);
    onClose();
  }

  function handleFile(e) {
    // parse(e.target.files[0], {
    //   complete: (csv) => {
    //     console.log(csv);
    //   },
    //   header: true,
    //   skipEmptyLines: false,
    //   encoding: "ISO-8859-1",
    // });

    const reader = new FileReader();
    const extname = e.target.files[0].name.split('.').pop();

    reader.onload = () => {
      oExtractFile(reader.result, extname);
    };

    /** processa o arquivo "ISO-8859-1" - para evitar erro de caracteres acentuados */
    reader.readAsText(e.target.files[0], 'ISO-8859-1');
  }

  switch (step) {
    case 0:
      return (
        <Modal width={95} height={90} onClose={onClose}>
          <div>
            <button type="button" onClick={handleSubmit}>
              Confirmar
            </button>
            <button type="button" onClick={() => onClose()}>
              Cancelar
            </button>
            <input
              id="txt"
              name="txt"
              type="file"
              accept=".txt,.csv"
              onChange={handleFile}
            />
          </div>
          <Container>
            <Wrapper>
              <Scroll>
                <table>
                  <thead>
                    <tr>
                      {oGetTitle(items).map((title, key) => (
                        <th
                          key={key}
                          id={key}
                          draggable="true"
                          onDragStart={handleTitleDragStart}
                        >
                          {title}
                        </th>
                      ))}
                      <th>
                        <button type="button" onClick={() => goStep('/add')}>
                          <MdAddCircle size={26} color="#538061" />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((d, i) => (
                      <tr key={i}>
                        {Object.values(d).map((v, iv) => (
                          <td key={iv}>{v}</td>
                        ))}
                        <td>
                          <button type="button" onClick={() => goStep('/edit')}>
                            <MdModeEdit size={16} />
                          </button>
                          <button type="button" onClick={() => handleRemove(i)}>
                            <MdDelete size={16} color="#B01810" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scroll>
            </Wrapper>

            <Mapper>
              <ul>
                {mapper.map((m) => (
                  <li
                    key={m.id}
                    id={m.field}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <h1>{m.name}</h1>
                    <span>
                      {m.fromId ? `${m.fromId}: ${m.from}` : 'Arraste Aqui'}
                    </span>
                  </li>
                ))}
              </ul>
            </Mapper>
          </Container>
        </Modal>
      );
    case 1:
      return <h1>Choose</h1>;
    case 2:
      return (
        <Modal width={95} height={90} onClose={onClose}>
          <ItemAdd {...props} fnAdd={() => {}} />
        </Modal>
      );
    case 3:
      return (
        <Modal width={95} height={90} onClose={onClose}>
          <ItemEdit {...props} id={1} item={{}} fnEdit={() => {}} />
        </Modal>
      );
    default:
      break;
  }
}

MappingModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MappingModal;
