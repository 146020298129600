import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const Container = styled.div`
  flex: 1;

  /* background: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 100%; */

  padding: 5px 10px;
`;

export const ToolBar = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export const Information = styled.div`
  form {
    input {
      width: 800px;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  div .TransformComponent-module_container__3NwNd {
    /* flex: 1;
    display: flex;
    height: 100%; */

    div .TransformComponent-module_content__TZU5O {
      /* flex: 1;
      display: flex;
      height: 100%;     */
    }
  }
`;

export const Reporter = styled.span`
  position: absolute;
  top: 5px;
  left: 5px;
`;

export const ZoomWrapper = styled.div`
  flex: 1;
  display: flex;
  min-height: 100%;
  position: relative;
`;
export const ZoomControl = styled.div`
  position: absolute;
  margin: 10px;
  right: 0;

  button {
    border: none;
    border-radius: 25%;
    background: rgba(255, 255, 255, 0.8);
    margin: 0 10px;

    svg {
      fill: #ccc;
    }
  }
`;

export const SVG = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  border-radius: 10px;
  margin-right: 10px;
  width: 800px;
  height: 100%;

  svg {
    border: 1px solid #ccc;

    tspan,
    text {
      pointer-events: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
    }

    tspan,
    text,
    image {
      &.selected {
        stroke: red;
      }
    }

    path {
      &.selected {
        fill: red;
      }
    }

    .selected {
      stroke: red;
      fill: red;
    }

    circle,
    rect {
      &.inject {
        stroke: blue;
      }
    }
  }

  span {
    /* width: 100%;
    height: 100%;
    border: 1px solid;     */
  }
`;
