import React from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import { ItemMenu } from './ItemMenu';

import { Menu, Fixed } from './styles';

function MainMenu({ hidden, menuItems }) {
  return (
    <>
      <Fixed visible={menuItems.length > 0} />
      <Menu hidden={hidden} visible={menuItems.length > 0}>
        <Menu.Wrapper>
          {menuItems.map((item) => (
            <ItemMenu
              key={item.idMenu}
              color={darken(0.015 * item.idMenu, '#eef1f8')}
              title={item.title}
              active={item.active}
              backTo={item.backTo}
              visible={item.visible}
              items={item.items}
            />
          ))}
        </Menu.Wrapper>
      </Menu>
    </>
  );
}

MainMenu.propTypes = {
  hidden: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(Object).isRequired,
};

export { MainMenu };
