import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { AppError } from '../../../../../errors/AppError';
import api from '../../../../../services/api';
import history from '../../../../../services/history';

import { getSistema, getLocal } from '../../../../../lib/asyncUtils';

import TitleBar from '../../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../../components/Form';
import AsyncSelect from '../../../../../components/Form/Input/AsyncSelect';

import TableHeader from '../../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../../components/Table';

import ActionMenu from '../../../../../components/ActionMenu';

function EmpresaRelacPaginaSistLocEditar() {
  const formRef = useRef(null);
  const location = useLocation();
  const { idEmpresa, idCatalogo, idPagina, background } = location.state;

  const [relacPaginaSistLoc, setRelacPaginaSistLoc] = useState([]);

  useEffect(() => {
    api
      .get(
        `empresa/${idEmpresa}/catalogo/${idCatalogo}/relacionamento-sistema-local/${idPagina}`
      )
      .then((response) => {
        setRelacPaginaSistLoc(
          response.data.relacPaginaSistLoc.map((relacionamento) => ({
            ...relacionamento,
            situacao: 0,
          }))
        );
      });
  }, [idEmpresa, idCatalogo, idPagina]);

  const handleSubmit = useCallback(async () => {
    try {
      await api.post(
        `empresa/${idEmpresa}/catalogo/${idCatalogo}/relacionamento-sistema-local/${idPagina}`,
        {
          relacionamento: relacPaginaSistLoc.map(
            ({ sistema: { codiSist }, local: { codiLoca } }) => ({
              codiSist,
              codiLoca,
            })
          ),
        }
      );

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, [idEmpresa, idCatalogo, idPagina, relacPaginaSistLoc]);

  const handleAdicionar = useCallback(
    async (data, { reset }) => {
      try {
        const { codiSist, codiLoca } = data;

        if (codiSist.length === 0 || codiLoca.length === 0) {
          window.alert('Necessário selecionar opção para Sistema e Local');
          return;
        }

        const descricaoSist = (
          await api.get(`empresa/${idEmpresa}/sistema`, {
            params: { q: `%${codiSist}` },
          })
        ).data.sistema
          .filter((sistema) => !sistema.dataDesa)
          .map((sistema) => ({
            value: sistema.codiSist,
            label: `${sistema.codiSist} - ${sistema.descricao}`,
          }));

        const descricaoLoca = (
          await api.get(`empresa/${idEmpresa}/local`, {
            params: { q: `%${codiLoca}` },
          })
        ).data.local
          .filter((local) => !local.dataDesa)
          .map((local) => ({
            value: local.codiLoca,
            label: `${local.codiLoca} - ${local.descricao}`,
          }));

        const verificarRelacPaginaSisLoc = relacPaginaSistLoc.some(
          (relacionamento) =>
            relacionamento.local.codiLoca === codiLoca &&
            relacionamento.sistema.codiSist === codiSist
        );

        if (verificarRelacPaginaSisLoc) {
          window.alert('Este relacionamento já foi adicionado');
          return;
        }

        setRelacPaginaSistLoc((state) => [
          ...state,
          {
            idPagina,
            sistema: {
              codiSist,
              descricao: descricaoSist[0].label,
            },
            local: {
              codiLoca,
              descricao: descricaoLoca[0].label,
            },
            situacao: 1,
          },
        ]);
        reset();
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa, idPagina, relacPaginaSistLoc]
  );

  const handleRemover = useCallback(
    (codiSist, codiLoca) => {
      setRelacPaginaSistLoc((state) =>
        state.reduce((accRpsl, currRpsl) => {
          if (
            currRpsl.idPagina === idPagina &&
            currRpsl.sistema.codiSist === codiSist &&
            currRpsl.local.codiLoca === codiLoca
          ) {
            return accRpsl;
          }

          accRpsl.push(currRpsl);
          return accRpsl;
        }, [])
      );
    },
    [idPagina]
  );

  const opSituacao = useCallback((situacao) => {
    switch (situacao) {
      case 0:
        return <Form.Column.Kind isAdded>Salvo</Form.Column.Kind>;
      case 1:
        return <Form.Column.Kind isPending>Pendente</Form.Column.Kind>;

      default:
        return <Form.Column.Kind>Indefinido</Form.Column.Kind>;
    }
  }, []);

  return (
    <>
      <TitleBar back={!background} title="Relac. Página / Sistema e Local " />
      <FormWrapper>
        <Form
          id="formEmpresaCatalogoMaterial"
          ref={formRef}
          onSubmit={handleAdicionar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <AsyncSelect
                id="codiSist"
                name="codiSist"
                label="Sistema"
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) => {
                  getSistema({ id: idEmpresa, value }, cb);
                }}
              />
              <AsyncSelect
                id="codiLoca"
                name="codiLoca"
                label="Local"
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) =>
                  getLocal({ id: idEmpresa, value }, cb)
                }
              />
            </Form.Row>

            <Form.Footer withStart>
              <button
                id="btn-submit"
                type="submit"
                form="formEmpresaCatalogoMaterial"
              >
                Gravar
              </button>
            </Form.Footer>

            <Form.Row>
              <Form.Column>
                <h3>Relacionamentos:</h3>
                <Table>
                  <TableHeader
                    withPadding
                    titles={[
                      { fieldname: 'codiSist', title: 'Cód. Sistema' },
                      { fieldname: 'sistema', title: 'Sistema' },
                      { fieldname: 'codiLoca', title: 'Cód. Local' },
                      { fieldname: 'local', title: 'Local' },
                      { fieldname: 'situacao', title: 'Situação' },
                      { fieldname: null, title: null },
                    ]}
                    cbSort={() => {}}
                  />
                  <tbody>
                    {relacPaginaSistLoc.map(
                      (
                        {
                          sistema: { codiSist, descricao: descSist },
                          local: { codiLoca, descricao: descLoca },
                          situacao,
                        },
                        index
                      ) => (
                        <RowMaster key={index}>
                          <td>{codiSist}</td>
                          <td>{descSist}</td>
                          <td>{codiLoca}</td>
                          <td>{descLoca}</td>
                          <td>{opSituacao(situacao)}</td>
                          <td>
                            <ActionMenu
                              data={{ codiSist, codiLoca }}
                              onLineRemove={() =>
                                handleRemover(codiSist, codiLoca)
                              }
                              onRemove={() => handleRemover(codiSist, codiLoca)}
                            />
                          </td>
                        </RowMaster>
                      )
                    )}
                  </tbody>
                </Table>
              </Form.Column>
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="button" onClick={handleSubmit}>
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EmpresaRelacPaginaSistLocEditar;
