import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import { numberInputFormat } from '../../../../lib/inputUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import { Container } from '../../../../components/Container';

const schema = Yup.object().shape({
  idItem: Yup.string().required('O campo "Item" deve ser preenchido'),
  partnumber: Yup.string().required('O campo "Partnumber" deve ser preenchido'),
  quantidade: Yup.number()
    .typeError('O campo "Quantidade" deve ser preenchido')
    .required('O campo "Quantidade" deve ser preenchido'),
  nota: Yup.string().nullable(),
  especTecnica: Yup.string().nullable(),
  ordena: Yup.number()
    .typeError('O campo "Ordena" deve ser preenchido')
    .required('O campo "Ordena" deve ser preenchido'),
});

function CatalogoItemCriar() {
  const location = useLocation();
  const { idCatalogo, idPagina } = location.state;

  const IDIOMA = {
    PT_BR: 0,
    EN: 1,
  };

  const [idioma, setIdioma] = useState(null);

  const formRef = useRef();

  useEffect(() => {
    api.get(`/catalogo/${idCatalogo}`).then((response) => {
      setIdioma(response.data.idioma);
    });
  }, [idCatalogo]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          idPagina: dataIdPagina,
          idItem,
          quantidade,
          nota,
          especTecnica,
          ordena,
          idPaginaOrigem,
          partnumber,
          descricaoBR,
          descricaoEN,
        } = data;

        const descricao =
          Number(idioma) === IDIOMA.PT_BR ? descricaoBR : descricaoEN;

        await api.post(`/catalogo/${idCatalogo}/pagina/${dataIdPagina}/item`, {
          idItem,
          partnumber,
          descricao,
          quantidade,
          nota,
          especTecnica,
          ordena,
          idPaginaOrigem,
        });

        history.goBack();

        toast.success('Item adicionado com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [IDIOMA, idioma, idCatalogo]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Novo item" />

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleConfirmar}
          initialData={{
            idPagina,
          }}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="idPagina"
                name="idPagina"
                label="Página"
                width={400}
                disabled={idPagina}
              />
              <Input id="idItem" name="idItem" label="Item" />
              <Input id="partnumber" name="partnumber" label="Partnumber" />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricaoBR"
                name="descricaoBR"
                label="Descrição (BR)"
                disabled={Number(idioma) !== IDIOMA.PT_BR}
              />
              <Input
                id="descricaoEN"
                name="descricaoEN"
                label="Descrição (EN)"
                disabled={Number(idioma) !== IDIOMA.EN}
              />
            </Form.Row>
            <Form.Row>
              <Input id="nota" name="nota" label="Nota" />
              <Input
                id="especTecnica"
                name="especTecnica"
                label="Espec. Técnica"
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="quantidade"
                name="quantidade"
                label="Quantidade"
                type="number"
                /** formata valor decimal */
                pattern="[0-9]+([\.,][0-9]+)?"
                step="0.0001"
                min="0.0000"
                onBlur={(e) => numberInputFormat(e, formRef, 4)}
              />
              <Input id="ordena" name="ordena" label="Ordena" type="number" />
              <Input
                id="idPaginaOrigem"
                name="idPaginaOrigem"
                label="Página Origem"
                type="number"
              />
            </Form.Row>
          </Form.Column>
          <Container.Note>
            Obs.: Utilize o @ para geração automática para os campos ITEM e
            PARTNUMBER
          </Container.Note>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default CatalogoItemCriar;
