import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  MdDone,
  MdClose,
  MdFormatIndentIncrease,
  MdClearAll,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import Pagination from '../../../components/Editor/Pagination';
import EditorTable from '../../../components/Editor/Table';
import Resizeble from '../../../components/Resizeble';

import EditorExtrairMapeamento from './mapeamento';

import { Container, Wrapper, Panel, ToolBar } from './styles';

function EditorExtrair() {
  const location = useLocation();
  const { id, paginaInicial = 1 } = location.state;

  const resizeRef = useRef(null);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [itensPagina, setItensPagina] = useState([]);

  const [headers, setHeaders] = useState(null);

  const [mapping, setMapping] = useState(null);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaPaginaCatalogo = useCallback(() => {
    if (catalogo) {
      setPaginaCatalogo(null);
      setItensPagina([]);
      setMapping(null);

      if (resizeRef.current) {
        resizeRef.current.clearSelection();
      }

      /** pega id da página  */
      const pagina = catalogo.paginas[currentPage - 1];
      if (pagina) {
        /** carrega itens da página */
        api.get(`editor.extrair/${id}/${pagina}`).then((response) => {
          setPaginaCatalogo(response.data.paginaCatalogo);

          setItensPagina(
            response.data.referItemPagina.map((item) => ({
              ...item,
              ...item.materialFabricante,
            }))
          );
        });
      }
    }
  }, [catalogo, id, currentPage]);

  useEffect(() => {
    /** informações do catálogo */
    api.get(`editor.extrair/${id}`).then((response) => {
      setCatalogo({
        ...response.data,
        paginas: response.data.paginaCatalogo.map((pagina) => pagina.idPagina),
      });

      setTotalPage(response.data.paginaCatalogo.length);
      setCurrentPage(paginaInicial);
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    carregaPaginaCatalogo();
  }, [catalogo, id, currentPage, carregaPaginaCatalogo]);

  const handleExtractData = useCallback(async () => {
    const { idPagina } = paginaCatalogo;

    const confirm = itensPagina.length
      ? window.confirm(
          'O processo vai substituir os itens cadastrados anteriormente para essa página, deseja realmente continuar?'
        )
      : true;

    if (confirm) {
      const selecao = resizeRef.current.getSelection();
      const { borderBox, points } = selecao;

      /** envia para o servidor extrair os dados da página */
      const response = await api.post(`editor.extrair/${id}/${idPagina}`, {
        pages: [
          {
            pageNumber: idPagina,
            borderBox,
            points: points.sort((a, b) => a - b),
          },
        ],
      });

      const { formattedData, formattedHeader } = response.data;

      /** carrega pela primeira vez os cabeçalhos */
      if (!headers) {
        setHeaders(
          formattedHeader.map((header) => ({ ...header, option: null }))
        );
      }

      /** abre tela de mapeamento */
      setMapping({
        headers: formattedHeader,
        data: formattedData,
      });

      if (resizeRef.current) {
        resizeRef.current.clearSelection();
      }
    }
  }, [id, headers, paginaCatalogo, itensPagina]);

  const handleMapping = useCallback((mappingData) => {
    // const { idioma } = catalogo;

    // setItensPagina(
    //   mappingData.map(({ descricao, ...item }) => ({
    //     ...item,
    //     descricaoBR: idioma === '0' ? descricao : null,
    //     descricaoEN: idioma === '1' ? descricao : null,
    //   }))
    // );
    setItensPagina(mappingData);
  }, []);

  const handleMappingCancel = useCallback(() => {
    setMapping(null);

    if (resizeRef.current) {
      resizeRef.current.clearSelection();
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const { idPagina } = paginaCatalogo;

      await api.put(`/editor.extrair/${id}/${idPagina}`, itensPagina);

      carregaPaginaCatalogo();

      toast.success('Itens cadastrados com sucesso!');
    } catch (err) {
      toast.error(err.response.data.message || err);
    }
  }, [id, paginaCatalogo, itensPagina, carregaPaginaCatalogo]);

  const handleCancel = useCallback(() => {
    setMapping(null);

    if (resizeRef.current) {
      resizeRef.current.clearSelection();
    }
  }, []);

  const handleClearHeaders = useCallback(() => {
    setHeaders(null);
  }, []);

  return (
    <>
      <TitleBar back title={catalogo && catalogo.nome}>
        <button
          type="button"
          id="btn-next"
          onClick={() => history.push('/edicao/editar', { id })}
        >
          Próximo
        </button>
      </TitleBar>

      {catalogo && (
        <Container>
          <Panel>
            <Pagination
              pageCount={totalPage}
              initialData={paginaInicial}
              onPageChange={(selected) => {
                setCurrentPage(selected);
              }}
            />

            {itensPagina && <EditorTable itensPagina={itensPagina} />}
          </Panel>

          <Wrapper>
            <ToolBar>
              <button title="Salvar" type="submit" onClick={handleSubmit}>
                <MdDone size={16} />
              </button>
              <button type="button" title="Cancelar" onClick={handleCancel}>
                <MdClose size={16} />
              </button>
              <button
                type="button"
                title="Extrair Tabela"
                onClick={handleExtractData}
              >
                <MdFormatIndentIncrease size={16} />
              </button>
              <button
                type="button"
                title="Limpar classificação de colunas"
                onClick={handleClearHeaders}
              >
                <MdClearAll size={16} />
              </button>
            </ToolBar>
            <div>
              {paginaCatalogo && (
                <Resizeble id={Math.random()} ref={resizeRef}>
                  <ReactSVG
                    id={`page-${paginaCatalogo.idPagina}`}
                    src={paginaCatalogo.urlEdicao}
                    tabIndex="0"
                    loading={() => <span>Loading</span>}
                    fallback={() => <span>Selecione a imagem para editar</span>}
                  />
                </Resizeble>
              )}
            </div>
          </Wrapper>
        </Container>
      )}

      {mapping && (
        <EditorExtrairMapeamento
          headers={headers}
          data={mapping.data}
          onCancel={handleMappingCancel}
          onMapping={handleMapping}
        />
      )}
    </>
  );
}

export default EditorExtrair;
