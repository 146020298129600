import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div``;

export const Wrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
`;

Wrapper.NotFound = styled.span`
  display: block;
  width: 100%;
  text-align: center;

  font-size: 14px;
  font-style: italic;
  color: rgba(44, 64, 90, 0.5);
  padding: 40px 0;
`;

Wrapper.BtnImg = styled.div`
  cursor: pointer;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;

  font-size: 14px;

  padding: 5px 10px;

  > thead {
    > tr {
      > th {
        font-size: 12px;
        color: #2c405a;
        font-weight: normal;
        text-align: left;
        padding: 0 20px;
      }
    }
  }

  > tbody {
    user-select: text !important;

    > tr {
      > td {
        text-align: left;
        vertical-align: top;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 15px 20px;
        background: #fff;
        color: #2c405a;

        img {
          width: 150px;
          border-radius: 5px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        }
        button {
          border: 0;
          background: none;
        }

        &:first-child {
          border-left: 1px solid #ccc;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          text-align: center;
          border-right: 1px solid #ccc;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      &:last-child {
        border: 1px solid;
      }
    }
  }
`;

Table.Description = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    margin-top: 5px;
  }
`;

Table.Combo = styled.div`
  display: flex;
  /* align-items: center; */
  margin-top: 5px;

  > div {
    padding: 0;
    margin-left: 5px;
  }
`;

Table.Error = styled.span`
  color: #e95938;
  font-style: italic;
  font-size: 13px;
`;

Table.Quantity = styled.div`
  display: flex;
  align-items: stretch;
  height: 30px;

  span {
    text-align: center;
    min-width: 40px;
    padding: 5px 0;
  }
`;

Table.Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Table.BtnRemove = styled.button`
  color: #8fa0ba;
  transition: color 1s;

  :hover {
    color: ${darken(0.05, '#de3b3b')};
  }
`;

export const Section = styled.div`
  padding: 10px 10px 20px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;

  > button {
    border: 0;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
  }

  #btn-action {
    border: 0;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
    background: #2c405a;
    transition: background 1s;

    :hover {
      color: #fff;
      background: ${darken(0.05, '#2c405a')};
    }
  }
`;

Footer.Export = styled.button`
  background: #2c405a;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#2c405a')};
  }
`;

Footer.Submit = styled.button`
  background: #ff6d3c;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#ff6d3c')};
  }
`;
