import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { DebounceInput as ReactDebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';

import { InputWrapper } from '../..';

function DebounceInput({
  name,
  label,
  width,
  flexDirection,
  isHidden,
  debounceTimeout,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.state.value = value || '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper
      width={width}
      isHidden={isHidden}
      flexDirection={flexDirection}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}

      <ReactDebounceInput
        ref={inputRef}
        debounceTimeout={debounceTimeout}
        className={error ? 'has-error' : ''}
        value={defaultValue}
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

DebounceInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  flexDirection: PropTypes.string,
  isHidden: PropTypes.bool,
  debounceTimeout: PropTypes.number,
};

DebounceInput.defaultProps = {
  label: null,
  width: null,
  flexDirection: 'column',
  isHidden: false,
  debounceTimeout: 500,
};

export default DebounceInput;
