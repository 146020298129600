import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  padding: 20px 10px 10px 10px;
  background: #334155;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -50%);
  z-index: 9999;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 18px;
  font-weight: bold;
  color: #f8fafc;
  padding: 0 0 10px 5px;
  max-width: 480px;
`;

export const Image = styled.img`
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 10px;
`;
