import React, {
  useState,
  useEffect,
  // useCallback,
  // useRef
} from 'react';
import { useLocation } from 'react-router-dom';
// import {
//   MdZoomIn,
//   MdTablet,
//   MdRemove,
//   MdPanorama,
//   MdPhotoLibrary,
//   MdLink,
//   MdErrorOutline,
// } from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import Pagination from '../../../components/Pagination';
// import Loading from '../../../components/Loading';
// import Viewer from '../../../components/Viewer';

// import { Form } from '../../../components/Form';
// import Input from '../../../components/Form/Input';
// import Select from '../../../components/Form/Input/Select';

import MappingTable from './MappingTable';

import {
  Container,
  Wrapper,
  CardList,
  Card,
  // CardMenu
} from './styles';

function EditorClassificar() {
  const { id } = useLocation().state;

  // const formRef = useRef();

  const [catalogo, setCatalogo] = useState(null);
  const [paginas, setPaginas] = useState([]);

  // const [viewer, setViewer] = useState(false);
  // const [viewerUri, setViewerUri] = useState('');
  // const [loading, setLoading] = useState(false);

  const [mapping, setMapping] = useState(null);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  useEffect(() => {
    async function loadGrader() {
      // setLoading(true);
      api
        .get(`grader/${id}`, {
          params: {
            page: currentPage,
          },
        })
        .then((response) => {
          /** informações do catálogo */
          setCatalogo({
            ...response.data.catalogo,
            fabricanteFormatado:
              response.data.catalogo.arquivo.fabricante &&
              response.data.catalogo.arquivo.fabricante.descricao,
            modeloFormatado: response.data.catalogo.arquivo.modelos
              .map((modelo) => modelo.descricao)
              .join(', '),
          });
          setPaginas(response.data.catalogo.paginaCatalogo);

          /** paginação */
          setTotalPage(response.data.total);
        })
        .catch((err) => {
          /** mensagem de erro */
          toast.error(err);
        });
      // .finally(() => {
      //   setLoading(false);
      // });
    }
    loadGrader();
  }, [currentPage, id]);

  // const handleZoomIn = useCallback((local) => {
  //   setViewerUri(local);
  //   setViewer(true);
  // }, []);

  // const handleGrader = useCallback(
  //   async (idPagina, tipo) => {
  //     const paginaIndex = paginas.findIndex(
  //       (pagina) => pagina.idPagina === idPagina
  //     );

  //     if (paginaIndex > -1) {
  //       paginas[paginaIndex].tipo = tipo;
  //       paginas[paginaIndex].situacao = 1;
  //       paginas[paginaIndex].referencia = null;

  //       if (tipo === 2) {
  //         paginas[paginaIndex].referencia = idPagina;

  //         /** verifica desenho anterior selecionado */
  //         if (paginaIndex > 0) {
  //           const paginaAnt = paginas[paginaIndex - 1];

  //           /** para classificação de desenho ou misto, considera o idPagina senão a sua referencia */
  //           const idPaginaAnt = [1, 3].includes(paginaAnt.tipo)
  //             ? paginaAnt.idPagina
  //             : paginaAnt.referencia;

  //           paginas[paginaIndex].referencia = idPaginaAnt;
  //         }
  //       }

  //       if (tipo === 3) {
  //         paginas[paginaIndex].referencia = idPagina;
  //       }

  //       await api.put(`grader/${id}`, paginas[paginaIndex]);

  //       setPaginas([...paginas]);
  //     }
  //   },
  //   [id, paginas]
  // );

  // const handleLinked = useCallback(
  //   (idPagina) => {
  //     const index = paginas.findIndex((pagina) => pagina.idPagina === idPagina);
  //     if (index >= 0) {
  //       paginas[index].linked = true;
  //       setPaginas([...paginas]);
  //     }
  //   },
  //   [paginas]
  // );

  // const handleLinkedSubmit = useCallback(
  //   async (data) => {
  //     const { idPagina, referencia } = data;

  //     const index = paginas.findIndex(
  //       (pagina) => pagina.idPagina === Number(idPagina)
  //     );
  //     if (index >= 0) {
  //       paginas[index].referencia = referencia;
  //       paginas[index].linked = false;

  //       await api.put(`grader/${id}`, paginas[index]);

  //       setPaginas([...paginas]);
  //     }
  //   },
  //   [id, paginas]
  // );

  // const handleMappingTemp = useCallback((pagina) => {
  //   setMapping(pagina);
  // }, []);

  // const getPageList = useCallback(
  //   (idPagina) =>
  //     /** gera lista com as páginas anteriores a página atual */
  //     Array.from({ length: idPagina }, (v, k) => ({
  //       value: k + 1,
  //       label: k + 1,
  //     })),
  //   []
  // );

  return (
    <Container>
      <TitleBar back title={catalogo && catalogo.nome}>
        <button
          type="button"
          id="btn-next"
          onClick={() => history.push('/edicao/extrair', { id })}
        >
          Próximo
        </button>
      </TitleBar>
      <Wrapper>
        <CardList>
          {paginas.map((pagina) => (
            <Card
              key={pagina.idPagina}
              id={pagina.idPagina}
              state={pagina.tipo}
            >
              <img
                src={pagina.urlEdicao}
                loading="lazy"
                alt={pagina.idPagina}
              />
              {/* <CardMenu>
                <CardMenu.Column>
                   {[2, 3].includes(pagina.tipo) && (
                    <CardMenu.BtnAction
                      type="button"
                      title="Mapeamento"
                      onClick={() => handleMappingTemp(pagina)}
                    >
                      <MdErrorOutline size={20} />
                    </CardMenu.BtnAction>
                  )} 
                   <CardMenu.Row alignEnd>
                    <CardMenu.BtnAction
                      type="button"
                      title="Ver"
                      onClick={() => handleZoomIn(pagina.urlEdicao)}
                    >
                      <MdZoomIn size={20} color="#1063B0" />
                    </CardMenu.BtnAction>
                     <CardMenu.BtnAction
                      type="button"
                      title="Desenho"
                      onClick={() => handleGrader(pagina.idPagina, 1)}
                    >
                      <MdPanorama size={20} color="#538061" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Tabela"
                      onClick={() => handleGrader(pagina.idPagina, 2)}
                    >
                      <MdTablet size={20} color="#E6DE2A" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Misto"
                      onClick={() => handleGrader(pagina.idPagina, 3)}
                    >
                      <MdPhotoLibrary size={20} color="#5485F7" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Eliminar"
                      onClick={() => handleGrader(pagina.idPagina, 4)}
                    >
                      <MdRemove size={20} color="#B01810" />
                    </CardMenu.BtnAction> 
                  </CardMenu.Row> 
                   <CardMenu.Row alignEnd>
                     {[2, 3].includes(pagina.tipo) &&
                      (pagina.linked ? (
                        <Form ref={formRef} onSubmit={handleLinkedSubmit}>
                          <Input
                            id="idPagina"
                            name="idPagina"
                            isHidden
                            defaultValue={pagina.idPagina}
                          />
                          <Select
                            id="referencia"
                            name="referencia"
                            isSearchable={false}
                            options={getPageList(pagina.idPagina)}
                            defaultValue={{
                              value: pagina.referencia,
                              label: pagina.referencia,
                            }}
                            width={100}
                            // onChange={() => formRef.current.submitForm()}
                            onBlur={() => formRef.current.submitForm()}
                          />
                        </Form>
                      ) : (
                        <CardMenu.BtnLink
                          type="button"
                          onClick={() => handleLinked(pagina.idPagina)}
                        >
                          {pagina.idPagina}
                          <MdLink size={16} />
                          {pagina.referencia}
                        </CardMenu.BtnLink>
                      ))}

                    {[1, 4].includes(pagina.tipo) && (
                      <span>{pagina.idPagina}</span>
                    )} 
                  </CardMenu.Row> 
                </CardMenu.Column>
              </CardMenu> */}
            </Card>
          ))}
        </CardList>
      </Wrapper>

      {totalPage && (
        <Pagination
          pageCount={totalPage}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
          }}
        />
      )}
      {/* <Loading visible={loading} />
      {viewer && (
        <Viewer uri={viewerUri} handleClose={() => setViewer(false)} />
      )} */}
      {mapping && (
        <MappingTable
          idCatalogo={id}
          page={mapping}
          onClose={() => setMapping(null)}
        />
      )}
    </Container>
  );
}

export default EditorClassificar;
