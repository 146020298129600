import React, { useRef, useCallback, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import * as Yup from 'yup';

import history from '../../../services/history';
import api from '../../../services/api';

import logotipo from '../../../assets/logo.svg';
import MarcaDAgua from '../../../assets/marca-dagua-assiste.svg';

import { AppError } from '../../../errors/AppError';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

import { Container, Wrapper, Footer } from './styles';

const schema = Yup.object().shape({
  login: Yup.string().required('O campo "login" é obrigatório!'),
});

function RecuperaAcesso() {
  const formRef = useRef(null);

  const [enviado, setEnviado] = useState(false);

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { login } = data;

      await api.post('/recupera-acesso', {
        login,
      });

      setEnviado((prevState) => !prevState);
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  const handleLogin = useCallback(() => {
    history.push('/login');
  }, []);

  return (
    <Container>
      <img src={logotipo} alt="Assiste" />
      <Wrapper>
        <h1>Recuperação de Acesso</h1>
        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          {enviado ? (
            <span>
              <MdEmail size={30} color="#ff6d3c" />
              <h3>E-mail enviado com sucesso!</h3>
            </span>
          ) : (
            <>
              <p>
                Digite o login associado à sua conta e enviaremos um link para
                redefinir sua senha no e-mail cadastrado.
              </p>

              <Form.Column>
                <Form.Row>
                  <Input
                    id="login"
                    name="login"
                    type="text"
                    placeholder="Login"
                  />
                </Form.Row>
              </Form.Column>

              <button id="btn-submit" type="submit">
                Enviar
              </button>
            </>
          )}

          <button id="btn-login" type="button" onClick={handleLogin}>
            Voltar
          </button>
        </Form>
      </Wrapper>

      <Footer>
        <a
          href="http://assiste.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          title={`Assiste - CDI - v${process.env.REACT_APP_VERSION}`}
        >
          <img src={MarcaDAgua} alt="logotipo" />
        </a>
      </Footer>
    </Container>
  );
}

export { RecuperaAcesso };
