import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signInTokenRequest } from '../../../../store/modules/auth/actions';
// import { setToken } from '../../../../store/modules/auth/sagas';

import api from '../../../../services/api';

import { useQuery } from '../../../../lib/reactRouter';
import { opTipoAcao } from '../../../../lib/const';

import { AppContainer } from '../../../../components/Container';

const INITIAL_PARAMS = {
  token: null,
  extNumeOS: null,
  extCodiDiv2: null,
  extUsuario: null,
};

const INITIAL_ROUTER = {
  situacao: 0,
  routerParam: { extCodiDiv2: null, extNumeOS: null, extUsuario: null },
};

function IntegraSolicitacao() {
  const dispatch = useDispatch();
  const routeQuery = useQuery(useLocation().search);
  const [router, setRouter] = useState(INITIAL_ROUTER);

  useEffect(() => {
    const parametroRota = {
      ...INITIAL_PARAMS,
      ...Object.fromEntries(routeQuery),
    };

    const { token, extCodiDiv2, extNumeOS, extUsuario } = parametroRota;

    dispatch(signInTokenRequest(token, opTipoAcao.INTEGRACAO_SOLICITACAO));

    /** token informado por parâmetro */
    // setToken({
    //   payload: {
    //     auth: {
    //       token,
    //     },
    //   },
    // });

    /** controle de tentativas de consulta de catálogo  */
    const timerCounterMAX = 3;
    let timerCounter = 0;
    let timerID;

    const carregaRotaApp = () => {
      /** controle de tentativas */
      timerCounter += 1;
      if (timerCounter > timerCounterMAX) clearInterval(timerID);

      /** verifica se o token já foi aplicado */
      if (api.defaults.headers.Authorization) {
        clearInterval(timerID);

        setRouter({
          situacao: 1,
          routerParam: { extCodiDiv2, extNumeOS, extUsuario },
        });
      }
    };

    /** seta intervalo de tempo para aguardar atribuição de token */
    timerID = setInterval(carregaRotaApp, 800);
  }, [routeQuery, dispatch]);

  const appRedirect = useMemo(
    () =>
      router.situacao === 1 ? (
        <Redirect
          to={{
            pathname: '/empresa/solicitacao',
            state: { ...router.routerParam },
          }}
        />
      ) : (
        <AppContainer>
          <span>Redirecionando, aguarde...</span>
        </AppContainer>
      ),
    [router]
  );

  return appRedirect;
}

export default IntegraSolicitacao;
