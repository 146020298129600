import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background-color: ${darken(0.04, '#f2f5fc')};

  & span {
    font-size: 12px;
    font-weight: bold;
  }
`;
