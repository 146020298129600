import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 10px #ddd;
  border-radius: 25px;
  width: 100%;
  height: 90%;
  overflow: auto;
`;

export const CardList = styled.div`
  flex: 1;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const handleColorType = (id) => {
  switch (id) {
    case 1:
      return '#538061';
    case 2:
      return '#E6DE2A';
    case 3:
      return '#5485F7';
    case 4:
      return '#B01810';
    default:
      return '#ccc';
  }
};

export const Card = styled.div`
  border: 2px solid ${({ state }) => handleColorType(state)};
  width: 150px;
  height: 180px;
  position: relative;
  margin: 10px;
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.2); */

  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
  }
`;

export const CardMenu = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
`;

CardMenu.Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

CardMenu.Row = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${(props) =>
    props.alignEnd &&
    css`
      align-items: flex-end;

      > form {
        display: flex;
        align-items: center;
      }
    `}

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff6d3c;
    background: #fff;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin-bottom: 5px;

    font-size: 10px;
    font-weight: bold;
    color: #ff6d3c;
  }
`;

CardMenu.BtnAction = styled.button`
  display: flex;
  align-items: center;

  margin: 0 2px;
  padding: 2px;
  border-radius: 25%;
  border: none;
  background: rgba(255, 255, 255, 0.9);
`;

CardMenu.BtnLink = styled.button`
  background: #ff6d3c;
  color: #fff;
  font-size: 10px;
  transition: background 1s;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  border: 0;
  height: 25px;
  padding: 0 5px;
  min-width: 50px;
  margin-bottom: 5px;

  > svg {
    margin: 0 2px;
  }

  :hover {
    background: ${darken(0.1, '#ff6d3c')};
  }
`;
