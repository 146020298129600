import React, { useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/Input/TextArea';

import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';

import ActionMenu from '../../../components/ActionMenu';

/** validação dos campos */
const schemaPara = Yup.object().shape({
  paraNome: Yup.string().required('O campo "Para" deve ser preenchido'),
  paraEmail: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
});

const schema = Yup.object().shape({
  deNome: Yup.string().required('O campo "Nome" deve ser preenchido'),
  deEmail: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
  comentario: Yup.string().nullable(),
});

export const whatsAppTipo = {
  WHATSAPP_CARRINHO: 0,
  WHATSAPP_CHECKOUT: 1,
  WHATSAPP_PAGINA: 2,
};

function UsuarioWhatsApp() {
  const formRef = useRef(null);

  const location = useLocation();
  const { tipo, data: dataExtra } = location.state;

  const [whatsApp, setWhatsApp] = useState([]);

  const handleConfirmar = useCallback(async () => {
    try {
      const data = formRef.current.getData();

      await schema.validate(data, {
        abortEarly: false,
      });

      // *** CELULAR ***
      const { deEmail, deNome, comentario } = data;

      /** verifica foi adicionado pelo meno um destinatário */
      // *** CELULAR ***
      if (!whatsApp.length) {
        throw new Error('É necessário adicionar pelo menos um destinatário');
      }

      /** formata o remetente */
      // *** CELULAR ***
      const remetente = `{"deEmail":"${deEmail}","deNome":"${deNome}"}`;

      /** formata os destinatários */
      // *** CELULAR ***
      const destinatarios = whatsApp
        .map(
          ({ paraEmail, paraNome }) =>
            `{"paraEmail":"${paraEmail}","paraNome":"${paraNome}"}`
        )
        .join(';');

      /** pega as informações extras */
      const {
        idCatalogo = null,
        idPagina = null,
        idSolicitacao = null,
        attachment = null,
      } = dataExtra;

      /** formulário de dados */
      const formData = new FormData();
      formData.append('file', attachment);
      formData.append('remetente', remetente);
      formData.append('destinatarios', destinatarios);
      formData.append('comentario', comentario);

      switch (tipo) {
        case whatsAppTipo.WHATSAPP_CARRINHO:
          // *** WHATSAPP POST ***
          await api.post('carrinhoMail', formData);
          break;
        case whatsAppTipo.WHATSAPP_CHECKOUT:
          /** informações adicionais da solicitação */
          formData.append('idSolicitacao', idSolicitacao);

          // *** WHATSAPP POST ***
          await api.post('solicitacaoMail', formData);
          break;
        case whatsAppTipo.WHATSAPP_PAGINA:
          /** informações adicionais da página */
          formData.append('idCatalogo', idCatalogo);
          formData.append('idPagina', idPagina);

          // *** WHATSAPP POST ***
          await api.post('paginaCatalogoMail', formData);
          break;
        default:
          break;
      }

      // toast.success('E-mail enviado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, [whatsApp, tipo, dataExtra]);

  const handleAdicionar = useCallback(async (data) => {
    try {
      await schemaPara.validate(data, {
        abortEarly: false,
      });

      // *** CELULAR ***
      const { paraNome, paraEmail } = data;

      setWhatsApp((state) => [
        ...state,
        // *** CELULAR ***
        {
          paraNome,
          paraEmail,
        },
      ]);

      /** limpa campos do destinatário */
      // *** CELULAR ***
      formRef.current.clearField('paraNome');
      formRef.current.clearField('paraEmail');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, []);

  const handleRemover = useCallback(
    (index) => {
      /** Excluí destinatário */
      whatsApp.splice(index, 1);

      setWhatsApp([...whatsApp]);
    },
    [whatsApp]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Envio por WhatsApp" />
      <FormWrapper>
        <Form
          id="formEmail"
          ref={formRef}
          onSubmit={handleAdicionar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="deNome" name="deNome" placeholder="De:" type="text" />
              <Input
                id="deEmail"
                name="deEmail"
                placeholder="E-mail:"
                type="text"
              />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="comentario"
                name="comentario"
                placeholder="Comentário"
                rows="3"
              />
            </Form.Row>
            <h3>Destinatário</h3>
            <Form.Row>
              <Input
                id="paraNome"
                name="paraNome"
                placeholder="Para:"
                type="text"
              />
              <Input
                id="paraEmail"
                name="paraEmail"
                placeholder="E-mail:"
                type="text"
              />
            </Form.Row>

            <Form.Footer withStart>
              <button id="btn-submit" type="submit" form="formEmail">
                Adicionar
              </button>
            </Form.Footer>

            <Form.Row>
              <Form.Column>
                <h3>Lista de Destinatários:</h3>
                <Table>
                  <TableHeader
                    withPadding
                    titles={[
                      { fieldname: 'para', title: 'Para' },
                      { fieldname: 'email', title: 'E-mail' },
                      { fieldname: null, title: null },
                    ]}
                    cbSort={() => {}}
                  />
                  <tbody>
                    {whatsApp.map((email, index) => (
                      <RowMaster key={index}>
                        <td>{email.paraNome}</td>
                        <td>{email.paraEmail}</td>
                        <td>
                          <ActionMenu
                            data={email}
                            onLineRemove={() => handleRemover(index)}
                            onRemove={() => handleRemover(index)}
                          />
                        </td>
                      </RowMaster>
                    ))}
                  </tbody>
                </Table>
              </Form.Column>
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="button" onClick={handleConfirmar}>
              Enviar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { UsuarioWhatsApp };
