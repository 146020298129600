import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';
import { opImport } from '../../../lib/csv-core';
import { AppError } from '../../../errors/AppError';

import TitleBar from '../../TitleBar';
import { FormWrapper, Form, ModeloButton } from '..';
import { Table, RowMaster } from '../../Table';
import TableHeader from '../../Table/TableHeader';
import { ImportacaoInput } from './ImportacaoInput';
import Loading from '../../Loading';
import { opIdioma } from '../../../lib/inputOption';
import Select from '../Input/Select';

function Importacao() {
  const location = useLocation();
  const { parametros } = location.state;

  const formRef = useRef();
  const [error, setError] = useState(null);

  const [title, setTitle] = useState('');
  const [params, setParams] = useState({ op: '' });
  const [loading, setLoading] = useState(false);
  const [idioma, setIdioma] = useState(opIdioma[0].value);

  useEffect(() => {
    const fmtParametros = { ...parametros, idioma };
    setParams(fmtParametros);
  }, [parametros, idioma]);

  const handleModelo = useCallback(async () => {
    try {
      /** requisição de modelo para o servidor */
      const response = await api.get(`download/modelo-${params.op}.csv`, {
        responseType: 'blob',
      });

      /** baixa o arquivo na máquina local */
      const url = URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.download = `importacao-${params.op}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      AppError(err);
    }
  }, [params]);

  const handleUploadEnd = useCallback(
    ({ message = '', validation = null, filename }) => {
      setLoading(false);

      if (message || (validation && validation.length > 0)) {
        setError(validation);
        AppError(message);
      } else {
        toast.success(
          `Importação${title ? ` de ${title.toLowerCase()}` : ''} concluída!`
        );

        history.goBack();
      }
      /** Se possuir arquivo, faz dowload com informações não processadas */
      if (filename) {
        const link = document.createElement('a');

        link.setAttribute('href', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    [title]
  );

  /** define título da janela */
  useEffect(() => {
    let text;
    switch (params.op) {
      case opImport.PAGINA_ITEMPAGINA:
      case opImport.CATALOGO_ITEMPAGINA:
        text = 'Itens';
        break;
      case opImport.CATALOGO_NIVEL:
        text = 'Niveis';
        break;
      case opImport.CATALOGO_SERIE:
        text = 'Nº de Série';
        break;
      case opImport.EMPRESA_MENU:
        text = 'Menus';
        break;
      case opImport.EMPRESA_SISTEMA:
        text = 'Sistemas';
        break;
      case opImport.EMPRESA_LOCAL:
        text = 'Locais';
        break;
      case opImport.EMPRESA_MOBILIDADE:
        text = 'Mobilidade';
        break;
      case opImport.FABRICANTE:
        text = 'Fabriacantes';
        break;
      case opImport.MODELO:
        text = 'Modelos';
        break;
      case opImport.EMPRESA_MATERIAL_ERP:
        text = 'Materiais ERP';
        break;
      default:
        text = '';
        break;
    }

    setTitle(text);
  }, [params]);

  return (
    <>
      <TitleBar back={!!location.background} title={`Importação de ${title}`} />
      <FormWrapper>
        <Form ref={formRef} id="formImportacao" autoComplete="off">
          {params.op === opImport.CATALOGO_NIVEL && (
            <Form.Column alignCenter>
              <Form.Row>
                <Select
                  id="idioma"
                  name="idioma"
                  label="Idioma"
                  isSearchable={false}
                  defaultValue={opIdioma[0]}
                  options={opIdioma}
                  onChange={(e) => setIdioma(e.value)}
                  width={200}
                />
              </Form.Row>
            </Form.Column>
          )}
          <Form.Column>
            <Form.Column alignCenter>
              <ImportacaoInput
                id={`${title.toLowerCase()}Importacao`}
                name={`${title.toLowerCase()}Importacao`}
                parametros={params}
                onUploadStart={() => setLoading(true)}
                onUploadEnd={(data) => handleUploadEnd(data)}
                onClick={() => setError(null)}
              />
              <Form.Row>
                <ModeloButton type="button" onClick={handleModelo}>
                  Baixar Modelo
                </ModeloButton>
              </Form.Row>
            </Form.Column>
            <Form.Column alignCenter withPadding>
              {error && (
                <>
                  <h3>Erros de validação</h3>
                  <Table>
                    <TableHeader
                      withPadding
                      titles={[
                        { fieldname: 'linha', title: 'Linha' },
                        // colocar fields dinâmicos opcionais (ex.: idPagina dos niveis)?
                        { fieldname: 'message', title: 'Mensagem' },
                      ]}
                      cbSort={() => {}}
                    />
                    <tbody>
                      {error.map((err, idx) => (
                        <RowMaster key={idx} onClick={() => {}}>
                          <td>{err.linha}</td>
                          <td>
                            <ul>
                              {err.message.map((m, i) => (
                                <li key={i}>{m}</li>
                              ))}
                            </ul>
                          </td>
                        </RowMaster>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Form.Column>
          </Form.Column>
        </Form>
      </FormWrapper>
      <Loading visible={loading} />
    </>
  );
}

export { Importacao };
