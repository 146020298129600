import styled, { keyframes } from 'styled-components';
import { lighten, darken } from 'polished';
import { ReactSVG } from 'react-svg';

const slideInFromLeft = keyframes`
 from {
   transform: translateX(-100%);
 }

 to {
   transform: translateX(0);
 }
 `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px dashed #ccc;
  padding: 5px;
  width: 100%;
`;

export const ToolBar = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export const SVG = styled(ReactSVG)`
  svg {
    tspan,
    text {
      pointer-events: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
    }

    a:hover {
      > circle,
      rect {
        &.inject:hover {
          fill: ${lighten(0.1, '#538061')};
        }
      }

      > text {
        stroke: #f5f5f5;
        fill: #f5f5f5;
      }
    }

    a.highlight {
      > circle,
      rect {
        &.inject {
          fill: ${lighten(0.1, '#538061')};
        }
      }

      > text {
        stroke: #f5f5f5;
        fill: #f5f5f5;
      }
    }

    &.selected {
      stroke: red;
      fill: red;
    }

    circle,
    rect {
      &.inject {
        stroke: green;
      }

      &.warning {
        stroke: red;
      }
    }

    #linkGroup {
      a {
        &.selected {
          circle,
          rect {
            stroke: blue;
          }
        }

        &.focused {
          circle,
          rect {
            stroke-width: 1.8px;
          }
        }
      }
    }

    #ocrGroup {
      a {
        rect {
          fill: rgba(255, 255, 255, 0.1);
          stroke: none;
        }

        :hover {
          rect.success {
            stroke: green;
          }

          rect.warning {
            stroke: red;
          }
        }

        &.selected {
          > rect.success {
            stroke: green;
          }

          > rect.warning {
            stroke: red;
          }
        }

        > text.success {
          fill: green;
          stroke: green;
        }

        > text.warning {
          fill: red;
          stroke: red;
        }
      }
    }
  }
`;

export const EditorWrapper = styled.div`
  position: relative;
  border: 1px solid #ccc;
`;

export const ControlWrapper = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  z-index: 97;
`;

export const Control = styled.div`
  display: flex;
  position: relative;
  order: ${(props) => {
    switch (props.control) {
      case 'zoom':
        return '1;';
      case 'size':
        return '2;';
      default:
        return 0;
    }
  }};
  padding: 2px 5px;
  overflow: hidden;
`;

Control.BtnClose = styled.button`
  position: absolute;
  top: -2px;
  left: -2px;
  background: none;
  border: 0;

  color: #de3b3b;
  z-index: 2;
`;

/* animacao para o load do componente */
Control.Animated = styled.div`
  display: flex;
  flex-flow: row nowrap;

  height: 2.5rem;

  border: none;
  background: none;

  animation: ${slideInFromLeft} 0.5s ease-out 0s 1;
`;

/** Zoom */
Control.ZoomBtnAct = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  border: 1px solid #cbd0d8;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  background: #fff;
  color: #cbd0d8;

  :hover {
    background: ${darken(0.1, '#f2f5fc')};
    color: ${darken(0.1, '#1789dd')};
  }
`;

/** Size */
Control.SizeInput = styled.div`
  display: flex;

  div {
    padding: 1px;
  }
`;
