import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { initialParams } from '../../../../lib/reactRouter';

import { Container, List } from './styles';

function Listagem({ data }) {
  const location = useLocation();
  const history = useHistory();

  const { homeFilter } = useSelector((state) => state || {});

  const [listagem, setListagem] = useState([]);

  const carregaListagem = useCallback(() => {
    setListagem(data);
  }, [data]);

  useEffect(() => {
    carregaListagem();
  }, [data, carregaListagem]);

  /** rotina recursiva para geração da listagem */
  const handleListagem = useCallback(
    (nodes = [], index = 0) => {
      const searchLocation = [];

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros da rota para filtrar os níveis */
      const currNivel = Object.keys(paramRoute).reduce(
        (nivAcumulado, nivAtual) => {
          if (nivAtual.startsWith('IDN_')) {
            const [, key] = nivAtual.split('_');
            nivAcumulado.push({
              codigo: Number(key),
              id: Number(paramRoute[nivAtual]),
            });
          }

          // armazena parâmetros da rota para estrutura do novo link
          if (paramRoute[nivAtual])
            searchLocation.push(`${nivAtual}=${paramRoute[nivAtual]}`);

          return nivAcumulado;
        },
        []
      );

      /** filtra os níveis */
      if (currNivel[index]) {
        const { codigo, id } = currNivel[index];

        const { itens = [] } =
          nodes.find((n) => n.codigo === codigo && n.id === id) || {};

        /** processa os itens do nível */
        return handleListagem(itens, index + 1);
      }

      /** Separa itens de nível e itens de página */
      const itemNivel = nodes
        .filter((n) => n.tipo === 0)
        .map((n) => ({
          ...n,
          search: `${searchLocation.join('&')}&IDN_${n.codigo}=${n.id}`,
        }));

      const itemPagina = nodes
        .filter((n) => n.tipo === 1)
        .map((n) => ({
          ...n,
          search: `${searchLocation.join('&')}&IDP=${n.id}`,
        }));

      const fmtHomeFilter =
        homeFilter.value &&
        homeFilter?.paginas.map((n) => ({
          ...n,
          search: `${searchLocation.join('&')}&IDP=${n.idPagina}`,
        }));

      if (itemNivel.length === 1) {
        history.push(`${location.pathname}?${itemNivel[0].search}`);
        /** interrompe o processo */
        return '';
      }

      // console.log(fmtHomeFilter);

      return (
        <>
          {fmtHomeFilter ? (
            <List.WrapperImgCard>
              {fmtHomeFilter.map((item) => (
                <List.ImgCard key={item.idPagina}>
                  <Link
                    to={(linkLocation) => ({
                      ...linkLocation,
                      search: item.search,
                    })}
                  >
                    <img
                      loading="lazy"
                      src={item.imagem}
                      alt={item.descricao}
                    />
                    {item.descricao}
                  </Link>
                </List.ImgCard>
              ))}
            </List.WrapperImgCard>
          ) : (
            <>
              <List.WrapperCard>
                {itemNivel.map((item) => (
                  <List.Card key={item.id}>
                    <Link
                      to={(linkLocation) => ({
                        ...linkLocation,
                        search: item.search,
                      })}
                    >
                      {item.descricao}
                    </Link>
                  </List.Card>
                ))}
              </List.WrapperCard>
              <List.WrapperImgCard>
                {itemPagina.map((item) => (
                  <List.ImgCard key={item.id}>
                    <Link
                      to={(linkLocation) => ({
                        ...linkLocation,
                        search: item.search,
                      })}
                    >
                      <img loading="lazy" src={item.url} alt={item.descricao} />
                      {item.descricao}
                    </Link>
                  </List.ImgCard>
                ))}
              </List.WrapperImgCard>
            </>
          )}
        </>
      );
    },
    [location, history, homeFilter]
  );

  return (
    <Container>
      {listagem && <List>{handleListagem(listagem.treeView)}</List>}
    </Container>
  );
}

Listagem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default Listagem;
