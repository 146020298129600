import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';
import { AppError } from '../../../errors/AppError';

import { CSV, opExport, opImport } from '../../../lib/csv-core';
import { opMobilidade } from '../../../lib/inputOption';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaMobilidade() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [mobilidades, setMobilidades] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaMobilidade = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page, ...flt } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      ...flt,
    };

    api
      .get(`empresa/${idEmpresa}/mobilidade`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setMobilidades(
          response.data.mobilidade.map(({ situacao, ...mobilidade }) => ({
            ...mobilidade,
            situacao: opMobilidade[situacao].label || situacao,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/mobilidade/criar`, {
      background: location,
      idEmpresa,
    });
  }, [location, idEmpresa]);

  const handleEditar = useCallback(
    (idConfiguracaoIMEI) => {
      history.push(`/empresa/mobilidade/editar`, {
        background: location,
        idEmpresa,
        id: idConfiguracaoIMEI,
      });
    },
    [location, idEmpresa]
  );

  const handleRemover = useCallback(
    async (idConfiguracaoIMEI) => {
      if (window.confirm('Deseja realmente excluir a mobilidade?')) {
        try {
          await api.delete(
            `empresa/${idEmpresa}/mobilidade/${idConfiguracaoIMEI}`
          );

          carregaMobilidade();

          toast.success('Mobilidade excluída com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaMobilidade]
  );

  const handleVisualizar = useCallback(
    (e, idConfiguracaoIMEI) => {
      e.preventDefault();
      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idConfiguracaoIMEI);
      }
    },
    [handleEditar]
  );

  const handleImportar = useCallback(() => {
    history.push(`/empresa/mobilidade/importar`, {
      background: { ...location },
      parametros: { op: opImport.EMPRESA_MOBILIDADE, idEmpresa },
    });
  }, [idEmpresa, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_MOBILIDADE, { idEmpresa });
  }, [idEmpresa]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaMobilidade();
  }, [location, currentPage, carregaMobilidade]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Mobilidades` : ''}
        isSearchable
        filterSearchType="EMPRESA_MOBILIDADE"
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idConfiguracaoIMEI', title: 'Mobilidade' },
            { fieldname: 'coletor', title: 'Coletor' },
            { fieldname: 'IMEI', title: 'IMEI' },
            { fieldname: 'situacao', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {mobilidades.map((mobilidade) => (
            <RowMaster
              key={mobilidade.idConfiguracaoIMEI}
              onClick={(e) =>
                handleVisualizar(e, mobilidade.idConfiguracaoIMEI)
              }
            >
              <td>{mobilidade.idConfiguracaoIMEI}</td>
              <td>{mobilidade.coletor}</td>
              <td>{mobilidade.IMEI}</td>
              <td>{mobilidade.situacao}</td>
              <td>
                <ActionMenu
                  data={mobilidade}
                  onLineRemove={() =>
                    handleRemover(mobilidade.idConfiguracaoIMEI)
                  }
                  onEdit={() => handleEditar(mobilidade.idConfiguracaoIMEI)}
                  onRemove={() => handleRemover(mobilidade.idConfiguracaoIMEI)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaMobilidade };
