import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  height: 100vh;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f2f5fc;
  box-shadow: 0px 0px 10px #00000033;

  height: 600px;
  width: 400px;

  @media (max-width: 600px) {
    height: 100%;
    width: 100%;
  }
`;
