import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

export const Container = styled(Stack)`
  & .MuiTypography-root.MuiTypography-h6 {
    font-size: 14px;
  }
`;

export const SwitchComponent = styled(Switch)`
  & .MuiSwitch-switchBase {
    color: #fab29b;

    &:hover {
      background-color: #ff6d3c10;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: #ff6d3c;

    &:hover {
      background-color: #ff6d3c30;
    }
  }

  & .MuiSwitch-track {
    background-color: #fab29b;
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #ff6d3c;
  }

  & .MuiSwitch-switchBase {
    transition-duration: 500ms;
  }
`;
