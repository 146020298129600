import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { MdMoreVert } from 'react-icons/md';
import PropTypes from 'prop-types';

import { Container, Wrapper } from './styles';

const ActionTitleMenuRoot = forwardRef(({ Icon, children }, ref) => {
  const [visible, setVisible] = useState(false);

  const handleVisible = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  useImperativeHandle(ref, () => ({
    handleVisible,
  }));

  return (
    <Container onBlur={() => setVisible(false)}>
      <button
        type="button"
        id="btn-action"
        title="Opções"
        onClick={handleVisible}
      >
        <Icon size={25} />
      </button>

      <Wrapper visible={visible} onBlur={() => setVisible(false)}>
        <ul>{children}</ul>
      </Wrapper>
    </Container>
  );
});

ActionTitleMenuRoot.propTypes = {
  Icon: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

ActionTitleMenuRoot.defaultProps = {
  Icon: MdMoreVert,
};

export { ActionTitleMenuRoot };
