import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template:
    'b c c'
    'b c c'
    'a a a';
  grid-template-columns: minmax(900px, 1fr) 1fr 1fr;
  grid-template-rows: minmax(900px, 1fr) 1fr;
  position: relative;
  height: calc(100% - 1px);
  overflow: auto;
`;

export const MenuWrapper = styled.div`
  grid-area: a;
  padding: 10px 20px;

  button {
    border: 1px solid #eee;
    border-radius: 5px;
    background: #ccc;
    padding: 5px 10px;
    min-width: 150px;
    margin: 0 5px;
    color: #fff;
    font-weight: bolder;
    text-transform: uppercase;

    &#btn-back {
      border: none;
      background: none;
      color: #ccc;
    }
  }
`;

export const Wrapper = styled.div`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = styled.div`
  grid-area: c;
  /* display: flex;   */
  padding: 10px 20px;
  /* overflow: auto; */
  /* width: 100%;  */
  display: block;
  max-width: 100%;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 5px 2px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        padding: 0;
        margin: 0;
        border: 0;
        width: 100%;

        :focus {
          background: #dfe092;
        }
      }

      button {
        background: none;
        border: none;
        width: 100%;
        color: #de3b3b;
      }

      .resizer {
        display: inline-block;
        background: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        &.isResizing {
          background: red;
        }
      }
    }
  }
`;
