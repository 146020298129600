import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

export const Header = styled.div`
  padding: 20px 5px 10px;

  > strong {
    display: block;
    color: #2c405a;
    font-size: 14px;
    padding-top: 5px;
  }
`;

export const WrapperItems = styled.div`
  max-height: 600px;
  overflow: auto;
`;

export const Footer = styled.div``;

Footer.Action = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0;
    height: 42px;
    min-width: 150px;

    > svg {
      margin-right: 5px;
    }

    & + button {
      margin-right: 10px;
    }

    &#btn-new {
      background: #ff6d3c;
      color: #fff;
      transition: background 1s;

      &:hover {
        background: ${darken(0.05, '#ff6d3c')};
      }
    }
  }
`;

export const ActionNav = styled.div`
  display: flex;
  align-items: center;
  box-sizing: content-box;
`;

ActionNav.BtnSquare = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  height: 42px;
  width: 50px !important;

  + button {
    margin-left: 15px;
  }

  &.btn-nav-ref,
  &.btn-delete {
    background: none;
    color: #cbd0d8;
    transition: all 1s;

    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }
`;

ActionNav.BtnDefault = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  height: 42px;
  width: 180px;
`;
