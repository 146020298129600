import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import logotipo from '../../../assets/logo.svg';

import { signInRequest } from '../../../store/modules/auth/actions';

import { AppError } from '../../../errors/AppError';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

import { Container, Wrapper } from './styles';

const schema = Yup.object().shape({
  login: Yup.string().required('O campo "login" é obrigatório!'),
  password: Yup.string().required('O campo "senha" é obrigatório!'),
});

function UsuarioForgot() {
  const formRef = useRef(null);
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { login, password } = data;

        dispatch(signInRequest(login, password));
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [dispatch]
  );

  return (
    <Container>
      <img src={logotipo} alt="Assiste" />
      <Wrapper>
        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <h1>Bem-Vindo</h1>
          <Input
            id="login"
            name="login"
            // label="Login"
            type="text"
            placeholder="Login ou e-mail"
          />

          <Input
            id="password"
            name="password"
            // label="Senha"
            type="password"
            placeholder="Senha"
          />
          <button id="btn-forgot" type="button">
            Esqueceu a senha?
          </button>

          <button id="btn-submit" type="submit">
            {loading ? 'Carregando...' : 'Entrar'}
          </button>

          <span>
            Não tem uma conta?
            <button id="btn-signup" type="button">
              Registre-se
            </button>
          </span>
        </Form>
      </Wrapper>
    </Container>
  );
}

export { UsuarioForgot };
