import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MdLink,
  MdAccountCircle,
  MdModeEdit,
  MdList,
  MdSmartphone,
  MdMoveToInbox,
  // MdOutlineFileDownload,
  // MdFileUpload,
} from 'react-icons/md';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import { opTipoAcesso } from '../../../lib/const';
import { permiteEmpSerieLoteCatalogo } from '../../../lib/paramUtils';

import TitleBar from '../../../components/TitleBar';

import Pagination from '../../../components/EditorAI/Pagination';
import { Form } from '../../../components/Form';
import DebounceInput from '../../../components/Form/Input/Debounce';
import InputViewer from '../../../components/Form/Input/Viewer';

import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';

import ActionMenu from '../../../components/ActionMenu';
import { ActionTitleMenu } from '../../../components/ActionTitleMenu';

import { Container, Wrapper, Footer } from './styles';

// import { opImport } from '../../../lib/csv-core';

function EmpresaDetalhe() {
  const location = useLocation();
  const { id } = location.state;

  const { adm } = useSelector(
    (state) =>
      state.user.profile || {
        name: undefined,
        adm: undefined,
        userId: undefined,
      }
  );

  const formRef = useRef(null);

  const [empresa, setEmpresa] = useState(null);
  const [modelos, setModelos] = useState([]);
  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  /** pesquisa */
  const [pesquisa, setPesquisa] = useState(null);

  const carregaEmpresa = useCallback(() => {
    api.get(`empresa/${id}`).then((response) => {
      setEmpresa(response.data);
    });
  }, [id]);

  const carregaEmpresaModelo = useCallback(() => {
    api
      .get(`empresa/${id}/modelo`, {
        params: {
          page: currentPage,
          q: pesquisa || null,
        },
      })
      .then((response) => {
        setModelos(response.data.relacEmprModelo);
        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (currentPage > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [id, currentPage, pesquisa]);

  useEffect(() => {
    carregaEmpresa();
  }, [id, location, carregaEmpresa]);

  useEffect(() => {
    carregaEmpresaModelo();
  }, [location, id, currentPage, carregaEmpresaModelo]);

  const handleEditar = useCallback(() => {
    history.push('/empresa/editar', { background: location, id });
  }, [id, location]);

  const handleUsuario = useCallback(() => {
    history.push('/empresa/usuario', { idEmpresa: id });
  }, [id]);

  const handleSistema = useCallback(() => {
    history.push('/empresa/sistema', { idEmpresa: id });
  }, [id]);

  const handleLocal = useCallback(() => {
    history.push('/empresa/local', { idEmpresa: id });
  }, [id]);

  const handleMobilidade = useCallback(() => {
    history.push('/empresa/mobilidade', { idEmpresa: id });
  }, [id]);

  const handleNavegacaoClassificacao = useCallback(() => {
    history.push('/empresa/navegacao-classificacao', { idEmpresa: id });
  }, [id]);

  const handleNavegacaoNotificacao = useCallback(() => {
    history.push('/empresa/notificacao', { idEmpresa: id });
  }, [id]);

  const handleNavegacaoSugestao = useCallback(() => {
    history.push('/empresa/sugestao', { idEmpresa: id });
  }, [id]);

  const handleRetirada = useCallback(() => {
    history.push('/empresa/retirada', { idEmpresa: id });
  }, [id]);

  const handleParametro = useCallback(() => {
    history.push('/empresa/parametro', { idEmpresa: id });
  }, [id]);

  const handleRelacPaginaSistLoc = useCallback(() => {
    history.push('/empresa/catalogo', { idEmpresa: id });
  }, [id]);

  const handleSerieLote = useCallback(() => {
    history.push(`/empresa/serie-lote`, {
      idEmpresa: id,
    });
  }, [id]);

  const handleEmprModeloCriar = useCallback(() => {
    history.push('/empresa/modelo/criar', {
      background: location,
      idEmpresa: id,
    });
  }, [id, location]);

  // const handleImportarMaterialErp = useCallback(() => {
  //   history.push(`/empresa/importar-erp`, {
  //     background: location,
  //     parametros: { op: opImport.EMPRESA_MATERIAL_ERP },
  //     idEmpresa: id,
  //   });
  // }, [id, location]);

  // const handleEmprModeloCarregar = useCallback(() => {
  //   history.push('/empresa/modelo/carregar', {
  //     background: location,
  //     idEmpresa: id,
  //   });
  // }, [id, location]);

  const handleEmprModeloRemove = useCallback(
    async (idModelo, modeloExt) => {
      if (window.confirm('Deseja realmente excluir o relacionamento?')) {
        try {
          await api.delete(`empresa/${id}/modelo/${idModelo}/${modeloExt}`);

          carregaEmpresaModelo();

          toast.success('Relacionamento removido com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [id, carregaEmpresaModelo]
  );

  return (
    <Container>
      <TitleBar back>
        <ActionTitleMenu.Root>
          {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
            <ActionTitleMenu.Item
              title="Parâmetros"
              onHandler={handleParametro}
            />
          )}
          <ActionTitleMenu.Item
            title="Menu"
            onHandler={handleNavegacaoClassificacao}
          />
          <ActionTitleMenu.Item
            title="Notificações"
            onHandler={handleNavegacaoNotificacao}
          />
          <ActionTitleMenu.Item
            title="Sugestões"
            onHandler={handleNavegacaoSugestao}
          />
        </ActionTitleMenu.Root>
      </TitleBar>

      <h2>Detalhes</h2>
      <Wrapper>
        <Form initialData={empresa}>
          {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
            <Form.Row>
              <InputViewer id="idEmpresa" name="idEmpresa" label="Empresa" />
              <InputViewer
                id="dataDesa"
                name="dataDesa"
                label="Data Desativação"
              />
            </Form.Row>
          )}
          <Form.Row>
            <InputViewer id="descricao" name="descricao" label="Descrição" />
            <InputViewer id="email" name="email" label="E-mail" />
          </Form.Row>
          <Form.Separate height={80} />
          <Form.Footer withStart>
            <Form.Footer.Link type="button" onClick={handleEditar}>
              <MdModeEdit size={18} />
              Editar
            </Form.Footer.Link>
            <Form.Footer.Link type="button" onClick={handleUsuario}>
              <MdAccountCircle size={18} />
              Usuários
            </Form.Footer.Link>
            {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
              <>
                <Form.Footer.Link type="button" onClick={handleRetirada}>
                  <MdMoveToInbox size={18} />
                  Retiradas
                </Form.Footer.Link>
              </>
            )}
            <Form.Footer.Link type="button" onClick={handleSistema}>
              <MdList size={18} />
              Sistemas
            </Form.Footer.Link>
            <Form.Footer.Link type="button" onClick={handleLocal}>
              <MdList size={18} />
              Locais
            </Form.Footer.Link>
            {permiteEmpSerieLoteCatalogo() && (
              <Form.Footer.Link type="button" onClick={handleSerieLote}>
                <MdList size={18} />
                Série / Lote
              </Form.Footer.Link>
            )}
            <Form.Footer.Link type="button" onClick={handleRelacPaginaSistLoc}>
              <MdLink size={18} />
              Catálogo
            </Form.Footer.Link>
            <Form.Footer.Link type="button" onClick={handleMobilidade}>
              <MdSmartphone size={18} />
              Mobilidade
            </Form.Footer.Link>
            {/* <Form.Footer.Link type="button" onClick={handleImportarMaterialErp}>
              <MdOutlineFileDownload size={18} />
              Importar material erp
            </Form.Footer.Link> */}
          </Form.Footer>
        </Form>
      </Wrapper>

      <h2>Modelos relacionados</h2>
      <Form ref={formRef} autoComplete="off">
        <DebounceInput
          id="search"
          name="search"
          type="text"
          placeholder="Digite para pesquisar..."
          debounceTimeout={600}
          width={300}
          onChange={(e) => {
            e.preventDefault();
            setPesquisa(e.target.value);
          }}
        />
      </Form>

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idModelo', title: 'Modelo' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: 'modeloExt', title: 'Modelo Externo' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {modelos.map((modelo, index) => (
            <RowMaster key={index}>
              <td>{modelo.idModelo}</td>
              <td>{modelo.modelo.descricao}</td>
              <td>{modelo.modeloExt}</td>
              <td>
                {adm === 1 && (
                  <ActionMenu
                    data={modelo}
                    onLineRemove={() =>
                      handleEmprModeloRemove(modelo.idModelo, modelo.modeloExt)
                    }
                    onRemove={() =>
                      handleEmprModeloRemove(modelo.idModelo, modelo.modeloExt)
                    }
                  />
                )}
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />

      {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
        <Footer.Action>
          <button type="button" id="btn-new" onClick={handleEmprModeloCriar}>
            Adicionar
          </button>

          {/* <button
            type="button"
            id="btn-upload"
            onClick={handleEmprModeloCarregar}
          >
            <MdFileUpload size={18} />
            Carregar
          </button> */}
        </Footer.Action>
      )}
    </Container>
  );
}

export { EmpresaDetalhe };
