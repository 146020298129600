import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { MdModeEdit } from 'react-icons/md';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';

import Pagination from '../../../components/EditorAI/Pagination';
import { Form } from '../../../components/Form';
import InputViewer from '../../../components/Form/Input/Viewer';
import ActionMenu from '../../../components/ActionMenu';

import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';

import api from '../../../services/api';
import history from '../../../services/history';

import { CSV, opExport } from '../../../lib/csv-core';

import { Container } from './styles';

function FabricanteDetalhe() {
  const location = useLocation();
  const { id } = location.state;

  const [fabricante, setFabricante] = useState(null);

  const [materialFabricante, setMaterialFabricante] = useState([]);
  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaMaterialFabricante = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      fab: id,
    };

    api
      .get('material-fabricante', {
        params: configFiltro,
      })
      .then((response) => {
        setMaterialFabricante(
          response.data.materialFabricante.map((material) => {
            const { dataDesa, ...rst } = material;

            return {
              ...rst,
              dataDesa: dataDesa
                ? format(parseISO(dataDesa), 'dd/MM/yyyy')
                : null,
            };
          })
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id]);

  useEffect(() => {
    api.get(`fabricante/${id}`).then((response) => {
      setFabricante(response.data);
    });
  }, [id]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaMaterialFabricante();
  }, [location, currentPage, carregaMaterialFabricante]);

  const handleEditar = useCallback(() => {
    history.push('/fabricante/editar', { background: location, id });
  }, [location, id]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.MATERIAL_FABRICANTE, { idFabricante: id });
  }, [id]);

  const handleCriarMaterial = useCallback(() => {
    history.push(`/fabricante/material-fabricante/criar`, {
      background: location,
      idFabricante: id,
    });
  }, [location, id]);

  const handleEditarMaterial = useCallback(
    (idMaterial) => {
      history.push(`/fabricante/material-fabricante/editar`, {
        background: location,
        id: idMaterial,
        idFabricante: id,
      });
    },
    [location, id]
  );

  const handleRemoverMaterial = useCallback(
    async (idMaterial) => {
      if (window.confirm('Deseja realmente desativar o material fabricante?')) {
        try {
          await api.delete(`/material-fabricante/${idMaterial}`);

          carregaMaterialFabricante();

          toast.success('Material desativado com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaMaterialFabricante]
  );

  return (
    <Container>
      <TitleBar back onFileDownload={handleExportar} />

      <h2>Detalhes</h2>
      <Form.Detail>
        <Form initialData={fabricante}>
          <Form.Row>
            <InputViewer
              id="idFabricante"
              name="idFabricante"
              label="Fabricante"
            />
            <InputViewer id="descricao" name="descricao" label="Descrição" />
          </Form.Row>
          <Form.Separate height={80} />
          <Form.Footer withStart>
            <Form.Footer.Link type="button" onClick={handleEditar}>
              <MdModeEdit size={18} /> Editar
            </Form.Footer.Link>
          </Form.Footer>
        </Form>
      </Form.Detail>

      <TitleBar
        title="Materiais"
        width={2000}
        isSearchable
        onNew={handleCriarMaterial}
      />
      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'descricaoBR', title: 'Descrição (BR)' },
            { fieldname: 'descricaoEN', title: 'Descrição (EN)' },
            { fieldname: 'materialOrigem', title: 'Material Origem' },
            { fieldname: 'dataDesa', title: 'Desativado' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {materialFabricante.map((material, index) => (
            <RowMaster
              key={index}
              onClick={() => handleEditarMaterial(material.idMaterial)}
            >
              <td>{material.partnumber}</td>
              <td>{material.descricaoBR}</td>
              <td>{material.descricaoEN}</td>
              <td>
                {material.materialOrigem
                  ? material.materialOrigem.partnumber
                  : 'N/A'}
              </td>
              <td>{material.dataDesa}</td>
              <td>
                <ActionMenu
                  data={material}
                  onEdit={() => handleEditarMaterial(material.idMaterial)}
                  onRemove={() => handleRemoverMaterial(material.idMaterial)}
                  onLineRemove={() =>
                    handleRemoverMaterial(material.idMaterial)
                  }
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>
      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </Container>
  );
}

export default FabricanteDetalhe;
