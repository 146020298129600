import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';
import ActionMenu from '../../../components/ActionMenu';

import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaSerieLoteCatalogo() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [serieLoteCatalogo, setSerieLoteCatalogo] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaLote = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/serie-lote`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setSerieLoteCatalogo(
          response.data.serieLoteCatalogo.map((s) => ({
            ...s,
            fmtInstInc: format(parseISO(s.instInc), 'dd/MM/yyyy HH:mm:ss'),
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaLote();
  }, [location, currentPage, carregaLote]);

  const handleCriar = () => {
    history.push(`/empresa/serie-lote/criar`, {
      background: location,
      idEmpresa,
    });
  };

  const handleEditar = useCallback(
    (idLote) => {
      history.push(`/empresa/serie-lote/editar`, {
        background: location,
        idEmpresa,
        idLote,
      });
    },
    [location, idEmpresa]
  );

  const handleVisualizar = useCallback(
    (e, idLote) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idLote);
      }
    },
    [handleEditar]
  );

  const handleRemover = useCallback(
    async (idLote) => {
      try {
        await api.delete(`empresa/${idEmpresa}/serie-lote/${idLote}`);

        carregaLote();

        toast.success('Catálogo excluído com sucesso!');
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa, carregaLote]
  );

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Série - Lote` : ''}
        isSearchable
        onNew={handleCriar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'numeEqui', title: 'Equipamento' },
            { fieldname: 'numeroLote', title: 'Lote' },
            { fieldname: 'instInc', title: 'Criado em' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {serieLoteCatalogo.map((serieLote, index) => (
            <RowMaster
              key={index}
              onClick={(e) => handleVisualizar(e, serieLote.idLote)}
            >
              <td>{serieLote.serieCatalogo.partnumber}</td>
              <td>{serieLote.codiEqui}</td>
              <td>{serieLote.numeroLote}</td>
              <td>{serieLote.fmtInstInc}</td>
              <td>
                <ActionMenu
                  data={serieLote}
                  onLineEdit={() => handleEditar(serieLote.idLote)}
                  onEdit={() => handleEditar(serieLote.idLote)}
                  onRemove={() => handleRemover(serieLote.idLote)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaSerieLoteCatalogo };
