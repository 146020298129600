import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  & > ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;

    & > li {
      display: inline-block;
      padding: 10px 15px;
      background: #ffffff;
      border: 1px solid #cbd0d8;
      color: #a0a9b9;

      :first {
        border-top-left-radius: 5px;
      }

      & > a {
        cursor: pointer;
      }

      &.disabled {
        color: #cbd0d8;
      }

      &.active {
        border: 1px solid #ff6d3c;
        background: #ff6d3c;
        color: #fff;
      }
    }
  }
`;
