import styled from 'styled-components';
import { darken } from 'polished';

export const CartModal = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`,
  },
}))`
  position: absolute;
  min-width: 400px;
  min-height: 250px;

  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 10px #00000033;
  background: #fff;
  border-radius: 10px;

  color: #2c405a;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 25px 25px;
  position: relative;
`;

Content.Move = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  transition: 1s all;
  cursor: grab;

  :hover {
    color: #ff6d3c;
  }

  :active {
    cursor: grabbing;
  }
`;

Content.BtnClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #cbd0d8;
  border: 0;
  background: none;
  transition: 1s all;

  :hover {
    color: ${darken(0.05, '#cbd0d8')};
  }
`;

Content.BtnAddCart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6d3c;
  border: 0;
  border-radius: 5px;
  color: #fff;
  height: 40px;
  transition: background 1s;

  > svg {
    margin-right: 5px;
  }

  :hover {
    background: ${darken(0.05, '#ff6d3c')};
  }
`;

Content.Title = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c405a;

  user-select: text;

  > strong {
    font-size: 16px;
  }
`;

Content.Title.Espec = styled.span`
  color: rgba(44, 64, 90, 0.5);
  font-style: italic;
`;

Content.Title.Note = styled.div`
  border-radius: 5px;
  background: #f8f8f8;
  padding: 5px 10px;
  color: rgba(44, 64, 90, 0.5);
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
`;

Content.Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > form {
    flex: 1;
    font-size: 14px;
    color: #2c405a;

    label {
      font-size: 12px;
      color: #2c405a;
      font-weight: bolder;
      text-transform: uppercase;
    }

    > strong {
      font-size: 16px;
    }

    > p {
      border-radius: 5px;
      background: #f8f8f8;
      padding: 5px 10px;
      color: rgba(44, 64, 90, 0.5);
      text-align: center;
      font-size: 12px;
      margin-top: 5px;
    }
  }
`;

Content.Form.Error = styled.span`
  width: 100%;
  text-align: center;
  color: #e95938;
  font-style: italic;
  font-size: 12px;
`;

Content.Form.Quantity = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex: 1;
    justify-content: center;

    input {
      text-align: center;
    }

    > button {
      border: 0;
      background: none;
      margin: 0 10px;
      padding: 5px 10px;
    }
  }
`;
