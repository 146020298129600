import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Checkbox from '@mui/material/Checkbox';

import { AppError } from '../../../../errors/AppError';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import Pagination from '../../../../components/EditorAI/Pagination';
import { Form } from '../../../../components/Form';
import { Table, RowDefault } from '../../../../components/Table';
import TableHeader from '../../../../components/Table/TableHeader';

function CatalogoRelacArranjoItem() {
  const location = useLocation();
  const { idCatalogo, idArranjo, idSerie } = location.state;

  const [arranjo, setArranjo] = useState(null);
  const [itens, setItens] = useState([]);
  const [arranjoItemPagina, setArranjoItemPagina] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaItemPagina = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    /** carrega itens do catálogo */
    api
      .get(`catalogo-filtro/${idCatalogo}/item`, {
        params: configFiltro,
      })
      .then((response) => {
        setItens(response.data.itemPagina);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [idCatalogo, location]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaItemPagina();
  }, [location, currentPage, carregaItemPagina]);

  useEffect(() => {
    /** carrega informações do arranjo */
    api
      .get(
        `catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}`
      )
      .then((response) => {
        setArranjo(response.data);
      });

    /** carrega relacionamentos existentes */
    api
      .get(
        `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}/relacionamento-item`
      )
      .then((response) => {
        const relacionamento = response.data.map((relac) => ({
          idItem: relac.idItem,
          idMaterial: relac.idMaterial,
          idPagina: relac.idPagina,
        }));

        setArranjoItemPagina(relacionamento);
      });
  }, [idCatalogo, idArranjo, idSerie]);

  const handleConfirmar = useCallback(async () => {
    try {
      await api.post(
        `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}/relacionamento-item`,
        {
          itens: arranjoItemPagina,
        }
      );

      history.goBack();

      toast.success('Relacionamentos salvos com sucesso!');
    } catch (err) {
      AppError(err);
    }
  }, [idCatalogo, idArranjo, idSerie, arranjoItemPagina]);

  const handleAlterar = useCallback(
    (checked, { idItem, materialFabricante, idPagina }) => {
      const item = itens.find(
        (e) =>
          e.idItem === idItem &&
          e.materialFabricante.idMaterial === materialFabricante.idMaterial &&
          e.idPagina === idPagina
      );

      if (checked && item) {
        setArranjoItemPagina((state) => [
          ...state,
          {
            idItem: item.idItem,
            idMaterial: item.materialFabricante.idMaterial,
            idPagina: item.idPagina,
          },
        ]);
      } else {
        setArranjoItemPagina((state) => [
          ...state.filter(
            (r) =>
              !(
                r.idItem === idItem &&
                r.idMaterial === materialFabricante.idMaterial &&
                r.idPagina === idPagina
              )
          ),
        ]);
      }
    },
    [itens]
  );

  return (
    <>
      <TitleBar
        back={!!location.background}
        title={`Relac. Arranjo / Item ${
          arranjo && `- (${arranjo.nome} / ${arranjo.serie.numeroSerie})`
        }`}
        isSearchable
        // onFileDownload={handleCsv}
        // onFileUpload={handleImportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'relac', title: '' },
            { fieldname: 'idPagina', title: 'Página' },
            { fieldname: 'idItem', title: 'Item' },
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'descricao', title: 'Descricao' },
            { fieldname: 'nota', title: 'Nota' },
            { fieldname: 'especTecnica', title: 'Espec. Técnica' },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {itens &&
            itens.map((item, index) => (
              <RowDefault key={index}>
                <td>
                  <Checkbox
                    id={`relac-${item.idItem}`}
                    name="relac"
                    checked={
                      !!arranjoItemPagina.find(
                        (r) =>
                          r.idItem === item.idItem &&
                          r.idMaterial === item.materialFabricante.idMaterial &&
                          r.idPagina === item.idPagina
                      )
                    }
                    disableRipple
                    onChange={({ target }) =>
                      handleAlterar(target.checked, item)
                    }
                  />
                </td>
                <td>{item.idPagina}</td>
                <td>{item.idItem}</td>
                <td>{item.materialFabricante.partnumber}</td>
                <td>{item.materialFabricante.descricao}</td>
                <td>{item.nota}</td>
                <td>{item.especTecnica}</td>
              </RowDefault>
            ))}
        </tbody>
      </Table>
      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />

      <Form.Separate withFlex />
      <Form.Footer>
        <button id="btn-cancel" type="button" onClick={() => history.goBack()}>
          Cancelar
        </button>
        <button id="btn-submit" type="submit" onClick={handleConfirmar}>
          Confirmar
        </button>
      </Form.Footer>
    </>
  );
}

export { CatalogoRelacArranjoItem };
