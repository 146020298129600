// import React from 'react';

import debounce from 'lodash.debounce';

// import { useDispatch } from 'react-redux';
import { opAtivoInativo } from './const';

import api from '../services/api';

import { AppError } from '../errors/AppError';
// import { handleHomeFilter } from '../store/modules/homeFilter/actions';

const fnDebounce = (fn) => debounce(fn, 600);

export const getMaterial = fnDebounce((props, cb) => {
  const { id: idCatalogo, value } = props;

  api
    .get(`/catalogo-filtro/${idCatalogo}/material-catalogo`, {
      params: {
        q: value,
      },
    })
    .then((response) => {
      const {
        materialERP,
        materialFabricante,
        // searchHandle,
        // paginas,
        // paginasERP,
      } = response.data;

      // console.log(materialERP, materialFabricante);

      /** REDIRECIONAMENTO MATERIAL_FABRICANTE */
      if (materialFabricante.length > 1) {
        // console.log('Lista paginas miniatura');
        const unique = [];
        const duplicates = [];

        materialFabricante.forEach((i) => {
          const { descricaoPagina } = i.paginaCatalogo;
          if (!unique.includes(descricaoPagina)) {
            unique.push(descricaoPagina);
          } else {
            duplicates.push(descricaoPagina);
          }
        });

        // console.log(unique);
        // console.log(duplicates);

        if (unique.length === 1)
          console.log('Redireciona para a pagina com os itens');
      }
      if (materialFabricante.length === 1) {
        console.log('Redireciona para o desenho com os itens');
      }

      // const fmtMaterial = [
      //   {
      //     label: 'Material Fabricante',
      //     options: materialFabricante.map(
      //       ({
      //         idPagina,
      //         idItem,
      //         materialFabricante: { idMaterial, partnumber, descricao },
      //         paginaCatalogo: {
      //           descricaoPagina,
      //           fmtPaginaCatalogoNivel,
      //           caminho,
      //         },
      //       }) => ({
      //         label: (
      //           <>
      //             <strong id="grey-bold-async-select">
      //               {partnumber} - {descricao}{' '}
      //               <strong id="black-bold-async-select">|</strong>{' '}
      //             </strong>
      //             <span id="grey-italic-async-select">
      //               {descricaoPagina}{' '}
      //               <strong id="black-bold-async-select">|</strong>{' '}
      //               {fmtPaginaCatalogoNivel}
      //             </span>
      //             <img alt={descricaoPagina} prop={caminho} />
      //           </>
      //         ),
      //         value: `MFABR.${idPagina}.${idItem}.${idMaterial}`,
      //       })
      //     ),
      //   },
      //   {
      //     label: 'Material ERP',
      //     options: materialERP.map(
      //       ({
      //         idPagina,
      //         idItem,
      //         materialERP: { idMaterialERP, codimate, descricao },
      //         paginaCatalogo: {
      //           descricaoPagina,
      //           fmtPaginaCatalogoNivel,
      //           caminho,
      //         },
      //       }) => ({
      //         // label: `${codimate} - ${descricao} | ${descricaoPagina} | ${fmtPaginaCatalogoNivel}`,
      //         label: (
      //           <>
      //             <strong id="grey-bold-async-select">
      //               {codimate} - {descricao}{' '}
      //               <strong id="black-bold-async-select">|</strong>{' '}
      //             </strong>
      //             <span id="grey-italic-async-select">
      //               {descricaoPagina}{' '}
      //               <strong id="black-bold-async-select">|</strong>{' '}
      //               {fmtPaginaCatalogoNivel}
      //             </span>
      //             <img alt={descricaoPagina} prop={caminho} />
      //           </>
      //         ),
      //         value: `MERP.${idPagina}.${idItem}.${idMaterialERP}`,
      //       })
      //     ),
      //   },
      // ];

      const fmtMaterial = [
        {
          label: 'Material Fabricante',
          options: materialFabricante.map(
            ({
              idPagina,
              idItem,
              materialFabricante: { idMaterial, partnumber, descricao },
              paginaCatalogo: {
                descricaoPagina,
                fmtPaginaCatalogoNivel,
                caminho,
              },
            }) => ({
              label: {
                partnumber,
                descricao,
                descricaoPagina,
                fmtPaginaCatalogoNivel,
                caminho,
              },
              value: `MFABR.${idPagina}.${idItem}.${idMaterial}`,
            })
          ),
        },
        {
          label: 'Material ERP',
          options: materialERP.map(
            ({
              idPagina,
              idItem,
              materialERP: { idMaterialERP, codimate, descricao },
              paginaCatalogo: {
                descricaoPagina,
                fmtPaginaCatalogoNivel,
                caminho,
              },
            }) => ({
              label: {
                codimate,
                descricao,
                descricaoPagina,
                fmtPaginaCatalogoNivel,
                caminho,
              },
              value: `MERP.${idPagina}.${idItem}.${idMaterialERP}`,
            })
          ),
        },
      ];

      cb(fmtMaterial);
      // useDispatch(handleHomeFilter(searchHandle, paginas, paginasERP));
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getCatalogo = fnDebounce((value, cb) => {
  api
    .get('catalogo', {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtCatalogo = response.data.catalogo.map((catalogo) => {
        const { modelos, fabricante } = catalogo.arquivo;

        const modelo = modelos.map((m) => m.descricao).join(',');

        return {
          value: catalogo.idCatalogo,
          label: `${catalogo.idCatalogo} - ${catalogo.nome} (${catalogo.versao}) | Mod.: ${modelo} - Fabr.: ${fabricante.descricao}`,
        };
      });

      cb(fmtCatalogo);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getPagina = fnDebounce((props, cb) => {
  const { id: idCatalogo, value } = props;

  api
    .get(`catalogo/${idCatalogo}/pagina`, {
      params: { tpo: '1,3', sto: '2', q: value },
    })
    .then((response) => {
      const fmtPagina = response.data.paginaCatalogo.map((pagina) => ({
        value: pagina.idPagina,
        label: `${pagina.idPagina} - ${pagina.descricao}`,
      }));

      cb(fmtPagina);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getSistema = fnDebounce((props, cb) => {
  const { id: idEmpresa, value } = props;

  api
    .get(`empresa/${idEmpresa}/sistema`, {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtSistema = response.data.sistema
        .filter((sistema) => !sistema.dataDesa)
        .map((sistema) => ({
          value: sistema.codiSist,
          label: `${sistema.codiSist} - ${sistema.descricao}`,
        }));

      cb(fmtSistema);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getLocal = fnDebounce((props, cb) => {
  const { id: idEmpresa, value } = props;

  api
    .get(`empresa/${idEmpresa}/local`, {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtLocal = response.data.local
        .filter((local) => !local.dataDesa)
        .map((local) => ({
          value: local.codiLoca,
          label: `${local.codiLoca} - ${local.descricao}`,
        }));

      cb(fmtLocal);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getModelo = fnDebounce((value, cb) => {
  /** pesquisa modelos */
  api
    .get('modelo', {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtModelo = response.data.modelo.map((modelo) => ({
        value: modelo.idModelo,
        label: modelo.descricao,
      }));

      cb(fmtModelo);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getFabricante = fnDebounce((value, cb) => {
  /** pesquisa fabricantes */
  api
    .get('fabricante', {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtFabricante = response.data.fabricante.map((fabricante) => ({
        value: fabricante.idFabricante,
        label: fabricante.descricao,
      }));

      cb(fmtFabricante);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getNivelClassificacao = fnDebounce((props, cb) => {
  const { id: idCatalogo, value } = props;

  /** pesquisa nivel classificacao do catálogo */
  api
    .get(`catalogo/${idCatalogo}/nivel-classificacao`, {
      params: {
        q: `%${value}`,
      },
    })
    .then((response) => {
      const fmtNivelClassificacao = response.data.nivelClassificacao.map(
        ({ nivel, descricao }) => ({
          value: nivel,
          label: `${nivel} - ${descricao}`,
        })
      );

      cb(fmtNivelClassificacao);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getNivel = fnDebounce((props, cb) => {
  const { id: idCatalogo, nivel, value } = props;

  /** pesquisa nivel do catálogo */
  api
    .get(`/catalogo/${idCatalogo}/nivel`, {
      params: {
        nvl: nivel,
        q: `%${value}`,
      },
    })
    .then((response) => {
      const fmtNivel = response.data.niveis.map(
        ({ idNivel, codigo, descricao }) => ({
          value: idNivel,
          label: `${codigo} - ${descricao}`,
        })
      );

      cb(fmtNivel);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const setNivel = async (props) => {
  const { e, id: idCatalogo, value, sync = true } = props;

  const [codigo, descricao] = value.split(';');

  const response = await api.post(`catalogo/${idCatalogo}/nivel`, {
    nivel: e.props.id,
    codigo,
    descricao,
  });

  if (sync) {
    const [nivel] = response.data;
    if (nivel) {
      e.select.onChange({
        value: nivel.idNivel,
        label: `${nivel.codigo} - ${nivel.descricaoBR || nivel.descricaoEN}`,
      });
    }
  }
};

export const getSerie = fnDebounce((props, cb) => {
  const { id: idCatalogo, value } = props;

  /** pesquisa numero de sérire do catálogo */
  api
    .get(`catalogo/${idCatalogo}/serie-catalogo`, {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtSerie = response.data.serie.map(({ idSerie, numeroSerie }) => ({
        value: idSerie,
        label: numeroSerie,
      }));

      cb(fmtSerie);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getArranjo = fnDebounce((props, cb) => {
  const { id: idCatalogo, value } = props;

  /** pesquisa numero de sérire do catálogo */
  api
    .get(`catalogo-filtro/${idCatalogo}/arranjo-catalogo`, {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtArranjo = response.data.arranjoCatalogo.map(
        ({ idArranjo, nome, serie: { idSerie, numeroSerie } }) => ({
          value: `${idSerie}.${idArranjo}`,
          label: `N/S: ${numeroSerie} - ${nome}`,
        })
      );

      cb(fmtArranjo);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getEmpresa = fnDebounce((value, cb) => {
  api
    .get('empresa', {
      params: { q: `%${value}` },
    })
    .then((response) => {
      const fmtEmpresa = response.data.empresa
        .sort((a, b) => {
          // ordenação por descrição
          const fmtA = a.descricao.toUpperCase();
          const fmtB = b.descricao.toUpperCase();

          if (fmtA < fmtB) return -1;

          if (fmtA > fmtB) return 1;

          return 0;
        })
        .map((emp) => ({
          value: emp.idEmpresa,
          label: emp.descricao,
        }));

      cb(fmtEmpresa);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getCatalogoPartnumber = fnDebounce((props, cb) => {
  const { id: idEmpresa, value } = props;

  api
    .get(`empresa/${idEmpresa}/catalogo`, {
      params: { q: `%${value}` },
    })
    .then((response) => {
      /** formata partnumbers da série do catálogo  */
      const fmtPartnumber = response.data.catalogo.reduce(
        (arrCata, curCata) => {
          const { series } = curCata;

          series.forEach((curSeca) => {
            if (curSeca.partnumber)
              arrCata.push({
                value: `${curCata.idCatalogo}.${curSeca.idSerie}`,
                label: `${curSeca.partnumber} | ${curCata.idCatalogo} - ${curCata.nome} (${curCata.versao})`,
              });
          });

          return arrCata;
        },
        []
      );

      cb(fmtPartnumber);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getTemplate = fnDebounce((value, cb) => {
  /** pesquisa fabricantes */
  api
    .get('template-catalogo', {
      params: { q: `%${value}`, sto: opAtivoInativo.ATIVO },
    })
    .then((response) => {
      const fmtTemplate = response.data.template.map((template) => ({
        value: template.idTemplate,
        label: template.nome,
      }));

      cb(fmtTemplate);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});

export const getMaterialFabricante = fnDebounce((props, cb) => {
  const { id: idFabricante, value } = props;

  api
    .get('material-fabricante', {
      params: { q: `%${value}`, fab: Number(idFabricante) },
    })
    .then((response) => {
      const fmtMaterialFabricante = response.data.materialFabricante.map(
        (material) => ({
          value: material.idMaterial,
          label: `${material.partnumber} | ${material.descricaoBR} (${material.descricaoEN})`,
        })
      );

      cb(fmtMaterialFabricante);
    })
    .catch((err) => {
      cb([]);
      AppError(err);
    });
});
