import styled from 'styled-components';

import signInBackground from '../../../assets/sign-in-background.png';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100vh;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f2f5fc;
  box-shadow: 0px 0px 10px #00000033;
  width: 100%;
  max-width: 400px;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: contain;
  margin: 5px;
`;
