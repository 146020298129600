import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { CSV, opExport, opImport } from '../../../lib/csv-core';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaLocal() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [locais, setLocais] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaLocal = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/local`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setLocais(
          response.data.local.map(({ dataDesa, ...item }) => ({
            ...item,
            dataDesa: dataDesa ? 'Inativo' : 'Ativo',
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/local/criar`, { background: location, idEmpresa });
  }, [location, idEmpresa]);

  const handleEditar = useCallback(
    (codiLoca) => {
      history.push(`/empresa/local/editar`, {
        background: location,
        idEmpresa,
        id: codiLoca,
      });
    },
    [location, idEmpresa]
  );

  const handleRemover = useCallback(
    async (codiLoca) => {
      if (window.confirm('Deseja realmente excluir o local?')) {
        try {
          await api.delete(`empresa/${idEmpresa}/local/${codiLoca}`);

          carregaLocal();

          toast.success('Local excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaLocal]
  );

  const handleVisualizar = useCallback(
    (e, codiLoca) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(codiLoca);
      }
    },
    [handleEditar]
  );

  const handleImportar = useCallback(() => {
    history.push(`/empresa/local/importar`, {
      background: { ...location },
      parametros: { op: opImport.EMPRESA_LOCAL, idEmpresa },
    });
  }, [idEmpresa, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_LOCAL, { idEmpresa });
  }, [idEmpresa]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaLocal();
  }, [location, currentPage, carregaLocal]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Locais` : ''}
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'codiLoca', title: 'Local' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: 'dataDesa', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {locais.map((local) => (
            <RowMaster
              key={local.codiLoca}
              onClick={(e) => handleVisualizar(e, local.codiLoca)}
            >
              <td>{local.codiLoca}</td>
              <td>{local.descricao}</td>
              <td>{local.dataDesa}</td>
              <td>
                <ActionMenu
                  data={local}
                  onLineRemove={() => handleRemover(local.codiLoca)}
                  onEdit={() => handleEditar(local.codiLoca)}
                  onRemove={() => handleRemover(local.codiLoca)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaLocal };
