import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { opEmpresa } from '../../../lib/inputOption';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
  qtdeUsuario: Yup.number()
    .typeError('O campo "Qtde Usuário" deve ser preenchido')
    .required('O campo "Qtde Usuário" deve ser preenchido'),
  tipo: Yup.number()
    .typeError('O campo "Tipo Empresa" deve ser preenchido')
    .required('O campo "Tipo Empresa" deve ser preenchido'),
});

function EmpresaCriar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { descricao, email, qtdeUsuario, tipo } = data;

      await api.post('empresa', {
        descricao,
        email,
        qtdeUsuario,
        tipo: String(tipo),
      });

      toast.success('Empresa cadastrada com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Nova empresa" />
      <FormWrapper>
        <Form
          id="formEmpresa"
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="descricao" name="descricao" label="Descrição" />
            </Form.Row>
            <Form.Row>
              <Input id="email" name="email" label="E-mail" />
            </Form.Row>
            <Form.Row>
              <Select
                id="tipo"
                name="tipo"
                label="Tipo de Empresa"
                isSearchable={false}
                defaultValue={opEmpresa[0]}
                options={opEmpresa}
              />
              <Input
                id="qtdeUsuario"
                name="qtdeUsuario"
                label="Qtde. Usuário"
                type="number"
                defaultValue={5}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresa">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaCriar };
