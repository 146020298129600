import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSpring } from 'react-spring';
import {
  MdClose,
  MdShoppingCart,
  MdLens,
  MdLabelOutline,
} from 'react-icons/md';

import { toast } from 'react-toastify';
import { PDF, opPdfExport } from '../../../../lib/pdf-core';

import { initialParams } from '../../../../lib/reactRouter';
import { useScrollLock } from '../../../../lib/pageUtils';
import { redirectToCart } from '../../../../lib/paramUtils';
import {
  adicionaCarrinhoArrayRequest,
  adicionaCarrinhoRequest,
  sharedManagementSet,
} from '../../../../store/modules/cart/actions';

import history from '../../../../services/history';
import api from '../../../../services/api';

import ItemCart from '../../../../components/ItemCart';

import { emailTipo } from '../../../Email';

import {
  Container,
  Wrapper,
  ZoomArea,
  ItemArea,
  Table,
  Info,
  SVG,
  NoteContainer,
  NoteContent,
  Notes,
  NotesModal,
  NoteHeader,
} from './styles';

const Pagina = forwardRef(
  ({ id, idPagina, zoomInRef, zoomOutRef, zoomResetRef }, ref) => {
    const carrinho = useSelector(
      (state) =>
        state.cart.items.map((item) => ({
          ...item,
          ...item.materialFabricante,
        })) || {}
    );

    const { token } = useSelector((state) => state.auth || {});
    const { sharedManagement } = useSelector((state) => state.cart || {});

    const containerRef = useRef();
    const mappingRef = useRef([]);
    const svgRef = useRef();

    const tableRef = useRef();

    const observerTarget = useRef(null);

    const location = useLocation();

    const [pagina, setPagina] = useState(null);
    const [itensPagina, setItensPagina] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();

    const [itemNote, setItemNote] = useState([]);
    const [lengthNote, setLengthNote] = useState(0);

    const [showNote, setShowNote] = useState(false);

    const [itemInfo, setItemInfo] = useState(null);
    const [itemCart, setItemCart] = useState(null);

    const [paginaMaximizada, setPaginaMaximizada] = useState(false);

    // const [zoomStep, setZoomStep] = useState(5);

    const [elementToZoom, setElementToZoom] = useState();

    const dispatch = useDispatch();

    const { lockScroll, unlockScroll } = useScrollLock();

    useEffect(() => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      api.get(`catalogo-publico/${id}/pagina/${idPagina}`).then((response) => {
        const { nota } = response.data;

        setPagina(response.data);
        setItemNote(() => (nota ? nota.split(';').filter(Boolean) : []));
        setLengthNote(nota ? nota.length : 0);
      });

      api
        .get(`/catalogo-publico/${id}/pagina/${idPagina}/item`, {
          params: {
            page: 1,
            ...paramRoute,
          },
        })
        .then((response) => {
          setItensPagina(
            response.data.responseItemPagina.map((item) => {
              const { idItem, materialEmpresa, materialFabricante } = item;

              /** formata IDITEM */
              const fmtItem =
                String(idItem).startsWith('@') || String(idItem) === '0'
                  ? ''
                  : idItem;

              /** formata PARTNUMBER */
              const fmtPartnumber = String(
                materialFabricante.partnumber
              ).startsWith('@')
                ? ''
                : materialFabricante.partnumber;

              return {
                ...item,
                materialEmpresa,
                ...materialFabricante,
                partnumber: fmtPartnumber,
                itemResum: fmtItem,

                // Concatena os materiais da empresa
                codimateResum:
                  materialEmpresa &&
                  materialEmpresa
                    .map((material) => material.codimate)
                    .join(', '),
                selecionado: false,
                exibido: false,
              };
            })
          );

          setTotalPage(response.data.total);
          setCurrentPage(response.data.pagAtual);

          if (response.data.total === 0) {
            setTotalPage(0);
            setCurrentPage(0);
          }
        });

      window.addEventListener('resize', () => {
        /** reposiciona o desenho conforme a página é redimensionada */
        if (zoomResetRef.current && zoomResetRef.current.onclick) {
          zoomResetRef.current.onclick();
        }
      });

      // return window.removeEventListener('resize', handleSVGResize);
    }, [location, id, idPagina, zoomResetRef]);

    const handleImprimirPagina = useCallback(
      async (isSendMail = false, setLoading) => {
        /** cria PDF com a página do catálogo */
        PDF.criarExportacao(
          opPdfExport.CATALOGO_PAGINA,
          {
            idCatalogo: id,
            idPagina,
          },
          (filename) => {
            if (isSendMail) {
              /** carrega foto para anexar no formulário de envio de e-mail */
              // api.get(filename).then((response) => {
              //   const blobFile = new Blob([response.data], {
              //     type: 'application/pdf',
              //   });

              //   history.push('/enviar-email', {
              //     background: location,
              //     tipo: emailTipo.EMAIL_PAGINA,
              //     data: { attachment: blobFile },
              //     filename,
              //   });
              // });

              history.push('/enviar-email', {
                background: location,
                tipo: emailTipo.EMAIL_PAGINA,
                data: { filename },
              });
            } else {
              const link = document.createElement('a');
              link.setAttribute('href', filename);
              link.setAttribute('target', '_blank');
              link.setAttribute('rel', 'noopener noreferrer');

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          },
          // cbBefore
          () => setLoading(true),
          // cbAfter
          () => setLoading(false)
        );
      },
      [location, id, idPagina]
    );

    const handleRedimensionarPagina = useCallback(
      (maximizado) => {
        setTimeout(() => {
          // if (maximizado) {
          //   const { width } = containerRef.current.getBoundingClientRect();

          //   setZoomStep(Math.trunc(width * 0.02));
          // } else {
          //   setZoomStep(5);
          // }

          if (zoomResetRef.current) {
            zoomResetRef.current.onclick();
          }
        }, 100);

        setPaginaMaximizada(maximizado);
      },
      [zoomResetRef]
    );

    useImperativeHandle(ref, () => ({
      handleImprimirPagina,
      handleRedimensionarPagina,
    }));

    const handleItemMouseEnter = useCallback((idItem) => {
      mappingRef.current.map((relacionamento) => {
        if (relacionamento.idItem === idItem) {
          relacionamento.node.classList.add('selecting');
        }
        return relacionamento;
      });
    }, []);

    const handleItemMouseLeave = useCallback((idItem) => {
      mappingRef.current.map((relacionamento) => {
        if (relacionamento.idItem === idItem) {
          if (!relacionamento.selected && !relacionamento.inCart) {
            relacionamento.node.classList.remove('selecting');
          }
        }
        return relacionamento;
      });
    }, []);

    const handleRelacionar = useCallback(() => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const paramRoute = {
        ...Object.fromEntries(paramQuery),
      };

      const { IDI: itemSearch } = paramRoute || {};

      /** SELECTING: ITEM QUE ESTAO NO CARRINHO */
      mappingRef.current.forEach((mapeamento) => {
        if (
          carrinho.find(
            (itemCarrinho) =>
              Number(itemCarrinho.idCatalogo) === id &&
              Number(itemCarrinho.idPagina) === idPagina &&
              itemCarrinho.idItem === mapeamento.idItem
          )
        ) {
          mapeamento.node.classList.add('selecting');
          mapeamento.inCart = true;
        }
      });

      /** SELECTING: ITEM PESQUISADO */
      let itemPaginaExt = null;
      if (itemSearch) {
        const [
          ,
          idPaginaSearch,
          idItemSearch,
          idMaterialSearch,
        ] = itemSearch.split('.');

        if (idItemSearch && idMaterialSearch) {
          itemPaginaExt = itensPagina.find(
            (i) =>
              String(idItemSearch) === String(i.idItem) &&
              Number(idPaginaSearch) === Number(i.idPagina)
          );

          if (itemPaginaExt) {
            const mapping = mappingRef.current.find(
              (item) => String(item.idItem) === String(itemPaginaExt.idItem)
            );
            if (mapping) mapping.node.onclick();
          }
        }
      }
      setItemCart(itemPaginaExt);
    }, [location, id, idPagina, carrinho, itensPagina]);

    const handleItemFechar = useCallback(
      (data) => {
        const { idItem } = itemCart;

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );

        if (itemIndex >= 0) {
          itensPagina[itemIndex].exibido = false;
          itensPagina[itemIndex].selecionado = false;

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );
          relacionamentos.forEach((relacionamento) => {
            relacionamento.selected = false;
            if (data) {
              relacionamento.inCart = true;
            }

            if (!relacionamento.inCart) {
              relacionamento.node.classList.remove('selecting');
            }
          });
        }

        setItemCart(null);
      },
      [itemCart, itensPagina]
    );

    const handleAdicionar = useCallback(
      (itemAdicionar) => {
        const {
          idItem,
          idMaterial,
          quantidade,
          materialEmpresa,
        } = itemAdicionar;

        zoomResetRef.current.onclick();

        itensPagina.forEach((item) => {
          if (item.selecionado !== false) item.selecionado = false;
        });

        itemAdicionar.selecionado = true;
        itemAdicionar.imagemCatalogo = pagina.url;

        /** limpa seleções anteriores que não foram adicionadas no carrinho */
        mappingRef.current.forEach((relacionamento) => {
          if (!relacionamento.inCart) {
            relacionamento.selected = false;
            relacionamento.node.classList.remove('selecting');
          }
        });

        const relacionamentos = mappingRef.current.filter(
          (item) => item.idItem === idItem
        );

        relacionamentos.forEach((relacionamento) => {
          relacionamento.node.classList.add('selecting');
          relacionamento.selected = true;
        });

        /** verifica quantidade de material ERP e parâmetro para adicionar automaticamente para o carrinho */
        if (materialEmpresa.length === 1 && redirectToCart()) {
          /** caso existe material relacionado, pega o código */
          const { codimate } = materialEmpresa[0];
          const imagemCatalogo = pagina.url;
          dispatch(
            adicionaCarrinhoRequest(
              id,
              idPagina,
              idItem,
              idMaterial,
              quantidade,
              codimate,
              imagemCatalogo
            )
          );

          relacionamentos.forEach((relacionamento) => {
            relacionamento.inCart = true;
          });

          return;
        }

        /** caso tenha mais de um material relacionado, abre o modal */
        setItemCart(itemAdicionar);
      },
      [id, idPagina, dispatch, itensPagina, pagina, zoomResetRef]
    );

    const handleLinkClick = useCallback(
      (e, idItem) => {
        if (e) e.preventDefault();

        // const [, , , idItem] = e.target.parentElement.href.baseVal.split('/');

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );

        if (itemIndex >= 0) {
          itensPagina[itemIndex].exibido = true;

          /** limpa seleção da tabela */
          itensPagina.forEach((item) => {
            if (item.idItem !== idItem) item.selecionado = false;
          });

          // const relacionamentos = mappingRef.current.filter(
          //   (item) => item.idItem === idItem
          // );
          // relacionamentos.forEach((relacionamento) => {
          //   relacionamento.node.classList.add('selecting');
          //   relacionamento.selected = true;
          // });

          /** oculta informativo e exibe o modal */
          setItemInfo(null);

          handleAdicionar(itensPagina[itemIndex]);

          // setItemCart(itensPagina[itemIndex]);
        }
      },
      [itensPagina, handleAdicionar]
    );

    const handleLinkMouseEnter = useCallback(
      (e, idItem) => {
        e.preventDefault();

        // const [, , , idItem] = e.target.href.baseVal.split('/');

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );
        if (itemIndex >= 0) {
          itensPagina[itemIndex].selecionado = true;

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );
          relacionamentos.forEach((relacionamento) => {
            relacionamento.node.classList.add('selecting');
          });

          const {
            left: leftContainer,
            top: topContainer,
          } = containerRef.current.getBoundingClientRect();

          const a = e.target.getBoundingClientRect();

          setItemInfo({
            ...itensPagina[itemIndex],
            posX: a.left - leftContainer - 90,
            posY: a.top - topContainer + a.height + 5,
          });

          /** reposiciona listagem de itens */
          if (tableRef.current) {
            let currRow = null;

            tableRef.current.childNodes.forEach((node) => {
              const [, rowIdItem, rowIdMaterial] = node.id.split('-');

              if (
                String(itensPagina[itemIndex].idItem) === String(rowIdItem) &&
                Number(itensPagina[itemIndex].idMaterial) ===
                  Number(rowIdMaterial)
              ) {
                currRow = node;
              }
            });

            if (currRow) {
              const { height = 0 } = tableRef.current.getBoundingClientRect();

              tableRef.current.scrollTo({
                top: currRow.offsetTop - Math.trunc(height / 2),
                behavior: 'smooth',
              });
            }
          }
        }
      },
      [itensPagina]
    );

    const handleLinkMouseOut = useCallback(
      (e, idItem) => {
        e.preventDefault();

        const itemIndex = itensPagina.findIndex(
          (item) => item.idItem === idItem
        );
        if (itemIndex >= 0) {
          /** limpa seleção da tabela */
          itensPagina.forEach((item) => {
            if (!item.exibido) item.selecionado = false;
          });

          const relacionamentos = mappingRef.current.filter(
            (item) => item.idItem === idItem
          );

          relacionamentos.forEach((relacionamento) => {
            if (!relacionamento.selected && !relacionamento.inCart) {
              relacionamento.node.classList.remove('selecting');
            }
          });

          /** oculta informativo */
          setItemInfo(null);
        }
      },
      [itensPagina]
    );

    const handleLink = useCallback(
      (childNodes) => {
        childNodes.forEach((node) => {
          if (node.nodeName === 'a') {
            const [, , , idItem] = node.href.baseVal.split('/');

            mappingRef.current.push({
              idItem,
              node,
              inCart: false,
              selected: false,
            });

            // node.addEventListener('click', (e) => handleLinkClick(e, idItem));
            node.onclick = (e) => handleLinkClick(e, idItem);

            node.addEventListener('mouseenter', (e) =>
              handleLinkMouseEnter(e, idItem)
            );

            node.addEventListener('mouseout', (e) => {
              /** previne a saída quando o mouse está sobre o texto */
              if (e.relatedTarget.parentElement !== e.target.parentElement) {
                handleLinkMouseOut(e, idItem);
              }
            });
          }

          /** processa nós do svg */
          if (node.hasChildNodes()) {
            return handleLink(node.childNodes);
          }

          return true;
        });
      },
      [handleLinkClick, handleLinkMouseEnter, handleLinkMouseOut]
    );

    const editorSvg = useMemo(
      () => (
        <SVG
          src={pagina && pagina.url}
          loading={() => <span>Loading</span>}
          useRequestCache={false}
          afterInjection={(err, svg) => {
            if (svg) {
              mappingRef.current = [];
              handleLink(svg.childNodes);
              handleRelacionar();

              // svg.setAttribute('width', '420');
              // svg.setAttribute('height', '390');

              // svg.setAttribute('viewBox', '0 0 750 850');
              svg.removeAttribute('width');
              svg.removeAttribute('height');

              svgRef.current = svg;

              setTimeout(() => {
                if (zoomResetRef.current) {
                  zoomResetRef.current.onclick();
                }
              }, 100);
            }
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pagina, handleLink, zoomResetRef]
    );

    const [noteStyle, spring] = useSpring(() => ({
      config: { mass: 2, tension: 80, friction: 25, precision: 0.001 },
    }));

    useEffect(() => {
      if (showNote) {
        lockScroll();
      } else {
        unlockScroll();
      }

      spring.start({
        opacity: showNote ? 1 : 0,
        scale: showNote ? 1 : 0,
      });

      return () => unlockScroll();
    }, [showNote, spring, lockScroll, unlockScroll]);

    useEffect(() => {
      if (itemCart) {
        window.onclick = (e) => {
          if (
            e.target.tagName === 'svg:circle' ||
            e.target.tagName === 'svg:square'
          ) {
            setElementToZoom(e);
          }
          setElementToZoom(null);
        };
        setElementToZoom(null);
      }
    }, [itemCart]);

    const renderizaNivel = (item) => {
      const niveis = [];
      for (let i = 0; i < item.nivelConjuntoItemPagina; i += 1) {
        niveis.push(
          <span key={i}>
            <MdLens size={8} id="lens" title="Item do conjunto" />
          </span>
        );
      }
      return niveis;
    };

    const carregaItensObserver = useCallback(() => {
      if (currentPage < totalPage) {
        api
          .get(`/catalogo-publico/${id}/pagina/${idPagina}/item`, {
            params: {
              page: currentPage + 1,
            },
          })
          .then((response) => {
            const add = response.data.responseItemPagina.map((item) => {
              const { idItem, materialEmpresa, materialFabricante } = item;

              /** formata IDITEM */
              const fmtItem =
                String(idItem).startsWith('@') || String(idItem) === '0'
                  ? ''
                  : idItem;

              /** formata PARTNUMBER */
              const fmtPartnumber = String(
                materialFabricante.partnumber
              ).startsWith('@')
                ? ''
                : materialFabricante.partnumber;

              return {
                ...item,
                materialEmpresa,
                ...materialFabricante,
                partnumber: fmtPartnumber,
                itemResum: fmtItem,

                // Concatena os materiais da empresa
                codimateResum:
                  materialEmpresa &&
                  materialEmpresa
                    .map((material) => material.codimate)
                    .join(', '),
                selecionado: false,
                exibido: false,
              };
            });

            add.forEach((addItem) => {
              if (!itensPagina.find((item) => addItem.idItem === item.idItem)) {
                setItensPagina((prevState) => [...prevState, ...add]);
              }
            });

            setCurrentPage(response.data.pagAtual);
          });
      }
    }, [currentPage, totalPage, id, idPagina, itensPagina]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            carregaItensObserver();
          }
        },
        { threshold: 0 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      // return () => {
      //   if (observerTarget.current) {
      //     /** Ponto de desativacao do observer */
      //     observer.unobserve(observerTarget.current)
      //   }
      // }
    }, [observerTarget, carregaItensObserver]);

    // console.log(itensPagina);

    return (
      <Container>
        {pagina && (
          <Wrapper>
            <ZoomArea ref={containerRef}>
              <TransformWrapper
                initialScale={2}
                initialPositionX={-500}
                initialPositionY={-100}
                centerOnInit
                centerZoomedOut
                doubleClick={{
                  step: 0.7,
                  animationTime: 500,
                  animationType: 'easeInOutQuad',
                }}
                // wheel={{ step: zoomStep }}
              >
                {({
                  zoomIn,
                  zoomOut,
                  resetTransform,
                  setTransform,
                  centerView,
                }) => {
                  if (
                    zoomInRef &&
                    zoomInRef.current &&
                    !zoomInRef.current.onclick
                  ) {
                    zoomInRef.current.onclick = () => zoomIn(2, 500, 'easeOut');
                  }

                  if (
                    zoomOutRef &&
                    zoomOutRef.current &&
                    !zoomOutRef.current.onclick
                  ) {
                    zoomOutRef.current.onclick = () =>
                      zoomOut(0.2, 500, 'easeOut');
                  }

                  if (
                    zoomResetRef &&
                    zoomResetRef.current &&
                    !zoomResetRef.current.onclick
                  ) {
                    zoomResetRef.current.onclick = () =>
                      resetTransform(500, 'easeOut');
                  }

                  if (itemCart && elementToZoom) {
                    /*
                    setTransform(
                      -Math.abs(elementToZoom.layerX),
                      -Math.abs(elementToZoom.layerY),
                      2,
                      1000,
                      'easeInOutQuad'
                    );
                    centerView(1.5, 1000, 'easeInOutQuad');
                    */
                  }

                  return (
                    <TransformComponent>
                      {currentPage === totalPage ? (
                        editorSvg
                      ) : (
                        <span>Loading</span>
                      )}
                    </TransformComponent>
                  );
                }}
              </TransformWrapper>

              {itemNote.length > 0 && (
                <NoteContainer
                  onMouseDown={() => setShowNote((state) => !state)}
                >
                  <NoteContent>
                    <Notes>
                      <p>
                        {itemNote[0]}
                        {itemNote[1] && '...'}
                      </p>
                    </Notes>
                  </NoteContent>
                </NoteContainer>
              )}

              <NotesModal
                style={{
                  opacity: noteStyle.opacity,
                  scale: noteStyle.scale,
                }}
                sizenote={lengthNote}
              >
                <NoteHeader>
                  <strong>NOTAS DA PÁGINA</strong>
                  <button
                    type="button"
                    onClick={() => setShowNote((state) => !state)}
                  >
                    <MdClose size={25} />
                  </button>
                </NoteHeader>

                <ul>
                  {!!itemNote.length &&
                    itemNote.map((item, index) => <p key={index}>{item}</p>)}
                </ul>
              </NotesModal>

              {itemInfo && (
                <Info posX={itemInfo.posX} posY={itemInfo.posY}>
                  <strong>{itemInfo.descricao}</strong>
                  <p>{itemInfo.partnumber}</p>
                </Info>
              )}

              {itemCart && (
                <ItemCart
                  containerRef={containerRef}
                  item={itemCart}
                  onClose={handleItemFechar}
                />
              )}
            </ZoomArea>
          </Wrapper>
        )}

        {!paginaMaximizada && (
          <ItemArea>
            {itensPagina && (
              <Table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Partnumber</th>
                    <th>Código ERP</th>
                    <th>Descrição</th>
                    <th>Quantidade</th>
                    <th>Nota</th>
                    <th>Adicionar</th>
                  </tr>
                </thead>
                <tbody ref={tableRef}>
                  {itensPagina.map((item, index) => (
                    <Table.Row
                      id={`row-${item.idItem}-${item.idMaterial}`}
                      onMouseEnter={() => handleItemMouseEnter(item.idItem)}
                      onMouseLeave={() => handleItemMouseLeave(item.idItem)}
                      selected={item.selecionado}
                      key={index}
                    >
                      <td>
                        <div>
                          {renderizaNivel(item)}
                          {item.isConjunto && (
                            <span>
                              <MdLabelOutline size={12} title="Conjunto" />
                            </span>
                          )}
                          {item.itemResum}
                        </div>
                      </td>
                      <td>{item.partnumber}</td>
                      <td>{item.codimateResum}</td>
                      <td>{item.descricao}</td>
                      <td>{item.quantidade}</td>
                      <td>{item.nota}</td>
                      <td>
                        <Table.BtnCart
                          inCart={
                            !!carrinho.find(
                              (itemCarrinho) =>
                                Number(itemCarrinho.idCatalogo) ===
                                  Number(item.idCatalogo) &&
                                Number(itemCarrinho.idPagina) ===
                                  Number(item.idPagina) &&
                                String(itemCarrinho.idItem) ===
                                  String(item.idItem) &&
                                Number(itemCarrinho.idMaterial) ===
                                  Number(item.idMaterial)
                            )
                          }
                          type="button"
                          onClick={() => handleAdicionar(item)}
                        >
                          <MdShoppingCart size={20} />
                        </Table.BtnCart>
                      </td>
                    </Table.Row>
                  ))}
                </tbody>
              </Table>
            )}
            {currentPage < totalPage && <div ref={observerTarget} />}
          </ItemArea>
        )}
      </Container>
    );
  }
);

Pagina.propTypes = {
  id: PropTypes.number.isRequired,
  idPagina: PropTypes.number.isRequired,
  zoomInRef: PropTypes.instanceOf(Object).isRequired,
  zoomOutRef: PropTypes.instanceOf(Object).isRequired,
  zoomResetRef: PropTypes.instanceOf(Object).isRequired,
};

export default Pagina;
