import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { MdModeEdit, MdLogin, MdLock } from 'react-icons/md';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';

import { Form } from '../../../../components/Form';
import InputViewer from '../../../../components/Form/Input/Viewer';

import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';

import { Container, Wrapper } from '../../detalhe/styles';
import { opTipoAcesso } from '../../../../lib/const';

function EmpresaUsuarioDetalhe() {
  const location = useLocation();
  const { idEmpresa, idUsuario } = location.state;

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const [usuario, setUsuario] = useState(null);
  const [usuarioLogin, setUsuarioLogin] = useState([]);

  const carregaUsuario = useCallback(() => {
    api.get(`empresa/${idEmpresa}/usuario/${idUsuario}`).then((response) => {
      setUsuario(response.data);
    });

    api
      .get(`usuario/${idUsuario}/login`, {
        params: {
          top: 5,
        },
      })
      .then((response) => {
        setUsuarioLogin(
          response.data.usuarioLogin.map((login) => ({
            ...login,
            instInc: format(
              parseISO(login.instInc),
              "dd/MM/yyyy 'às' HH:mm:ss"
            ),
            dataDesa: login.dataDesa
              ? format(parseISO(login.dataDesa), "dd/MM/yyyy 'às' HH:mm:ss")
              : null,
          }))
        );
      });
  }, [idEmpresa, idUsuario]);

  useEffect(() => {
    carregaUsuario();
  }, [idUsuario, location, carregaUsuario]);

  const handleEditar = useCallback(() => {
    history.push(`/empresa/usuario/editar`, {
      background: location,
      idEmpresa: usuario.idEmpresa,
      idUsuario,
    });
  }, [location, usuario, idUsuario]);

  const handleLogin = useCallback(() => {
    history.push('/empresa/usuario/login', { idEmpresa, idUsuario });
  }, [idEmpresa, idUsuario]);

  const handleTrocarSenha = useCallback(() => {
    history.push(`/empresa/usuario/trocar`, {
      background: location,
      id: idUsuario,
    });
  }, [location, idUsuario]);

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      <Wrapper>
        <Form initialData={usuario}>
          <Form.Row>
            <InputViewer id="nome" name="nome" label="Descrição" />
            <InputViewer id="idEmpresa" name="idEmpresa" label="Empresa" />
            <InputViewer id="email" name="email" label="E-mail" />
          </Form.Row>
          {/* <Form.Row>
            <InputViewer id="documento" name="documento" label="Documento" />
            <InputViewer
              id="utilizaMobile"
              name="utilizaMobile"
              label="Utiliza Mobile"
            />
          </Form.Row>
          <Form.Row>
            {usuario &&
              usuario.complemento.map((comp) => (
                <InputViewer
                  id={comp.valor}
                  name={comp.valor}
                  label={comp.descricao}
                />
              ))}
          </Form.Row> */}
          <Form.Separate height={80} />
          <Form.Footer withStart>
            <Form.Footer.Link type="button" onClick={handleEditar}>
              <MdModeEdit size={18} />
              Editar
            </Form.Footer.Link>
            {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && (
              <>
                <Form.Footer.Link type="button" onClick={handleLogin}>
                  <MdLogin size={18} />
                  Login
                </Form.Footer.Link>
                <Form.Footer.Link type="button" onClick={handleTrocarSenha}>
                  <MdLock size={18} />
                  Senha
                </Form.Footer.Link>
              </>
            )}
          </Form.Footer>
        </Form>
      </Wrapper>

      <TitleBar title="Autenticação" subtitle="Últimos 5 acessos" />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'instInc', title: 'Acessado em' },
            { fieldname: 'dataDesa', title: 'Desabilitado em' },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {usuarioLogin.map((login) => (
            <RowMaster key={login.idLogin}>
              <td>{login.instInc}</td>
              <td>{login.dataDesa}</td>
            </RowMaster>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export { EmpresaUsuarioDetalhe };
