import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { CSV, opExport } from '../../../../lib/csv-core';

import TitleBar from '../../../../components/TitleBar';
import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';
import ActionMenu from '../../../../components/ActionMenu';

import Pagination from '../../../../components/EditorAI/Pagination';

function EmpresaCatalogoMaterial() {
  const location = useLocation();
  const { idEmpresa, idCatalogo } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [catalogo, setCatalogo] = useState(null);
  const [materialFabricante, setMaterialFabricante] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaMaterialERP = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/catalogo/${idCatalogo}/material-erp`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setCatalogo(response.data.catalogo);
        setMaterialFabricante(response.data.materialFabricante);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [idEmpresa, idCatalogo, location]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaMaterialERP();
  }, [location, currentPage, carregaMaterialERP]);

  const handleGerenciar = useCallback(
    (idMaterial) => {
      history.push(`/empresa/catalogo/material/editar`, {
        background: location,
        idEmpresa,
        idCatalogo,
        idMaterial,
      });
    },
    [location, idEmpresa, idCatalogo]
  );

  const handleVisualizar = useCallback(
    (e, idMaterial) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleGerenciar(idMaterial);
      }
    },
    [handleGerenciar]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.MATERIAL_ERP, { idEmpresa, idCatalogo });
  }, [idCatalogo, idEmpresa]);

  return (
    <>
      <TitleBar
        back
        title={
          empresa && catalogo
            ? `${empresa.descricao} / ${catalogo.nome} / Material`
            : ''
        }
        isSearchable
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: 'materialERP', title: 'Qtde. Material ERP' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {materialFabricante.map((material, index) => (
            <RowMaster
              key={index}
              onClick={(e) => handleVisualizar(e, material.idMaterial)}
            >
              <td>{material.partnumber}</td>
              <td>{material.descricao}</td>
              <td>{material.qtdMaterialERP}</td>
              <td>
                <ActionMenu
                  data={material}
                  onLineManager={() => handleGerenciar(material.idMaterial)}
                  onManager={() => handleGerenciar(material.idMaterial)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export default EmpresaCatalogoMaterial;
