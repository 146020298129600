import styled from 'styled-components';

const Menu = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => (props.hidden ? '100%' : '100%')};
  z-index: 97;
`;

Menu.Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
`;

const Fixed = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  height: 100%;
  width: 100%;
`;

export { Menu, Fixed };
