export function globParamSuccess(params) {
  return {
    type: '@param/GLOB_PARAM_SUCCESS',
    payload: { params },
  };
}

export function globParamFailure() {
  return {
    type: '@param/GLOB_PARAM_FAILURE',
  };
}
