import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  MdDone,
  MdClose,
  MdDelete,
  MdLink,
  MdFormatIndentIncrease,
  MdFileDownload,
  MdCropFree,
  MdCenterFocusStrong,
  MdExposureNeg1,
  MdExposurePlus1,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdUnfoldMore,
  MdUnfoldLess,
  MdRotateLeft,
  MdRotateRight,
  MdStayPrimaryLandscape,
  MdStayPrimaryPortrait /* MdZoomIn, MdZoomOut, MdSettingsOverscan */,
  MdRadioButtonUnchecked,
  MdCheckBoxOutlineBlank,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import MappingModal from './Table/MappingModal';

import {
  Container,
  Wrapper,
  ToolBar,
  /* Reporter, ZoomWrapper, ZoomControl, */ SVG,
} from './styles';

function EditorArea({
  catalogo,
  pagina,
  itensPagina,
  onMapping,
  onSave,
  onCancel,
}) {
  /** controles de posicionamento e selecção */
  const selectingRef = useRef(false);

  const minCoordRef = useRef({ x: 0, y: 0 });
  const maxCoordRef = useRef({ x: 0, y: 0 });
  const rectCoordRef = useRef({ x: 0, y: 0 });

  const actionRef = useRef();
  const linkRef = useRef();

  const mappingRef = useRef([]);
  const linkMappingRef = useRef([]);
  const selectedRef = useRef([]);

  const svgRef = useRef();
  const rootRef = useRef();
  const selectorRef = useRef();

  const rootBoundingRef = useRef();
  const svgBoundingRef = useRef();

  const rootXRef = useRef(0);
  const rootYRef = useRef(0);
  const rootScaleRef = useRef(1);
  const rootRotateRef = useRef(0);

  const ACTION_STATE = {
    selected: 'SELECTED',
    moved: 'MOVED',
    multiSelect: 'MULTISELECT',
  };

  // Controle do editor
  const INITIAL_STATE = {
    catalogId: 0,
    path: '',
    pageNum: 0,
    state: 0,
    type: 0,
    inDesign: false,
  };

  const [editor, setEditor] = useState(INITIAL_STATE);
  const [mappingTable, setMappingTable] = useState([]);
  const [table, setTable] = useState([]);
  const [show, setShow] = useState(false);

  const [currentLinkType, setCurrentLinkType] = useState(0);
  const currentLinkTypeRef = useRef(0);

  useEffect(() => {
    if (pagina) {
      const { idPagina, urlEdicao, situacao, tipo } = pagina;

      const { idCatalogo } = catalogo;

      setEditor({
        catalogId: idCatalogo,
        pageNum: idPagina,
        path: urlEdicao,
        state: situacao,
        type: tipo,
        inDesign: true,
      });
    }
  }, [catalogo, pagina]);

  useEffect(() => {
    if (itensPagina) {
      setTable(
        itensPagina.map((item) => ({
          ...item,
          quantidade: item.quantidade ? Number(item.quantidade) : 0,
        }))
      );
    }
  }, [itensPagina]);

  // const [ status, setStatus ] = useState('');
  // const [ scale, setScale ] = useState(1);

  // const mappingText = (childNodes, textContent) => {
  //   Array.from(childNodes).map(node => {

  //     if (node.nodeName === 'svg:tspan') {
  //       const { left, top } = svgRef.getBoundingClientRect();
  //       const { x, y, width, height } = node.getBoundingClientRect();

  //       const xMin = (x-left) - 5;
  //       const xMax = (xMin + width) + 5;
  //       const yMax = (y-top) + 5;
  //       const yMin = (yMax - height) - 5;

  //       const rec = textContent.find(el => (xMin <= el.tx &&
  //                                           xMax >= el.tx &&
  //                                           yMax >= el.ty &&
  //                                           yMin <= el.ty));

  //       if (rec) node.textContent = rec.txt;
  //     }

  //     if (node.hasChildNodes()) {
  //       return mappingText(node.childNodes, textContent);
  //     }
  //   });
  // }

  const mappingElement = useCallback(
    (lstNode) => {
      Array.from(lstNode).map((node) => {
        if (
          node.nodeName === 'svg:path' ||
          node.nodeName === 'svg:rect' ||
          node.nodeName === 'svg:image' ||
          node.nodeName === 'svg:tspan' ||
          node.nodeName === 'a'
        ) {
          const { left, top } = svgRef.current.getBoundingClientRect();
          const { x, y, width, height } = node.getBoundingClientRect();

          /** tratamento para links já carregados */
          if (node.nodeName === 'a') {
            node.addEventListener('click', (e) => e.preventDefault());
          }

          node.id = Math.random();

          mappingRef.current.push({
            node,
            x: x - left,
            y: y - top,
            width,
            height,
          });
        }

        if (node.hasChildNodes()) {
          return mappingElement(node.childNodes);
        }

        return node;
      });
    },
    [svgRef, mappingRef]
  );

  const createLink = useCallback((x, y, radius, id, text, href) => {
    /** verifica se o grupo dos links já foi criado */
    linkRef.current = document.getElementById('linkGroup');
    if (!linkRef.current) {
      linkRef.current = document.createElementNS(
        svgRef.current.namespaceURI,
        'g',
        svgRef.current
      );
      linkRef.current.id = 'linkGroup';

      /** adiciona elementos no nó raiz */
      svgRef.current.appendChild(linkRef.current);
    }

    const link = document.createElementNS(
      svgRef.current.namespaceURI,
      'a',
      svgRef.current
    );
    link.id = id;
    link.setAttribute('xlink:href', href);
    link.setAttribute('target', '_blank');
    // código preventivo para o link não ser executado no design
    link.addEventListener('click', (e) => e.preventDefault());
    linkRef.current.appendChild(link);

    let linkForm;
    let linkText;

    const linkFormWidth = text.length * 10 + 20;

    switch (currentLinkTypeRef.current) {
      case 1:
        /** cria retangulo */
        linkForm = document.createElementNS(
          svgRef.current.namespaceURI,
          'rect',
          svgRef.current
        );

        linkForm.setAttribute('class', 'inject');
        linkForm.setAttribute('stroke', '#ccc');
        linkForm.setAttribute('fill', '#fff');
        linkForm.setAttribute('x', x - linkFormWidth / 2);
        linkForm.setAttribute('y', y - 10);
        linkForm.setAttribute('height', '20');
        linkForm.setAttribute('width', linkFormWidth);
        linkForm.setAttribute('rx', '5');
        linkForm.setAttribute('ry', '5');
        link.appendChild(linkForm);

        linkText = document.createElementNS(
          svgRef.current.namespaceURI,
          'text',
          svgRef.current
        );

        linkText.setAttribute(
          'x',
          linkForm.x.baseVal.value + linkForm.width.baseVal.value / 2
        );
        linkText.setAttribute(
          'y',
          linkForm.y.baseVal.value + linkForm.height.baseVal.value / 2
        );
        linkText.setAttribute('text-anchor', 'middle');
        linkText.setAttribute('dy', '.3em');
        linkText.setAttribute('font-size', '100%');
        linkText.textContent = text;
        link.appendChild(linkText);
        break;

      default:
        linkForm = document.createElementNS(
          svgRef.current.namespaceURI,
          'circle',
          svgRef.current
        );
        linkForm.setAttribute('class', 'inject');
        linkForm.setAttribute('stroke', '#ccc');
        linkForm.setAttribute('fill', '#fff');
        linkForm.setAttribute('cx', x);
        linkForm.setAttribute('cy', y);
        linkForm.setAttribute('r', radius);
        link.appendChild(linkForm);

        linkText = document.createElementNS(
          svgRef.current.namespaceURI,
          'text',
          svgRef.current
        );
        linkText.setAttribute('x', x);
        linkText.setAttribute('y', y);
        linkText.setAttribute('text-anchor', 'middle');
        linkText.setAttribute('dy', '.3em');
        linkText.setAttribute('font-size', '100%');
        linkText.textContent = text;
        link.appendChild(linkText);
        break;
    }

    linkMappingRef.current.push(link);
  }, []);

  function oLink(data) {
    const dotted = mappingRef.current.filter(
      (m) =>
        m.node.nodeName === 'svg:tspan' &&
        String(m.node.textContent) === String(data.idItem)
    );

    const { catalogId, pageNum: pageId } = editor;

    if (!dotted.length) return false;

    dotted.forEach((d) =>
      createLink(
        d.x + 5,
        d.y + 6,
        Math.trunc(d.height),
        data.partnumber,
        data.idItem,
        `/${catalogId}/${pageId}/${data.idItem}`
      )
    );

    return true;
  }

  const oMousePosSVG = useCallback(
    (e) => {
      let p = svgRef.current.createSVGPoint();
      p.x = e.clientX;
      p.y = e.clientY;
      const ctm = svgRef.current.getScreenCTM().inverse();
      p = p.matrixTransform(ctm);
      return p;
    },
    [svgRef]
  );

  const oSelectElment = useCallback(
    (el) => {
      el.node.setAttribute('class', 'selected');
      selectedRef.current.push(el);
    },
    [selectedRef]
  );

  const oMappingTable = useCallback(() => {
    let line = [];
    const title = [];
    const mapping = [];

    /** posição inicial do primeiro item para  o mapeamento */
    const node = selectedRef.current.find(
      (el) =>
        el.node.nodeName === 'svg:tspan' &&
        (el.node.textContent.toUpperCase().includes('ITEM') ||
          el.node.textContent.toUpperCase().includes('POS.') ||
          el.node.textContent.toUpperCase().includes('REF'))
    );

    /** verifica se o nó foi encontrado */
    if (node) {
      const { x, width } = node;

      /** ponto médio para filtrar a coluna index */
      const xMid = x + width / 2;

      const index = selectedRef.current.filter(
        (el) =>
          el.node.nodeName === 'svg:tspan' &&
          xMid >= el.x &&
          xMid <= el.x + el.width
      );

      /** percorre as colunas index mapeando as linhas */
      index.forEach((el, indexEach) => {
        line = [];

        /** ponto médio da linha */
        const yMapMid = el.y + el.height / 2;

        const collection = selectedRef.current.filter(
          (elFil) =>
            elFil.node.nodeName === 'svg:tspan' &&
            yMapMid >= elFil.y &&
            yMapMid <= elFil.y + el.height
        );

        if (indexEach === 0) {
          /** título das colunas */
          collection.map((c) => title.push(c.node.textContent));
        } else {
          collection.map((c, indexMap) =>
            line.push([title[indexMap], c.node.textContent])
          );

          const obj = Object.fromEntries(new Map(line));
          mapping.push(obj);
        }
        // collection.map(c => line.push(c.node.textContent));
        // mapping.push(line);
      });
    }

    return mapping;
  }, [selectedRef]);

  const oRemove = useCallback((el) => {
    // if (el) {
    const drop = el.parentElement || null;

    if (!drop) return el;

    if (drop.childNodes.length > 1) return el;

    return oRemove(drop);
    // }
  }, []);

  const handleDragOverSVG = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDropSVG = useCallback(
    (e) => {
      e.preventDefault();

      const m = oMousePosSVG(e);

      const data = JSON.parse(e.dataTransfer.getData('text/row-list'));
      if (data) {
        const dotted = mappingRef.current.filter(
          (mappingFilter) =>
            mappingFilter.node.nodeName === 'svg:tspan' &&
            String(mappingFilter.node.textContent) === String(data.idItem)
        );

        const { catalogId, pageNum: pageId } = editor;

        if (!dotted.length)
          createLink(
            m.x,
            m.y,
            15,
            data.partnumber,
            data.idItem,
            `/${catalogId}/${pageId}/${data.idItem}`
          );

        dotted.forEach((d) =>
          createLink(
            d.x + 5,
            d.y + 6,
            Math.trunc(d.height),
            data.partnumber,
            data.idItem,
            `/${catalogId}/${pageId}/${data.idItem}`
          )
        );
      }
    },
    [editor, oMousePosSVG, createLink]
  );

  // function handleDrop(e) {
  //   // if (!inDesign) {
  //   e.preventDefault();

  //   const page_id = e.dataTransfer.getData('image/svg+xml');
  //   if (page_id) {
  //     const page = pages.find((p) => p.idPagina === Number(page_id));

  //     setEditor({
  //       catalogId: catalogo.idCatalogo,
  //       path: page.urlEdicao,
  //       pageNum: page.idPagina,
  //       state: page.situacao,
  //       type: page.tipo,
  //       inDesign: true,
  //     });
  //   }
  //   // }
  // }

  const handleDragOver = useCallback((e) => {
    const isLink = e.dataTransfer.types.includes('image/svg+xml');
    if (isLink) {
      e.preventDefault();
    }
  }, []);

  const handleDbClick = useCallback(
    (e) => {
      const { x, y } = oMousePosSVG(e);

      // const { shiftKey } = e;

      selectorRef.current.setAttribute('x', 0);
      selectorRef.current.setAttribute('y', 0);
      selectorRef.current.setAttribute('width', 0);
      selectorRef.current.setAttribute('height', 0);

      if (e.target.nodeName === 'circle' || e.target.nodeName === 'rect') {
        // if (shiftKey)
        actionRef.current = ACTION_STATE.multiSelect;

        oSelectElment({ node: e.target.parentElement });
      } else {
        const rec = mappingRef.current.filter(
          (el) =>
            x >= el.x &&
            x <= el.x + el.width &&
            y >= el.y &&
            y <= el.y + el.height
        );

        rec.map((el) => oSelectElment(el));
      }
    },
    [
      ACTION_STATE,
      selectorRef,
      actionRef,
      mappingRef,
      oMousePosSVG,
      oSelectElment,
    ]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (selectingRef.current) {
        const m = oMousePosSVG(e);

        switch (actionRef.current) {
          case ACTION_STATE.selected:
            selectorRef.current.setAttribute(
              'width',
              m.x - rectCoordRef.current.x
            );
            selectorRef.current.setAttribute(
              'height',
              m.y - rectCoordRef.current.y
            );
            break;
          case ACTION_STATE.moved:
            if (selectedRef.current.length !== 0) {
              selectedRef.current.forEach((element) => {
                if (element && element.node) {
                  if (
                    element.node.nodeName === 'a' &&
                    element.node.hasChildNodes()
                  ) {
                    const linkForm = element.node.childNodes[0];
                    const linkText = element.node.childNodes[1];

                    if (currentLinkTypeRef.current === 0) {
                      linkForm.setAttribute('cx', m.x);
                      linkForm.setAttribute('cy', m.y);

                      linkText.setAttribute('x', m.x);
                      linkText.setAttribute('y', m.y);
                    } else {
                      linkForm.setAttribute('x', m.x);
                      linkForm.setAttribute('y', m.y);

                      linkText.setAttribute(
                        'x',
                        m.x + linkForm.width.baseVal.value / 2
                      );
                      linkText.setAttribute(
                        'y',
                        m.y + linkForm.height.baseVal.value / 2
                      );
                    }
                  }
                }
              });
            }
            break;
          default:
            break;
        }
      }
    },
    [ACTION_STATE, selectorRef, actionRef, selectingRef, oMousePosSVG]
  );

  const handleMouseDown = useCallback(
    (e) => {
      /** verifica se existe um arquivo na área de edição */
      if (svgRef.current) {
        selectingRef.current = true;

        const { shiftKey, ctrlKey } = e;

        if (!shiftKey) {
          selectedRef.current.forEach((el) =>
            el.node.setAttribute('class', '')
          );
          selectedRef.current = [];
        }

        actionRef.current = ctrlKey
          ? (actionRef.current = ACTION_STATE.selected)
          : ACTION_STATE.moved;

        const m = oMousePosSVG(e);

        switch (actionRef.current) {
          case ACTION_STATE.moved:
            if (
              e.target.nodeName === 'circle' ||
              e.target.nodeName === 'rect'
            ) {
              oSelectElment({ node: e.target.parentElement });
            }
            break;
          case ACTION_STATE.selected:
            minCoordRef.current.x = m.x;
            minCoordRef.current.y = m.y;
            rectCoordRef.current.x = m.x;
            rectCoordRef.current.y = m.y;

            selectorRef.current.setAttribute('x', rectCoordRef.current.x);
            selectorRef.current.setAttribute('y', rectCoordRef.current.y);
            break;
          default:
            break;
        }
      }
    },
    [
      ACTION_STATE,
      selectorRef,
      actionRef,
      selectingRef,
      minCoordRef,
      rectCoordRef,
      oMousePosSVG,
      oSelectElment,
    ]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (selectingRef.current) {
        const m = oMousePosSVG(e);

        maxCoordRef.current.x = m.x;
        maxCoordRef.current.y = m.y;

        const rec = mappingRef.current.filter(
          (el) =>
            el.x >= minCoordRef.current.x &&
            el.x <= maxCoordRef.current.x &&
            el.y >= minCoordRef.current.y &&
            el.y <= maxCoordRef.current.y
        );

        switch (actionRef.current) {
          case ACTION_STATE.selected:
            selectorRef.current.setAttribute('x', 0);
            selectorRef.current.setAttribute('y', 0);
            selectorRef.current.setAttribute('width', 0);
            selectorRef.current.setAttribute('height', 0);

            rec.map((el) => oSelectElment(el));
            break;
          case ACTION_STATE.moved:
            // selectedRef.forEach(el => el.node.setAttribute('class', ''));
            // selectedRef = [];
            break;
          default:
            break;
        }
      }

      selectingRef.current = false;
    },
    [
      ACTION_STATE,
      selectorRef,
      actionRef,
      mappingRef,
      selectingRef,
      maxCoordRef,
      minCoordRef,
      oMousePosSVG,
      oSelectElment,
    ]
  );

  async function handleSubmit() {
    // try {
    /** carrega o arquivo editado */
    const blob = new Blob([svgRef.current.outerHTML], {
      type: 'image/svg+xml',
    });

    onSave({ svg: blob });

    // setEditor(INITIAL_STATE);
    // setTable([]);
    // setMappingTable([]);
    // linkMappingRef.current = [];

    // /** mensagem de sucesso */
    // toast.success('Página salva com sucesso!');
    // } catch (err) {
    /** mensagem de erro */
    // toast.error(`Ops! Ocorreu um problema. ${err}`);
    // }
  }

  function handleCancel() {
    setEditor(INITIAL_STATE);
    setMappingTable([]);
    linkMappingRef.current = [];

    onCancel();
  }

  function handleMappingTable() {
    try {
      /** mapeamento dos campos da tabela */
      const mapping = oMappingTable();
      setMappingTable(mapping);
      setShow(true);
    } catch (err) {
      /** erro */
      toast.error(`Ops! Ocorreu um problema. ${err}`);
    }
  }

  const handleRemove = useCallback(() => {
    try {
      /** percorre elemento selecionados */
      selectedRef.current.forEach((s) => {
        /** remove elemento do mapeamento principal */
        mappingRef.current.splice(
          mappingRef.current.findIndex((m) => m.node.id === s.node.id),
          1
        );
        /** verifica o nível para eliminar o elemento pai */
        oRemove(s.node).remove();
      });
      selectedRef.current = [];
    } catch (err) {
      /** erro */
      toast.error(`Ops! Ocorreu um problema. ${err}`);
    }
  }, [selectedRef, mappingRef, oRemove]);

  function handleDownload() {
    try {
      /** cria um link para o processo de download */
      const link = document.createElement('a');
      const file = new Blob([svgRef.current.outerHTML], {
        type: 'image/svg+xml',
      });

      link.href = URL.createObjectURL(file);
      link.download = `${catalogo.arquivo.nome}-${editor.pageNum}`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      /** erro */
      toast.error(`Ops! Ocorreu um problema. ${err}`);
    }
  }

  function handleNormalize(d) {
    setTable(
      d.map((l) => ({
        ...l,
        quantidade: l.quantidade ? Number(l.quantidade) : 0,
        ordena: l.ordena ? Number(l.ordena) : 0,
      }))
    );

    onMapping(d);
  }

  function handleResize() {
    const currentGuid = svgRef.current.getAttribute('data-guid');
    const currentWidth = svgRef.current.getAttribute('width');
    const currentHeight = svgRef.current.getAttribute('height');

    const width = currentGuid ? currentWidth : 750;
    const height = currentGuid ? currentHeight : 850;
    const dataGuid = currentGuid || 'portrait';

    const viewBox = `0 0 ${width} ${height}`;

    svgRef.current.setAttribute('width', width);
    svgRef.current.setAttribute('height', height);
    svgRef.current.setAttribute('viewBox', viewBox);
    svgRef.current.setAttribute('data-guid', dataGuid);
  }

  function handleCenter() {
    const { baseVal } = rootRef.current.transform;

    const i = Array.from(baseVal).findIndex((e) => e.type === 2);
    let translate;

    if (i > 0) {
      translate = baseVal.getItem(i);
    } else {
      translate = svgRef.current.createSVGTransform();
      baseVal.appendItem(translate);
    }

    const { height: hSVG, width: wSVG, x: xSVG } = svgBoundingRef.current;
    const { height: hRoot, width: wRoot } = rootBoundingRef.current;

    let x = xSVG - (wSVG - wRoot);
    let y = hSVG - hRoot;
    if (rootScaleRef.current > 1) {
      x *= 1 - rootScaleRef.current;
      y *= rootScaleRef.current * -1;
    }

    translate.setTranslate(x, y);

    rootXRef.current = x;
    rootYRef.current = y;
  }

  function handleLandscape() {
    const width = 850;
    const height = 750;
    const viewBox = `0 0 ${width} ${height}`;

    svgRef.current.setAttribute('width', width);
    svgRef.current.setAttribute('height', height);
    svgRef.current.setAttribute('viewBox', viewBox);
    svgRef.current.setAttribute('data-guid', 'landscape');
  }

  function handlePortrait() {
    const width = 750;
    const height = 850;
    const viewBox = `0 0 ${width} ${height}`;

    svgRef.current.setAttribute('width', width);
    svgRef.current.setAttribute('height', height);
    svgRef.current.setAttribute('viewBox', viewBox);
    svgRef.current.setAttribute('data-guid', 'portrait');
  }

  const handleScale = useCallback(
    (value) => {
      const { baseVal } = rootRef.current.transform;

      const i = Array.from(baseVal).findIndex((e) => e.type === 3);
      let scale;

      if (i > 0) {
        scale = baseVal.getItem(i);
      } else {
        scale = svgRef.current.createSVGTransform();
        baseVal.appendItem(scale);
      }
      scale.setScale(
        rootScaleRef.current + value,
        rootScaleRef.current + value
      );

      rootScaleRef.current += value;

      mappingRef.current = [];
      mappingElement(svgRef.current.childNodes);
    },
    [rootRef, svgRef, mappingRef, rootScaleRef, mappingElement]
  );

  const handlePosition = useCallback(
    (x, y) => {
      const { baseVal } = rootRef.current.transform;
      const i = Array.from(baseVal).findIndex((e) => e.type === 2);
      let translate;

      if (i > 0) {
        translate = baseVal.getItem(i);
      } else {
        translate = svgRef.current.createSVGTransform();
        baseVal.appendItem(translate);
      }

      translate.setTranslate(rootXRef.current + x, rootYRef.current + y);

      rootXRef.current += x;
      rootYRef.current += y;

      mappingRef.current = [];
      mappingElement(svgRef.current.childNodes);
    },
    [rootRef, svgRef, rootXRef, rootYRef, mappingRef, mappingElement]
  );

  const handleLinkScale = useCallback(
    (value) => {
      switch (actionRef.current) {
        case ACTION_STATE.multiSelect:
          selectedRef.current.forEach((link) => {
            if (link.node.nodeName === 'a' && link.node.hasChildNodes()) {
              const linkForm = link.node.childNodes[0];
              const linkText = link.node.childNodes[1];

              if (currentLinkTypeRef.current === 0) {
                const radius = Number(linkForm.getAttribute('r'));
                linkForm.setAttribute('r', radius + value);
                const textSize = Number(
                  linkText.getAttribute('font-size').replace('%', '')
                );
                linkText.setAttribute('font-size', `${textSize + value * 5}%`);
              } else {
                const width = Number(linkForm.getAttribute('width'));
                linkForm.setAttribute('width', width + value);
                const textLeft = Number(linkText.getAttribute('x'));
                linkText.setAttribute('x', textLeft + value / 2);
              }
            }
          });
          break;
        default:
          linkMappingRef.current.forEach((link) => {
            if (link.hasChildNodes()) {
              const linkForm = link.childNodes[0];
              const linkText = link.childNodes[1];

              if (currentLinkTypeRef.current === 0) {
                const radius = Number(linkForm.getAttribute('r'));
                linkForm.setAttribute('r', radius + value);
                const textSize = Number(
                  linkText.getAttribute('font-size').replace('%', '')
                );
                linkText.setAttribute('font-size', `${textSize + value * 5}%`);
              } else {
                const width = Number(linkForm.getAttribute('width'));
                linkForm.setAttribute('width', width + value);
                const textLeft = Number(linkText.getAttribute('x'));
                linkText.setAttribute('x', textLeft + value / 2);
              }
            }
          });
          break;
      }
    },
    [ACTION_STATE, actionRef, selectedRef, linkMappingRef]
  );

  function handleLink() {
    table.forEach((item) => {
      if (!item.linked) {
        oLink(item);
        // setTable([...table.filter(t => t.item !== item.item), {...item, linked}].sort((a,b) => a.item - b.item));
      }
    });
  }

  function handleRotate(value) {
    const { baseVal } = rootRef.current.transform;
    const i = Array.from(baseVal).findIndex((e) => e.type === 4);
    let rotate;

    if (i > 0) {
      rotate = baseVal.getItem(i);
    } else {
      rotate = svgRef.current.createSVGTransform();
      baseVal.appendItem(rotate);
    }

    rootRotateRef.current += value;
    if (rootRotateRef.current >= 360 || rootRotateRef.current < 0)
      rootRotateRef.current = 0;

    rotate.setRotate(rootRotateRef.current, rootXRef.current, rootYRef.current);

    mappingRef.current = [];
    mappingElement(svgRef.current.childNodes);
  }

  const handleKeyDown = useCallback(
    (e) => {
      if (svgRef.current) {
        switch (e.keyCode) {
          /** 17:ctrl */
          case 17:
            break;
          /** 16:shift */
          case 16:
            break;
          /** 18:alt */
          case 18:
            break;
          /** 46:delete */
          case 46:
            if (selectedRef.current && selectedRef.current.length > 0)
              handleRemove();
            break;
          /** 37,38,39,40:arrow left, up, right, down */
          case 37:
            if (e.shiftKey) handlePosition(-10, 0);
            break;
          case 38:
            if (e.shiftKey) handlePosition(0, 10);
            break;
          case 39:
            if (e.shiftKey) handlePosition(10, 0);
            break;
          case 40:
            if (e.shiftKey) handlePosition(0, -10);
            break;
          case 187:
            if (e.shiftKey) handleScale(0.1);
            break;
          case 189:
            if (e.shiftKey) handleScale(-0.1);
            break;
          default:
            break;
        }
      }
    },
    [handlePosition, handleScale, handleRemove]
  );

  const editorSvg = useMemo(
    () => (
      <SVG
        id={`page-${editor.pageNum}`}
        src={editor.path}
        tabIndex="0"
        useRequestCache={false}
        onDoubleClick={handleDbClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onKeyDown={handleKeyDown}
        loading={() => <span>Loading</span>}
        fallback={() => <span>Selecione a imagem para editar</span>}
        beforeInjection={(svg) => {
          /** cria o componente seletor e atribui ao SVG */
          const selector = document.createElementNS(
            svg.namespaceURI,
            'rect',
            svg
          );
          selector.setAttribute('id', 'selector');
          selector.setAttribute('stroke', '#ccc');
          selector.setAttribute('fill', 'rgba(0,0,0,.2)');
          svg.appendChild(selector);

          /** atribui component a variáveis */
          svgRef.current = svg;
          selectorRef.current = selector;
        }}
        afterInjection={(err, svg) => {
          if (svg) {
            /** nó raiz */
            rootRef.current = Array.from(svg.childNodes).find(
              (e) => e.nodeName === 'svg:g'
            );
            /** limpa variáveis de alteração */
            rootXRef.current = 0;
            rootYRef.current = 0;
            rootScaleRef.current = 1;
            rootRotateRef.current = 0;

            /** mapeamento dos elementos */
            mappingRef.current = [];
            mappingElement(svg.childNodes);

            /** adicionar processo de arrastar e soltar no SVG */
            svg.ondragover = handleDragOverSVG;
            svg.ondrop = handleDropSVG;

            /** redimensiona automaticamente para o tamanho padrão */
            handleResize();

            rootBoundingRef.current = rootRef.current.getBoundingClientRect();
            svgBoundingRef.current = svgRef.current.getBoundingClientRect();
          }
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editor.pageNum,
      editor.path,
      // handleDbClick,
      // handleDropSVG,
      // handleDragOverSVG,
      // handleKeyDown,
      // handleMouseDown,
      // handleMouseMove,
      // handleMouseUp,
      // mappingElement,
    ]
  );

  return (
    <Container
      id="editor"
      // onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <ToolBar>
        <button title="Salvar" type="submit" onClick={handleSubmit}>
          <MdDone size={16} />
        </button>
        <button type="button" title="Cancelar" onClick={handleCancel}>
          <MdClose size={16} />
        </button>
        <button type="button" title="Remover" onClick={handleRemove}>
          <MdDelete size={16} />
        </button>
        <button type="button" title="Download" onClick={handleDownload}>
          <MdFileDownload size={16} />
        </button>
        <button
          type="button"
          title="Extrair Tabela"
          onClick={handleMappingTable}
        >
          <MdFormatIndentIncrease size={16} />
        </button>
        <button type="button" title="Redimensionar" onClick={handleResize}>
          <MdCropFree size={16} />
        </button>
        <button type="button" title="Centralizar" onClick={handleCenter}>
          <MdCenterFocusStrong size={16} />
        </button>
        <button type="button" title="Horizontal" onClick={handleLandscape}>
          <MdStayPrimaryLandscape size={16} />
        </button>
        <button type="button" title="Vertical" onClick={handlePortrait}>
          <MdStayPrimaryPortrait size={16} />
        </button>
        <button type="button" title="Escala +" onClick={() => handleScale(0.1)}>
          <MdExposurePlus1 size={16} />
        </button>
        <button
          type="button"
          title="Escala -"
          onClick={() => handleScale(-0.1)}
        >
          <MdExposureNeg1 size={16} />
        </button>
        <button
          type="button"
          title="Rotacionar"
          onClick={() => handleRotate(-45)}
        >
          <MdRotateLeft size={16} />
        </button>
        <button
          type="button"
          title="Rotacionar"
          onClick={() => handleRotate(45)}
        >
          <MdRotateRight size={16} />
        </button>
        <button
          type="button"
          title="Posiciona"
          onClick={() => handlePosition(-10, 0)}
        >
          <MdKeyboardArrowLeft size={16} />
        </button>
        <button
          type="button"
          title="Posiciona"
          onClick={() => handlePosition(0, -10)}
        >
          <MdKeyboardArrowDown size={16} />
        </button>
        <button
          type="button"
          title="Posiciona"
          onClick={() => handlePosition(0, 10)}
        >
          <MdKeyboardArrowUp size={16} />
        </button>
        <button
          type="button"
          title="Posiciona"
          onClick={() => handlePosition(10, 0)}
        >
          <MdKeyboardArrowRight size={16} />
        </button>
        <button type="button" title="Link" onClick={handleLink}>
          <MdLink size={20} />
        </button>
        <button type="button" title="Link +" onClick={() => handleLinkScale(1)}>
          <MdUnfoldMore size={16} />
        </button>
        <button
          type="button"
          title="Link -"
          onClick={() => handleLinkScale(-1)}
        >
          <MdUnfoldLess size={16} />
        </button>
        <button
          type="button"
          title="Link círculo"
          disabled={currentLinkType === 0}
          onClick={() => {
            currentLinkTypeRef.current = 0;
            setCurrentLinkType(0);
          }}
        >
          <MdRadioButtonUnchecked size={16} />
        </button>
        <button
          type="button"
          title="Link retângulo"
          disabled={currentLinkType === 1}
          onClick={() => {
            currentLinkTypeRef.current = 1;
            setCurrentLinkType(1);
          }}
        >
          <MdCheckBoxOutlineBlank size={16} />
        </button>
      </ToolBar>

      <Wrapper>
        {/* <Reporter>{`${status}`}</Reporter>        */}
        {/* <TransformWrapper          
          defaultScale={1}
          defaultPositionX={200}
          defaultPositionY={100}
          options={ {disabled: true} }
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (                      
            <ZoomWrapper>            
              <ZoomControl>
                <button onClick={zoomIn}><MdZoomIn size={35} /></button>                
                <button onClick={zoomOut}><MdZoomOut size={35} /></button>
                <button onClick={resetTransform}><MdSettingsOverscan size={35} /></button>
              </ZoomControl>            
              <TransformComponent> */}
        {editorSvg}
        {/* </TransformComponent>             
            </ZoomWrapper>
          )} 
        </TransformWrapper> */}
      </Wrapper>

      {show && (
        <MappingModal
          onClose={() => setShow(false)}
          onSubmit={handleNormalize}
          data={mappingTable}
          pageId={editor.pageNum}
        />
      )}
    </Container>
  );
}

EditorArea.propTypes = {
  catalogo: PropTypes.instanceOf(Object).isRequired,
  pagina: PropTypes.instanceOf(Object).isRequired,
  itensPagina: PropTypes.arrayOf(Object).isRequired,
  onMapping: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditorArea;
