import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../../../errors/AppError';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { Form, FormWrapper } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

function CatalogoArranjoEditar() {
  const location = useLocation();
  const { idCatalogo, idSerie, idArranjo } = location.state;

  const formRef = useRef(null);

  const [arranjo, setArranjo] = useState(null);

  useEffect(() => {
    /** informações do arranjo */
    api
      .get(
        `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}`
      )
      .then((response) => {
        formRef.current.setFieldValue('arranjo', {
          value: response.data.idArranjo,
          label: response.data.nome,
        });

        setArranjo(response.data);
      });
  }, [idCatalogo, idSerie, idArranjo]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await api.put(
          `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}`,
          data
        );

        history.goBack();

        toast.success('Arranjo alterado com sucesso!');
      } catch (err) {
        AppError(err);
      }
    },
    [idCatalogo, idSerie, idArranjo]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Editar arranjo" />

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleConfirmar}
          initialData={arranjo}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row />
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" />
              <Input id="descricao" name="descricao" label="Descrição" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { CatalogoArranjoEditar };
