import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { MdOutlineClose } from 'react-icons/md';

import { setNotificationRead } from '../../store/modules/user/actions';

import { Container, BoxText, Text, Button } from './styles';

function Notificacao({ listaNotificacao }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user || {});
  const { notificationRead } = user;

  const handleClose = useCallback(() => {
    dispatch(setNotificationRead(true));
  }, [dispatch]);

  return (
    <>
      {!notificationRead && listaNotificacao.length > 0 && (
        <Container>
          <BoxText>
            {listaNotificacao.map((item, key) => (
              <Text key={key}>{item.descricao}</Text>
            ))}
          </BoxText>
          <Button
            onClick={handleClose}
            type="button"
            title="Fechar notificação"
          >
            <MdOutlineClose size={16} />
          </Button>
        </Container>
      )}
    </>
  );
}

Notificacao.propTypes = {
  listaNotificacao: PropTypes.arrayOf(PropTypes.string),
};

Notificacao.defaultProps = {
  listaNotificacao: null,
};

export { Notificacao };
