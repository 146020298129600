import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 20px;
`;

export const Panel = styled.div`
  width: 400px;
`;
