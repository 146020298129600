import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const LeftContainer = styled.div`
  width: 150px;
  height: 100%;
  margin-right: 10px;
  overflow: hidden;
  border-right: 2px solid #79869e40;

  h3 {
    color: #2C4068;
    font-weight: bold;
    padding: 10px 0;
  }

  p {
    padding: 5px 5px 5px 0;
    color: #79869e;
    font-weight: bold;
  }

  .columnSpace {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .counter {
    color: #79869e;
    font-weight: bold;
    font-size: 14px;

    span {
      color: #2C4068;
      font-weight: bolder;
      font-size: 14px;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .titleColuns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    margin-bottom: 5px;
    padding-left: 10px;
    
    .wrapperCheckTitle {
      display: flex;
      align-items: center;

      h3 {
        padding: 5px 20px;
        margin: 0 10px;
      }
    }
  }
`;

export const ListItensBox = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  margin-bottom: 10px;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;

    .hover-class {
      background-color: #79869e40;
    }

    .selected-item {
      background-color: #5f697c70;
    }
  }

  thead th {
    font-size: 12px;
    color: #79869e;
    text-align: left;

    tr td {
      max-width: 400px;
      white-space : nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  th {
    width: ${(props) => props.width};
    text-align: left;
    padding: 0 3px;
  }

  tbody tr {
    background: #fff;
    cursor: pointer;
  }

  td {
    padding: 10px 2px;
    max-width: 400px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    white-space : nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      border-left: 1px solid #ccc;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-left: 10px;
    }

    &:last-child {
      border-right: 1px solid #ccc;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .leftItens {
    grid-area: 1 / 1 / 7 / 4;
  }

  .rightItens {
    grid-area: 1 / 4 / 7 / 7;
  }
`;

export const InfoItemBox = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr 100px 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  align-items: center;
  margin-right: 12px;
  background-color: #e6e6e669;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px 1px #79869e4d;

  input {
    border: 1px solid #cbd0d8;
    border-radius: 5px;
    color: #444;
    font-size: 12px;
    padding: 2px 5px;
    width: 100%;
    margin-bottom: 5px;
  }

  label {
    color: #2C4068;
    width: 200px;
    font-weight: bold;
    grid-area: ${(props) => props.gridArea}
  }
`;

export const Label = styled.label`
  color: #2C4068;
  width: 200px;
  font-weight: bold;
  grid-area: ${(props) => props.gridArea}
`;

export const BtnDisplay = styled.button`
  width: 100px;
  margin: auto;
  border-radius: 5px;
  padding: 5px 0;
  border: none;
  background-color: ${(props) => (props.isSave ? '#FF6D3C' : 'none')};
  color: ${(props) => (props.isSave ? '#fff' : '#79869e')};
  font-weight: bold;
  grid-area: ${(props) => props.gridArea};
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: #2C4068;
`;
