import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  tamanho: Yup.string(),
  mascara: Yup.string(),
});

function EmpresaTipoComplementoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const [tipoComplemento, setTipoComplemento] = useState(null);

  useEffect(() => {
    api.get(`tipo-complemento/${id}`).then((response) => {
      setTipoComplemento(response.data);
    });
  }, [id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { descricao, tamanho, mascara } = data;

        await api.put(`tipo-complemento/${id}`, {
          descricao,
          tamanho,
          mascara,
        });

        toast.success('Recurso atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Tipo Complemento" />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          schema={schema}
          initialData={tipoComplemento}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="chave"
                name="chave"
                label="Chave"
                width={300}
                disabled
              />
              <Input id="descricao" name="descricao" label="Descrição" />
            </Form.Row>
            <Form.Row>
              <Input
                id="tamanho"
                name="tamanho"
                label="Tamanho"
                type="number"
              />
              <Input id="mascara" name="mascara" label="Máscara" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="form-ref">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaTipoComplementoEditar };
