import styled from 'styled-components';
import { darken } from 'polished';

const Item = styled.div`
  flex: 1;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;

  background: ${(props) => props.theme.background};
  box-shadow: inset 0 0 1em #00000026;
  /* position: relative; */
  position: absolute;

  width: 100%;
  max-width: 100vw;
  height: 100%;
  overflow-y: auto;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50px;
    display: block;

    background: ${(props) => `${(0.08, props.theme.arrow)}`};
    width: 25px;
    height: 25px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

Item.Title = styled.div`
  padding: 40px 40px 5px;

  > div {
    display: flex;
    align-items: center;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: none;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;

      color: ${(props) => props.theme.color};

      :hover {
        background: ${darken(0.05, '#dde3f1')};
      }
    }
  }

  > form {
    border-bottom: 1px solid ${(props) => props.theme.color};
  }
`;

Item.Wrapper = styled.ul`
  font-size: 18px;
  transform: color 1s;

  > li {
    > a {
      display: flex;
      padding: 15px 40px;

      color: ${(props) => props.theme.color};
    }

    a.disabled-link {
      color: ${(props) => props.theme.color};
      pointer-events: none;
    }

    a:hover {
      background: ${darken(0.05, '#dde3f1')};
    }

    a:visited,
    a:active {
      color: ${(props) => props.theme.color};
    }
  }
`;

export { Item };
