import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import PropTypes from 'prop-types';

import history from '../../../services/history';

import { Form } from '../../Form';
import Input from '../../Form/Input';

import { Item } from './styles';

function ItemMenu({ visible, color, title, active, backTo, items }) {
  const { app, action } = useSelector((state) => state.auth || {});

  const formRef = useRef(null);

  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setOriginalData(items);
    setData(items);
  }, [items]);

  const handleSearch = useCallback(
    (e) => {
      const { value = '' } = e.target || {};
      const filtered = originalData.filter((d) =>
        String(d.name).toUpperCase().includes(value.toUpperCase())
      );
      setData(filtered);
    },
    [originalData]
  );

  return (
    <Item color={color} visible={visible}>
      <Item.Title>
        <div>
          {(!app || action === 1) && backTo && (
            <button type="button" onClick={() => history.push(backTo)}>
              <MdKeyboardArrowLeft size={20} />
            </button>
          )}
          <h2>{title}</h2>
        </div>
        <Form ref={formRef} autoComplete="off">
          <Input
            id="search"
            name="search"
            placeholder="Buscar"
            onChange={(e) => handleSearch(e)}
            disabled={!active}
          />
        </Form>
      </Item.Title>
      <Item.Wrapper>
        {data.map((item) => (
          <li key={item.id}>
            <Link
              className={active ? '' : 'disabled-link'}
              to={(path) => ({ ...path, search: item.linkTo })}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </Item.Wrapper>
    </Item>
  );
}

ItemMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  backTo: PropTypes.string,
  items: PropTypes.arrayOf(Object).isRequired,
};

ItemMenu.defaultProps = {
  color: '#eef1f8',
  backTo: '',
};

export { ItemMenu };
