import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signInTokenRequest } from '../../../../store/modules/auth/actions';
// import { setToken } from '../../../../store/modules/auth/sagas';

import api from '../../../../services/api';

import { useQuery } from '../../../../lib/reactRouter';
import { opTipoAcao } from '../../../../lib/const';

import { AppContainer } from '../../../../components/Container';
import history from '../../../../services/history';

const INITIAL_PARAMS = {
  token: null,
};

const INITIAL_ROUTER = {
  situacao: 0,
  routeParams: null,
};

function IntegraCatalogoToken() {
  const dispatch = useDispatch();

  const routeQuery = useQuery(useLocation().search);

  const [router, setRouter] = useState(INITIAL_ROUTER);

  useEffect(
    () => {
      async function carregaRecurso() {
        const parametroRota = {
          ...INITIAL_PARAMS,
          ...Object.fromEntries(routeQuery),
        };

        let token;

        console.log(parametroRota);

        if (parametroRota.token) {
          token = parametroRota.token;
          console.log(token);
          dispatch(signInTokenRequest(token, opTipoAcao.INTEGRACAO_TOKEN));
        }

        if (parametroRota.ass) {
          const { ass } = parametroRota;
          console.log(ass);
          const { data } = await api.get(`/app.session/token/${ass}`);

          token = data;
          console.log(token);

          dispatch(signInTokenRequest(token, opTipoAcao.INTEGRACAO_TOKEN));
        }

        /** token informado por parâmetro */
        // setToken({
        //   payload: {
        //     auth: {
        //       token,
        //     },
        //   },
        // });

        /** controle de tentativas de consulta de catálogo  */
        const timerCounterMAX = 3;
        let timerCounter = 0;
        let timerID;

        const carregaCatalogoToken = async () => {
          /** controle de tentativas */
          timerCounter += 1;
          if (timerCounter > timerCounterMAX) {
            clearInterval(timerID);

            /** para token expirado, exibe mensagem de erro */
            if (!api.defaults.headers.Authorization) {
              setRouter({
                situacao: 1,
                routeRedirect: null,
              });
            }
          }

          /** verifica se o token já foi aplicado */
          if (api.defaults.headers.Authorization) {
            clearInterval(timerID);

            try {
              /** consulta informações do catálogo a partir do token */
              const response = await api.get(`/catalogo-token/${token}`);

              /** extrai informações da página e do modelo */
              const { idCatalogo = 0 } = response.data || {};

              /** constroi rota para o catálogo do token */
              const routeRedirect = `/${idCatalogo}`;

              setRouter({
                situacao: idCatalogo > 0 ? 2 : 1,
                routeRedirect,
              });
            } catch (error) {
              setRouter({
                situacao: 1,
                routeRedirect: null,
              });
            }
          }
        };

        /** seta intervalo de tempo para aguardar atribuição de token */
        timerID = setInterval(carregaCatalogoToken, 800);
      }

      carregaRecurso();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const appRedirect = useMemo(
    () =>
      router.routeRedirect ? (
        <Redirect to={`/catalogo.partes${router.routeRedirect}`} />
      ) : (
        <AppContainer>
          {router.situacao === 1 ? (
            <>
              <span>
                Nenhum catálogo relacionado com o Token. Por favor, entre em
                contato com o fornecedor.
              </span>
              <button type="button" onClick={() => history.push('/login')}>
                Acessar
              </button>
            </>
          ) : (
            <span>Redirecionando, aguarde...</span>
          )}
        </AppContainer>
      ),
    [router]
  );

  return appRedirect;
}

export { IntegraCatalogoToken };
