import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import InputViewer from '../../../../components/Form/Input/Viewer';

function EmpresaRetiradaVisualizar() {
  const location = useLocation();
  const { idEmpresa, idSolicitacao, idItemSolicitacao } = location.state;

  const [itemSolicitacao, setItemSolicitacao] = useState();

  useEffect(() => {
    api
      .get(
        `empresa/${idEmpresa}/solicitacao/${idSolicitacao}/item/${idItemSolicitacao}`
      )
      .then((response) => {
        const { dataDesa } = response.data.itemSolicitacao;
        setItemSolicitacao({
          ...response.data.itemSolicitacao,
          dataDesa: dataDesa
            ? format(parseISO(dataDesa), 'dd/MM/yyyy HH:mm:ss')
            : null,
        });
      });
  }, [idEmpresa, idSolicitacao, idItemSolicitacao]);

  return (
    <>
      <TitleBar back={!!location.background} title="Item da solicitação" />

      <FormWrapper>
        <Form
          id="formItemSolicitacao"
          initialData={itemSolicitacao}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <InputViewer
                id="idItemSolicitacao"
                name="idItemSolicitacao"
                label="Item"
              />
              <InputViewer
                id="extItemOS"
                name="extItemOS"
                label="Ext. Item OS"
              />
            </Form.Row>
            <Form.Row>
              <InputViewer
                id="quantidade"
                name="quantidade"
                label="Quantidade"
              />
              <InputViewer id="unidade" name="unidade" label="Unidade" />
            </Form.Row>
            <h3>Material Fabricante</h3>
            <Form.Row>
              <InputViewer
                id="materialFabricante.partnumber"
                name="materialFabricante.partnumber"
                label="Codimate"
              />
              <InputViewer
                id="materialFabricante.descricaoBR"
                name="materialFabricante.descricaoBR"
                label="Descrição (BR)"
              />
              <InputViewer
                id="materialFabricante.descricaoEN"
                name="materialFabricante.descricaoEN"
                label="Descrição (EN)"
              />
            </Form.Row>
            <h3>Material ERP</h3>
            <Form.Row>
              <InputViewer
                id="materialERP.codimate"
                name="materialERP.codimate"
                label="Codimate"
              />
              <InputViewer
                id="materialERP.descricao"
                name="materialERP.descricao"
                label="Descrição"
              />
            </Form.Row>
            <h3>Cancelamento</h3>
            <Form.Row>
              <InputViewer
                id="cancelamento.nome"
                name="cancelamento.nome"
                label="Responsável"
              />
              <InputViewer id="dataDesa" name="dataDesa" label="Data" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Fechar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EmpresaRetiradaVisualizar;
