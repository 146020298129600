import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 600px;
`;

export const Wrapper = styled.div`
  flex: 1;
  > h2 {
    text-align: center;
    padding: 0 10px 100px 10px;
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;

  > button {
    border: 0;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
  }

  #btn-action {
    border: 0;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    min-width: 220px;
    background: #2c405a;
    transition: background 1s;

    :hover {
      color: #fff;
      background: ${darken(0.05, '#2c405a')};
    }
  }
`;

Action.Export = styled.button`
  background: #2c405a;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#2c405a')};
  }
`;

Action.Home = styled.button`
  background: #ff6d3c;
  transition: background 1s;

  :hover {
    background: ${darken(0.05, '#ff6d3c')};
  }
`;

Action.Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  height: 42px;
  min-width: 220px;
  background: #2c405a;
  transition: background 1s;

  #icon {
    margin: 0 5px;
  }
`;
