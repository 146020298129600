import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-self: center;
  flex: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 150px;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px;

    &:hover {
      opacity: 0.7;
    }

    img {
      display: flex;
      border: 1px dashed #ccc;
      border-radius: 25px;
      background: #fff;
      height: 150px;
      width: 100%;
      max-width: 350px;
      padding: 40px 0;
    }

    input {
      display: none;
    }
  }
`;
