import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

const schema = Yup.object().shape({
  login: Yup.string()
    .min(3, 'O campo "login" precisa ter no mínimo 3 caracteres')
    .required('O campo "login" é obrigatório'),
  nome: Yup.string()
    .min(3, 'O campo "nome" precisa ter no mínimo 3 caracteres')
    .required('O campo "nome" é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "e-mail" é obrigatório'),
  senha: Yup.string()
    .min(6, 'O campo "Senha" precisa ter no mínimo 6 caracteres')
    .required('O campo "Senha" é obrigatório'),
});

function UsuarioCriar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { login, senha, nome, email } = data;

      await api.post('usuario', {
        login,
        senha,
        nome,
        email,
      });

      toast.success('Usuário cadastrado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Novo Usuário" />
      <FormWrapper>
        <Form
          id="formUsuario"
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="login" name="login" label="Login" type="text" />
              <Input id="senha" name="senha" label="Senha" type="password" />
            </Form.Row>
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" type="text" />
              <Input id="email" name="email" label="E-mail" type="text" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formUsuario">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { UsuarioCriar };
