import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 270px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #a0a9b9;
    border: 0;
    margin: 0 1px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    transition: background 1s;

    :hover {
      background: #ff6d3c;
      color: #fff;
    }
  }

  form {
    flex: 1;
    padding: 0 1px;
    min-width: 120px;
  }
`;

// export const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px;

//   & > ul {
//     display: inline-block;
//     padding-left: 15px;
//     padding-right: 15px;

//     & > li {
//       display: inline-block;
//       padding: 10px 15px;
//       background: #ffffff;
//       border: 1px solid #cbd0d8;
//       color: #a0a9b9;

//       :first {
//         border-top-left-radius: 5px;
//       }

//       & > a {
//         cursor: pointer;
//       }

//       &.disabled {
//         color: #cbd0d8;
//       }

//       &.active {
//         border: 1px solid #ff6d3c;
//         background: #ff6d3c;
//         color: #fff;
//       }
//     }
//   }
// `;
