export function adicionaOrdemRequest(
  extCodiDiv2,
  extNumeOS,
  extItemOS,
  extObservacao,
  extUsuario,
  extExtra01,
  extExtra02,
  extExtra03,
  extExtra04
) {
  return {
    type: '@order/ADD_SUCCESS',
    payload: {
      order: {
        extCodiDiv2,
        extNumeOS,
        extItemOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
      },
    },
  };
}
