import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { MdPhotoCamera, MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';

import { InputWrapper, ImgFileWrapper } from '../..';

import noImage from '../../../../assets/no-photo-available.png';

export default function InputImgFile({
  id,
  name,
  label,
  src,
  width,
  height,

  handleUpload,
  handleDelete,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper>
      <ImgFileWrapper width={width} height={height}>
        <img src={defaultValue || noImage} alt={label} />
        <ImgFileWrapper.Control>
          <label htmlFor={id}>
            <MdPhotoCamera size={16} />
            <input
              id={id}
              type="file"
              ref={inputRef}
              className={error ? 'has-error' : ''}
              onChange={handleUpload}
              {...rest}
            />
          </label>
          <button type="button" onClick={() => handleDelete()}>
            <MdDelete size={16} />
          </button>
        </ImgFileWrapper.Control>
      </ImgFileWrapper>
    </InputWrapper>
  );
}

InputImgFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  src: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  handleUpload: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

InputImgFile.defaultProps = {
  src: '',
  height: 200,
  width: 300,
};
