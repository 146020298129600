import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';
import history from '../../services/history';

import { CSV, opExport } from '../../lib/csv-core';

import { AppError } from '../../errors/AppError';

import ActionMenu from '../../components/ActionMenu';
import TitleBar from '../../components/TitleBar';
import { Table, RowMaster } from '../../components/Table';
import TableHeader from '../../components/Table/TableHeader';
import Pagination from '../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function Fabricante() {
  const location = useLocation();

  const [fabricantes, setFabricantes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaFabricante = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get('/fabricante', {
        params: configFiltro,
      })
      .then((response) => {
        setFabricantes(response.data.fabricante);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location]);

  const handleCriar = useCallback(() => {
    history.push('/fabricante/criar', { background: location });
  }, [location]);

  const handleEditar = useCallback(
    (idFabricante) => {
      history.push('/fabricante/editar', {
        background: location,
        id: idFabricante,
      });
    },
    [location]
  );

  const handleVisualizar = useCallback((e, idFabricante) => {
    e.preventDefault();

    /** verifica se a linha foi clicada */
    if (e.target.nodeName.toUpperCase() === 'TD') {
      history.push('/fabricante/detalhe', {
        id: idFabricante,
      });
    }
  }, []);

  const handleRemover = useCallback(
    async (idFabricante) => {
      if (window.confirm('Deseja realmente excluir o fabricante?')) {
        try {
          await api.delete(`fabricante/${idFabricante}`);

          carregaFabricante();

          toast.success('Fabricante excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [carregaFabricante]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.FABRICANTE);
  }, []);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaFabricante();
  }, [location, currentPage, carregaFabricante]);

  return (
    <Container>
      <TitleBar
        title="Fabricantes"
        isSearchable
        onNew={handleCriar}
        onFileDownload={handleExportar}
      />

      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idFabricante', title: 'Fabricante' },
              { fieldname: 'descricao', title: 'Descrição' },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {fabricantes.map((fabricante) => (
              <RowMaster
                key={fabricante.idFabricante}
                onClick={(e) => handleVisualizar(e, fabricante.idFabricante)}
              >
                <td>{fabricante.idFabricante}</td>
                <td>{fabricante.descricao}</td>
                <td>
                  <ActionMenu
                    data={fabricante}
                    onEdit={() => handleEditar(fabricante.idFabricante)}
                    onRemove={() => handleRemover(fabricante.idFabricante)}
                    onLineRemove={() => handleRemover(fabricante.idFabricante)}
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </Container>
  );
}

export default Fabricante;
