import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import logotipo from '../../../assets/logo.svg';

import history from '../../../services/history';
import api from '../../../services/api';

import { AppError } from '../../../errors/AppError';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

import { Container, Wrapper } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "e-mail" é obrigatório'),
  nome: Yup.string()
    .min(3, 'O campo "nome" precisa ter no mínimo 3 caracteres')
    .required('O campo "nome" é obrigatório'),
  senha: Yup.string()
    .min(6, 'O campo "Senha" precisa ter no mínimo 6 caracteres')
    .required('O campo "Senha" é obrigatório'),
  confirmaSenha: Yup.string().oneOf(
    [Yup.ref('senha'), null],
    'A confirmação de senha não confere'
  ),
});

function UsuarioSignin() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleLogin = useCallback(() => {
    history.push(`/login${location.search}`);
  }, [location]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, nome, senha } = data;

        await api.post('usuario', {
          login: email,
          senha,
          nome,
          email,
        });

        toast.success('Usuário cadastrado com sucesso!');

        /** redireciona para a tela de login */
        handleLogin();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [handleLogin]
  );

  return (
    <Container>
      <img src={logotipo} alt="Assiste" />
      <Wrapper>
        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <h1>Crie sua conta</h1>
          <Input id="email" name="email" type="text" placeholder="Seu e-mail" />
          <Input id="nome" name="nome" type="text" placeholder="Seu nome" />
          <Input
            id="senha"
            name="senha"
            type="password"
            placeholder="Sua senha"
          />
          <Input
            id="confirmaSenha"
            name="confirmaSenha"
            type="password"
            placeholder="Confirme sua senha"
          />

          <button id="btn-submit" type="submit">
            Cadastrar
          </button>

          <button id="btn-back" type="button" onClick={handleLogin}>
            Voltar para login
          </button>
        </Form>
      </Wrapper>
    </Container>
  );
}

export { UsuarioSignin };
