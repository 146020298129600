import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

Container.Wrapper = styled.div`
  padding: 10px 5px;
  height: calc(100% - 5px);
  overflow-y: auto;
`;

Container.Footer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 10px;

  ${(props) =>
    props.withFlexEnd
      ? css`
          justify-content: flex-end;
        `
      : props.withSpaceBetween &&
        css`
          justify-content: space-between;
        `}

  ${(props) =>
    props.withSeparate &&
    css`
      border-top: 1px solid #ccc;
    `}

  
  
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 5px;
    min-height: 42px;
    min-width: 150px;
    color: #a0a9b9;
    transition: all 1s;

    & + button {
      margin-left: 10px;
    }

    & > svg {
      margin-right: 5px;
    }

    &#btn-submit {
      color: #fff;
      background: #ff6d3c;

      &:hover {
        background: ${darken(0.1, '#ff6d3c')};
      }
    }

    &#btn-cancel {
      &:hover {
        color: ${darken(0.1, '#a0a9b9')};
      }
    }
  }
`;

Container.TemplateControl = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: 1px solid ${darken(0.1, '#f2f5fc')};
    border-radius: 5px;

    height: 42px;
    width: 50px;
    margin: 0;

    color: ${darken(0.1, '#f2f5fc')};
    transition: all 1s;

    + button {
      margin-left: 10px;
    }

    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }
`;

Container.HistoryControl = styled.div`
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: 1px solid ${darken(0.1, '#f2f5fc')};
    border-radius: 5px;

    height: 42px;
    width: 50px;
    margin: 0;

    color: ${darken(0.1, '#f2f5fc')};
    transition: all 1s;

    + button {
      margin-left: 10px;
    }

    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }
`;

Container.FormControl = styled.div`
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 5px;
    min-height: 42px;
    min-width: 150px;
    color: #a0a9b9;
    transition: all 1s;

    & + button {
      margin-left: 10px;
    }

    & > svg {
      margin-right: 5px;
    }

    &#btn-submit {
      color: #fff;
      background: #ff6d3c;

      &:hover {
        background: ${darken(0.1, '#ff6d3c')};
      }
    }

    &#btn-cancel {
      &:hover {
        color: ${darken(0.1, '#a0a9b9')};
      }
    }
  }
`;

Container.Note = styled.h5`
  color: #dd0312;
  padding: 5px;
`;

Container.Message = styled.p`
  color: #dd0312;
  padding: 5px;
`;

Container.Footer.Separate = styled.div`
  border-bottom: 1px solid #dbe1e9;
  min-height: ${(props) => (props.height ? `${props.height}px` : `1px`)};

  ${(props) =>
    props.withFlex &&
    css`
      flex: 1;
    `}
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  > span {
    font-size: 14px;
    font-style: italic;
    color: rgba(44, 64, 90, 0.5);
    padding: 20px 10px;
  }

  > button {
    background: #ff6d3c;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 40px;
    font-weight: bold;
    min-width: 180px;
  }
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;
  margin: 0 auto;
  width: 100%;

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${`${props.maxWidth}px`};
    `}
`;

EditorContainer.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 10px #ddd;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
`;

EditorContainer.EditorArea = styled.div`
  display: flex;
`;

EditorContainer.EditorControl = styled.div`
  max-width: 300px;
  width: 100%;
`;
