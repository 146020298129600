import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { darken } from 'polished';
import { ReactSVG } from 'react-svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  /** utiliza largura fixa na tela para o container */
  /* height: calc(100vh - 230px); */
  /* max-width: 600px; */
`;

export const Wrapper = styled.div`
  background: ${(props) => props.theme.background};
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000000d;
  height: 100%;
  width: 98%;
`;

export const ZoomArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  height: 40vh;
  /* max-height: inherit; */
  width: 98%;

  .react-transform-wrapper {
    height: 100%;
    width: 100%;

    .react-transform-component {
      height: 100%;
      width: 100%;
      position: relative;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const SVG = styled(ReactSVG)`
  svg {
    height: 40vh;
    width: auto;
    max-width: 1100px;
    max-height: 390px;

    & image {
      filter: ${(props) => `invert(${props.theme.invertCatalogImage})`};
    }

    @media (min-width: 320px) {
      height: 20vh;
    }

    @media (min-width: 400px) {
      height: 25vh;
    }

    @media (min-width: 480px) {
      height: 30vh;
    }

    @media (min-width: 560px) {
      height: 35vh;
    }
  }

  text {
    pointer-events: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;

    &:hover {
      fill: #fff;
    }
  }

  /* a:hover, */
  .selecting {
    > circle {
      fill: #ff6d3c;
      stroke: #ff6d3c;
    }

    > rect {
      fill: #ff6d3c;
      stroke: #ff6d3c;
    }

    > text {
      /* fill: #fff; */
      stroke: #fff;
    }
  }
`;

export const ItemArea = styled.div`
  display: flex;
  padding: 5px 0 0;
`;

export const ItemAreaExtend = styled.div`
  display: flex;
  padding: 5px 0 0;
  height: 90%;

  #flutuante {
    position: relative;
    margin-top: 5px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  align-items: flex-end;

  display: flex;
  flex-flow: column;
  height: 100%;
  width: 98%;

  font-size: 12px;
  color: ${(props) => props.theme.color};

  > thead {
    flex: 0 0 auto;
    width: calc(100% - 0.9em);

    > tr {
      > th {
        padding: 5px 0;
        font-weight: normal;
        text-align: left;
      }
    }
  }

  > tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    background: ${(props) => props.theme.cardBackground};
    color: ${(props) => props.theme.color};
    ::-webkit-scrollbar {
      display: none;
    }

    /** utiliza largura fixa na tela */
    /* height: 200px; */
    /* height: 20vh; */

    border-radius: 10px;
    box-shadow: 0px 3px 6px #0000000d;
  }

  > thead,
  tbody tr {
    display: table;
    table-layout: fixed;
  }
`;

Table.Row = styled.tr`
  width: 100%;

  > td {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 10px;

    button {
      border: 0;
      background: none;
    }

    div {
      display: flex;
      flex-direction: row;

      span {
        display: flex;
        align-items: center;
        padding: 0 2px;

        #lens {
          scale: 0.5;
        }

        * {
          align-self: center;
        }
      }
    }
  }

  ${(props) =>
    props.selected &&
    css`
      background: #cad5f8;
      font-weight: bold;
    `}

  :hover {
    background: #cad5f8;
    font-weight: bold;
  }
`;

Table.BtnCart = styled.button`
  color: ${(props) => (props.inCart ? '#ff6d3c' : `${props.theme.color}`)};

  transform: color 1s;

  :hover {
    color: ${darken(0.05, '#ff6d3c')};
  }
`;

export const Info = styled.div`
  position: absolute;
  background: rgba(44, 64, 90, 0.8);
  width: 200px;
  min-height: 50px;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  top: ${(props) => `${props.posY}px`};
  left: ${(props) => `${props.posX}px`};

  > strong {
    padding: 10px 0;
  }

  /* ::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 110px;
    width: 0;
    border-bottom: 5px solid rgba(44, 64, 90, 0.8);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  } */
`;

export const NoteContainer = styled(animated.div)`
  position: absolute;
  display: flex;
  flex: 1;
  left: 0;
  bottom: 0;
  margin: 15px;
  color: #27272a;
  background-color: #cbd5e1;
  scale: 0.9;

  min-width: 50px;
  max-width: 200px;
  min-height: 30px;
  max-height: 50px;

  overflow: hidden;
  white-space: nowrap;

  transition: all 0.5s;

  :hover {
    cursor: pointer;
    border-radius: 10px;
    color: #f4f4f5;
    background-color: #64748b;
    transform: scale(1.1);
    transition: all 0.5s;
  }
`;

export const NoteContent = styled(animated.div)`
  position: relative;
  display: flex;
  flex: 1;

  overflow: hidden;
`;

export const Notes = styled.div`
  padding: 10px;
  height: 100%;
  width: 100%;

  & * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NotesModal = styled(animated.div)`
  position: fixed;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 95%;
  max-height: 70%;
  padding: 30px;
  color: #f4f4f5;
  letter-spacing: 0.7px;
  line-height: 20px;
  text-align: justify;
  background: #334155f2;
  border-radius: 10px;
  overflow: auto;
  z-index: 100;
`;

export const NoteHeader = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #f4f4f5;
  margin-bottom: 10px;

  button {
    background: none;
    border: none;

    color: #f4f4f5;
    font-weight: 800;
  }
`;

// Criar um componente deste botão para compor os componentes
//  de tela cheia da imagem ou da tabela
export const BtnFlutuante = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 15px 15px 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  /* background-color: #2c405a; */
  background-color: #e5e7eb;
  /* color: #fff; */
  color: #3b82f6;
`;

export const BtnFlutuanteShare = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* bottom: 0; */
  top: 10px;
  /* left: 10px; */
  right: 0;
  margin: 0 15px 15px 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  /* background-color: #2c405a; */
  background-color: #e5e7eb;
  /* color: #fff; */
  color: #3b82f6;
`;

export const ListaOpcoes = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 5px;
`;

export const Opcoes = styled.button`
  color: ${(props) => props.theme.color};
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  padding: 15px;
`;
