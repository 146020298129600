import React, { useState, useEffect } from 'react';
import { MdZoomIn, MdZoomOut, MdYoutubeSearchedFor } from 'react-icons/md';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';

import api from '../../services/api';

import { AppError } from '../../errors/AppError';

import Modal from '../Modal';

import { Wrapper, ZoomWrapper, ZoomControl } from './styles';

function Viewer({ idCatalogo, idPagina, uri, handleClose }) {
  const [image, setImage] = useState(uri);

  useEffect(() => {
    async function handlePagina() {
      try {
        /** carrega imagem */
        const response = await api.get(
          `editor-classificar/${idCatalogo}/pagina/${idPagina}`
        );

        const { urlImagem } = response.data;

        setImage(urlImagem);
      } catch (err) {
        throw new AppError(err);
      }
    }

    handlePagina();
  }, [idCatalogo, idPagina]);

  return (
    <Modal width={95} height={95} onClose={handleClose}>
      <Wrapper>
        <TransformWrapper initialScale={1} centerOnInit centerZoomedOut>
          {({ zoomIn, zoomOut, centerView }) => (
            <ZoomWrapper>
              <TransformComponent
                wrapperStyle={{
                  justifyContent: 'center',
                  border: '3px solid #444',
                  borderRadius: '8px',
                  backgroundColor: '#444',
                  margin: '0 3px',
                  width: '100%',
                  height: '100%',
                }}
                contentStyle={{
                  width: '50%',
                }}
              >
                <img src={image} alt="Visualização da página" />
              </TransformComponent>
              <ZoomControl>
                <button type="button" onClick={() => zoomIn(0.3)}>
                  <MdZoomIn size={35} />
                </button>
                <button type="button" onClick={() => zoomOut(0.3)}>
                  <MdZoomOut size={35} />
                </button>
                <button type="button" onClick={() => centerView(1, 500)}>
                  <MdYoutubeSearchedFor size={35} />
                </button>
              </ZoomControl>
            </ZoomWrapper>
          )}
        </TransformWrapper>
      </Wrapper>
    </Modal>
  );
}

export default Viewer;

Viewer.propTypes = {
  idCatalogo: PropTypes.number.isRequired,
  idPagina: PropTypes.number.isRequired,
  uri: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
