import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  MdZoomIn,
  MdTablet,
  MdRemove,
  MdPanorama,
  MdPhotoLibrary,
  MdLink,
} from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import {
  opPaginaCatalogoTipo,
  opTipoUpdateClassificacao,
  // opCatalogoTipoSequenciaPagina,
} from '../../../lib/const';

import TitleBar from '../../../components/TitleBar';
import Loading from '../../../components/Loading';
import Viewer from '../../../components/Viewer';

import Pagination from '../../../components/EditorAI/Pagination';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';

import { EditorContainer } from '../../../components/Container';

import { CardList, Card, CardMenu } from './styles';

function EditorClassificarAI() {
  const { id, paginaInicial = 0 } = useLocation().state;

  const formRef = useRef();

  const [catalogo, setCatalogo] = useState(null);
  const [paginas, setPaginas] = useState([]);

  const [viewer, setViewer] = useState(null);
  const [loading, setLoading] = useState(false);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState(null);
  const paginationRef = useRef();

  useLayoutEffect(() => {
    setLoading(true);
    api
      .get(`editor-classificar/${id}`, {
        params: {
          page: 1,
        },
      })
      .then((response) => {
        const {
          paginaCatalogo: _datPaginaCatalogo,
          paginaCatalogoSequencia: _datPaginaCatalogoSequencia,
          ...datCatalogo
        } = response.data;
        setCatalogo(datCatalogo);

        /** paginação */
        setTotalPage(datCatalogo.total);
        setCurrentPage(() => {
          const pagina =
            Math.ceil(paginaInicial / response.data.porPagina) || 1;
          paginationRef.current.setPage(pagina);
          return pagina;
        });
      })
      .catch((err) => {
        AppError(err);
      });
  }, [id, paginaInicial]);

  const carregaClassificacao = useCallback(() => {
    api
      .get(`editor-classificar/${id}`, {
        params: {
          page: currentPage,
        },
      })
      .then((response) => {
        const { paginaCatalogo: datPaginaCatalogo } = response.data;
        setPaginas(datPaginaCatalogo);
      })
      .catch((err) => {
        AppError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, currentPage]);

  useEffect(() => {
    carregaClassificacao();
  }, [id, currentPage, carregaClassificacao]);

  const handleMostrarPagina = useCallback((pagina) => {
    setViewer({
      idCatalogo: pagina.idCatalogo,
      idPagina: pagina.idPagina,
      urlCard: pagina.urlCard,
    });
  }, []);

  const handleClassificar = useCallback(
    async (idPagina, tipo) => {
      await api.put(`editor-classificar/${id}/pagina/${idPagina}`, {
        tipoUpdate: opTipoUpdateClassificacao.CLASSIFICADO,
        tipo,
      });

      carregaClassificacao();
    },
    [id, carregaClassificacao]
  );

  const handleLinked = useCallback(
    (idPagina) => {
      const index = paginas.findIndex((pagina) => pagina.idPagina === idPagina);
      if (index >= 0) {
        paginas[index].linked = true;
        setPaginas([...paginas]);
      }
    },
    [paginas]
  );

  const handleLinkedSubmit = useCallback(
    async (data) => {
      const { idPagina, referencia } = data;

      const index = paginas.findIndex(
        (pagina) => pagina.idPagina === Number(idPagina)
      );
      if (index >= 0) {
        paginas[index].referencia = referencia;
        paginas[index].linked = false;

        const { tipo } = paginas[index];

        await api.put(`editor-classificar/${id}/pagina/${idPagina}`, {
          tipoUpdate: opTipoUpdateClassificacao.ATUALIZA_PAGINA,
          tipo,
          referencia,
        });

        setPaginas([...paginas]);
      }
    },
    [id, paginas]
  );

  const getPageList = useCallback(() => {
    const { qtdePaginaCatalogo = 0 } = catalogo;

    /** gera lista com as páginas anteriores a página atual */
    return Array.from({ length: qtdePaginaCatalogo }, (v, k) => ({
      value: k + 1,
      label: k + 1,
    }));
  }, [catalogo]);

  return (
    <EditorContainer maxWidth={1440}>
      <TitleBar back title="Classificação">
        <button
          type="button"
          id="btn-next"
          onClick={() => history.replace('/edicao/extrairAI', { id })}
        >
          Próximo
        </button>
      </TitleBar>

      <EditorContainer.Wrapper>
        <CardList>
          {paginas.map((pagina) => (
            <Card
              key={pagina.idPagina}
              id={pagina.idPagina}
              state={pagina.tipo}
              selected={pagina.idPagina === paginaInicial}
            >
              <img src={pagina.urlCard} loading="lazy" alt={pagina.idPagina} />
              <CardMenu>
                <CardMenu.Column>
                  <CardMenu.Row alignEnd>
                    <CardMenu.BtnAction
                      type="button"
                      title="Ver"
                      onClick={() => handleMostrarPagina(pagina)}
                    >
                      <MdZoomIn size={20} color="#1063B0" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Desenho"
                      onClick={() =>
                        handleClassificar(
                          pagina.idPagina,
                          opPaginaCatalogoTipo.DESENHO
                        )
                      }
                    >
                      <MdPanorama size={20} color="#538061" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Tabela"
                      onClick={() =>
                        handleClassificar(
                          pagina.idPagina,
                          opPaginaCatalogoTipo.TABELA
                        )
                      }
                    >
                      <MdTablet size={20} color="#E6DE2A" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Misto"
                      onClick={() =>
                        handleClassificar(
                          pagina.idPagina,
                          opPaginaCatalogoTipo.MISTO
                        )
                      }
                    >
                      <MdPhotoLibrary size={20} color="#5485F7" />
                    </CardMenu.BtnAction>
                    <CardMenu.BtnAction
                      type="button"
                      title="Eliminar"
                      onClick={() =>
                        handleClassificar(
                          pagina.idPagina,
                          opPaginaCatalogoTipo.ELIMINADO
                        )
                      }
                    >
                      <MdRemove size={20} color="#B01810" />
                    </CardMenu.BtnAction>
                  </CardMenu.Row>
                  <CardMenu.Row alignEnd>
                    {[2, 3].includes(pagina.tipo) &&
                      (pagina.linked ? (
                        <Form ref={formRef} onSubmit={handleLinkedSubmit}>
                          <Input
                            id="idPagina"
                            name="idPagina"
                            isHidden
                            defaultValue={pagina.idPagina}
                          />
                          <Select
                            id="referencia"
                            name="referencia"
                            isSearchable
                            options={getPageList(pagina.idPagina)}
                            defaultValue={{
                              value: pagina.referencia,
                              label: pagina.referencia,
                            }}
                            width={100}
                            // onChange={() => formRef.current.submitForm()}
                            onBlur={() => formRef.current.submitForm()}
                          />
                        </Form>
                      ) : (
                        <CardMenu.BtnLink
                          type="button"
                          onClick={() => handleLinked(pagina.idPagina)}
                        >
                          {pagina.idPagina}
                          <MdLink size={16} />
                          {pagina.referencia}
                        </CardMenu.BtnLink>
                      ))}

                    {[1, 4].includes(pagina.tipo) && (
                      <span>{pagina.idPagina}</span>
                    )}
                  </CardMenu.Row>
                </CardMenu.Column>
              </CardMenu>
            </Card>
          ))}
        </CardList>
      </EditorContainer.Wrapper>

      {totalPage && (
        <Pagination
          ref={paginationRef}
          pageCount={totalPage}
          onPageChange={(selected) => {
            setCurrentPage(selected);
          }}
        />
      )}

      <Loading visible={loading} />

      {viewer && (
        <Viewer
          idCatalogo={id}
          idPagina={viewer.idPagina}
          uri={viewer.urlCard}
          handleClose={() => setViewer(null)}
        />
      )}
    </EditorContainer>
  );
}

export default EditorClassificarAI;
