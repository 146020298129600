import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  max-width: 280px;
  padding: 0 5px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
