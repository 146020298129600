import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AppError } from '../../../../../errors/AppError';
import api from '../../../../../services/api';
import history from '../../../../../services/history';

import { getFabricante } from '../../../../../lib/asyncUtils';

import TitleBar from '../../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import CheckBox from '../../../../../components/Form/Input/Checkbox';
import AsyncSelect from '../../../../../components/Form/Input/AsyncSelect';
import Switch from '../../../../../components/Form/Input/Switch';

import TableHeader from '../../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../../components/Table';

import ActionMenu from '../../../../../components/ActionMenu';

/** validação dos campos */
const schema = Yup.object().shape({
  codimate: Yup.string().required(
    'O campo "Cód. Material" deve ser preenchido'
  ),
  unidade: Yup.string().nullable(),
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  idFabricante: Yup.number()
    .typeError('O campo "Fabricante" deve ser preenchido')
    .required('O campo "Fabricante" deve ser preenchido'),
});

function EmpresaCatalogoMaterialEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idCatalogo, idMaterial, background } = location.state;

  const [materialEmpresa, setMaterialEmpresa] = useState([]);
  const [linkedFiltro, setLinkedFiltro] = useState(false);

  const carregaMaterialEmpresa = useCallback(() => {
    api
      .get(
        `empresa/${idEmpresa}/catalogo/${idCatalogo}/material-erp/${idMaterial}`
      )
      .then((response) => {
        setMaterialEmpresa(
          response.data.materialERP.map(({ dataDesa, ...m }) => ({
            ...m,
            labParalelo: m.paralelo === '1' ? 'Sim' : 'Não',
            situacao: dataDesa ? 1 : 0,
          }))
        );
      });
  }, [idEmpresa, idCatalogo, idMaterial]);

  useEffect(() => {
    carregaMaterialEmpresa();
  }, [carregaMaterialEmpresa]);

  const handleAdicionar = useCallback(
    async (data, { reset }) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { codimate, descricao, unidade, paralelo, idFabricante } = data;

        await api.post(
          `empresa/${idEmpresa}/catalogo/${idCatalogo}/material-erp`,
          {
            materialEmpresa: [
              {
                codimate,
                descricao,
                unidade,
                idMaterial,
                idFabricante,
                paralelo,
              },
            ],
          }
        );

        reset();

        toast.success('Material adicionado com sucesso!');

        carregaMaterialEmpresa();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idCatalogo, idMaterial, carregaMaterialEmpresa]
  );

  const handleRemover = useCallback(
    async (idMaterialERP) => {
      try {
        await api.delete(
          `empresa/${idEmpresa}/catalogo/${idCatalogo}/material-erp/${idMaterialERP}`
        );

        toast.success('Material removido com sucesso!');

        carregaMaterialEmpresa();
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa, idCatalogo, carregaMaterialEmpresa]
  );

  const opSituacao = useCallback((situacao) => {
    switch (situacao) {
      case 0:
        return <Form.Column.Kind isAdded>Relacionado</Form.Column.Kind>;
      case 1:
        return <Form.Column.Kind isRemoved>Desativado</Form.Column.Kind>;
      default:
        return <Form.Column.Kind>Indefinido</Form.Column.Kind>;
    }
  }, []);

  const handleFiltrar = useCallback(() => {
    setLinkedFiltro(!linkedFiltro);
  }, [linkedFiltro]);

  const listaFiltrada = linkedFiltro
    ? materialEmpresa
    : materialEmpresa.filter((m) => m.situacao === 0);

  return (
    <>
      <TitleBar back={!background} title="Material ERP" />
      <FormWrapper>
        <Form
          id="formEmpresaCatalogoMaterial"
          ref={formRef}
          schema={schema}
          onSubmit={handleAdicionar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="codimate" name="codimate" label="Cód. Material" />
              <Input id="unidade" name="unidade" label="Unidade" />
            </Form.Row>
            <Form.Row>
              <Input id="descricao" name="descricao" label="Descrição" />
              <AsyncSelect
                id="idFabricante"
                name="idFabricante"
                label="Fabricante"
                cacheOptions
                defaultOptions
                loadOptions={getFabricante}
              />
            </Form.Row>
            <Form.Row>
              <CheckBox id="paralelo" name="paralelo" label="Paralelo" />
            </Form.Row>

            <Form.Footer withStart>
              <button
                id="btn-submit"
                type="submit"
                form="formEmpresaCatalogoMaterial"
              >
                Gravar
              </button>
              {/* <Form.Footer.Link type="button" onClick={() => {}}>
                Importar...
              </Form.Footer.Link> */}
            </Form.Footer>

            <Form.Row>
              <Form.Column>
                <Form.Row justifyBetween alignCenter>
                  <h3>Relacionamentos:</h3>
                  <Switch
                    rigthText="Mostrar todos relacionamentos "
                    defaultChecked={linkedFiltro}
                    onChange={handleFiltrar}
                  />
                </Form.Row>

                <Table>
                  <TableHeader
                    withPadding
                    titles={[
                      { fieldname: 'codimate', title: 'Cod. Material' },
                      { fieldname: 'descricao', title: 'Descrição' },
                      { fieldname: 'unidade', title: 'Unidade' },
                      { fieldname: 'paralelo', title: 'Paralelo' },
                      { fieldname: 'situacao', title: 'Situação' },
                      { fieldname: null, title: null },
                    ]}
                    cbSort={() => {}}
                  />
                  <tbody>
                    {listaFiltrada.map((material, index) => (
                      <RowMaster key={index}>
                        <td>{material.codimate}</td>
                        <td>{material.descricao}</td>
                        <td>{material.unidade}</td>
                        <td>{material.labParalelo}</td>
                        <td>{opSituacao(material.situacao)}</td>
                        <td>
                          {material.situacao === 0 && (
                            <ActionMenu
                              data={material}
                              onLineRemove={() =>
                                handleRemover(material.idMaterialERP)
                              }
                              onRemove={() =>
                                handleRemover(material.idMaterialERP)
                              }
                            />
                          )}
                        </td>
                      </RowMaster>
                    ))}
                  </tbody>
                </Table>
              </Form.Column>
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Fechar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EmpresaCatalogoMaterialEditar;
