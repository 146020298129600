import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Container, SwitchComponent } from './styles';

export default function Switch({
  spacing,
  margin,
  leftText,
  rigthText,
  ...rest
}) {
  return (
    <Container
      direction="row"
      spacing={spacing}
      margin={margin}
      alignItems="center"
      justifyContent="center"
    >
      {leftText && <Typography variant="h6">{leftText}</Typography>}
      <SwitchComponent {...rest} />
      {rigthText && <Typography variant="h6">{rigthText}</Typography>}
    </Container>
  );
}

Switch.propTypes = {
  spacing: PropTypes.number,
  margin: PropTypes.string,
  leftText: PropTypes.string,
  rigthText: PropTypes.string,
};

Switch.defaultProps = {
  spacing: 0,
  margin: '0',
  leftText: null,
  rigthText: null,
};
