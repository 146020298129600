import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Checkbox from '@mui/material/Checkbox';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import Pagination from '../../../../components/EditorAI/Pagination';
import { Form } from '../../../../components/Form';
import { Table, RowDefault } from '../../../../components/Table';
import TableHeader from '../../../../components/Table/TableHeader';

function CatalogoRelacArranjoPagina() {
  const location = useLocation();
  const { idCatalogo, idSerie, idArranjo } = location.state;

  const [arranjo, setArranjo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);
  const [arranjoPaginaCatalogo, setArranjoPaginaCatalogo] = useState([]);

  const [tableHeaders, setTableHeaders] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaPagina = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      tpo: '1,3',
      sto: '2',
    };

    /** carrega páginas do catálogo */
    api
      .get(`catalogo-filtro/${idCatalogo}/pagina`, {
        params: configFiltro,
      })
      .then((response) => {
        const fmtNivelClassificacao = [];

        response.data.paginaCatalogo.forEach((pagina, index) => {
          const { nivelClassificacao } = pagina;

          /** pega a descrição dos níveis de classificação na primeira iteração */
          if (index === 0) {
            nivelClassificacao.forEach(
              ({ idNivelClassificacao, descricao }) => {
                fmtNivelClassificacao.push({
                  fieldname: `nivelClassificacao_${idNivelClassificacao}`,
                  title: descricao,
                });
              }
            );
          }
        });

        setPaginaCatalogo(response.data.paginaCatalogo);

        setTableHeaders([
          { fieldname: 'relac', title: '' },
          { fieldname: 'idPagina', title: 'Página' },
          { fieldname: 'descricao', title: 'Descricao' },
          ...fmtNivelClassificacao,
        ]);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        AppError(err);
      });
  }, [location, idCatalogo]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaPagina();
  }, [location, currentPage, carregaPagina]);

  useEffect(() => {
    /** carrega informações do arranjo */
    api
      .get(
        `catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}`
      )
      .then((response) => {
        setArranjo(response.data);
      });

    /** carrega relacionamentos existentes */
    api
      .get(
        `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}/relacionamento-pagina`
      )
      .then((response) => {
        const relacionamento = response.data.map((relac) => relac.idPagina);
        setArranjoPaginaCatalogo(relacionamento);
      });
  }, [idCatalogo, idArranjo, idSerie]);

  const handleAlterar = (checked, idPagina) => {
    if (checked) {
      setArranjoPaginaCatalogo((state) => [...state, idPagina]);
    } else {
      setArranjoPaginaCatalogo((state) => [
        ...state.filter((r) => r !== idPagina),
      ]);
    }
  };

  const handleConfirmar = useCallback(async () => {
    try {
      await api.post(
        `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}/arranjo-catalogo/${idArranjo}/relacionamento-pagina`,
        {
          paginas: arranjoPaginaCatalogo,
        }
      );

      toast.success('Relacionamentos salvos com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, [idCatalogo, idArranjo, idSerie, arranjoPaginaCatalogo]);

  return (
    <>
      <TitleBar
        back={!!location.background}
        title={`Relac. Arranjo / Página ${
          arranjo && `- (${arranjo.nome} / ${arranjo.serie.numeroSerie})`
        }`}
        isSearchable
        // onFileDownload={handleCsv}
        // onFileUpload={handleImportar}
      />

      <Table>
        <TableHeader withPadding titles={tableHeaders} />
        <tbody>
          {paginaCatalogo.map((pagina) => (
            <RowDefault key={pagina.idPagina}>
              <td>
                <Checkbox
                  id={`relac-pagina${pagina.idPagina}`}
                  name="relac"
                  checked={arranjoPaginaCatalogo.includes(pagina.idPagina)}
                  disableRipple
                  onChange={({ target }) =>
                    handleAlterar(target.checked, pagina.idPagina)
                  }
                />
              </td>
              <td>{pagina.idPagina}</td>
              <td>{pagina.descricao}</td>
              {pagina.nivelClassificacao.map(({ nivel }) => {
                const { descricao = '' } = nivel || {};
                return <td>{descricao}</td>;
              })}
            </RowDefault>
          ))}
        </tbody>
      </Table>
      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />

      <Form.Separate withFlex />
      <Form.Footer>
        <button id="btn-cancel" type="button" onClick={() => history.goBack()}>
          Cancelar
        </button>
        <button id="btn-submit" type="submit" onClick={handleConfirmar}>
          Confirmar
        </button>
      </Form.Footer>
    </>
  );
}

export { CatalogoRelacArranjoPagina };
