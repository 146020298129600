import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import { InputWrapper } from '../..';

export default function MaskedInput({
  name,
  label,
  width,
  mask,
  maskChar,
  flexDirection,
  isHidden,
  customPagination,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (!ref.props.mask) return ref.getInputValue();

        let unmasked = '';
        Array.from(ref.value).forEach((v, i) => {
          const isValueChar =
            ref.props.mask[i] === '0' ||
            ref.props.mask[i] === 'a' ||
            ref.props.mask[i] === '*';

          const isMaskChar = v === ref.props.maskChar;

          if (isValueChar && !isMaskChar) unmasked += v;
        });

        return unmasked;
      },
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: (ref) => {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper
      width={width}
      isHidden={isHidden}
      flexDirection={flexDirection}
      customPagination={customPagination}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputMask
        ref={inputRef}
        className={error ? 'has-error' : ''}
        defaultValue={defaultValue}
        mask={mask || ''}
        maskChar={maskChar || ''}
        formatChars={{
          0: '[0-9]',
          a: '[a-z,A-Z ]',
          '*': '[0-9,a-z,A-Z ]',
        }}
        alwaysShowMask={false}
        {...rest}
      >
        {(inputProps) => <input {...inputProps} />}
      </InputMask>
      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

MaskedInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  width: PropTypes.number,
  flexDirection: PropTypes.string,
  isHidden: PropTypes.bool,
  customPagination: PropTypes.bool,
};

MaskedInput.defaultProps = {
  label: null,
  mask: null,
  maskChar: null,
  width: null,
  flexDirection: 'column',
  isHidden: false,
  customPagination: false,
};
