import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useSpring } from 'react-spring';

import {
  MdDelete,
  MdOutlineControlCamera,
  MdContentCopy,
} from 'react-icons/md';
import { VscClearAll } from 'react-icons/vsc';

import PropTypes from 'prop-types';

import { throttle, decimalAdjust } from '../../../lib/inputUtils';
import { Context, Line, Resizer, Resizers, Wrapper } from './styles';

const SelectorElement = forwardRef(
  (
    {
      id,
      initialCoords,
      containerCoords,
      initialLines,
      withLines,
      noResize,
      onRemove,
      onDuplicate,
    },
    ref
  ) => {
    const selectorRef = useRef();
    const resizerRef = useRef();
    const moveHandleRef = useRef();
    const scrollRef = useRef(HTMLElement);
    const contextMenuRef = useRef(HTMLElement);

    const [coords, setCoords] = useState(initialCoords);
    const [onTop, setOnTop] = useState(true);
    const [lines, setLines] = useState(initialLines);
    const [contextIsVisible, setContextIsVisible] = useState(false);

    const selectorSpring = useSpring({
      config: {
        mass: 0.8,
        tension: 300,
        friction: 15,
        precision: 0.001,
      },
      transform: contextIsVisible ? 'scale(100%) ' : 'scale(3%)',
      display: contextIsVisible ? 'flex' : 'none',
    });

    const lineSpring = useSpring({
      config: {
        mass: 0.5,
        tension: 300,
        friction: 15,
        precision: 0.001,
      },
      transform: contextIsVisible
        ? 'translateX(-50%) scale(100%) '
        : 'translateX(-50%) scale(3%)',
      display: contextIsVisible ? 'flex' : 'none',
    });

    const SELECTOR_SIZE = useCallback(
      () => ({
        minTop: 0,
        minLeft: 0,
        minWidth: 10,
        minHeight: 10,
        maxWidth: containerCoords.width,
        maxHeight: containerCoords.height,
      }),
      [containerCoords]
    );

    useEffect(() => {
      setCoords((old) => {
        /** limites máximo e mínimo */

        let newTop;
        let newLeft;
        let newWidth;
        let newHeight;
        const limits = SELECTOR_SIZE();

        if (old.width < limits.minWidth) {
          newWidth = limits.minWidth;
          if (old.left + newWidth > limits.maxWidth)
            newLeft = limits.maxWidth - limits.minWidth;
        }

        if (old.height < limits.minHeight) {
          newHeight = limits.minHeight;
          if (old.top + newHeight > limits.maxHeight)
            newTop = limits.maxHeight - limits.minHeight;
        }

        return {
          top: newTop || old.top,
          left: newLeft || old.left,
          width: newWidth || old.width,
          height: newHeight || old.height,
        };
      });
    }, [SELECTOR_SIZE]);

    const getCurrentLines = useCallback(() => lines, [lines]);

    useImperativeHandle(ref, () => ({
      id,
      getData: () => ({
        borderBox: {
          top: coords.top,
          left: coords.left,
          w: coords.width,
          h: coords.height,
          width: coords.width + coords.left,
          height: coords.height + coords.top,
        },
        points: lines
          .map((line) => line.left + coords.left)
          .sort((a, b) => a.left - b.left),
      }),
      showContext: (state) => setContextIsVisible(state),
      getCurrentLines,
    }));

    /** define posição e tamanho do seletor a partir dos controles nos cantos */
    const handleResize = useCallback(
      ({ clientX, clientY }) => {
        if (!resizerRef || !resizerRef.current) return;

        setCoords((old) => {
          const { id: resizer } = resizerRef.current;

          const { scrollTop, scrollLeft } = scrollRef.current;

          /** posição do mouse */
          const mouseX =
            Math.round(clientX) - containerCoords.left + Math.round(scrollLeft);
          const mouseY =
            Math.round(clientY) - containerCoords.top + Math.round(scrollTop);

          let vectorX;
          let vectorY;
          let newTop;
          let newLeft;
          let newWidth;
          let newHeight;

          const limits = SELECTOR_SIZE();

          /** cálculos da nova posição */
          if (resizer.includes('top')) {
            vectorY = mouseY - old.top;
            newHeight = old.height - vectorY;
            newTop = old.top + vectorY;
          } else {
            vectorY = mouseY - (old.top + old.height);
            newHeight = old.height + vectorY;
            newTop = old.top;
          }

          if (resizer.includes('left')) {
            vectorX = mouseX - old.left;
            newWidth = old.width - vectorX;
            newLeft = old.left + vectorX;
          } else {
            vectorX = mouseX - (old.left + old.width);
            newWidth = old.width + vectorX;
            newLeft = old.left;
          }

          /** limites máximo e mínimo */
          if (!noResize) {
            if (newTop < limits.minTop) {
              newTop = limits.minTop;
              newHeight = old.height + limits.minTop + old.top;
            }

            if (newLeft < limits.minLeft) {
              newLeft = limits.minLeft;
              newWidth = old.width + limits.minLeft + old.left;
            }

            if (newLeft + newWidth > limits.maxWidth) {
              newLeft = old.left;
              newWidth = limits.maxWidth - newLeft;
            }

            if (newTop + newHeight > limits.maxHeight) {
              newTop = old.top;
              newHeight = limits.maxHeight - newTop;
            }

            if (newWidth < limits.minWidth) {
              newLeft -= resizer.includes('left')
                ? limits.minWidth - newWidth
                : 0;
              newWidth = limits.minWidth;
            }

            if (newHeight < limits.minHeight) {
              newTop -= resizer.includes('top')
                ? limits.minHeight - newHeight
                : 0;
              newHeight = limits.minHeight;
            }
          }

          /** redefine posição das linhas em proporção à nova largura */
          if (lines.length > 0) {
            setLines((_lines) =>
              _lines
                .map((line) => ({
                  ...line,
                  left: Math.round(newWidth * line.relativeLeft),
                }))
                .sort((a, b) => a.left - b.left)
            );
          }

          return {
            top: newTop,
            left: newLeft,
            width: newWidth,
            height: newHeight,
          };
        });
      },
      [containerCoords, noResize, SELECTOR_SIZE, resizerRef, lines]
    );

    const handleColumnResize = useCallback(({ clientX }) => {
      if (resizerRef && resizerRef.current) {
        setLines((_lines) => {
          const line = _lines.find(
            (_line) => Number(_line.id) === Number(resizerRef.current.id)
          );

          const { left, width } = selectorRef.current.getBoundingClientRect();

          const mouseX = Math.round(clientX) - Math.round(left);

          let newLeft = mouseX;

          /** limites das colunas */
          if (mouseX < 0) {
            newLeft = 0;
          }

          if (mouseX > Math.round(width)) {
            newLeft = Math.round(width);
          }

          return [
            ..._lines.filter(
              (filteredLine) => Number(filteredLine.id) !== Number(line.id)
            ),
            {
              ...line,
              left: newLeft,
              relativeLeft: decimalAdjust(newLeft / width, -2),
            },
          ].sort((a, b) => a.left - b.left);
        });
      }
    }, []);

    const handleCreateLine = useCallback(
      ({ clientX, shiftKey }) => {
        const { left, width } = selectorRef.current.getBoundingClientRect();

        if (withLines && shiftKey) {
          setLines((_lines) =>
            [
              ..._lines,
              {
                id: Math.random(),
                left: Math.round(clientX) - Math.round(left),
                relativeLeft: decimalAdjust(
                  (Math.round(clientX) - Math.round(left)) / width,
                  -2
                ),
                /** necessário definir as propriedades aqui por questões de performance */
                position: 'absolute',
                top: '0',
                bottom: '0',
                border: '#4286f4',
                cursor: 'w-resize',
              },
            ].sort((a, b) => a.left - b.left)
          );
        }
      },
      [withLines]
    );

    /** define posição do seletor a partir do controle do menu de contexto */
    const handleSelectorMove = useCallback(
      ({ clientX, clientY }) => {
        setCoords((old) => {
          if (document.querySelector('.topmostWrapper')) {
            scrollRef.current = document.querySelector('.topmostWrapper');
          }

          const {
            top: t,
            left: l,
            width: w,
            height: h,
          } = moveHandleRef.current.getBoundingClientRect();

          let newTop;
          let newLeft;

          const limits = SELECTOR_SIZE();
          const vectorX = Math.round(clientX - (l + w / 2));
          const vectorY = Math.round(clientY - (t + h / 2));

          /** cálculos da nova posição */
          newTop = old.top + vectorY;
          newLeft = old.left + vectorX;

          /** limites máximo e mínimo */
          if (newTop < limits.minTop) {
            newTop = limits.minTop;
          }

          if (newLeft < limits.minLeft) {
            newLeft = limits.minLeft;
          }

          if (newLeft + old.width > limits.maxWidth) {
            newLeft = limits.maxWidth - old.width;
          }

          if (newTop + old.height > limits.maxHeight) {
            newTop = limits.maxHeight - old.height;
          }

          return {
            top: newTop,
            left: newLeft,
            width: old.width,
            height: old.height,
          };
        });
      },
      [SELECTOR_SIZE]
    );

    const handleMouseMove = useCallback(
      (e) => {
        e.stopPropagation();

        if (resizerRef && resizerRef.current) {
          const { id: _id } = resizerRef.current;

          /** linhas */
          if (/\d\.\d+$/gi.test(_id)) {
            handleColumnResize(e);
            return;
          }

          /** cantos */
          if (/[A-Za-z]-[A-Za-z]+$/gi.test(_id)) {
            handleResize(e);
            return;
          }

          /** botão mover */
          if (String(_id) === String(moveHandleRef.current.id)) {
            handleSelectorMove(e);
          }
        }
      },
      [handleResize, handleColumnResize, handleSelectorMove]
    );

    const throttledHandleMouseMove = useCallback(
      throttle((e) => {
        e.stopPropagation();
        handleMouseMove(e);
      }, 16),
      [handleMouseMove]
    );

    const handleMouseDown = useCallback(
      (e) => {
        e.stopPropagation();
        e.persist();

        /** pode ser um dos cantos do seletor, uma coluna do seletor ou o botão par mover o seletor */
        if (e.currentTarget !== resizerRef.current)
          resizerRef.current = e.currentTarget;

        window.addEventListener('mousemove', throttledHandleMouseMove);
      },
      [throttledHandleMouseMove]
    );

    const handleMouseUp = useCallback(() => {
      window.removeEventListener('mousemove', throttledHandleMouseMove);
      resizerRef.current = null;
    }, [throttledHandleMouseMove]);

    const handleContextMenu = useCallback((e) => {
      e.preventDefault();

      setContextIsVisible((state) => !state);
    }, []);

    const handleClearLines = () => {
      setLines([]);
    };

    const handleRemoveSelection = useCallback(() => {
      const confirm = window.confirm(
        'Isso irá remover essa seleção, deseja continuar?'
      );

      if (confirm) {
        selectorRef.current = null;
        resizerRef.current = null;
        scrollRef.current = null;
        setLines(null);
        setContextIsVisible(false);
        onRemove();
      }
    }, [onRemove]);

    const handleDuplicateSelection = useCallback(() => {
      setContextIsVisible(false);
      onDuplicate({ coords: { ...coords }, lines: [...lines] });
    }, [coords, lines, onDuplicate]);

    const handleRemoveLine = useCallback((lineId) => {
      setLines((old) =>
        old.filter((line) => line.id !== lineId).sort((a, b) => a.left - b.left)
      );
    }, []);

    const handleLoad = useCallback(() => {
      if (document.querySelector('.topmostWrapper')) {
        scrollRef.current = document.querySelector('.topmostWrapper');
      }

      if (document.getElementById('ContextMenu')) {
        contextMenuRef.current = document
          .getElementById('ContextMenu')
          .getBoundingClientRect();
      }

      selectorRef.current.addEventListener('mousedown', handleCreateLine);
      window.addEventListener('mouseup', handleMouseUp);
    }, [handleCreateLine, handleMouseUp]);

    const handlePutOnTop = (mouseover) => {
      setOnTop(mouseover);
    };

    useEffect(() => {
      handleLoad();

      /** cleanup */
      return () => {
        window.removeEventListener('mouseup', handleMouseUp);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Wrapper
          id={id}
          ref={selectorRef}
          className="selector"
          top={coords.top}
          left={coords.left}
          width={coords.width}
          height={coords.height}
          placeOnTop={onTop}
          onMouseEnter={() => handlePutOnTop(true)}
          onMouseLeave={() => handlePutOnTop(false)}
          onContextMenu={handleContextMenu}
        >
          <Resizers>
            {!noResize && (
              <>
                <Resizer
                  id="top-left"
                  className="resize"
                  onMouseDown={handleMouseDown}
                />
                <Resizer
                  id="top-right"
                  className="resize"
                  onMouseDown={handleMouseDown}
                />
                <Resizer
                  id="bottom-left"
                  className="resize"
                  onMouseDown={handleMouseDown}
                />
                <Resizer
                  id="bottom-right"
                  className="resize"
                  onMouseDown={handleMouseDown}
                />
              </>
            )}
          </Resizers>

          <Context.Selector
            onMouseEnter={() => handlePutOnTop(true)}
            onMouseLeave={() => handlePutOnTop(false)}
          >
            <Context.SelectorOptionGroup>
              {!noResize && (
                <>
                  <Context.SelectorOption
                    ref={moveHandleRef}
                    id="moveHandle"
                    title="Mover Seleção"
                    type="button"
                    style={selectorSpring}
                    onMouseDown={handleMouseDown}
                  >
                    <MdOutlineControlCamera size={16} />
                  </Context.SelectorOption>

                  <Context.SelectorOption
                    title="Duplicar"
                    type="button"
                    style={selectorSpring}
                    onClick={handleDuplicateSelection}
                  >
                    <MdContentCopy size={16} />
                  </Context.SelectorOption>
                </>
              )}
              <Context.SelectorOption
                title="Remover Seleção"
                style={selectorSpring}
                type="button"
                onClick={handleRemoveSelection}
              >
                <MdDelete size={16} />
              </Context.SelectorOption>
            </Context.SelectorOptionGroup>
            <Context.SelectorOptionGroup>
              {withLines && (
                <Context.SelectorOption
                  title="Limpar Divisões"
                  type="button"
                  style={selectorSpring}
                  onClick={handleClearLines}
                >
                  <VscClearAll className="rotate90" size={16} />
                </Context.SelectorOption>
              )}
            </Context.SelectorOptionGroup>
          </Context.Selector>

          {lines.map((line) => (
            <Line
              key={line.id}
              id={line.id}
              left={coords.width * line.relativeLeft}
              position={line.position}
              top={line.top}
              bottom={line.bottom}
              border={line.border}
              cursor={line.cursor}
              onMouseDown={handleMouseDown}
            />
          ))}

          {withLines && (
            <Context.Line
              onMouseEnter={() => handlePutOnTop(true)}
              onMouseLeave={() => handlePutOnTop(false)}
            >
              {lines.map((line, index) => (
                <Context.LineOption
                  key={line.id}
                  title="Remover Linha"
                  type="button"
                  left={line.left}
                  maxleft={coords.width}
                  order={index}
                  inverseorder={Math.abs(index - (lines.length - 1))}
                  style={lineSpring}
                  onClick={() => handleRemoveLine(line.id)}
                >
                  <MdDelete size={16} />
                </Context.LineOption>
              ))}
            </Context.Line>
          )}
        </Wrapper>
      </>
    );
  }
);

export default SelectorElement;

SelectorElement.propTypes = {
  id: PropTypes.number.isRequired,
  initialCoords: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  containerCoords: PropTypes.objectOf(PropTypes.number),
  initialLines: PropTypes.arrayOf(PropTypes.object),
  withLines: PropTypes.bool,
  noResize: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

SelectorElement.defaultProps = {
  initialLines: [],
  containerCoords: { top: 0, left: 0, width: 0, height: 0 },
  withLines: false,
  noResize: false,
};
