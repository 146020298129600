import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';
import {
  opTipoAcesso,
  opTipoDocumento,
  opMascaraDocumento,
} from '../../../../lib/const';
import {
  opAdministrador,
  opEmpresaAdministrador,
  opDocumento,
} from '../../../../lib/inputOption';
import { YupValidaDocumento, splitArray } from '../../../../lib/inputUtils';

import { permiteEmpEmailUsuarioReponsavel } from '../../../../lib/paramUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Input/Select';
import Checkbox from '../../../../components/Form/Input/Checkbox';
import MaskedInput from '../../../../components/Form/Input/MaskedInput';

const schema = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'O campo "nome" precisa ter no mínimo 3 caracteres')
    .required('O campo "Nome" é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" é obrigatório'),
  emailResponsavel: Yup.string()
    .when('$paramField1', (paramField1, schema1) =>
      paramField1
        ? schema1.required('O campo "E-mail Responsável" é obrigatório')
        : schema1
    )
    .email('E-mail responsável é inválido'),
  adm: Yup.number()
    .typeError('O campo "Administrador" deve ser preenchido')
    .required('O campo "Administrador" é obrigatório'),
  utilizaMobile: Yup.string().oneOf(['0', '1']),
  tipoDocumento: Yup.number()
    .typeError('O campo "Documento" deve ser preenchido')
    .required('O campo "Documento" precisa ser preenchido'),
  documento: Yup.string()
    .required('O campo "Número Documento" é obrigatório')
    .test('documento', 'O documento é inválido', function isValid(value) {
      return YupValidaDocumento(value, this);
    }),
});

function EmpresaUsuarioEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idUsuario } = location.state;

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const [usuario, setUsuario] = useState({});
  const [usuarioComplemento, setUsuarioComplemento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState(opTipoDocumento.CPF);

  const [nivelUsuario, setNivelUsuario] = useState(opAdministrador[0]);

  useEffect(() => {
    api.get(`empresa/${idEmpresa}/usuario/${idUsuario}`).then((response) => {
      const {
        adm,
        utilizaMobile,
        tipoDocumento: tipoDoc,
        documento = '',
        dataDesa,
        complemento,
      } = response.data;

      /** Atribui valor inicial para inputs de componentes externos  */
      formRef.current.setFieldValue('adm', opAdministrador[adm]);
      formRef.current.setFieldValue('utilizaMobile', utilizaMobile);
      formRef.current.setFieldValue(
        'tipoDocumento',
        opDocumento[tipoDoc] || opDocumento[0]
      );
      if (documento) formRef.current.setFieldValue('documento', documento);

      setUsuario({
        ...response.data,
        /** formata campo data */
        dataDesa: dataDesa
          ? format(parseISO(dataDesa), 'dd/MM/yyyy HH:mm:ss')
          : null,
      });
      setUsuarioComplemento(complemento);
    });
  }, [idEmpresa, idUsuario]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          context: {
            paramField1: permiteEmpEmailUsuarioReponsavel(),
          },
          abortEarly: false,
        });

        const {
          nome,
          email,
          emailResponsavel,
          adm,
          utilizaMobile,
          tipoDocumento: tipoDoc,
          documento,
          complemento,
        } = data;

        // /** validação de complemento */
        // const schemaComplemento = Yup.object().shape(
        //   usuarioComplemento.reduce(
        //     (accUsco, curUsco) =>
        //       Object.assign(accUsco, {
        //         [curUsco.chave]: Yup.string().required(
        //           `O campo "${curUsco.descricao}" deve ser preenchido`
        //         ),
        //       }),
        //     {}
        //   )
        // );

        // await schemaComplemento.validate(complemento, {
        //   abortEarly: false,
        // });

        const fmtComplemento = complemento
          ? Object.entries(complemento).map((arr) => {
              const [chave, valor] = arr;
              return { chave, valor };
            })
          : null;

        await api.put(`empresa/${idEmpresa}/usuario/${idUsuario}`, {
          nome,
          email,
          emailResponsavel,
          adm: String(adm),
          utilizaMobile: String(utilizaMobile),
          tipoDocumento: String(tipoDoc),
          documento,
          complemento: fmtComplemento,
        });

        toast.success('Usuário atualizado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idUsuario]
  );

  const handleTipoDocumento = useCallback((value) => {
    setTipoDocumento(value);
  }, []);

  const handleNivelUsuario = useCallback((usuarioSelecionado) => {
    setNivelUsuario(usuarioSelecionado);
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Usuário" />
      <FormWrapper>
        <Form
          id="formUsuario"
          ref={formRef}
          initialData={usuario}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="login"
                name="login"
                label="Login"
                type="text"
                disabled
              />
              <Input
                id="dataDesa"
                name="dataDesa"
                label="Data desativação"
                type="text"
                width={500}
                disabled
              />
            </Form.Row>
            <Form.Row>
              <Input id="nome" name="nome" label="Nome" type="text" />
              <Input id="email" name="email" label="E-mail" type="text" />
            </Form.Row>

            <Form.Row>
              {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && (
                <Select
                  id="adm"
                  name="adm"
                  label="Administrador"
                  isSearchable={false}
                  defaultValue={opAdministrador[0]}
                  options={opAdministrador}
                  onChange={(usuarioSelecionado) =>
                    handleNivelUsuario(usuarioSelecionado)
                  }
                />
              )}
              {tipoAcesso === opTipoAcesso.ADMIN_EMPRESA && (
                <Select
                  id="adm"
                  name="adm"
                  label="Administrador"
                  isSearchable={false}
                  defaultValue={opEmpresaAdministrador[0]}
                  options={opEmpresaAdministrador}
                />
              )}
              <Select
                id="tipoDocumento"
                name="tipoDocumento"
                label="Documento"
                defaultValue={opDocumento[opTipoDocumento.CPF]}
                options={opDocumento}
                isSearchable={false}
                onChange={({ value }) => {
                  handleTipoDocumento(value);
                }}
                width={400}
              />
              <MaskedInput
                id="documento"
                name="documento"
                label="Número Documento"
                mask={opMascaraDocumento[tipoDocumento] || ''}
              />
            </Form.Row>
            <Form.Row>
              {permiteEmpEmailUsuarioReponsavel() && (
                <Input
                  id="emailResponsavel"
                  name="emailResponsavel"
                  label="E-mail Responsável"
                  type="text"
                />
              )}
            </Form.Row>
            <Form.Row>
              <Checkbox
                id="utilizaMobile"
                name="utilizaMobile"
                label="Utiliza Mobile?"
              />
            </Form.Row>
            <Form.Column>
              <h3>Informações complementares</h3>
              {usuarioComplemento && usuarioComplemento.length ? (
                <Scope path="complemento">
                  {splitArray(usuarioComplemento, 2).map((arr, idx1) => (
                    // divide array em subarrays de duas posições [[1,2],[3,4]]
                    <Form.Row key={idx1}>
                      {arr.map((cp, idx2) =>
                        nivelUsuario.value === opTipoAcesso.CLIENTE &&
                        cp.descricao === 'WhatsApp' ? null : (
                          <MaskedInput
                            key={idx2}
                            id={cp.chave}
                            name={cp.chave}
                            label={cp.descricao}
                            mask={cp.mascara}
                          />
                        )
                      )}
                    </Form.Row>
                  ))}
                </Scope>
              ) : (
                <Form.NotFound>Nenhum item encontrado</Form.NotFound>
              )}
            </Form.Column>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formUsuario">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaUsuarioEditar };
