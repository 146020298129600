import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  height: 100%;
  width: 100%;

  > img {
    align-self: center;
    height: auto;
    width: 150px;
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 22px;
    color: #2c405a;
    text-align: center;
    margin: 20px 0;
  }

  > form {
    padding: 30px 0;
    /* align-items: stretch; */
    width: 275px;

    > p {
      text-align: center;
      margin: 20px 0;
    }

    > h5 {
      text-align: center;
    }

    button#btn-submit {
      background: #ff6d3c;
      border: 0;
      color: #fff;
      font-size: 15px;
      border-radius: 5px;
      padding: 10px 20px;
      min-width: 150px;
      transition: background 1s;

      :hover {
        background: ${shade(0.2, '#ff6d3c')};
      }
    }

    button {
      background: none;
      border: 0;
      font-weight: bold;
      font-size: 12px;
      color: #ff6d3c;
      transition: color 1s;
      margin-top: 30px;

      :hover {
        color: ${shade(0.2, '#ff6d3c')};
      }
    }

    #btn-login {
      margin: 10px;
    }

    > span {
      color: #2c405a;
      padding: 10px;
      font-size: 12px;
      text-align: center;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  a {
    img {
      height: auto;
      width: 150px;
    }
  }
`;
