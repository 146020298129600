import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { MdModeEdit } from 'react-icons/md';

import TitleBar from '../../../components/TitleBar';

import { Form } from '../../../components/Form';
import InputViewer from '../../../components/Form/Input/Viewer';

import api from '../../../services/api';
import history from '../../../services/history';

import { Container } from './styles';

function UsuarioDetalhe() {
  const location = useLocation();
  const { id } = location.state;

  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    api.get(`usuario/${id}`).then((response) => {
      setUsuario(response.data);
    });
  }, [id]);

  const handleEditar = useCallback(() => {
    history.push('/usuario/editar', {
      background: location,
      idUsuario: id,
    });
  }, [location, id]);

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      <Form.Detail>
        <Form initialData={usuario}>
          <Form.Row>
            <InputViewer id="nome" name="nome" label="Nome" />
            <InputViewer id="login" name="login" label="Login" type="text" />
          </Form.Row>
          <Form.Row>
            <InputViewer id="email" name="email" label="E-mail" type="text" />
            <InputViewer
              id="telefone"
              name="telefone"
              label="Telefone"
              type="text"
            />
            <InputViewer
              id="ramal"
              name="ramal"
              label="Ramal"
              type="text"
              width={250}
            />
          </Form.Row>
          <Form.Separate height={80} />
          <Form.Footer withStart>
            <Form.Footer.Link type="button" onClick={handleEditar}>
              <MdModeEdit size={18} /> Editar
            </Form.Footer.Link>
          </Form.Footer>
        </Form>
      </Form.Detail>
    </Container>
  );
}

export { UsuarioDetalhe };
