import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99;
`;

export const Wrapper = styled(ReactLoading)`
  color: #fff;
`;
