import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

import { Wrapper } from './styles';

function ViewerDetail({ children, handleClose }) {
  return (
    <Modal width={30} height={80} onClose={handleClose}>
      <Wrapper>{children}</Wrapper>
    </Modal>
  );
}

export default ViewerDetail;

ViewerDetail.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  handleClose: PropTypes.func.isRequired,
};
