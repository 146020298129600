import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';
import { toast } from 'react-toastify';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport } from '../../../lib/csv-core';

import { CatalogoArranjo } from '../_catalogo-arranjo';

import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';
import { Table, RowMaster, RowDetail, Expand } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TitleBar from '../../../components/TitleBar';

function CatalogoSerie() {
  const location = useLocation();
  const { id } = location.state;

  const [series, setSeries] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNumeroSerie = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    /** lista de séries do catálogo */
    api
      .get(`catalogo/${id}/serie-catalogo`, {
        params: configFiltro,
      })
      .then((response) => {
        setSeries(
          response.data.serie.map((serie) => ({
            ...serie,
            expandir: false,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNumeroSerie();
  }, [location, currentPage, carregaNumeroSerie]);

  const handleCriar = useCallback(() => {
    history.push('/catalogo/serie-arranjo/criar', {
      background: location,
      idCatalogo: id,
    });
  }, [location, id]);

  const handleEditarSerie = useCallback(
    (idSerie) => {
      history.push(`/catalogo/serie/editar`, {
        background: location,
        idCatalogo: id,
        idSerie,
      });
    },
    [location, id]
  );

  const handleRemoverSerie = useCallback(
    async (idSerie) => {
      if (window.confirm('Deseja realmente excluir o número de série?')) {
        try {
          await api.delete(`catalogo/${id}/serie-catalogo/${idSerie}`);

          carregaNumeroSerie();

          toast.success('Número de série removido com sucesso.');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [id, carregaNumeroSerie]
  );

  const handleExpandir = useCallback(
    (idSerie) => {
      try {
        /** altera propriedade para exibir informações dos arranjos */
        const index = series.findIndex((s) => s.idSerie === idSerie);

        series[index].expandir = !series[index].expandir;

        setSeries([...series]);
      } catch (err) {
        AppError(err);
      }
    },
    [series]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_SERIE, { idCatalogo: id });
  }, [id]);

  return (
    <>
      <TitleBar
        back
        title="Nº de série & Arranjos"
        isSearchable
        onNew={handleCriar}
        // onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: null, title: null },
            { fieldname: 'numeroSerie', title: 'Nº de série' },
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {series.map((serie) => (
            <React.Fragment key={serie.idSerie}>
              <RowMaster onClick={() => handleExpandir(serie.idSerie)}>
                <Expand rotate={serie.expandir ? '1' : '0'}>
                  <MdExpandMore size={25} />
                </Expand>
                <td>{serie.numeroSerie}</td>
                <td>{serie.partnumber}</td>
                <td>
                  <ActionMenu
                    data={serie}
                    onEdit={() => handleEditarSerie(serie.idSerie)}
                    onRemove={() => handleRemoverSerie(serie.idSerie)}
                    onLineEdit={() => handleEditarSerie(serie.idSerie)}
                    onLineRemove={() => handleRemoverSerie(serie.idSerie)}
                  />
                </td>
              </RowMaster>
              {serie.expandir && (
                <RowDetail>
                  <td colSpan="3">
                    <CatalogoArranjo idCatalogo={id} idSerie={serie.idSerie} />
                  </td>
                </RowDetail>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { CatalogoSerie };
