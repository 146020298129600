import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import param from './param/reducer';
import cart from './cart/reducer';
import homeFilter from './homeFilter/reducer';

/** combina os reducers */
export default combineReducers({ auth, user, param, cart, homeFilter });
