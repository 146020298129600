import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { opMobilidade } from '../../../../lib/inputOption';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Input/Select';
import Checkbox from '../../../../components/Form/Input/Checkbox';
import TextArea from '../../../../components/Form/Input/TextArea';

/** validação dos campos */
const schema = Yup.object().shape({
  situacao: Yup.number()
    .typeError('O campo "Situação" deve ser preenchido')
    .required('O campo "Situação" deve ser preenchido'),
  observacao: Yup.string().nullable(),
});

function EmpresaMobilidadeEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, id } = location.state;

  const [mobilidade, setMobilidade] = useState([]);

  useEffect(() => {
    api.get(`empresa/${idEmpresa}/mobilidade/${id}`).then((response) => {
      const { situacao } = response.data;

      /** Atribui valor inicial para o "select" */
      formRef.current.setFieldValue('situacao', opMobilidade[situacao]);

      setMobilidade(response.data);
    });
  }, [idEmpresa, id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { observacao, situacao, hashCEP, limpaHashControle } = data;

        await api.put(`empresa/${idEmpresa}/mobilidade/${id}`, {
          observacao,
          situacao,
          hashCEP: Number(limpaHashControle) === 0 ? hashCEP : '',
        });

        toast.success('Mobilidade atualizada com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Mobilidade" />
      <FormWrapper>
        <Form
          id="formEmpresaMobilidade"
          ref={formRef}
          schema={schema}
          initialData={mobilidade}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="IMEI" name="IMEI" label="IMEI" disabled />
              <Input id="coletor" name="coletor" label="Coletor" disabled />
              <Input id="hashCEP" name="hashCEP" isHidden />
              <Select
                id="situacao"
                name="situacao"
                label="Situação"
                options={opMobilidade}
              />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="observacao"
                name="observacao"
                label="Observação"
                rows={5}
              />
            </Form.Row>
            <Checkbox
              id="limpaHashControle"
              name="limpaHashControle"
              label="Limpar hash de controle"
            />
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresaMobilidade">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaMobilidadeEditar };
