/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdTableRows, MdImage, MdSchema } from 'react-icons/md';

import api from '../../services/api';
import history from '../../services/history';

import { AppError } from '../../errors/AppError';
import { opPaginaCatalogoSituacao } from '../../lib/const';

import TitleBar from '../../components/TitleBar';
import EditorArea from '../../components/EditorAI';
import EditorTable from '../../components/EditorAI/Table';
import Pagination from '../../components/EditorAI/Pagination';
import Switch from '../../components/Form/Input/Switch';

import { Form } from '../../components/Form';
import Input from '../../components/Form/Input';
import Loading from '../../components/Loading';

import { EditorContainer } from '../../components/Container';

import { Action } from './styles';

function EditorAssociarAI() {
  const location = useLocation();
  const { id, paginaInicial = 0 } = location.state;

  const editorRef = useRef();
  const tableRef = useRef();
  const formRef = useRef();
  const formPesqRef = useRef();

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [itensPagina, setItensPagina] = useState([]);
  const [itensFiltro, setItensFiltro] = useState([]);
  const [linkedFiltro, setLinkedFiltro] = useState(false);
  const [loading, setLoading] = useState(false);

  /** controle de pesquisa */
  const [search, setSearch] = useState();

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaPagina = useCallback(async () => {
    /** limpa variável de itens */
    setItensPagina([]);
    setItensFiltro([]);

    /** limpa campo de pesquisa */
    if (formPesqRef.current) {
      formPesqRef.current.reset();
    }

    /** cancela operação se não houver catálogo e página para busca */
    if (!(catalogo && currentPage)) return true;

    const pagina = catalogo.paginas[currentPage - 1];

    /** cancela operação se página não existir */
    if (!pagina) return true;

    setLoading(true);
    try {
      /** carrega itens da página */
      const response = await api.get(`editor-associar/${id}/pagina/${pagina}`);
      setPaginaCatalogo(response.data);

      setItensPagina(() =>
        response.data.itemPagina.map((item) => ({
          ...item,
          ...item.materialFabricante,
          linked: false,
          highlighted: false,
          locked: false,
        }))
      );

      setSearch('');
    } finally {
      setLoading(false);
      if (editorRef.current) editorRef.current.getLinked();
    }
  }, [id, catalogo, currentPage]);

  useEffect(() => {
    /** informações do catálogo */
    api.get(`editor-associar/${id}`).then((response) => {
      const paginas = response.data.paginaCatalogo || [];

      setPaginaCatalogo(null);

      setCatalogo({
        ...response.data,
        paginas: paginas.map((pagina) => pagina.idPagina),
      });

      setTotalPage(response.data.total);

      setCurrentPage(() => {
        /** index da última página concluída do catálogo */
        const lastIndex = Math.max(
          ...paginas.reduce((acumuladoPagina, atualPagina, index) => {
            // adiciona primeira página
            if (
              index === 0 ||
              atualPagina.situacao === opPaginaCatalogoSituacao.CONCLUIDO
            ) {
              acumuladoPagina.push(index);
            }

            return acumuladoPagina;
          }, [])
        );

        const lastDonePage = lastIndex + 1;

        return paginaInicial || lastDonePage;
      });
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    carregaPagina();
  }, [id, catalogo, currentPage, carregaPagina]);

  const handleSalvar = useCallback(async ({ svg }) => {
    const formData = new FormData();
    formData.append('file', svg);

    /** enviar o arquivo para o servidor e recebe o local em que ele foi armazenado */
    const response = await api.post('files', formData);
    const { path } = response.data;

    formRef.current.setFieldValue('caminho', path);

    formRef.current.submitForm();
  }, []);

  const handleCancelar = useCallback(async () => {
    carregaPagina();
  }, [carregaPagina]);

  const handleConfirmar = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const { caminho } = data;
        const { idPagina } = paginaCatalogo;

        /** envia todas as informações para serem persistidas */
        await api.put(`editor-associar/${id}/pagina/${idPagina}`, {
          caminho,
        });

        carregaPagina();

        /** mensagem de sucesso */
        toast.success('Página salva com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      } finally {
        setLoading(false);
      }
    },
    [id, paginaCatalogo, carregaPagina]
  );

  const handleFiltro = useCallback(
    (searching, filtered) => {
      setItensFiltro(() => {
        let newValue = itensPagina;

        if (filtered) newValue = newValue.filter((item) => !item.linked);

        if (searching)
          newValue = newValue.filter((item) =>
            String(item.idItem)
              .toUpperCase()
              .startsWith(String(searching).toUpperCase())
          );

        return newValue;
      });

      setSearch(searching);
      setLinkedFiltro(filtered);
    },
    [itensPagina]
  );

  const handlePesquisar = useCallback(
    (e) => {
      handleFiltro(e.target.value, linkedFiltro);
    },
    [linkedFiltro, handleFiltro]
  );

  const handleFiltrar = useCallback(() => {
    handleFiltro(search, !linkedFiltro);
  }, [search, linkedFiltro, handleFiltro]);

  const handleLink = useCallback((itens) => {
    const lockStates = tableRef.current ? tableRef.current.getLockStates() : [];

    setItensPagina((old) =>
      old.map((item) => {
        const { locked } =
          lockStates.find((link) => link.idItem === item.idItem) || false;

        const linked = itens.includes(item.idItem);

        return {
          ...item,
          linked,
          highlighted: false,
          locked: linked && locked,
        };
      })
    );
  }, []);

  const handleHighlight = useCallback((links, component) => {
    let ref = null;
    switch (component) {
      case 'table':
        ref = editorRef.current;
        break;
      case 'editor':
        ref = tableRef.current;
        break;
      default:
        break;
    }

    if (ref) ref.highlightLinks(links);
  }, []);

  useEffect(
    () => {
      handleFiltro(search, linkedFiltro);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFiltro]
  );

  /** redireciona para a referencia */
  const handleNavegar = useCallback(
    (param = 'isClassificar' || 'isExtrair' || 'isEditar') => {
      const { idPagina } = paginaCatalogo || {};

      if (!idPagina) {
        throw new AppError('Referência não encontrada!');
      }

      try {
        switch (param) {
          case 'isClassificar':
            history.replace(`/edicao/classificarAI`, {
              id,
              paginaInicial: idPagina,
            });
            break;
          case 'isExtrair':
            api.get(`editor-extrair/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.referencia === idPagina
              );

              if (paginaIndex === -1)
                throw new AppError(
                  'Não há tabela com referência para esta página'
                );

              history.replace(`/edicao/extrairAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          case 'isEditar':
            api.get(`editor-editar/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.idPagina === idPagina
              );
              history.replace(`/edicao/editarAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          default:
            break;
        }
      } catch (err) {
        AppError(err);
      }
    },
    [id, paginaCatalogo]
  );

  const handleVoltar = useCallback(() => {
    history.replace('/edicao/editarAI', { id });
  }, [id]);

  return (
    <EditorContainer maxWidth={1440}>
      <TitleBar back onBack={() => handleVoltar()} title="Mapeamento">
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Classificação"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isClassificar')}
        >
          <MdSchema size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Extração"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isExtrair')}
        >
          <MdTableRows size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Edição"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isEditar')}
        >
          <MdImage size={30} />
        </Action.BtnSquare>
      </TitleBar>

      <Loading visible={loading} />

      <EditorContainer.Wrapper>
        {catalogo && (
          <EditorContainer.EditorArea>
            <EditorContainer.EditorControl>
              <Pagination
                pageCount={totalPage}
                initialData={currentPage}
                onPageChange={(selected) => {
                  setCurrentPage(selected);
                }}
                location={{
                  catalogo: catalogo.idCatalogo,
                  pagina: paginaCatalogo ? paginaCatalogo.idPagina : 0,
                }}
              />
              {paginaCatalogo && (
                <>
                  <Form
                    ref={formRef}
                    id="editorForm"
                    onSubmit={handleConfirmar}
                  >
                    <Input id="caminho" name="caminho" isHidden />
                  </Form>
                  <Form ref={formPesqRef} autoComplete="off">
                    <Input
                      id="pesquisa"
                      name="pesquisa"
                      placeholder="Digita o item para pesquisar..."
                      onChange={handlePesquisar}
                    />
                  </Form>
                  <Switch
                    rigthText="Ocultar relacionados"
                    defaultChecked={linkedFiltro}
                    onChange={handleFiltrar}
                  />
                </>
              )}
              {paginaCatalogo && itensPagina && (
                <EditorTable
                  ref={tableRef}
                  idCatalogo={id}
                  idPagina={paginaCatalogo.idPagina}
                  itensPagina={itensFiltro}
                  isDraggable
                  onLinkHighlight={(links, component) => {
                    handleHighlight(links, component);
                  }}
                />
              )}
            </EditorContainer.EditorControl>

            <EditorArea
              ref={editorRef}
              pagina={paginaCatalogo}
              itensPagina={itensPagina}
              catalogo={catalogo}
              onSave={handleSalvar}
              onCancel={handleCancelar}
              onBeforeOCR={() => setLoading(true)}
              onAfterOCR={() => setLoading(false)}
              onAssociate={(links) => {
                handleLink(links);
              }}
              onLinkHighlight={(links, component) => {
                handleHighlight(links, component);
              }}
            />
          </EditorContainer.EditorArea>
        )}
      </EditorContainer.Wrapper>
    </EditorContainer>
  );
}

export default EditorAssociarAI;
