import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AppError } from '../../errors/AppError';

import api from '../../services/api';
import history from '../../services/history';

import { opTipoAcao } from '../../lib/const';

import { limpaCarrinho } from '../../store/modules/cart/actions';

import TitleBar from '../../components/TitleBar';
import { FormWrapper, Form } from '../../components/Form';
import Input from '../../components/Form/Input';
import TextArea from '../../components/Form/Input/TextArea';

import TableHeader from '../../components/Table/TableHeader';
import { Table, RowMaster } from '../../components/Table';

import ActionMenu from '../../components/ActionMenu';

/** validação dos campos */
const schemaPara = Yup.object().shape({
  paraNome: Yup.string().required('O campo "Para" deve ser preenchido'),
  paraEmail: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
});

const schema = Yup.object().shape({
  deNome: Yup.string().required('O campo "Nome" deve ser preenchido'),
  deEmail: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
  deTelefone: Yup.number()
    .typeError('O campo "Telefone" deve ser preenchido')
    .required('O campo "Telefone" deve ser preenchido'),
  deEmpresa: Yup.string().required('O campo "Empresa" deve ser preenchido'),
  mensagem: Yup.string().nullable(),
});

export const whatsAppTipo = {
  WHATSAPP_CARRINHO: 0,
  WHATSAPP_CHECKOUT: 1,
  WHATSAPP_PAGINA: 2,
};

function WhatsApp() {
  const formRef = useRef(null);

  const location = useLocation();
  const { tipo, paraEmpresa, data: extras } = location.state;

  const profile = useSelector((state) => state.user.profile || {});
  const { action } = useSelector((state) => state.auth || {});

  const dispatch = useDispatch();

  const [whatsApp, setWhatsApp] = useState([]);

  useEffect(() => {
    const { empresa } = profile;

    /** atribui automaticamente a empresa como destinatário único */
    if (empresa && empresa.email && paraEmpresa) {
      setWhatsApp([{ paraNome: empresa.descricao, paraEmail: empresa.email }]);
    }
  }, [profile, paraEmpresa]);

  const handleConfirmar = useCallback(async () => {
    try {
      const data = formRef.current.getData();

      await schema.validate(data, {
        abortEarly: false,
      });

      const { deEmail, deNome, deTelefone, deEmpresa, mensagem } = data;

      /** verifica foi adicionado pelo meno um destinatário */
      if (whatsApp.length === 0) {
        throw new Error('É necessário adicionar pelo menos um destinatário');
      }

      /** formata o remetente */
      const remetente = `{"deEmail":"${deEmail}","deNome":"${deNome}","deTelefone":"${deTelefone}","deEmpresa":"${deEmpresa}"}`;

      /** formata os destinatários */
      const destinatario = whatsApp
        .map(
          ({ paraEmail, paraNome }) =>
            `{"paraEmail":"${paraEmail}","paraNome":"${paraNome}"}`
        )
        .join(';');

      /** pega as informações extras */
      const { idSolicitacao = null, attachment = null, filename = null } =
        extras || {};

      /** formulário de dados */
      const formData = new FormData();
      formData.append('file', attachment);
      formData.append('remetente', remetente);
      formData.append('destinatario', destinatario);
      formData.append('mensagem', mensagem);

      switch (tipo) {
        case whatsApp.WHATSAPP_CARRINHO:
          await api.post('/email/carrinho', formData);
          break;
        case whatsApp.WHATSAPP_CHECKOUT:
          /** informações adicionais da solicitação */
          formData.append('idSolicitacao', idSolicitacao);

          await api.post('/email/solicitacao', formData);
          break;
        case whatsApp.WHATSAPP_PAGINA:
          await api.post('/email/pagina-catalogo', {
            remetente,
            destinatario,
            mensagem,
            filename,
          });
          break;
        default:
          break;
      }

      if (action === opTipoAcao.INTEGRACAO_TOKEN) {
        /** limpa itens do carrinho */
        dispatch(limpaCarrinho());
      }

      toast.success('E-mail enviado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err, formRef);
    }
  }, [whatsApp, tipo, extras, action, dispatch]);

  const handleAdicionar = useCallback(async (data) => {
    try {
      await schemaPara.validate(data, {
        abortEarly: false,
      });

      const { paraNome, paraEmail } = data;

      setWhatsApp((state) => [
        ...state,
        {
          paraNome,
          paraEmail,
        },
      ]);

      /** limpa campos do destinatário */
      formRef.current.clearField('paraNome');
      formRef.current.clearField('paraEmail');
    } catch (err) {
      AppError(err);
    }
  }, []);

  const handleRemover = useCallback(
    (index) => {
      /** Excluí destinatário */
      whatsApp.splice(index, 1);

      setWhatsApp([...whatsApp]);
    },
    [whatsApp]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Envio por WhatsApp" />
      <FormWrapper>
        <Form
          id="formEmail"
          ref={formRef}
          onSubmit={handleAdicionar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="deNome"
                name="deNome"
                placeholder="Seu nome"
                type="text"
              />
              <Input
                id="deTelefone"
                name="deTelefone"
                placeholder="Seu telefone"
                type="number"
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="deEmail"
                name="deEmail"
                placeholder="Seu e-mail"
                type="text"
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="deEmpresa"
                name="deEmpresa"
                placeholder="Sua empresa"
                type="text"
              />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="mensagem"
                name="mensagem"
                placeholder="Sua mensagem"
                rows="8"
              />
            </Form.Row>

            {!paraEmpresa && (
              <>
                <h3>Destinatário</h3>
                <Form.Row>
                  <Input
                    id="paraNome"
                    name="paraNome"
                    placeholder="Para:"
                    type="text"
                  />
                  <Input
                    id="paraEmail"
                    name="paraEmail"
                    placeholder="E-mail:"
                    type="text"
                  />
                </Form.Row>

                <Form.Footer withStart>
                  <button id="btn-submit" type="submit" form="formEmail">
                    Adicionar
                  </button>
                </Form.Footer>

                <Form.Row>
                  <Form.Column>
                    <h3>Lista de Destinatários:</h3>
                    <Table>
                      <TableHeader
                        withPadding
                        titles={[
                          { fieldname: 'para', title: 'Para' },
                          { fieldname: 'email', title: 'E-mail' },
                          { fieldname: null, title: null },
                        ]}
                        cbSort={() => {}}
                      />
                      <tbody>
                        {whatsApp.map((email, index) => (
                          <RowMaster key={index}>
                            <td>{email.paraNome}</td>
                            <td>{email.paraEmail}</td>
                            <td>
                              <ActionMenu
                                data={email}
                                onLineRemove={() => handleRemover(index)}
                                onRemove={() => handleRemover(index)}
                              />
                            </td>
                          </RowMaster>
                        ))}
                      </tbody>
                    </Table>
                  </Form.Column>
                </Form.Row>
              </>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="button" onClick={handleConfirmar}>
              Enviar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { WhatsApp };
