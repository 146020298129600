import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

/** validação dos campos */
const schema = Yup.object().shape({
  chave: Yup.string().required('O campo "Chave" deve ser preenchido'),
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  tamanho: Yup.string(),
  mascara: Yup.string(),
});

function EmpresaTipoComplementoCriar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { chave, descricao, tamanho, mascara } = data;

      await api.post('tipo-complemento', {
        chave,
        descricao,
        tamanho,
        mascara,
      });

      toast.success('Recurso cadastrado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Novo Tipo Complemento" />
      <FormWrapper>
        <Form
          id="formEmpresaTipoComplemento"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="chave" name="chave" label="Chave" width={300} />
              <Input id="descricao" name="descricao" label="Descrição" />
            </Form.Row>
            <Form.Row>
              <Input
                id="tamanho"
                name="tamanho"
                label="Tamanho"
                type="number"
              />
              <Input id="mascara" name="mascara" label="Máscara" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button
              id="btn-submit"
              type="submit"
              form="formEmpresaTipoComplemento"
            >
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaTipoComplementoCriar };
