import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MdImageSearch } from 'react-icons/md';
import { Image, Title, Wrapper } from './styles';

export default function HoverImagePortal({ option, src, alt, isVisible }) {
  const [hoverContainer, setHoverContainer] = useState(null);

  useEffect(() => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    setHoverContainer(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  if (!hoverContainer || !isVisible) return null;

  return ReactDOM.createPortal(
    <Wrapper>
      <Title>
        <MdImageSearch style={{ marginRight: '10px' }} size={25} />
        {option.label.descricaoPagina}
      </Title>
      <Image className="hover-image-async-select" src={src} alt={alt} />
    </Wrapper>,
    hoverContainer
  );
}
