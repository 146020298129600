import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { store } from '../store';

import { opLayout, opTipoAcao, opTipoAcesso } from '../lib/const';

import { AuthLoginLayout } from '../pages/_layouts/auth-login';
import { AuthSigninLayout } from '../pages/_layouts/auth-signin';
import { AuthForgotLayout } from '../pages/_layouts/auth-forgot';
import { AuthRedefinirLayout } from '../pages/_layouts/auth-redefinir';
import DefaultLayout from '../pages/_layouts/default';
import ModalLayout from '../pages/_layouts/modal';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAdmin,
  isAdminComp,
  isLayout,
  withPadding,
  withFooter,
  withModalBtnClose,
  ...rest
}) {
  /** verifica estado de autenticação */
  const { auth } = store.getState();

  /** redireciona caso o usuário não esteja autenticado e não seja um acesso pelo token */
  if (!auth.signed && isPrivate && auth.action !== opTipoAcao.INTEGRACAO_TOKEN)
    return <Redirect to="/login" />;

  if (auth.administrator === opTipoAcesso.USUARIO && isAdmin)
    return <Redirect to="/" />;

  /** controle de redirecionamento para usuário administrador da empresa */
  if (
    auth.administrator === opTipoAcesso.ADMIN_EMPRESA &&
    isAdmin &&
    !isAdminComp
  )
    return <Redirect to="/" />;

  /** caso esteja autenticado, redireciona para tela principal */
  if (
    auth.signed &&
    [opLayout.AUTH_LOGIN, opLayout.AUTH_SIGNIN, opLayout.AUTH_FORGOT].includes(
      isLayout
    )
  )
    return <Redirect to="/" />;

  /** definição do layout para autenticação ou layout padrão */
  let Layout = null;
  switch (isLayout) {
    case opLayout.MODAL:
      Layout = ModalLayout;
      break;
    case opLayout.AUTH_LOGIN:
      Layout = AuthLoginLayout;
      break;
    case opLayout.AUTH_SIGNIN:
      Layout = AuthSigninLayout;
      break;
    case opLayout.AUTH_FORGOT:
      Layout = AuthForgotLayout;
      break;
    case opLayout.AUTH_REDEFINIR:
      Layout = AuthRedefinirLayout;
      break;
    default:
      Layout = DefaultLayout;
      break;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout
          withPadding={withPadding}
          withFooter={withFooter}
          withModalBtnClose={withModalBtnClose}
          {...rest}
        >
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isPrivate: PropTypes.bool,
  isLayout: PropTypes.number,
  isAdmin: PropTypes.bool,
  isAdminComp: PropTypes.bool,
  withPadding: PropTypes.bool,
  withFooter: PropTypes.bool,
  withModalBtnClose: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isLayout: 0,
  isAdmin: false,
  isAdminComp: false,
  withPadding: true,
  withFooter: true,
  withModalBtnClose: true,
};
