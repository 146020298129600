import React, { useRef, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { opMobilidade } from '../../../../lib/inputOption';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Input/Select';
import Checkbox from '../../../../components/Form/Input/Checkbox';
import TextArea from '../../../../components/Form/Input/TextArea';

/** validação dos campos */
const schema = Yup.object().shape({
  geraIMEIAutomatico: Yup.boolean(),
  IMEI: Yup.mixed()
    .nullable()
    .when('geraIMEIAutomatico', {
      is: false,
      then: Yup.number()
        .typeError('O campo "IMEI" deve ser preenchido')
        .required('O campo "IMEI" deve ser preenchido'),
    }),
  coletor: Yup.number()
    .typeError('O campo "Coletor" deve ser preenchido')
    .required('O campo "Coletor" deve ser preenchido')
    .min(1, 'O valor do campo deve estar entre 1 e 999')
    .max(999, 'O valor do campo deve estar entre 1 e 999'),
  situacao: Yup.number()
    .typeError('O campo "Situação" deve ser preenchido')
    .required('O campo "Situação" deve ser preenchido'),
  observacao: Yup.string().nullable(),
});

function EmpresaMobilidadeCriar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa } = location.state;

  const [checagem, setChecagem] = useState(false);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { IMEI, coletor, observacao, situacao } = data;

        await api.post(`empresa/${idEmpresa}/mobilidade`, {
          IMEI,
          coletor,
          observacao,
          situacao,
        });

        toast.success('Mobilidade cadastrada com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa]
  );

  const handleGerarIMEIAutomatico = useCallback(() => {
    setChecagem((state) => !state);
    /** limpa campo IMEI */
    formRef.current.setFieldValue('IMEI', '');
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Nova Mobilidade" />
      <FormWrapper>
        <Form
          id="formEmpresaMobilidade"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Column>
              <Form.Row>
                <Input
                  id="IMEI"
                  name="IMEI"
                  label="IMEI"
                  type="number"
                  disabled={checagem}
                />
                <Input
                  id="coletor"
                  name="coletor"
                  label="Coletor"
                  type="number"
                />
                <Select
                  id="situacao"
                  name="situacao"
                  label="Situação"
                  options={opMobilidade}
                  defaultValue={opMobilidade[0]}
                />
              </Form.Row>
              <Checkbox
                id="geraIMEIAutomatico"
                name="geraIMEIAutomatico"
                label="Gerar IMEI Automaticamente"
                onChange={() => handleGerarIMEIAutomatico()}
              />
            </Form.Column>
            <Form.Row>
              <TextArea
                id="observacao"
                name="observacao"
                label="Observação"
                rows={5}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresaMobilidade">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaMobilidadeCriar };
