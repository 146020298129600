import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';

import { store } from '../../../store';
import { AppError } from '../../../errors/AppError';

import { Header } from '../../../components/Header';
import { MobileHeader } from '../../../components/Mobile/Header';

import MarcaDAgua from '../../../assets/marca-dagua-assiste.svg';

import {
  Wrapper,
  Content,
  Footer,
  MobileWrapper,
  MobileContent,
  MobileFooter,
} from './styles';
import api from '../../../services/api';
import { signOut } from '../../../store/modules/auth/actions';

export default function DefaultLayout({ children, withPadding, withFooter }) {
  /** verifica estado de autenticação */
  const {
    user: { profile },
  } = store.getState();

  const dispatch = useDispatch();

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          dispatch(signOut());

          AppError(error);
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <>
      {isMobile ? (
        <MobileWrapper className="topmostWrapper">
          <MobileHeader />
          <MobileContent withPadding={withPadding}>
            {children}

            <MobileFooter>
              <a
                href="http://assiste.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                title={`Assiste - CDI - v${process.env.REACT_APP_VERSION}`}
              >
                <img src={MarcaDAgua} alt="logotipo" />
              </a>
            </MobileFooter>
          </MobileContent>
        </MobileWrapper>
      ) : (
        <Wrapper className="topmostWrapper">
          <Header />
          <Content withPadding={withPadding}>
            {children}

            {withFooter && profile && profile.empresa && (
              <Footer>
                <a
                  href="http://assiste.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Assiste - CDI - v${process.env.REACT_APP_VERSION}`}
                >
                  <img src={MarcaDAgua} alt="logotipo" />
                </a>
              </Footer>
            )}
          </Content>
        </Wrapper>
      )}
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  withPadding: PropTypes.bool,
  withFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  withPadding: true,
  withFooter: true,
};
