import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../../services/api';
import history from '../../../../../services/history';

import { AppError } from '../../../../../errors/AppError';

import TitleBar from '../../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
});

function EmpresaNavegacaoCriar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa, idClassificacao } = location.state;

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { descricao } = data;

        await api.post(
          `empresa/${idEmpresa}/navegacao-classificacao/${idClassificacao}/navegacao`,
          {
            descricao,
          }
        );

        toast.success('Item cadastrado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa, idClassificacao]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Novo Item" />
      <FormWrapper>
        <Form
          id="formEmpresaNavegacao"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                width={800}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresaNavegacao">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaNavegacaoCriar };
