import styled, { css } from 'styled-components';

const handleColorType = (id) => {
  switch (id) {
    case 1:
      return 'rgba(83,128,97,.9)';
    case 2:
      return 'rgba(230,222,42,.9)';
    case 3:
      return 'rgba(84,133,247,.9)';
    case 4:
      return 'rgba(176,24,16,.9)';
    default:
      return 'rgba(170,170,170,.9)';
  }
};

const handleColorStatus = (id) => {
  switch (id) {
    case 1:
      return 'rgba(230,222,42,.9)';
    case 2:
      return 'rgba(83,128,97,.9)';
    default:
      return 'rgba(170,170,170,.9)';
  }
};

export const Container = styled.div`
  > h2 {
    padding: 20px 10px 10px;
  }

  > strong {
    display: block;
    color: #2c405a;
    font-size: 14px;
    padding: 0 10px 10px;
  }

  padding-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

export const TableCatalog = styled.div`
  display: flex;
  height: 500px;
  overflow: auto;
`;

TableCatalog.Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin: 0 5px;
  }
`;

TableCatalog.ColumnType = styled.div`
  display: block;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 100px;
  min-width: 100px;

  ${({ type }) =>
    type &&
    css`
      color: ${handleColorType(type)};
      border: 2px solid ${handleColorType(type)};
    `}
`;

TableCatalog.ColumnStatus = styled.div`
  display: block;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 100px;
  min-width: 100px;

  ${({ status }) =>
    status &&
    css`
      color: ${handleColorStatus(status)};
      border: 2px solid ${handleColorStatus(status)};
    `}
`;

TableCatalog.Menu = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    border: 0;
    padding: 0 2px;
    background: none;
    color: #8fa0ba;
    transition: color 1s;

    & + button {
      margin-left: 10px;
    }
  }
`;

TableCatalog.Menu.BtnEdit = styled.button`
  :hover {
    color: #538061;
  }
`;

TableCatalog.Menu.BtnExtract = styled.button`
  :hover {
    color: #e6de2a;
  }
`;

TableCatalog.Menu.BtnDelete = styled.button`
  :hover {
    color: #de3b3b;
  }
`;

export const CatalogStatus = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 150px;
  min-width: 150px;

  ${({ type }) =>
    css`
      color: ${handleColorStatus(type)};
      border: 2px solid ${handleColorStatus(type)};
    `}

  & + span {
    margin-left: 5px;
  }
`;

export const MessageStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* border: 2px dashed #2c405a; */
  /* border-radius: 5px; */
  color: #2c405a;
  height: 100px;
`;

MessageStatus.Title = styled.span``;

MessageStatus.Error = styled.span`
  color: #dd0312;
  font-size: 12px;
  padding: 5px;
`;

MessageStatus.BtnAct = styled.button`
  background: none;
  border: 2px solid #ff6d3c;
  border-radius: 5px;
  color: #ff6d3c;
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 1s;

  :hover {
    background: #ff6d3c;
    border: 2px solid #ff6d3c;
    border-radius: 5px;
    color: #fff;
  }
`;

MessageStatus.BtnError = styled.button`
  background: none;
  border: 2px solid #dd0312;
  border-radius: 5px;
  color: #dd0312;
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 1s;

  :hover {
    background: #dd0312;
    border: 2px solid #dd0312;
    border-radius: 5px;
    color: #fff;
  }
`;
