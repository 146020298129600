import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';

import history from '../../../../services/history';

import { Action, Faded } from './styles';

export function EditButton({ idCatalogo, idPagina, item }) {
  const location = useLocation();

  const [itemSalvo, setItemSalvo] = useState(null);

  useEffect(() => {
    setItemSalvo(!!item.idMaterial);
  }, [item]);

  const handleEditarItem = useCallback(() => {
    history.push('/catalogo/itens/editar', {
      background: location,
      idCatalogo,
      idPagina,
      idItem: String(item.idItem),
      idMaterial: String(item.idMaterial),
    });
  }, [idCatalogo, idPagina, item, location]);

  return itemSalvo ? (
    <Action className="edit">
      <MdEdit onClick={() => handleEditarItem(item)} size={20} />
    </Action>
  ) : (
    <Faded>
      <MdEdit size={20} />
    </Faded>
  );
}

export function LinkButton({ handleItem, children }) {
  return (
    <button type="button" onClick={handleItem}>
      {children}
    </button>
  );
}

EditButton.propTypes = {
  idCatalogo: PropTypes.number.isRequired,
  idPagina: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
};

LinkButton.propTypes = {
  // idItem: PropTypes.string.isRequired,
  handleItem: PropTypes.func,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  handleItem: null,
};
