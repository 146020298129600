import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

function CatalogoSerieEditar() {
  const location = useLocation();
  const { idCatalogo, idSerie } = location.state;

  const formRef = useRef(null);

  const [serie, setSerie] = useState(null);

  useEffect(() => {
    api
      .get(`/catalogo/${idCatalogo}/serie-catalogo/${idSerie}`)
      .then((response) => {
        formRef.current.setFieldValue('serie', {
          value: response.data.idSerie,
          label: response.data.numeroSerie,
        });

        setSerie(response.data);
      });
  }, [idCatalogo, idSerie]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await api.put(
          `/catalogo/${idCatalogo}/serie-catalogo/${idSerie}`,
          data
        );

        history.goBack();

        toast.success('Número de série alterado com sucesso!');
      } catch (err) {
        AppError(err);
      }
    },
    [idCatalogo, idSerie]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Editar nº de série" />

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={serie}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input id="numeroSerie" name="numeroSerie" label="Nº de Série" />
              <Input id="partnumber" name="partnumber" label="Partnumber" />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { CatalogoSerieEditar };
