import { takeLatest, call, put, all } from 'redux-saga/effects';

// import { useDispatch } from 'react-redux';
import { signInSuccess, signFailure } from './actions';

import { AppError } from '../../../errors/AppError';
import history from '../../../services/history';
import api from '../../../services/api';

import { opNumeroAcesso, opTipoAcesso } from '../../../lib/const';

export function* signIn({ payload }) {
  try {
    const { login, password, returnTo } = payload;

    const response = yield call(api.post, 'session', {
      login,
      password,
    });

    const { token, user } = response.data;

    if (user && user.userId) user.adm = Number(user.adm);

    /** define o token do usuário autenticado para as requisições */
    api.defaults.headers.Authorization = `Bearer ${token}`;

    const responseAcesso = yield call(api.get, `usuario/${user.userId}/login`);

    yield put(signInSuccess(token, user));

    /** verifica se o redirecionamento foi informado */
    if (returnTo) {
      history.push(returnTo);
      return;
    }

    /** verifica se é o primeiro acesso na plataforma */
    if (responseAcesso.data.usuarioLogin.length === opNumeroAcesso.PRIMEIRO) {
      history.push('/primeiro-acesso');
      return;
    }

    /** verifica se é acesso de cliente */
    if (user.adm === opTipoAcesso.CLIENTE) {
      history.push('/catalogo.partes');
      return;
    }

    history.push('/catalogo');
  } catch (err) {
    AppError('Falha na autenticacação. Usuário ou senha inválidos');

    yield put(signFailure());
  }
}

export function* signInToken({ payload }) {
  try {
    // PASSAR ESSE PROCESSO PARA O CARREGAMENTO DA PAGINA
    // PEGAR O TOKEN DE LOCALHOST AUTH.TOKEN
    const { token, action } = payload;

    console.log(payload);

    const response = yield call(api.get, `app.session/${token}`);

    console.log(response.data);

    const { user, info } = response.data;

    const output = [];

    if (info) {
      const fmtInfo = info.split('&');

      fmtInfo.forEach((i) => {
        if (i.includes('IDI')) {
          const idOutput = i.split('=')[1].replaceAll(',', '');
          output.push({ idItem: Number(idOutput) });
        }
      });

      fmtInfo.forEach((i) => {
        if (i.includes('IDPAG')) {
          output.forEach((obj) => {
            if (i.includes(String(obj.idItem))) {
              const pagOutput = i.split('=')[1].replaceAll(',', '');
              Object.assign(obj, { idPagina: Number(pagOutput) });
            }
          });
        }
        if (i.includes('IDMAT')) {
          output.forEach((obj) => {
            if (i.includes(String(obj.idItem))) {
              const matOutput = i.split('=')[1].replaceAll(',', '');
              Object.assign(obj, { idMaterial: String(matOutput) });
            }
          });
        }
        if (i.includes('CODMAT')) {
          output.forEach((obj) => {
            if (i.includes(String(obj.idItem))) {
              const codeOutput = i.split('=')[1].replaceAll(',', '');
              Object.assign(obj, { codimate: String(codeOutput) });
            }
          });
        }
        if (i.includes('QTD')) {
          output.forEach((obj) => {
            if (i.includes(String(obj.idItem))) {
              const qtdOutput = i.split('=')[1].replaceAll(',', '');
              Object.assign(obj, { quantidade: Number(qtdOutput) });
            }
          });
        }
        if (i.includes('IMG')) {
          output.forEach((obj) => {
            if (i.includes(String(obj.idItem))) {
              const imgOutput = i.split('=')[1].replaceAll(',', '');
              Object.assign(obj, { imagemCatalogo: String(imgOutput) });
            }
          });
        }
      });
    }

    if (user && user.userId) user.adm = Number(user.adm);

    /** define o token do usuário autenticado para as requisições */
    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, true, action));

    // yield put(
    //   output.length > 0 &&
    //     output.forEach((itemCart) => {
    //       adicionaCarrinhoRequest({
    //         idCatalogo,
    //         idPagina: itemCart.idPagina,
    //         idItem: itemCart.idItem,
    //         idMaterial: itemCart.idMaterial,
    //         quantidade: itemCart.quantidade,
    //         codimate: itemCart.codimate,
    //         imagemCatalogo: itemCart.imagemCatalogo,
    //       });
    //     })
    // );

    // yield output.forEach((itemCart) => {
    //   adicionaCarrinhoRequest(
    //     idCatalogo,
    //     itemCart.idPagina,
    //     itemCart.idItem,
    //     itemCart.idMaterial,
    //     itemCart.quantidade,
    //     itemCart.codimate,
    //     itemCart.imagemCatalogo
    //   );
    // });
  } catch (err) {
    AppError('Falha na autenticacação. Token expirado.');

    yield put(signFailure());
  }
}

export function signOut({ payload }) {
  const { name } = payload;
  history.push(`/login/${name.toLowerCase()}`);
}

export function* setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    try {
      /** define o token do usuário autenticado para as requisições */
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield call(api.get, `app.session/${token}`);
    } catch (err) {
      AppError(
        'Token expirado. Por favor, entre novamente com seu usuário e senha'
      );

      yield put(signFailure());

      history.push('/login');
    }
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_TOKEN_REQUEST', signInToken),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
