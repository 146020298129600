import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import CheckBox from '../../../../components/Form/Input/Checkbox';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  padraoFabricante: Yup.string(),
  padraoModelo: Yup.string(),
});

function EmpresaNavegacaoClassificacaoCriar() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const formRef = useRef(null);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { descricao, padraoFabricante, padraoModelo } = data;

        /** verifica se os campos padrão estão selecionados par ao mesmo menu */
        if (padraoFabricante === '1' && padraoModelo === '1') {
          throw new Error(
            'Não é possível selecionar "Padrão Fabricante" e "Padrão Modelo" para um mesmo menu'
          );
        }

        await api.post(`empresa/${idEmpresa}/navegacao-classificacao`, {
          descricao,
          padraoFabricante,
          padraoModelo,
        });

        toast.success('Menu cadastrado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idEmpresa]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Novo Menu" />
      <FormWrapper>
        <Form
          id="formEmpresaNavegacaoClassificacao"
          ref={formRef}
          schema={schema}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                width={800}
              />
            </Form.Row>
            <Form.Row>
              <CheckBox
                id="padraoFabricante"
                name="padraoFabricante"
                label="Padrão fabricante"
              />
              <CheckBox
                id="padraoModelo"
                name="padraoModelo"
                label="Padrão modelo"
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button
              id="btn-submit"
              type="submit"
              form="formEmpresaNavegacaoClassificacao"
            >
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaNavegacaoClassificacaoCriar };
