import React, { useRef, useState, useCallback } from 'react';
import { Scope } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { getModelo, getFabricante, getTemplate } from '../../../lib/asyncUtils';
import {
  opIdioma,
  opNivel,
  opConversaoTipo,
  opTipoItemPagina,
  opTipoSequenciaPagina,
  opTipoExtracaoTabela,
} from '../../../lib/inputOption';

import { opSugestaoOrigem, opSimNao } from '../../../lib/const';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Input/Select';
import AsyncSelect from '../../../components/Form/Input/AsyncSelect';
import CreatableSelect from '../../../components/Form/Input/CreatableSelect';
import AsyncCreatableSelect from '../../../components/Form/Input/AsyncCreatableSelect';
import UploadInput from '../../../components/Form/UploadInput';

import TitleBar from '../../../components/TitleBar';

const schema = Yup.object().shape({
  nome: Yup.string().required(
    'O campo "Classe Operacional" deve ser preenchido'
  ),
  versao: Yup.string().required('O campo "Versão" deve ser preenchido'),
  numeroSerie: Yup.array().of(Yup.string()),
  idioma: Yup.number()
    .typeError('O campo "Idioma" deve ser preenchido')
    .required('O campo "Idioma" deve ser preenchido'),
  tipoConversao: Yup.number()
    .typeError('O campo "Tipo Conversão" deve ser preenchido')
    .required('O campo "Tipo Conversão" deve ser preenchido'),
  iaTipoItemPagina: Yup.number()
    .typeError('O campo "Tipo Item Página" deve ser preenchido')
    .required('O campo "Tipo Item Página" deve ser preenchido'),
  iaTipoSequenciaPagina: Yup.number()
    .typeError('O campo "Tipo Sequência Página" deve ser preenchido')
    .required('O campo "Tipo Sequência Página" deve ser preenchido'),
  tipoExtracaoTabela: Yup.number()
    .typeError('O campo "Tipo Extração Tabela" deve ser preenchido')
    .required('O campo "Tipo Extração Tabela" deve ser preenchido'),
  idModelo: Yup.number()
    .typeError('O campo "Modelo" deve ser preenchido')
    .required('O campo "Modelo" deve ser preenchido'),
  idFabricante: Yup.number()
    .typeError('O campo "Fabricante" deve ser preenchido')
    .required('O campo "Fabricante" deve ser preenchido'),
  qtdeNivel: Yup.number()
    .typeError('O campo "Quantidade de níveis" deve ser preenchido')
    .required('O campo "Quantidade de níveis" deve ser preenchido'),
  idTemplate: Yup.string(),
});

function EditorCriarAI() {
  const formRef = useRef(null);

  const [qtdeClassificacao, setQtdeClassificacao] = useState([]);

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        idArquivo,
        nome,
        versao,
        numeroSerie,
        idioma,
        iaTipoItemPagina,
        iaTipoSequenciaPagina,
        tipoExtracaoTabela,
        idFabricante,
        idModelo,
        qtdeNivel,
        classificacoes,
        tipoConversao,
        idTemplate,
        descModelo = null,
        descFabricante = null,
      } = data;

      const nivelClassificacao = Object.getOwnPropertyNames(
        classificacoes
      ).reduce((acumulado, atributo) => {
        if (atributo.includes('nivel_')) {
          acumulado.push({
            nivel: Number(atributo.replace(/\D/g, '')),
            descricao: classificacoes[atributo],
          });
        }

        return acumulado;
      }, []);

      /** processa o catálogo no servidor */
      const response = await api.post('/catalogo', {
        idArquivo,
        nome,
        versao,
        numeroSerie,
        idioma: String(idioma),
        iaTipoItemPagina,
        iaTipoSequenciaPagina,
        tipoExtracaoTabela,
        idFabricante,
        idModelo,
        qtdeNivel,
        tipoConversao: String(tipoConversao),
        nivelClassificacao,
        idTemplate: idTemplate ? Number(idTemplate) : null,
        descModelo,
        descFabricante,
      });

      const { idCatalogo } = response.data;

      history.replace('/catalogo/detalhe', { id: idCatalogo });

      toast.success('Catálogo criado com sucesso!');
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  const handleUpload = useCallback(({ metadados }) => {
    const { title } = metadados;
    if (title) formRef.current.setFieldValue('name', title);
  }, []);

  const handleQtdeNivel = useCallback((e) => {
    setQtdeClassificacao(Array.from({ length: e.value || 0 }, (v, k) => k + 1));
  }, []);

  const handleSugestacao = useCallback(async (e, value, op) => {
    e.select.onChange({
      value: op,
      label: `● ${value}`,
    });

    /** atualiza valor para envio */
    if (op === opSugestaoOrigem.CATALOGO_CRIACAO_FABRICANTE) {
      formRef.current.setFieldValue('descFabricante', value);
    }

    if (op === opSugestaoOrigem.CATALOGO_CRIACAO_MODELO) {
      formRef.current.setFieldValue('descModelo', value);
    }
  }, []);

  return (
    <>
      <TitleBar width={1440} title="Novo Catálogo" />

      <Form
        id="formEditorCriar"
        ref={formRef}
        onSubmit={handleConfirmar}
        autoComplete="off"
      >
        <Form.Column>
          <UploadInput name="idArquivo" onUpload={handleUpload} />
        </Form.Column>
        <Form.Row>
          <Form.Column>
            <Input id="nome" name="nome" label="Classe Operacional" />
            <Form.Row>
              <Input id="versao" name="versao" label="Versão" width={150} />
              <CreatableSelect
                id="numeroSerie"
                name="numeroSerie"
                label="Número de Série"
                placeholder="Insira o número de série e pressione enter..."
                isMulti
              />
            </Form.Row>
            <Form.Row>
              <Select
                id="idioma"
                name="idioma"
                label="Idioma"
                isSearchable={false}
                defaultValue={opIdioma[0]}
                options={opIdioma}
                menuPosition="fixed"
                width={300}
              />
              <Select
                id="tipoConversao"
                name="tipoConversao"
                label="Tipo de Conversão"
                isSearchable={false}
                defaultValue={opConversaoTipo[1]}
                options={opConversaoTipo}
                isDisabled
              />
            </Form.Row>
            <Form.Row>
              <Select
                id="iaTipoSequenciaPagina"
                name="iaTipoSequenciaPagina"
                label="Tipo Sequência Página"
                isSearchable={false}
                defaultValue={opTipoSequenciaPagina[1]}
                options={opTipoSequenciaPagina}
                menuPosition="fixed"
              />
              <Select
                id="tipoExtracaoTabela"
                name="tipoExtracaoTabela"
                label="Tipo Extração Tabela"
                isSearchable={false}
                defaultValue={opTipoExtracaoTabela[0]}
                options={opTipoExtracaoTabela}
                menuPosition="fixed"
              />
              <Select
                id="iaTipoItemPagina"
                name="iaTipoItemPagina"
                label="Tipo Item Página"
                isSearchable={false}
                defaultValue={opTipoItemPagina[0]}
                options={opTipoItemPagina}
                menuPosition="fixed"
              />
            </Form.Row>
            <Form.Row>
              {process.env.REACT_APP_EXPERIMENTAL_SUGESTAO_MODELO ===
              opSimNao.SIM ? (
                <AsyncCreatableSelect
                  id="idModelo"
                  name="idModelo"
                  label="Modelo"
                  cacheOptions
                  defaultOptions
                  loadOptions={getModelo}
                  createLabel="Sugerir criação de modelo"
                  onCreate={(e, value) =>
                    handleSugestacao(
                      e,
                      value,
                      opSugestaoOrigem.CATALOGO_CRIACAO_MODELO
                    )
                  }
                  menuPosition="fixed"
                />
              ) : (
                <AsyncSelect
                  id="idModelo"
                  name="idModelo"
                  label="Modelo"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={getModelo}
                  menuPosition="fixed"
                />
              )}
              {process.env.REACT_APP_EXPERIMENTAL_SUGESTAO_FABRICANTE ===
              opSimNao.SIM ? (
                <AsyncCreatableSelect
                  id="idFabricante"
                  name="idFabricante"
                  label="Fabricante"
                  cacheOptions
                  defaultOptions
                  loadOptions={getFabricante}
                  createLabel="Sugerir criação de fabricante"
                  onCreate={(e, value) =>
                    handleSugestacao(
                      e,
                      value,
                      opSugestaoOrigem.CATALOGO_CRIACAO_FABRICANTE
                    )
                  }
                  menuPosition="fixed"
                />
              ) : (
                <AsyncSelect
                  id="idFabricante"
                  name="idFabricante"
                  label="Fabricante"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={getFabricante}
                  menuPosition="fixed"
                />
              )}
              <Input id="descFabricante" name="descFabricante" isHidden />
              <Input id="descModelo" name="descModelo" isHidden />
            </Form.Row>
            {process.env.REACT_APP_EXPERIMENTAL_CATALOGO_TEMPLATE ===
              opSimNao.SIM && (
              <AsyncSelect
                id="idTemplate"
                name="idTemplate"
                label="Template"
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={getTemplate}
                menuPosition="fixed"
              />
            )}
          </Form.Column>
          <Form.Column>
            <Select
              id="qtdeNivel"
              name="qtdeNivel"
              label="Quantidade de Níveis"
              options={opNivel}
              onChange={handleQtdeNivel}
              menuPosition="fixed"
            />
            <Form.Column>
              <Form.Separate />
              {!!qtdeClassificacao.length && (
                <Scope path="classificacoes">
                  {qtdeClassificacao.map((classificacao) => (
                    <Input
                      key={classificacao}
                      id={`nivel_${classificacao}"`}
                      name={`nivel_${classificacao}"`}
                      label={`Descrição Nível ${classificacao}`}
                      type="text"
                      defaultValue={`Nível ${classificacao}`}
                    />
                  ))}
                </Scope>
              )}
            </Form.Column>
          </Form.Column>
        </Form.Row>
        <Form.Separate withFlex />
        <Form.Footer>
          <button
            id="btn-cancel"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancelar
          </button>
          <button id="btn-submit" type="submit" form="formEditorCriar">
            Confirmar
          </button>
        </Form.Footer>
      </Form>
    </>
  );
}

export { EditorCriarAI };
