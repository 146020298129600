import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import Pagination from '../../../components/EditorAI/Pagination';
import { Table, RowDefault } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import { Form } from '../../../components/Form';

const CatalogoRelacConjuntoItemPagina = () => {
  const location = useLocation();
  const { idCatalogo, idPagina, idItem, idMaterial, itemOrdena } = location.state;

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [itemPaginaOrigem, setItemPaginaOrigem] = useState(null);
  const [itemPagina, setItemPagina] = useState([]);

  const [conjuntoItemPagina, setConjuntoItemPagina] = useState([]);

  const carregaPagina = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      itemOrdena
    };

    api
      .get(`/catalogo/${idCatalogo}/pagina/${idPagina}/item`, {
        params: configFiltro,
      })
      .then((response) => {
        let currItemPagina = itemPaginaOrigem;

        if (!currItemPagina) {
          currItemPagina = response.data.itemPagina.find(
            (item) => item.idItem === idItem && item.idMaterial === idMaterial
          );
          setItemPaginaOrigem(currItemPagina);

          /** conjuntos relacionados inicialmente */
          setConjuntoItemPagina(currItemPagina.relacConjuntoItemPagina);
        }

        /** itens da página */
        setItemPagina(
          response.data.itemPagina
            .filter((itpa) => itpa.ordena > currItemPagina.ordena)
            .map((itpa) => ({
              idCatalogo: itpa.idCatalogo,
              idPagina: itpa.idPagina,
              idItem: itpa.idItem,
              idMaterial: itpa.idMaterial,
              partnumber: itpa.materialFabricante.partnumber,
              descricao: itpa.materialFabricante.descricao,
            }))
        );

        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        AppError(err);
      });
  }, [location, idCatalogo, idPagina, idItem, idMaterial, itemPaginaOrigem, itemOrdena]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaPagina();
  }, [location, currentPage, carregaPagina]);

  const handleAlterar = useCallback((item, checked) => {
    if (checked) {
      setConjuntoItemPagina((state) => [
        ...state,
        { idItem: item.idItem, idMaterial: item.idMaterial },
      ]);
    } else {
      setConjuntoItemPagina((state) => {
        /** pesquisa conjunto */
        const currConjuntoItemPagina = state.find(
          (rcip) =>
            rcip.idItem === item.idItem && rcip.idMaterial === item.idMaterial
        );

        return [...state.filter((rcip) => rcip !== currConjuntoItemPagina)];
      });
    }
  }, []);

  const handleConfirmar = useCallback(async () => {
    try {
      await api.post(
        `/catalogo/${idCatalogo}/pagina/${idPagina}/conjunto-pagina`,
        {
          itemOri: {
            idItem,
            idMaterial,
          },
          itens: conjuntoItemPagina.map((e) => ({
            idItem: e.idItem,
            idMaterial: e.idMaterial,
          })),
        }
      );

      toast.success('Conjunto salvo com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, [conjuntoItemPagina, idCatalogo, idItem, idMaterial, idPagina]);

  return (
    <>
      {itemPaginaOrigem && (
        <TitleBar
          back={!!location.background}
          title={`Relac. Conjunto / ${itemPaginaOrigem.materialFabricante.descricao} / ${idItem} / ${itemPaginaOrigem.materialFabricante.partnumber}`}
          isSearchable
        />
      )}
      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'relac', title: '' },
            { fieldname: 'isItem', title: 'Item' },
            { fieldname: 'partnumber', title: 'Partnumber' },
            { fieldname: 'descricao', title: 'Descrição' },
          ]}
        />
        <tbody>
          {itemPagina.map((item, index) => (
            <RowDefault key={index}>
              <td>
                <Checkbox
                  id={`relac-conjunto-${index}`}
                  name={`relac-conjunto-${index}`}
                  checked={Boolean(
                    conjuntoItemPagina.find(
                      (rcip) =>
                        rcip.idItem === item.idItem &&
                        rcip.idMaterial === item.idMaterial
                    )
                  )}
                  disableRipple
                  onChange={({ target }) => handleAlterar(item, target.checked)}
                />
              </td>
              <td>{item.idItem}</td>
              <td>{item.partnumber}</td>
              <td>{item.descricao}</td>
            </RowDefault>
          ))}
        </tbody>
      </Table>
      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
      <Form.Separate withFlex />
      <Form.Footer>
        <button id="btn-cancel" type="button" onClick={() => history.goBack()}>
          Cancelar
        </button>
        <button id="btn-submit" type="submit" onClick={handleConfirmar}>
          Confirmar
        </button>
      </Form.Footer>
    </>
  );
};

export { CatalogoRelacConjuntoItemPagina };
