import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper } from './styles';

function Location({ location }) {
  return (
    <Container>
      <Wrapper>
        <span>{`Cat.: ${location.catalogo} | Pág.: ${location.pagina}`}</span>
      </Wrapper>
    </Container>
  );
}

export { Location };

Location.propTypes = {
  location: PropTypes.shape({
    catalogo: PropTypes.number,
    pagina: PropTypes.number,
  }).isRequired,
};
