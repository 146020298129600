import React from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import { Wrapper, Container } from './styles';

export default function Modal({ width, height, children, onClose }) {
  return (
    <Wrapper>
      <Container width={width} height={height}>
        {onClose && (
          <button id="close" type="button" onClick={onClose}>
            <MdClose size={16} />
          </button>
        )}

        {children}
      </Container>
    </Wrapper>
  );
}

Modal.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func])
    .isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  width: null,
  height: null,
  onClose: null,
};
