import React from 'react';
import ReactPaginate from 'react-paginate';

import { Container } from './styles';

function Pagination(props) {
  return (
    <Container>
      <ReactPaginate
        previousLabel="Anterior"
        nextLabel="Próxima"
        breakLabel="..."
        breakClassName="break-me"
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        {...props}
      />
    </Container>
  );
}

export default Pagination;
