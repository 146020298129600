import { store } from '../store';

const globParams = {
  REDIRECIONA_PARA_CARRINHO: 1,
  ENVIA_EMAIL_SERVIDOR: 2,
  PERMITE_EMPRESA_CONVERSAO: 3,
  PERMITE_MENU_PERSONALIZADO: 4,
  PERMITE_GERACAO_TOKEN: 5,
  ROTULO_INTEGRACAO_EXT_EXTRA_01: 6,
  ROTULO_INTEGRACAO_EXT_EXTRA_02: 7,
  ROTULO_INTEGRACAO_EXT_EXTRA_03: 8,
  ROTULO_INTEGRACAO_EXT_EXTRA_04: 9,
  PERMITE_EMAIL_USUARIO_RESPONSAVEL: 10,
  PERMITE_SERIE_LOTE_CATALOGO: 11,
  ENVIA_WHATSAPP_SERVIDOR: 12,
};

function getGlobParam(globParam) {
  /** verifica estado de autenticação */
  const { param } = store.getState();
  const { globParams: params = [] } = param || {};

  const chave = params.find((p) => p.chave === globParam);

  const { valor = 0 } = chave || {};

  return valor;
}

export function allowEmpCatalogConversor() {
  return getGlobParam(globParams.PERMITE_EMPRESA_CONVERSAO) === '1';
}

export function allowEmpCustomMenu() {
  return getGlobParam(globParams.PERMITE_MENU_PERSONALIZADO) === '1';
}

export function allowEmpTokenGenerate() {
  return getGlobParam(globParams.PERMITE_GERACAO_TOKEN) === '1';
}

export function redirectToCart() {
  return getGlobParam(globParams.REDIRECIONA_PARA_CARRINHO) === '1';
}

export function sendServerEmail() {
  return getGlobParam(globParams.ENVIA_EMAIL_SERVIDOR) === '1';
}

export function sendServerWhatsApp() {
  return getGlobParam(globParams.ENVIA_WHATSAPP_SERVIDOR) === '1';
}

export function labelExtExtra01() {
  return getGlobParam(globParams.ROTULO_INTEGRACAO_EXT_EXTRA_01);
}

export function labelExtExtra02() {
  return getGlobParam(globParams.ROTULO_INTEGRACAO_EXT_EXTRA_02);
}

export function labelExtExtra03() {
  return getGlobParam(globParams.ROTULO_INTEGRACAO_EXT_EXTRA_03);
}

export function labelExtExtra04() {
  return getGlobParam(globParams.ROTULO_INTEGRACAO_EXT_EXTRA_04);
}

export function permiteEmpEmailUsuarioReponsavel() {
  return getGlobParam(globParams.PERMITE_EMAIL_USUARIO_RESPONSAVEL) === '1';
}

export function permiteEmpSerieLoteCatalogo() {
  return getGlobParam(globParams.PERMITE_SERIE_LOTE_CATALOGO) === '1';
}
