const opTemplateOPT = {
  SEARCH: 0,
  RECOUNT_ROW: 1,
  REMOVE_ROW: 2,
};

const opTemplateFUNC = {
  BY_START_END: 0,
  BY_MATCH: 1,
  BY_EMPTY: 2,
};

const opTemplateACT = {
  MOVE_VALUE: 0,
  DIVIDE_VALUE: 1,
  REPLACE_VALUE: 2,
  EMPTY_ROW: 3,
};

const opTemplateRPC = {
  BY_STRING: 0,
  BY_REGEX: 1,
  BY_PARAM: 2,
  BY_SEARCH: 3,
};

const handleLineAvaliable = ({
  arrData,
  arrDelete,
  initialIndex,
  initialId,
  copyToLinPos,
}) => {
  let id = initialId;
  let idAux1 = copyToLinPos;
  let notDeleted = false;

  while (!notDeleted) {
    const { id: idAux2 = -1 } = arrData[initialIndex + idAux1] || {};

    const idxDel1 = arrDelete.findIndex((u) => u.id === idAux2);

    idAux1 -= 1;
    if (idxDel1 < 0) id = idAux2;

    notDeleted = idxDel1 < 0 || idAux2 === -1;
  }

  return id;
};

const handleNoEmptyRow = ({ curData, exceptNoEmptyCol }) => {
  const exp1 = ['id'];
  if (exceptNoEmptyCol) {
    exceptNoEmptyCol.forEach((e) => exp1.push(`col${e}`));
  }

  return Object.keys(curData).reduce((accV, curK) => {
    const vAux1 = String(curData[curK]);

    if (!exp1.includes(curK)) if (vAux1.trim()) accV.push(vAux1);

    return accV;
  }, []);
};

const handleNormalize = ({ curData, noSpaces }) => {
  let normalize = String(curData);
  normalize = normalize.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  normalize = normalize.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  normalize = normalize.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  normalize = normalize.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  normalize = normalize.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  normalize = normalize.replace(new RegExp('[Ç]', 'gi'), 'c');
  normalize = normalize.toUpperCase().trim();

  if (noSpaces) normalize = normalize.replace(/(\s+)/gm, '');

  return normalize;
};

const execute = ({ template, tbColumn, tbData }) => {
  let fmtColumn = Array.from(tbColumn);
  let fmtData = Array.from(tbData);

  const { columns = [], steps = [] } = template;

  /** processa headers */
  columns.forEach((d1, idx1) => {
    const { created, header } = d1;

    /** organiza as colunas */
    if (!created) {
      fmtColumn.splice(idx1, 0, {
        Header: `Coluna ${idx1}`,
        accessor: `col${idx1}`,
        option: null,
      });

      fmtColumn = fmtColumn.map((h1, idx2) => ({
        Header: `Coluna ${idx2}`,
        accessor: `col${idx2}`,
        option: h1.option,
      }));

      /** cria campo para os dados */
      fmtData = fmtData.map((d2) => {
        const curData = Object.entries(d2);

        curData.splice(idx1, 0, [`col${idx1}`, '']);

        curData.forEach((d3, idx2) => {
          d3[0] = `col${idx2}`;
        });

        return Object.fromEntries(curData);
      });
    }

    /** classifica a coluna */
    const idxCol = fmtColumn.findIndex(
      (c) => c.accessor === `col${d1.curColPos}`
    );
    if (idxCol >= 0) fmtColumn[idxCol].option = header;
  });

  /** cria campo de controle ID  */
  fmtData = fmtData.map((d1, idx) => ({ ...d1, id: idx + 1 }));

  const forUpdate = [];
  const forDelete = [];

  const rowHistory = [];

  /** pega o valor mínimo da coluna ordem para incremento */
  let initColOrder = 1;
  const colOrder = fmtColumn.find((c1) => c1.option === 'ordena');
  if (colOrder) {
    const minValue = Math.min(
      ...fmtData.reduce((acc, cur) => {
        if (cur[colOrder.accessor]) acc.push(Number(cur[colOrder.accessor]));
        return acc;
      }, [])
    );

    /** pega valor campo */
    if (minValue && minValue !== Infinity) initColOrder = minValue;
  }

  /** processa dados */
  steps.forEach((d1) => {
    const {
      // seq,
      cols,
      opt,
      shape,
      remove,
    } = d1;

    /** processa as linhas conforme o método */
    cols.forEach((col) => {
      forUpdate.splice(0, forUpdate.length);

      if ([opTemplateOPT.REMOVE_ROW].includes(Number(opt))) {
        let justOnceExecuted = false;

        fmtData = fmtData.reduce((acc, cur) => {
          const curArr = Object.entries(cur);

          if (Number(opt) === opTemplateOPT.REMOVE_ROW) {
            if (
              [opTemplateFUNC.BY_START_END, opTemplateFUNC.BY_MATCH].includes(
                Number(shape.fnc)
              )
            ) {
              const p1 = [];
              let p2 = [];

              if (shape.stopAtFisrt && justOnceExecuted) {
                acc.push(Object.fromEntries(curArr));
                return acc;
              }

              if (Number(shape.fnc) === opTemplateFUNC.BY_START_END) {
                shape.start.forEach((s1) => {
                  let vAux1 = String(curArr[s1.colRef][1]) || '';

                  vAux1 = handleNormalize({
                    curData: vAux1,
                    noSpaces: shape.noSpaces,
                  });

                  let pAux1 = -1;

                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      s1.terms.forEach((term) => {
                        if (pAux1 < 0) pAux1 = vAux1.indexOf(term);
                      });
                      break;
                    case opTemplateRPC.BY_REGEX:
                      s1.terms.forEach((term) => {
                        if (pAux1 < 0)
                          pAux1 = vAux1
                            .trim()
                            .search(new RegExp(term, shape.flag));
                      });
                      break;
                    default:
                      break;
                  }

                  p1.push(pAux1);
                });

                shape.end.forEach((e1) => {
                  let vAux1 = String(curArr[e1.colRef][1]) || '';

                  vAux1 = handleNormalize({
                    curData: vAux1,
                    noSpaces: shape.noSpaces,
                  });

                  let pAux2 = -1;

                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      e1.terms.forEach((term) => {
                        if (pAux2 > 0) pAux2 = vAux1.indexOf(term);
                      });
                      break;
                    case opTemplateRPC.BY_REGEX:
                      e1.terms.forEach((term) => {
                        if (pAux2 > 0)
                          pAux2 = vAux1
                            .trim()
                            .search(new RegExp(term, shape.flag));
                      });
                      break;
                    default:
                      break;
                  }

                  p2.push(pAux2);
                });

                if (shape.end.length === 0) p2 = [0];
              }

              if (Number(shape.fnc) === opTemplateFUNC.BY_MATCH) {
                shape.start.forEach((s1) => {
                  let vAux1 = String(curArr[s1.colRef][1]) || '';

                  vAux1 = handleNormalize({
                    curData: vAux1,
                    noSpaces: shape.noSpaces,
                  });

                  let pAux1 = -1;

                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      s1.terms.forEach((term) => {
                        if (pAux1 < 0) pAux1 = vAux1.indexOf(term);
                      });
                      break;
                    case opTemplateRPC.BY_REGEX:
                      s1.terms.forEach((term) => {
                        if (pAux1 < 0)
                          pAux1 = vAux1
                            .trim()
                            .search(new RegExp(term, shape.flag));
                      });
                      break;
                    default:
                      break;
                  }

                  p1.push(pAux1);
                });

                p2 = [0];
              }

              /** verifica se o termo foi encontrado no início ou no fim e retorna o array vazio
               * desconsiderando informações processadas anteriormente  */
              justOnceExecuted =
                p1.every((curP1) => curP1 >= 0) &&
                p2.every((curP2) => curP2 >= 0);
              if (justOnceExecuted) return [];
            }

            if (Number(shape.fnc) === opTemplateFUNC.BY_EMPTY) {
              /** 1. PROCESSO DE VERIFICACAO DE LINHA VAZIA */
              const noEmptyRow = handleNoEmptyRow({
                curData: cur,
                exceptNoEmptyCol: shape.exceptNoEmptyCol,
              });

              if (noEmptyRow.length === 0) return acc;
            }
          }

          acc.push(cur);
          return acc;
        }, []);
      }

      if (
        [opTemplateOPT.SEARCH, opTemplateOPT.RECOUNT_ROW].includes(Number(opt))
      ) {
        fmtData = fmtData.reduce((acc, cur, idx2, arr) => {
          const curArr = Object.entries(cur);

          if (Number(opt) === opTemplateOPT.SEARCH) {
            let p1 = -1;
            let p2 = -1;

            let m1 = null;

            let v1 = null;

            let vAux = String(curArr[col][1]);
            vAux = handleNormalize({
              curData: vAux,
              noSpaces: shape.noSpaces,
            });

            if (Number(shape.fnc) === opTemplateFUNC.BY_START_END) {
              shape.start.forEach((s1) => {
                if (p1 < 0) {
                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      p1 = vAux.indexOf(s1);
                      break;
                    case opTemplateRPC.BY_REGEX:
                      p1 = vAux.trim().search(new RegExp(s1, shape.flag));
                      break;
                    default:
                      break;
                  }
                }
              });

              shape.end.forEach((e1) => {
                if (p2 < 0) {
                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      p2 = vAux.indexOf(e1);
                      break;
                    case opTemplateRPC.BY_REGEX:
                      p2 = vAux.trim().search(new RegExp(e1, shape.flag));
                      break;
                    default:
                      break;
                  }

                  if (p2 >= 0) p2 += e1.length;
                }
              });

              if (shape.end.length === 0) p2 = p1;
            }

            if (Number(shape.fnc) === opTemplateFUNC.BY_MATCH) {
              shape.start.forEach((s1) => {
                if (p1 < 0) {
                  switch (Number(shape.mtd)) {
                    case opTemplateRPC.BY_STRING:
                      p1 = vAux.indexOf(s1);
                      break;
                    case opTemplateRPC.BY_REGEX:
                      p1 = vAux.trim().search(new RegExp(s1, shape.flag));
                      break;
                    default:
                      break;
                  }

                  m1 = s1;
                }
              });

              p2 = 0;
            }

            /** VALOR CAMPO */
            v1 = String(curArr[col][1]);

            if (p1 >= 0 && p2 >= 0) {
              let executed = false;

              shape.like.forEach((like1) => {
                /** ID LINHA DESTINO */
                let { id } = arr[idx2 + like1.copyToLinPos] || {};

                /** 1. PROCESSO DE VERIFICACAO DE LINHA VAZIA */
                const noEmptyRow = handleNoEmptyRow({
                  curData: cur,
                  exceptNoEmptyCol: like1.exceptNoEmptyCol,
                });

                /** 2. PROCESSO DE SUBSTITUICAO VALOR */
                like1.replaceBy.forEach((r1) => {
                  if (
                    [
                      opTemplateRPC.BY_REGEX,
                      opTemplateRPC.BY_STRING,
                      opTemplateRPC.BY_SEARCH,
                    ].includes(r1.rpc)
                  ) {
                    let vAux1 = v1;
                    if (r1.flag === 'noSpaces')
                      vAux1 = vAux1.replace(/(\s+)/gm, '');

                    /** SUBSTITUICAO POR STRING|REGEX|SEARCH  */
                    let fAux1 = r1.of;
                    if (r1.rpc === opTemplateRPC.BY_REGEX)
                      fAux1 = new RegExp(r1.of, r1.flag);

                    if (r1.rpc === opTemplateRPC.BY_SEARCH)
                      fAux1 = v1.slice(p1, p2 + 1);

                    if (String(vAux1).match(fAux1)) {
                      /** TODO O CONTEUDO */
                      if (r1.replaceAll) {
                        v1 = r1.to.trim();
                        return;
                      }

                      if (r1.separator)
                        vAux1 = vAux1.match(fAux1).join(r1.separator);

                      v1 = vAux1
                        .replace(fAux1, (match1) => r1.to || match1)
                        .trim();
                    }
                  }

                  if ([opTemplateRPC.BY_PARAM].includes(r1.rpc)) {
                    /** SUBSTITUICAO POR PARAMETRO  */
                    const refAux1 = r1.ofColRef.map(
                      (ref) => String(curArr[ref][1]) || ''
                    );

                    /** SUBSTITUI TODO PARAMETRO {0} PELO VALOR DA COLUNA ENCONTRADO */
                    v1 = r1.to.replace(/{([0-9]+)}/g, (match1, idx3) =>
                      refAux1[idx3] ? refAux1[idx3] : match1
                    );
                  }
                });

                if (Number(like1.act) === opTemplateACT.MOVE_VALUE) {
                  if (
                    like1.exceptNoEmptyCol.length === 0 ||
                    (like1.exceptNoEmptyCol.length > 0 &&
                      noEmptyRow.length === 0)
                  ) {
                    /** gera um ID de criação de coluna */
                    if (!id && like1.createLineIfNeeds) {
                      id = Number(`999${String(idx2 + 1).padStart(3, 0)}`);

                      /** cria uma nova linha em branco */
                      const fmtLine = [];
                      fmtLine.push(
                        ...fmtColumn.map((c1) => [`${c1.accessor}`, ''])
                      );
                      fmtLine.push(['id', id]);

                      acc.push(Object.fromEntries(fmtLine));
                    } else {
                      /** VERIFICA SE A LINHA DE TRANSFERENCIA ESTA DISPONIVEL */
                      id = handleLineAvaliable({
                        arrData: arr,
                        arrDelete: forDelete,
                        initialIndex: idx2,
                        initialId: id,
                        copyToLinPos: like1.copyToLinPos,
                      });
                    }

                    if (id >= 0) {
                      if (
                        like1.rpc === opTemplateRPC.BY_SEARCH &&
                        like1.replaceBy.length <= 0
                      ) {
                        let vAux1 = String(curArr[col][1]);
                        const vAux2 = handleNormalize({
                          curData: vAux1,
                          noSpaces: false,
                        });

                        if (Number(shape.fnc) === opTemplateFUNC.BY_MATCH) {
                          const mAux1 = vAux2.match(m1);

                          const [pos1 = ''] = mAux1;

                          if (p1 >= 0) p2 = p1 + pos1.length - 1;
                        }

                        vAux1 = vAux1.slice(p1, p2 + 1);

                        v1 = vAux1;
                      }

                      /** verifica se a coluna já foi origem em processo anterior */
                      const idxAux1 = forUpdate.findIndex(
                        (u) =>
                          u.idOri === id && u.col === `col${like1.copyToColPos}`
                      );

                      if (idxAux1 >= 0) {
                        /** atualiza o  */
                        forUpdate[idxAux1].data = `${forUpdate[idxAux1].data}${
                          like1.separator || ''
                        } ${v1}`.trim();
                      } else {
                        forUpdate.push({
                          id,
                          idOri: cur.id,
                          col: `col${like1.copyToColPos}`,
                          replace: like1.replace || false,
                          separator: like1.separator,
                          data: v1,
                        });
                      }

                      if (!like1.keepOrigin) {
                        const vAux1 = String(curArr[col][1]);
                        const vAux2 = handleNormalize({
                          curData: vAux1,
                          noSpaces: false,
                        });

                        let vOri1 = '';
                        if (like1.rpc === opTemplateRPC.BY_SEARCH) {
                          vOri1 = vAux1;

                          if (Number(shape.fnc) === opTemplateFUNC.BY_MATCH) {
                            const mAux1 = String(vAux2).match(m1);
                            const [pos1 = ''] = mAux1;

                            if (p1 >= 0) p2 = p1 + pos1.length - 1;
                          }

                          const vAux3 = vAux1.slice(p1, p2 + 1);

                          vOri1 = vAux1.replace(vAux3, '');
                        }

                        forUpdate.push({
                          id: cur.id,
                          idOri: cur.id,
                          col: `col${col}`,
                          replace: true,
                          separator: '',
                          data: vOri1,
                        });
                      }

                      executed = true;
                    }
                  }
                }

                if (Number(like1.act) === opTemplateACT.DIVIDE_VALUE) {
                  const [div1, div2] = v1.split(like1.separator);

                  /** VERIFICA SE A LINHA DE TRANSFERENCIA ESTA DISPONIVEL */
                  id = handleLineAvaliable({
                    arrData: arr,
                    arrDelete: forDelete,
                    initialIndex: idx2,
                    initialId: id,
                    copyToLinPos: like1.copyToLinPos,
                  });

                  if (id >= 0) {
                    if (div1 && div1.trim())
                      forUpdate.push({
                        id: cur.id,
                        idOri: cur.id,
                        col: `col${col}`,
                        replace: true,
                        data: div1.trim(),
                      });

                    if (div2 && div2.trim())
                      forUpdate.push({
                        id,
                        idOri: cur.id,
                        col: `col${like1.copyToColPos}`,
                        replace: true,
                        data: div2.trim(),
                      });

                    executed = true;
                  }
                }

                if (Number(like1.act) === opTemplateACT.REPLACE_VALUE) {
                  /** VERIFICA SE A LINHA DE TRANSFERENCIA ESTA DISPONIVEL */
                  id = handleLineAvaliable({
                    arrData: arr,
                    arrDelete: forDelete,
                    initialIndex: idx2,
                    initialId: id,
                    copyToLinPos: like1.copyToLinPos,
                  });

                  if (id >= 0) {
                    /** verifica se a coluna já foi origem em processo anterior */
                    const idxAux1 = forUpdate.findIndex(
                      (u) =>
                        u.idOri === id && u.col === `col${like1.copyToColPos}`
                    );

                    if (idxAux1 >= 0) {
                      /** atualiza o  */
                      forUpdate[idxAux1].data = `${forUpdate[idxAux1].data}${
                        like1.separator || ''
                      } ${v1}`.trim();
                    } else {
                      forUpdate.push({
                        id,
                        idOri: cur.id,
                        col: `col${col}`,
                        replace: like1.replace || false,
                        separator: like1.separator,
                        data: v1,
                      });
                    }

                    executed = true;
                  }
                }

                if (Number(like1.act) === opTemplateACT.EMPTY_ROW) {
                  executed = noEmptyRow.length === 0;
                }
              });

              if (executed && remove) {
                forDelete.push(Object.fromEntries(curArr));
                return acc;
              }
            }

            if (p1 < 0 || p2 < 0) {
              let executed = false;

              shape.iLike.forEach((ilike1) => {
                /** ID LINHA DESTINO */
                let { id } = arr[idx2 + ilike1.copyToLinPos] || {};

                /** 1. PROCESSO DE VERIFICACAO DE LINHA VAZIA */
                const noEmptyRow = handleNoEmptyRow({
                  curData: cur,
                  exceptNoEmptyCol: ilike1.exceptNoEmptyCol,
                });

                /** 2. PROCESSO DE SUBSTITUICAO VALOR */
                ilike1.replaceBy.forEach((r1) => {
                  if (
                    [opTemplateRPC.BY_REGEX, opTemplateRPC.BY_STRING].includes(
                      r1.rpc
                    )
                  ) {
                    let vAux1 = v1;
                    if (r1.flag === 'noSpaces')
                      vAux1 = vAux1.replace(/(\s+)/gm, '');

                    /** SUBSTITUICAO POR STRING|REGEX  */
                    let fAux1 = r1.of;
                    if (r1.rpc === opTemplateRPC.BY_REGEX)
                      fAux1 = new RegExp(r1.of, r1.flag);

                    if (vAux1.match(fAux1)) {
                      /** TODO O CONTEUDO */
                      if (r1.replaceAll) {
                        v1 = r1.to.trim();
                        return;
                      }

                      if (r1.separator)
                        vAux1 = vAux1.match(fAux1).join(r1.separator);

                      v1 = vAux1
                        .replace(fAux1, (match1) => r1.to || match1)
                        .trim();
                    }
                  }

                  if ([opTemplateRPC.BY_PARAM].includes(r1.rpc)) {
                    /** SUBSTITUICAO POR PARAMETRO  */
                    const refAux1 = r1.ofColRef.map(
                      (ref) => String(curArr[ref][1]) || ''
                    );

                    /** SUBSTITUI TODO PARAMETRO {0} PELO VALOR DA COLUNA ENCONTRADO */
                    v1 = r1.to.replace(/{([0-9]+)}/g, (match1, idx3) =>
                      refAux1[idx3] ? refAux1[idx3] : match1
                    );
                  }
                });

                if (Number(ilike1.act) === opTemplateACT.MOVE_VALUE) {
                  if (
                    ilike1.exceptNoEmptyCol.length === 0 ||
                    (ilike1.exceptNoEmptyCol.length > 0 &&
                      noEmptyRow.length === 0)
                  ) {
                    /** gera um ID de criação de coluna */
                    if (!id && ilike1.createLineIfNeeds) {
                      id = Number(`999${String(idx2 + 1).padStart(3, 0)}`);

                      /** cria uma nova linha em branco */
                      const fmtLine = [];
                      fmtLine.push(
                        ...fmtColumn.map((c1) => [`${c1.accessor}`, ''])
                      );
                      fmtLine.push(['id', id]);

                      acc.push(Object.fromEntries(fmtLine));
                    } else {
                      /** VERIFICA SE A LINHA DE TRANSFERENCIA ESTA DISPONIVEL */
                      id = handleLineAvaliable({
                        arrData: arr,
                        arrDelete: forDelete,
                        initialIndex: idx2,
                        initialId: id,
                        copyToLinPos: ilike1.copyToLinPos,
                      });
                    }

                    if (id >= 0) {
                      let vAux1 = v1;

                      if (ilike1.rpc === opTemplateRPC.BY_SEARCH) {
                        if (Number(shape.fnc) === opTemplateFUNC.BY_MATCH) {
                          const mAux1 = String(vAux1).match(m1);
                          const [pos1 = ''] = mAux1;

                          if (p1 >= 0) p2 = p1 + pos1.length - 1;
                        }

                        vAux1 = vAux1.slice(p1, p2 + 1);
                      }

                      /** verifica se a coluna já foi origem em processo anterior */
                      const idxAux1 = forUpdate.findIndex(
                        (u) =>
                          u.idOri === id &&
                          u.col === `col${ilike1.copyToColPos}`
                      );

                      if (idxAux1 >= 0) {
                        /** atualiza o  */
                        forUpdate[idxAux1].data = `${forUpdate[idxAux1].data}${
                          ilike1.separator || ''
                        } ${v1}`.trim();
                      } else {
                        forUpdate.push({
                          id,
                          idOri: cur.id,
                          col: `col${ilike1.copyToColPos}`,
                          replace: ilike1.replace || false,
                          separator: ilike1.separator,
                          data: v1,
                        });
                      }

                      if (!ilike1.keepOrigin) {
                        let vOri1 = '';
                        if (ilike1.rpc === opTemplateRPC.BY_SEARCH) {
                          vOri1 = v1;
                          vOri1 = vOri1.replace(vAux1, '');
                        }

                        forUpdate.push({
                          id: cur.id,
                          idOri: cur.id,
                          col: `col${col}`,
                          replace: true,
                          separator: '',
                          data: vOri1,
                        });
                      }

                      executed = true;
                    }
                  }
                }

                if (Number(ilike1.act) === opTemplateACT.REPLACE_VALUE) {
                  /** VERIFICA SE A LINHA DE TRANSFERENCIA ESTA DISPONIVEL */
                  id = handleLineAvaliable({
                    arrData: arr,
                    arrDelete: forDelete,
                    initialIndex: idx2,
                    initialId: id,
                    copyToLinPos: ilike1.copyToLinPos,
                  });

                  if (id >= 0) {
                    /** verifica se a coluna já foi origem em processo anterior */
                    const idxAux1 = forUpdate.findIndex(
                      (u) =>
                        u.idOri === id && u.col === `col${ilike1.copyToColPos}`
                    );

                    if (idxAux1 >= 0) {
                      /** atualiza o  */
                      forUpdate[idxAux1].data = `${forUpdate[idxAux1].data}${
                        ilike1.separator || ''
                      } ${v1}`.trim();
                    } else {
                      forUpdate.push({
                        id,
                        idOri: cur.id,
                        col: `col${col}`,
                        replace: ilike1.replace || false,
                        separator: ilike1.separator,
                        data: v1,
                      });
                    }

                    executed = true;
                  }
                }

                if (Number(ilike1.act) === opTemplateACT.EMPTY_ROW) {
                  executed = true;
                }
              });

              if (executed && remove) {
                forDelete.push(Object.fromEntries(curArr));
                return acc;
              }
            }

            /** ADICIONA LINHA */
            acc.push(Object.fromEntries(curArr));
            return acc;
          }

          if (Number(opt) === opTemplateOPT.RECOUNT_ROW) {
            forUpdate.push({
              id: cur.id,
              idOri: cur.id,
              col: `col${col}`,
              replace: true,
              data: String(initColOrder + idx2),
            });

            acc.push(Object.fromEntries(curArr));
            return acc;
          }

          acc.push(cur);
          return acc;
        }, []);
      }

      forUpdate.forEach((u1) => {
        const idx1 = fmtData.findIndex((d2) => d2.id === u1.id);
        if (idx1 >= 0) {
          if (u1.replace) {
            fmtData[idx1][u1.col] = u1.data;
          } else if (fmtData[idx1][u1.col]) {
            fmtData[idx1][u1.col] = `${fmtData[idx1][u1.col]}${
              u1.separator || ''
            } ${u1.data}`.trim();
          } else {
            fmtData[idx1][u1.col] = `${u1.data}`.trim();
          }
        }
      });
    });
  });

  // /** atualiza dados conforme lista */
  // forUpdate.forEach((d1) => {
  //   const idx1 = fmtData.findIndex((d2) => d2.id === d1.id);
  //   if (idx1 >= 0) {
  //     if (d1.replace) {
  //       fmtData[idx1][d1.col] = d1.data;
  //     } else if (fmtData[idx1][d1.col]) {
  //       fmtData[idx1][d1.col] = `${fmtData[idx1][d1.col]}${
  //         d1.separator || ''
  //       } ${d1.data}`.trim();
  //     } else {
  //       fmtData[idx1][d1.col] = `${d1.data}`.trim();
  //     }
  //   }
  // });

  /** processo dados para criar histórico de alterações */
  tbData.forEach((old, idx1) => {
    /** previsão da linha antes das alterações */
    let after = { ...old };
    Object.keys(after).forEach((k) => {
      after[k] = '';
    });

    const idx2 = fmtData.findIndex((d1) => d1.id === idx1 + 1);
    if (idx2 >= 0) after = fmtData[idx2];

    /** salva histórico */
    rowHistory.push({
      rowBefore: old,
      rowAfter: after,
      rowIndex: idx1,
    });
  });

  /** remove campo de controle ID */
  fmtData.forEach((d1) => {
    delete d1.id;
  });

  return [fmtColumn, fmtData, rowHistory];
};

const templateMapper = {
  execute,
};

export { templateMapper };
