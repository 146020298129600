import styled from 'styled-components';

export const Container = styled.div`
  > h2 {
    padding: 20px 10px 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;
