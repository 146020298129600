import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

function ModeloCriar() {
  const formRef = useRef(null);

  const location = useLocation();

  const handleSubmit = useCallback(async (data) => {
    try {
      const { descricao } = data;

      await api.post('modelo', {
        descricao,
      });

      toast.success('Modelo cadastrado com sucesso!');

      history.goBack();
    } catch (err) {
      AppError(err);
    }
  }, []);

  return (
    <>
      <TitleBar back={!!location.background} title="Novo modelo" />
      <FormWrapper>
        <Form
          id="formModelo"
          ref={formRef}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="descricao"
                name="descricao"
                label="Descrição"
                width={600}
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formModelo">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default ModeloCriar;
