const initialParams = {
  FLT_IDS: null,
  FLT_IDL: null,
};

function useQuery(searchLocation) {
  return new URLSearchParams(searchLocation);
}

export { useQuery, initialParams };
