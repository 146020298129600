import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport, opImport } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TitleBar from '../../../components/TitleBar';

function CatalogoNivel() {
  const location = useLocation();
  const { id } = location.state;

  const [niveis, setNiveis] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNivel = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`catalogo/${id}/nivel`, {
        params: configFiltro,
      })
      .then((response) => {
        setNiveis(
          response.data.niveis.map(({ ...nivel }) => ({
            ...nivel,
            checked: false,
          }))
        );
        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNivel();
  }, [location, currentPage, carregaNivel]);

  const handleCriar = useCallback(() => {
    history.push('/catalogo/nivel/criar', {
      background: location,
      idCatalogo: id,
    });
  }, [location, id]);

  const handleEditar = useCallback(
    (idNivel) => {
      history.push(`/catalogo/nivel/editar`, {
        background: location,
        idCatalogo: id,
        idNivel,
      });
    },
    [location, id]
  );

  const handleRemover = useCallback(
    async (idNivel) => {
      const niveisDeletar = niveis.filter((n) => n.checked);

      const deletar = niveisDeletar.map(
        (n) =>
          ({
            idNivel: n.idNivel,
            nivel: n.nivel,
            codigo: n.codigo,
          } || [])
      );

      if (
        deletar.length === 0
          ? window.confirm(
              `Deseja realmente excluir todos os níveis do catálogo? \nCatálogo: ${id}`
            )
          : window.confirm(
              `Deseja realmente excluir os níveis do catálogo? \nCatálogo: ${id} \nNível: ${deletar
                .map((n) => `${n.nivel}-${n.codigo}`)
                .join(',')}`
            )
      ) {
        try {
          const fmtDeletar = deletar.map((n) => ({ idNivel: n.idNivel }));

          await api.patch(`catalogo/${id}/nivel`, {
            nivel: fmtDeletar,
          });

          setNiveis((state) =>
            state.filter((nivel) => nivel.idNivel !== idNivel)
          );

          carregaNivel();

          toast.success('Níveis excluídos com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [niveis, id, carregaNivel]
  );

  const handleVisualizar = useCallback(
    (e, idNivel) => {
      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') handleEditar(idNivel);
    },
    [handleEditar]
  );

  const handleImportar = useCallback(() => {
    history.push(`/catalogo/nivel/importar`, {
      background: { ...location },
      parametros: { op: opImport.CATALOGO_NIVEL, idCatalogo: id },
    });
  }, [id, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_NIVEL, { idCatalogo: id });
  }, [id]);

  const handleAlterarNivelChecagem = useCallback(
    (e, idNivel, checked) => {
      e.stopPropagation();

      const index = niveis.findIndex((i) => i.idNivel === idNivel);
      if (index > -1) {
        niveis[index].checked = checked;
        setNiveis([...niveis]);
      }
    },
    [niveis]
  );

  return (
    <>
      <TitleBar
        back
        title="Níveis do catálogo"
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
        onIconDeletar={handleRemover}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'nivel', title: 'Nível' },
            { fieldname: 'codigo', title: 'Código' },
            { fieldname: 'descricaoBR', title: 'Descrição (BR)' },
            { fieldname: 'descricaoEN', title: 'Descrição (EN)' },
            { fieldname: null, title: null },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {niveis.map((nivel, index) => (
            <RowMaster
              key={index}
              onClick={(e) => handleVisualizar(e, nivel.idNivel)}
            >
              <td>{nivel.nivel}</td>
              <td>{nivel.codigo}</td>
              <td>{nivel.descricaoBR}</td>
              <td>{nivel.descricaoEN}</td>
              <td>
                <Checkbox
                  id={`nivel-${index}`}
                  name={`nivel-${index}`}
                  checked={nivel.checked}
                  disableRipple
                  onChange={(e) => {
                    handleAlterarNivelChecagem(
                      e,
                      nivel.idNivel,
                      e.target.checked
                    );
                  }}
                />
              </td>
              <td>
                <ActionMenu
                  data={nivel}
                  onEdit={() => handleEditar(nivel.idNivel)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export default CatalogoNivel;
