import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';
import * as Yup from 'yup';

import history from '../../../../services/history';
import api from '../../../../services/api';

import logotipo from '../../../../assets/logo.svg';
import MarcaDAgua from '../../../../assets/marca-dagua-assiste.svg';

import { AppError } from '../../../../errors/AppError';

import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

import { Container, Wrapper, Footer } from '../_usuario-recupera-acesso/styles';

const schema = Yup.object().shape({
  senha: Yup.string()
    .min(6, 'O campo "Senha" precisa ter no mínimo 6 caracteres')
    .required('O campo "Senha" é obrigatório'),
  confirmaSenha: Yup.string().oneOf(
    [Yup.ref('senha'), null],
    'A confirmação de senha não confere'
  ),
});

function MobileRedefineSenha() {
  const formRef = useRef(null);

  const [enviado, setEnviado] = useState(false);

  const location = useLocation();

  const { token: urlToken } =
    Object.fromEntries(new URLSearchParams(location.search)) || {};

  useEffect(() => {
    /** aguarda valicadao do token */
    const validaToken = async (token) => {
      try {
        await api.get(`/recupera-acesso/${token}`);
      } catch (err) {
        AppError(err);
        history.push('/login');
      }
    };

    validaToken(urlToken);
  }, [urlToken]);

  const handleConfirmar = useCallback(async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const { token, senha, confirmaSenha } = data;

      await api.put(`/redefinicao-senha/${token}`, {
        senha,
        confirmaSenha,
      });

      setEnviado((prevState) => !prevState);
    } catch (err) {
      AppError(err, formRef);
    }
  }, []);

  const handleLogin = useCallback(() => {
    history.push('/login');
  }, []);

  return (
    <Container>
      <img src={logotipo} alt="Assiste" />
      <Wrapper>
        <h1>Redefinição de Senha</h1>

        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          {enviado ? (
            <span>
              <MdCheckCircle size={30} color="#ff6d3c" />
              <h3>Senha redefinida com sucesso!</h3>
            </span>
          ) : (
            <>
              <p>Crie uma nova senha</p>

              <Input
                type="hidden"
                id="token"
                name="token"
                defaultValue={urlToken}
              />

              <Form.Column>
                <Form.Row>
                  <Input
                    id="senha"
                    name="senha"
                    type="password"
                    placeholder="Criar senha"
                  />
                </Form.Row>

                <Form.Row>
                  <Input
                    id="confirmaSenha"
                    name="confirmaSenha"
                    type="password"
                    placeholder="Confirmar senha"
                  />
                </Form.Row>
              </Form.Column>

              <button id="btn-submit" type="submit">
                Salvar
              </button>
            </>
          )}

          <button id="btn-login" type="button" onClick={handleLogin}>
            Voltar
          </button>
        </Form>
      </Wrapper>

      <Footer>
        <a
          href="http://assiste.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          title={`Assiste - CDI - v${process.env.REACT_APP_VERSION}`}
        >
          <img src={MarcaDAgua} alt="logotipo" />
        </a>
      </Footer>
    </Container>
  );
}

export { MobileRedefineSenha };
