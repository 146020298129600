import styled from 'styled-components';
import { animated } from 'react-spring';
import { darken } from 'polished';

export const Container = styled.div`
  /* flex: 1;
  display: flex; */
`;

export const List = styled.div`
  flex: 1;
  height: 100%;
  /* max-height: calc(100vh - 220px); */
  /* overflow-y: auto; */
`;

List.WrapperCard = styled.div`
  /* flex: 1; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

List.WrapperImgCard = styled.div`
  /* flex: 1; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

List.Card = styled(animated.div)`
  display: flex;
  background: ${(props) => props.theme.cardBackground};
  box-shadow: ${(props) => `0px 3px 6px ${props.theme.boxShadow}`};
  border-radius: 10px;
  margin: 10px 20px;
  min-height: 50px;
  width: 100%;
  max-width: 500px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${(props) => props.theme.color};
    text-decoration: none;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: inherit;
    padding: 5px;

    :hover {
      /* background: ${darken(0.05, '#fff')}; */
      border: 1px solid #ccc;
    }
  }
`;

List.ImgCard = styled(animated.div)`
  display: flex;
  background: ${(props) => props.theme.cardBackground};
  box-shadow: ${(props) => `0px 3px 6px ${props.theme.boxShadow}`};
  border-radius: 10px;
  margin: 10px 20px;
  height: 250px;
  width: 100%;
  max-width: 250px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${(props) => props.theme.color};
    text-decoration: none;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    text-align: center;

    :hover {
      /* background: ${darken(0.05, '#fff')}; */
      border: 1px solid #ccc;
    }

    img {
      height: 200px;
      width: 200px;

      border-radius: 10px;

      filter: ${(props) => `invert(${props.theme.invertCatalogImage})`};
    }
  }
`;
