import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import createStore from './createStore';
import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

/** cria monitor do saga para ambiente de desenvolvimento */
const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

/** cria "middlewares" do saga */
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const middlewares = [sagaMiddleware];

/** cria sistema de redux persistindo os dados e adicionando os "middlewares" saga */
const store = createStore(persistReducers(rootReducer), middlewares);
// const store = createStore(rootReducer, middlewares);
const persistor = persistStore(store);

/** inicializa o saga */
sagaMiddleware.run(rootSaga);

export { store, persistor };
