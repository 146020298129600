import styled from 'styled-components';
import { animated } from 'react-spring';

const Main = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  max-height: calc(100vh - 75px);

  h2 {
    font-size: 28px;
    font-weight: bold;
    color: #2c405a;
    margin-bottom: 20px;
  }
`;

Main.Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 1em #00000026;
  padding: 40px 5px 20px 20px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  height: 100%;
`;

Main.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

Main.Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

Main.Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: none;

  > a {
    > img {
      width: 150px;
      opacity: 0.9;
    }
  }
`;

const Card = styled(animated.div)`
  display: flex;
  position: relative;
  background: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 15px;
  height: 100%;
  height: 300px;
  width: 100%;
  max-width: 290px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: inherit;
    text-decoration: none;
    text-align: center;
    height: 100%;
    width: 100%;
    /* border: 1px solid #e5e7eb; */
    border-radius: 10px;
    padding: 20px;
  }
`;

Card.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

Card.Language = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: #2c405a;
  /* border: 1px solid #2c405a; */
  border-radius: 5px;
  font-size: 10px;
`;

Card.Title = styled.h4`
  margin: 10px 0;
  color: #2c405a;
`;

Card.Image = styled.img`
  border-radius: 10px;
  /* width: 100%; */
  max-width: 200px;
  max-height: 200px;
  /* height: 100%; */
  /* object-fit: cover; */
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); */
`;

Card.Content = styled.span`
  color: #555;
  font-size: 14px;
  margin: 5px 0;
`;

Card.Maintenance = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
`;

Card.Maintenance.Content = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.92);
  color: #de3b3b;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: 95%;

  > span {
    text-align: center;
  }
`;

export { Main, Card };
