import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { getNivelClassificacao } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';

function CatalogoNivelEditar() {
  const location = useLocation();
  const { idCatalogo, idNivel } = location.state;

  const formRef = useRef(null);

  const [nivel, setNivel] = useState(null);

  useEffect(() => {
    api.get(`/catalogo/${idCatalogo}/nivel/${idNivel}`).then((response) => {
      formRef.current.setFieldValue('nivel', {
        value: response.data.nivel,
        label: response.data.nivel,
      });

      setNivel(response.data);
    });
  }, [idCatalogo, idNivel]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        const {
          nivel: nivelClassificacao,
          codigo,
          descricaoBR,
          descricaoEN,
        } = data;

        await api.put(`/catalogo/${idCatalogo}/nivel/${idNivel}`, {
          nivel: nivelClassificacao,
          codigo,
          descricaoBR,
          descricaoEN,
        });

        history.goBack();

        toast.success('Nível alterado com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [idCatalogo, idNivel]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Editar nível" />

      <FormWrapper>
        <Form
          ref={formRef}
          onSubmit={handleConfirmar}
          initialData={nivel}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="idCatalogo"
                name="idCatalogo"
                label="Catálogo"
                width={200}
                disabled
              />
            </Form.Row>
            <Form.Row>
              <AsyncSelect
                id="nivel"
                name="nivel"
                label="Nível"
                cacheOptions
                defaultOptions
                loadOptions={(value, cb) =>
                  getNivelClassificacao({ id: idCatalogo, value }, cb)
                }
              />
              <Input id="codigo" name="codigo" label="Código" />
            </Form.Row>
            <Form.Row>
              <Input
                id="descricaoBR"
                name="descricaoBR"
                label="Descrição (BR)"
              />
              <Input
                id="descricaoEN"
                name="descricaoEN"
                label="Descrição (EN)"
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Salvar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default CatalogoNivelEditar;
