import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import { Table, RowMaster } from '../../../../components/Table';
import TableHeader from '../../../../components/Table/TableHeader';
import ActionMenu from '../../../../components/ActionMenu';
import Pagination from '../../../../components/EditorAI/Pagination';

function EmpresaNavegacao() {
  const location = useLocation();
  const { idEmpresa, idClassificacao } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [navegacaoClassificacao, setNavegacaoClassificacao] = useState(null);
  const [navegacoes, setNavegacoes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNavegacao = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(
        `empresa/${idEmpresa}/navegacao-classificacao/${idClassificacao}/navegacao`,
        {
          params: configFiltro,
        }
      )
      .then((response) => {
        setEmpresa(response.data.empresa);
        setNavegacaoClassificacao(response.data.navegacaoClassificacao);
        setNavegacoes(response.data.navegacao);

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa, idClassificacao]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNavegacao();
  }, [location, currentPage, carregaNavegacao]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/navegacao-classificacao/navegacao/criar`, {
      background: location,
      idEmpresa,
      idClassificacao,
    });
  }, [location, idEmpresa, idClassificacao]);

  const handleEditar = useCallback(
    (idNavegacao) => {
      history.push(`/empresa/navegacao-classificacao/navegacao/editar`, {
        background: location,
        idEmpresa,
        idClassificacao,
        id: idNavegacao,
      });
    },
    [location, idEmpresa, idClassificacao]
  );

  const handleRemover = useCallback(
    async (idNavegacao) => {
      if (!window.confirm('Deseja realmente excluir o item?')) return;

      try {
        await api.delete(
          `empresa/${idEmpresa}/navegacao-classificacao/${idClassificacao}/navegacao/${idNavegacao}`
        );

        const filtered = navegacoes.filter(
          (item) => item.idNavegacao !== idNavegacao
        );
        if (filtered) {
          setNavegacoes(filtered);
        }
      } catch (err) {
        /** mensagem de erro */
        AppError(err);
      }
    },
    [idEmpresa, idClassificacao, navegacoes]
  );

  const handleVisualizar = useCallback(
    (e, idNavegacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idNavegacao);
      }
    },
    [handleEditar]
  );

  return (
    <>
      <TitleBar
        back
        title={`${empresa && empresa.descricao} / ${
          navegacaoClassificacao && navegacaoClassificacao.descricao
        } / Item`}
        isSearchable
        onNew={handleCriar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'codigo', title: 'Código' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {navegacoes.map((navegacao) => (
            <RowMaster
              key={navegacao.idNavegacao}
              onClick={(e) => handleVisualizar(e, navegacao.idNavegacao)}
            >
              <td>{navegacao.codigo}</td>
              <td>{navegacao.descricao}</td>
              <td>
                <ActionMenu
                  data={navegacao}
                  onLineEdit={() => handleEditar(navegacao.idNavegacao)}
                  onLineRemove={() => handleRemover(navegacao.idNavegacao)}
                  onEdit={() => handleEditar(navegacao.idNavegacao)}
                  onRemove={() => handleRemover(navegacao.idNavegacao)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaNavegacao };
