import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { CSV, opExport } from '../../../lib/csv-core';

import { AppError } from '../../../errors/AppError';
import { conCatalogoSituacao, opTipoAcesso } from '../../../lib/const';
import { opCatalogoSituacao } from '../../../lib/inputOption';
import { allowEmpCatalogConversor } from '../../../lib/paramUtils';

import ActionMenu from '../../../components/ActionMenu';
import { RowMaster, Table } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TitleBar from '../../../components/TitleBar';

import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaCatalogo() {
  const { adm: tipoAcesso } = useSelector((state) => state.user.profile || {});

  const location = useLocation();
  const { idEmpresa } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [empresaCatalogo, setEmpresaCatalogo] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [tableHeaders, setTableHeaders] = useState([]);

  const opSituacao = useCallback(
    (situacao) =>
      situacao ? (
        <Table.Row.Kind isAdded>Sim</Table.Row.Kind>
      ) : (
        <Table.Row.Kind isRemoved>Não</Table.Row.Kind>
      ),
    []
  );

  const carregaEmpresaCatalogo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page, ...flt } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      ...flt,
    };

    api
      .get(`/empresa/${idEmpresa}/catalogo`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setEmpresaCatalogo(
          response.data.catalogo.map(({ arquivo, empresas, ...catalogo }) => {
            const { modelos, fabricante } = arquivo;

            return {
              ...catalogo,
              fabricante: fabricante.descricao,
              modelo: modelos.map((m) => m.descricao).join(','),
              relacionadoEmpresa:
                empresas.filter((e) => e.idEmpresa === idEmpresa).length > 0,
            };
          })
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleCriar = useCallback(() => {
    history.push(`/edicao/catalogo-criar`, { background: location });
  }, [location]);

  const handleRelacEmprCatalogo = useCallback(() => {
    history.push('/empresa/catalogo/criar', {
      background: location,
      idEmpresa,
    });
  }, [location, idEmpresa]);

  const handleGerarToken = useCallback(
    (idCatalogo) => {
      history.push(`/empresa/catalogo/token-acesso/criar`, {
        background: location,
        idEmpresa,
        idCatalogo,
      });
    },
    [location, idEmpresa]
  );

  const handleVisualizar = useCallback(
    (e, idCatalogo) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push(`/empresa/catalogo/detalhe`, {
          idEmpresa,
          id: idCatalogo,
        });
      }
    },
    [idEmpresa]
  );

  const handleRemover = useCallback(
    async (idCatalogo) => {
      /** confirmação */
      if (window.confirm('Deseja realmente excluir o catálogo?')) {
        try {
          /** exclui o registro */
          await api.delete(`empresa/${idEmpresa}/catalogo/${idCatalogo}`);

          carregaEmpresaCatalogo();

          toast.success('Catálogo excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaEmpresaCatalogo]
  );

  const handlePublico = useCallback((idCatalogo) => {
    history.push(`/catalogo.partes/${idCatalogo}`);
  }, []);

  const handleEnviarAprovacao = useCallback(
    async (idCatalogo) => {
      try {
        /** atualiza situação registro */
        await api.put(
          `empresa/${idEmpresa}/catalogo/${idCatalogo}/enviar-aprovacao`
        );

        carregaEmpresaCatalogo();

        toast.success('Catálogo enviado para aprovação!');
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa, carregaEmpresaCatalogo]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_CATALOGO, { idEmpresa });
  }, [idEmpresa]);

  useEffect(() => {
    setTableHeaders(() => {
      const headersPadrao = [
        { fieldname: 'nome', title: 'Classe Operacional' },
        { fieldname: 'modelo', title: 'Modelo' },
        { fieldname: 'fabricante', title: 'Fabricante' },
        { fieldname: 'versao', title: 'Versão' },
        // { fieldname: 'reponsavel', title: 'Responsável' },
        { fieldname: 'situacao', title: 'Situação' },
      ];

      const headersPersonalizados =
        empresa && empresa.navegacaoPersonalizada
          ? [
              {
                fieldname: 'relacionadaNavegacao',
                title: 'Relac. Navegação',
              },
            ]
          : [];

      const headersAdm =
        Number(tipoAcesso) === opTipoAcesso.ADMIN_PLATAFORMA
          ? [{ fieldname: 'idCatalogo', title: 'Catálogo' }]
          : [];

      return [
        ...headersAdm,
        ...headersPadrao,
        ...headersPersonalizados,
        { fieldname: null, title: null },
      ];
    });
  }, [empresa, tipoAcesso]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaEmpresaCatalogo();
  }, [location, currentPage, carregaEmpresaCatalogo]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Catálogo` : ''}
        isSearchable
        filterSearchType="EMPRESA_CATALOGO"
        onFileDownload={handleExportar}
      >
        <>
          {Number(tipoAcesso) === opTipoAcesso.ADMIN_PLATAFORMA && (
            <button
              type="button"
              id="btn-new"
              onClick={handleRelacEmprCatalogo}
            >
              Relacionar
            </button>
          )}

          {Number(tipoAcesso) === opTipoAcesso.ADMIN_EMPRESA &&
            allowEmpCatalogConversor() && (
              <button type="button" id="btn-new" onClick={handleCriar}>
                Novo
              </button>
            )}
        </>
      </TitleBar>

      <Table>
        <TableHeader withPadding titles={tableHeaders} cbSort={() => {}} />
        <tbody>
          {empresaCatalogo.map((catalogo) => (
            <RowMaster
              key={catalogo.idCatalogo}
              onClick={(e) => handleVisualizar(e, catalogo.idCatalogo)}
            >
              {Number(tipoAcesso) === opTipoAcesso.ADMIN_PLATAFORMA && (
                <td>{catalogo.idCatalogo}</td>
              )}
              <td>{catalogo.nome}</td>
              <td>{catalogo.modelo}</td>
              <td>{catalogo.fabricante}</td>
              <td>{catalogo.versao}</td>
              {/* <td>{catalogo.empresaResponsavel.descricao}</td> */}
              <td>
                {opCatalogoSituacao[catalogo.situacao]
                  ? opCatalogoSituacao[catalogo.situacao].label
                  : ''}
              </td>
              {empresa && empresa.navegacaoPersonalizada && (
                <td>{opSituacao(catalogo.relacionadaNavegacao)}</td>
              )}
              <td>
                {[
                  conCatalogoSituacao.EM_DESENVOLVIMENTO,
                  conCatalogoSituacao.AGUARDANDO_APROVACAO,
                ].includes(catalogo.situacao) ||
                !catalogo.relacionadoEmpresa ? (
                  <ActionMenu
                    data={catalogo}
                    onView={() => handlePublico(catalogo.idCatalogo)}
                    onLineView={() => handlePublico(catalogo.idCatalogo)}
                    onRemove={() => handleRemover(catalogo.idCatalogo)}
                    onSendToApprove={
                      catalogo.situacao ===
                        conCatalogoSituacao.EM_DESENVOLVIMENTO &&
                      allowEmpCatalogConversor()
                        ? () => handleEnviarAprovacao(catalogo.idCatalogo)
                        : null
                    }
                  />
                ) : (
                  <ActionMenu
                    data={catalogo}
                    onView={() => handlePublico(catalogo.idCatalogo)}
                    onLineView={() => handlePublico(catalogo.idCatalogo)}
                    onRemove={() => handleRemover(catalogo.idCatalogo)}
                    onLineGenerateToken={() =>
                      handleGerarToken(catalogo.idCatalogo)
                    }
                    onGenerateToken={() =>
                      handleGerarToken(catalogo.idCatalogo)
                    }
                  />
                )}
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaCatalogo };
