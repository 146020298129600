import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(220, 38, 38, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  position: absolute;
  z-index: 98;
  box-shadow: 0px 3px 6px #00000026;
`;

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
`;

export const Text = styled.h5`
  color: #f5f5f5;
  font-size: 12px;
  margin: 3px 0;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  background: #ef4444;
  color: #f5f5f5;
  border: none;
  border-radius: 5px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 2px #b91c1c;
`;
